import React from 'react';

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        viewBox="0 0 29 29"
    >
        <path
            fill="#FFF"
            fillRule="nonzero"
            d="M8.395 0C3.77 0 0 3.77 0 8.395v12.21C0 25.23 3.77 29 8.395 29h12.21C25.23 29 29 25.23 29 20.605V8.395C29 3.77 25.23 0 20.605 0H8.395zm0 3.053h12.21a5.297 5.297 0 0 1 5.342 5.342v12.21a5.297 5.297 0 0 1-5.342 5.342H8.395a5.297 5.297 0 0 1-5.342-5.342V8.395a5.297 5.297 0 0 1 5.342-5.342zm13.737 2.29a1.526 1.526 0 1 0 0 3.052 1.526 1.526 0 0 0 0-3.053zM14.5 6.486c-4.407 0-8.013 3.606-8.013 8.013s3.606 8.013 8.013 8.013 8.013-3.606 8.013-8.013-3.606-8.013-8.013-8.013zm0 3.052a4.938 4.938 0 0 1 4.96 4.961 4.938 4.938 0 0 1-4.96 4.96 4.938 4.938 0 0 1-4.96-4.96 4.938 4.938 0 0 1 4.96-4.96z"
        />
    </svg>
);
