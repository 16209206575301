/******************************************************************************\
* File: Plus.jsx
*
* Author: Gigster
*
* Description: SVG icon of plus
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Bookmark.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const BookmarkWithoutFill = ({ className, onClick, color }) => (
    <svg
        className={className}
        viewBox="0 0 24 24"
        onClick={onClick}
        id="markAsFavorite">
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <path
                className={className}
                style={{ stroke: 'none' }}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V17.382L7.55279 14.6056C7.83431 14.4648 8.16569 14.4648 8.44721 14.6056L14 17.382V3C14 2.73478 13.8946 2.48043 13.7071 2.29289C13.5196 2.10536 13.2652 2 13 2H3ZM0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H13C13.7957 0 14.5587 0.31607 15.1213 0.87868C15.6839 1.44129 16 2.20435 16 3V19C16 19.3466 15.8205 19.6684 15.5257 19.8506C15.2309 20.0329 14.8628 20.0494 14.5528 19.8944L8 16.618L1.44721 19.8944C1.13723 20.0494 0.769085 20.0329 0.474269 19.8506C0.179452 19.6684 0 19.3466 0 19V3C0 2.20435 0.31607 1.44129 0.87868 0.87868Z"
                fill={color ? color : 'white'}
            />
        </g>
    </svg>
);

const BookmarkWithFill = ({ className, onClick, active }) => {
    return (
        <svg
            className={className}
            viewBox="0 0 24 24"
            onClick={onClick}
            id="markAsFavorite">
            <g fillRule="evenodd" transform="translate(1 1)">
                <path
                    className={className}
                    style={{ stroke: 'none' }}
                    fillRule="evenodd"
                    clipRule="evenodd"
                    fill={active ? '#FA6600' : ''}
                    d="M0.877703 0.878191C1.44031 0.315582 2.20337 -0.000488281 2.99902 -0.000488281H12.999C13.7947 -0.000488281 14.5577 0.315582 15.1203 0.878191C15.683 1.4408 15.999 2.20386 15.999 2.99951V18.9995C15.999 19.3461 15.8196 19.668 15.5248 19.8502C15.2299 20.0324 14.8618 20.0489 14.5518 19.8939L7.99902 16.6175L1.44624 19.8939C1.13625 20.0489 0.768109 20.0324 0.473292 19.8502C0.178476 19.668 -0.000976562 19.3461 -0.000976562 18.9995V2.99951C-0.000976562 2.20386 0.315094 1.4408 0.877703 0.878191Z"
                />
            </g>
        </svg>
    );
};

class Bookmark extends React.Component {
    render() {
        const {
            className,
            onClick,
            active,
            onToggle,
            inactiveClassName,
            disabled,
            color
        } = this.props;
        const cn = classNames(style.Bookmark, {
            [style['clickable']]: onClick,
            [className]: className,
            [inactiveClassName]: !!disabled
        });
        if (active === false || active === undefined) {
            return (
                <BookmarkWithoutFill
                    className={cn}
                    color={color}
                    onClick={onToggle || onClick}
                />
            );
        }
        return (
            <BookmarkWithFill
                className={cn}
                onClick={onToggle || onClick}
                active={active}
            />
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Bookmark;
