/******************************************************************************\
 * File: Toggle.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';

//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Tooltip from '@/components/common/form/Tooltip';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/Toggle.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Toggle extends React.Component {
    render() {
        const {
            value,
            text,
            onClick,
            icon,
            tooltipText,
            tooltipLeftAlign,
            narrow,
            display,
            noFlex,
            className,
            id,
            ...rest
        } = this.props;

        const cn = classNames(style.toggle, {
            [style['checked']]: !!value
        });
        const containerStyle = classNames(style.container, {
            [style['narrow']]: !!narrow
        });
        return (
            <div
                className={classNames({
                    [style['displayFlex']]: display,
                    [className]: !!className
                })}>
                <span
                    className={classNames(style.text, {
                        [style['narrow']]: !!narrow
                    })}>
                    {text}
                </span>
                {!!icon && !!tooltipText && (
                    <Tooltip
                        value={tooltipText}
                        placement="s"
                        multiline={true}
                        noDelay
                        wide={true}
                        noFlex={noFlex}
                        leftAlign={!!tooltipLeftAlign ? true : false}>
                        <span className={style.icon}>
                            <img src={icon} />
                        </span>
                    </Tooltip>
                )}
                {!!icon && !tooltipText && (
                    <span className={style.icon}>
                        <img src={icon} />
                    </span>
                )}
                {!!icon && <span className={style.paddingRight}></span>}
                <input
                    style={{ display: 'none' }}
                    type="checkbox"
                    defaultChecked={value}
                />
                <span
                    className={cn}
                    onClick={onClick}
                    value={value}
                    id={id ? id : undefined}
                    data-testid={id ? id : undefined}
                />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Toggle;
