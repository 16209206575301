/******************************************************************************\
 * File: Bubble.jsx
 *
 * Author: Gigster
 *
 * Description: clustering number bubble
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Bubble extends React.Component {
    static defaultProps = {
        color: '#F60'
    };

    render() {
        const { count, large, color, ...rest } = this.props;

        const size = large ? 21 : 15;

        return (
            <div
                style={{
                    height: size,
                    width: size,
                    borderRadius: '50%',
                    backgroundColor: color,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    top: large ? -8 : -4,
                    right: large ? -8 : -6,
                    zIndex: 4,
                    ...rest
                }}
            >
                <p
                    style={{
                        fontSize: large ? '10px' : '7px',
                        fontWeight: 'bold',
                        fontFamily: '"Noto Sans", sans-serif',
                        color: '#fff',
                        lineHeight: `${size}px`
                    }}
                >
                    {count}
                </p>
            </div>
        );
    }
}
export default Bubble;
