/******************************************************************************\
 * File: ridesEmptyState.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/
import { Routes } from '@/helpers/routes';
import { getRideCheckboxData } from '@/helpers/checkboxes';
import AppStoreBadges from '@/components/common/AppStoreBadges';
import { translate } from '@/helpers/i18n';
const t = translate('helpers.rideEmptyState');

const emptyState = () => ({
    title: t('THERE’S NOTHING HERE YET.'),
    links: [
        {
            subtitle: t(
                'The filter categories you’ve selected don’t have any saved rides yet. Please select different categories. \n\n If you don’t have any saved rides yet, create your own ride and find it here after saving.'
            ),
            cta: {
                text: t('Create a ride'),
                href: Routes.RIDE_CREATE
            }
        }
    ]
});

export const rideEmptyState = emptyState;

export function emptyStateRides(selected, countByType) {
    const rideCheckboxData = getRideCheckboxData();
    const recommendedTypes = rideCheckboxData
        .filter((rideCheckbox) => rideCheckbox.type === 'Recommended')
        .map((checkbox) => checkbox.value);

    const isRecommended = (s) => recommendedTypes.includes(s);
    const isTrue = (s) => s === true;

    const selectedAndEmpty = (prop, selected, countByType = null) => {
        if (countByType === null)
            return selected.includes(prop) && selected.length === 1;
        const isEmpty =
            countByType[prop] === undefined || countByType[prop] === 0;
        return selected.includes(prop) && isEmpty;
    };

    const noneSelected = selected === [] && countByType.total > 0;
    const isEmpty = selected.length > 0 && countByType.total === 0;

    const sharedSelected = selectedAndEmpty('SHARED', selected);
    const plannedSelected = selectedAndEmpty('PLANNED', selected);
    const recordedSelected = selectedAndEmpty('RECORDED', selected);

    const recommendedEmptyBools = recommendedTypes.map((type) =>
        selectedAndEmpty(type, selected, countByType)
    );
    const recommendedSelected =
        selected.length > 0 &&
        selected.every(isRecommended) &&
        recommendedEmptyBools.find(isTrue);

    if (isEmpty) return emptyState();

    if (noneSelected)
        return {
            title: t('SELECT A RIDE TYPE TO VIEW SAVED RIDES'),
            links: [
                {
                    subtitle: t(
                        'Select a filter category to view those saved rides. If you don’t have any saved rides yet, create your own ride and find it here after saving.'
                    ),
                    cta: {
                        text: t('Create a Ride'),
                        href: Routes.RIDE_CREATE
                    }
                }
            ]
        };

    if (plannedSelected)
        return {
            title: t('YOU DO NOT HAVE ANY PLANNED RIDES SAVED YET.'),
            links: [
                {
                    subtitle: t(
                        'Create your own ride and find it here after saving. Get planning!'
                    ),
                    cta: {
                        text: t('Create a ride'),
                        href: Routes.RIDE_CREATE
                    }
                }
            ]
        };

    if (sharedSelected)
        return {
            title: t('YOU DO NOT HAVE ANY SHARED RIDES SAVED YET.'),
            links: [
                {
                    subtitle: t(
                        'When someone shares a ride with you, you can find it here after starring it. Rides can be shared from the H-D Ride Planner site or mobile app.'
                    ),
                    component: AppStoreBadges
                }
            ]
        };

    if (recordedSelected)
        return {
            title: t('YOU DO NOT HAVE ANY RECORDED RIDES SAVED YET.'),
            links: [
                {
                    subtitle: t(
                        'Record your ride using the H-D mobile app and find it here after saving. Download the app for iOS or Android and get riding.'
                    ),
                    component: AppStoreBadges
                }
            ]
        };

    if (recommendedSelected)
        return {
            title: t('YOU DO NOT HAVE ANY RECOMMENDED RIDES SAVED YET.'),
            links: [
                {
                    subtitle: t(
                        'When you find a recommended ride, you can find it here after starring it. Check out our curated rides and star your favorites.'
                    ),
                    cta: {
                        text: t('H-D Recommended Rides'),
                        href: Routes.MAP
                    }
                }
            ]
        };

    // DEFAULT
    return emptyState();
}
