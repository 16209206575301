/******************************************************************************\
* File: Oval.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Oval extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [style['no-fill']]: true,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 14 14" onClick={onClick}>
                <circle
                    id="Oval-3"
                    stroke="#8F8F8F"
                    strokeWidth="1.6"
                    cx="7"
                    cy="7"
                    r="5.8"
                />
            </svg>
        );
    }
}
export default Oval;
