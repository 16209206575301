/******************************************************************************\
 * File: ShowModal.jsx
 *
 * Author: Gigster
 *
 * Description: Show modal
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import Helmet from 'react-helmet';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#app');

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Close from '@/components/common/icons/Close';
import Link from '@/components/common/header/Link';
import { SHOW_CONTENT, appStoreLinks } from '@/shared/constants';
import { universalLinkScroll } from '@/helpers/mobile';
import history from '@/helpers/history';

//---------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/homeView/HomeModal.scss';

import { translate, getLocale } from '@/helpers/i18n';
const t = translate('homeView.HomeModal');

export const Header = ({ children }) => (
    <header className={style.header}>{children}</header>
);

class ShowModal extends React.Component {
    state = {
        frameUrls: null
    };

    scrolled = false;

    constructor() {
        super();

        this.state = {
            modalIsOpen: true
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    static defaultProps = {
        closeFully: false
    };

    clicked = false;

    redirectToAppStore = (showCollection) => {
        if (
            showCollection === 'app' ||
            showCollection === 'mobileApp' ||
            showCollection === 'dealer'
        )
            return;

        // If /show/ is accessed on a mobile device redirect to App Store
        const { userAgent } = navigator;
        let appURL;
        const [ios, android] = appStoreLinks;
        if (userAgent.match(/android/i)) {
            appURL = android.link;
        } else if (userAgent.match(/iphone/i)) {
            appURL = ios.link;
        }
        if (appURL)
            setTimeout(() => {
                window.location.replace(appURL);
            }, 100);
    };

    render() {
        const {
            className,
            withBackground,
            large,
            items,
            onRequestClose,
            selected,
            isMobile,
            urlProp,
            content,
            ...props
        } = this.props;

        const cnOverlay = classNames(style.overlay, {
            [style['large']]: !!large
        });
        const { pathname, href } = window.location;
        const appleLink = `app-id=1292723595, app-argument=${href}`;
        const showCollection = (urlProp || pathname).replace('/show/', '');
        const modalContent = content || SHOW_CONTENT[showCollection];

        if (isMobile) this.redirectToAppStore(showCollection);

        const {
            image,
            mobileImage,
            title,
            contentDisplayEndDateTime,
            subtitle,
            subtitleStyle,
            imageDivStyle,
            dates,
            description,
            imageStyle,
            noClip,
            shortMarginLeft,
            headerImage,
            fallbackURL,
            minAdjust,
            mobileStyle
        } = modalContent;

        const isExpired = (expireDateTime) =>
            new Date().getTime() > new Date(expireDateTime).getTime();

        if (
            !isExpired(contentDisplayEndDateTime) &&
            fallbackURL &&
            !this.clicked
        ) {
            this.clicked = true;
            setTimeout(() => {
                window.location.replace(fallbackURL);
            }, 100);
        }

        const cn = classNames(style.content, style.showModalContent, {
            [className]: !!className
        });
        const isTablet = 768 < window.innerWidth < 1024;
        const isShort = window.innerHeight < 624;
        // console.log('isShort: ', isShort);
        // console.log('window.innerHeight: ', window.innerHeight);
        const imgStyle = isShort
            ? { ...imageStyle, marginLeft: shortMarginLeft }
            : imageStyle;

        // Universal link hidden
        if (isMobile && !this.scrolled) {
            universalLinkScroll();
            this.scrolled = true;
        }

        if (contentDisplayEndDateTime) {
            if (isExpired(contentDisplayEndDateTime)) {
                onRequestClose();
                history.push('/');
            }
        }
        const getString = (collection, prop, string) => {
            const translation = t(`${collection}.${prop}`);
            if (!translation.includes(`${collection}.${prop}`)) {
                return translation;
            } else {
                return string;
            }
        };

        const getUserLanguageSelection = () => getLocale().match('//(.*)-')[1];
        const lang = getUserLanguageSelection();
        const titleStr = title && getString(showCollection, 'title', title);
        const subtitleStr =
            subtitle && getString(showCollection, 'subtitle', subtitle);
        const datesStr = dates && getString(showCollection, 'dates', dates);
        const descriptionStr =
            description &&
            getString(showCollection, 'description', description);

        // promotional images can now include language specific images
        const imgSrc = isMobile
            ? // if mobile and images have lanague props try specific lang or use english image
              !!(mobileImage || {}).en
                ? mobileImage[lang] || mobileImage.en
                : // if not just use mobile image
                  mobileImage
            : // if images have lanague props try specific lang or use english image
              !!(image || {}).en
              ? image[lang] || image.en
              : // if not just use me image
                image;
        const detailStyle = classNames(
            style.details,
            !!modalContent.title && style.detailsFullWidth
        );
        return (
            <ReactModal
                isOpen={true}
                overlayClassName={cnOverlay}
                className={cn}
                shouldCloseOnOverlayClick={true}
                closeTimeoutMS={0}
                isMobile={isMobile}
                {...props}>
                {pathname && (
                    <Helmet>
                        <meta name="apple-itunes-app" content={appleLink} />
                    </Helmet>
                )}

                <div className={style.ModalContainer}>
                    <div className={style.header}>
                        <span className={style.headerImage}>
                            {isMobile ? (
                                <img
                                    src={headerImage}
                                    className={style.scrollAdjust}
                                />
                            ) : (
                                <img src={headerImage} />
                            )}
                        </span>
                        <Link className={style.close} to="/">
                            <Close onClick={onRequestClose} />
                        </Link>
                    </div>
                    <div
                        className={classNames(
                            style.content,
                            style.showContent
                        )}>
                        {!content && (
                            <div
                                className={classNames(
                                    style.ShowModalImageModalBg,
                                    noClip && style.noClip,
                                    minAdjust && style.minAdjust
                                )}
                                style={imageDivStyle}>
                                {isMobile ? (
                                    <img src={imgSrc} style={mobileStyle} />
                                ) : (
                                    <img
                                        src={imgSrc}
                                        style={imgStyle}
                                        className={style.ri}
                                    />
                                )}
                            </div>
                        )}
                        <div className={detailStyle}>
                            <div className={style.section}>
                                {titleStr && (
                                    <div className={style.SectionShowTitle}>
                                        {titleStr}
                                    </div>
                                )}
                                {subtitleStr && (
                                    <div
                                        className={style.SectionShowSubTitle}
                                        style={subtitleStyle}>
                                        {subtitleStr}
                                    </div>
                                )}
                                {datesStr && (
                                    <div className={style.SectionShowDates}>
                                        {datesStr}
                                    </div>
                                )}
                                {descriptionStr && (
                                    <div
                                        className={style.SectionShowDescription}
                                        dangerouslySetInnerHTML={{
                                            __html: descriptionStr
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={style.footer}>
                        <div className={style.footerText}>
                            {t('GET THE HARLEY-DAVIDSON™ APP')}
                        </div>
                        <div className={style.footerLinks}>
                            {appStoreLinks.map((item, i) => (
                                <a href={item.link} key={i}>
                                    <img src={item.img} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </ReactModal>
        );
    }
}

export default ShowModal;
