/******************************************************************************\
 * File: lists.js
 *
 * Author: Gigster
 *
 * Description: Exported lists used throughout the app
 *
 * Notes:
 \******************************************************************************/
import { languages } from '@/helpers/languages';

export const hours = [
    { text: '00:00', value: 0 },
    { text: '01:00', value: 1 },
    { text: '02:00', value: 2 },
    { text: '03:00', value: 3 },
    { text: '04:00', value: 4 },
    { text: '05:00', value: 5 },
    { text: '06:00', value: 6 },
    { text: '07:00', value: 7 },
    { text: '08:00', value: 8 },
    { text: '09:00', value: 9 },
    { text: '10:00', value: 10 },
    { text: '11:00', value: 11 },
    { text: '12:00', value: 12 },
    { text: '13:00', value: 13 },
    { text: '14:00', value: 14 },
    { text: '15:00', value: 15 },
    { text: '16:00', value: 16 },
    { text: '17:00', value: 17 },
    { text: '18:00', value: 18 },
    { text: '19:00', value: 19 },
    { text: '20:00', value: 20 },
    { text: '21:00', value: 21 },
    { text: '22:00', value: 22 },
    { text: '23:00', value: 23 }
];

export const halfHours = [
    { value: '12:00 am', text: '12:00 am' },
    { value: '12:30 am', text: '12:30 am' },
    { value: '1:00 am', text: '1:00 am' },
    { value: '1:30 am', text: '1:30 am' },
    { value: '2:00 am', text: '2:00 am' },
    { value: '2:30 am', text: '2:30 am' },
    { value: '3:00 am', text: '3:00 am' },
    { value: '3:30 am', text: '3:30 am' },
    { value: '4:00 am', text: '4:00 am' },
    { value: '4:30 am', text: '4:30 am' },
    { value: '5:00 am', text: '5:00 am' },
    { value: '5:30 am', text: '5:30 am' },
    { value: '6:00 am', text: '6:00 am' },
    { value: '6:30 am', text: '6:30 am' },
    { value: '7:00 am', text: '7:00 am' },
    { value: '7:30 am', text: '7:30 am' },
    { value: '8:00 am', text: '8:00 am' },
    { value: '8:30 am', text: '8:30 am' },
    { value: '9:00 am', text: '9:00 am' },
    { value: '9:30 am', text: '9:30 am' },
    { value: '10:00 am', text: '10:00 am' },
    { value: '10:30 am', text: '10:30 am' },
    { value: '11:00 am', text: '11:00 am' },
    { value: '11:30 am', text: '11:30 am' },
    { value: '12:00 pm', text: '12:00 pm' },
    { value: '12:30 pm', text: '12:30 pm' },
    { value: '1:00 pm', text: '1:00 pm' },
    { value: '1:30 pm', text: '1:30 pm' },
    { value: '2:00 pm', text: '2:00 pm' },
    { value: '2:30 pm', text: '2:30 pm' },
    { value: '3:00 pm', text: '3:00 pm' },
    { value: '3:30 pm', text: '3:30 pm' },
    { value: '4:00 pm', text: '4:00 pm' },
    { value: '4:30 pm', text: '4:30 pm' },
    { value: '5:00 pm', text: '5:00 pm' },
    { value: '5:30 pm', text: '5:30 pm' },
    { value: '6:00 pm', text: '6:00 pm' },
    { value: '6:30 pm', text: '6:30 pm' },
    { value: '7:00 pm', text: '7:00 pm' },
    { value: '7:30 pm', text: '7:30 pm' },
    { value: '8:00 pm', text: '8:00 pm' },
    { value: '8:30 pm', text: '8:30 pm' },
    { value: '9:00 pm', text: '9:00 pm' },
    { value: '9:30 pm', text: '9:30 pm' },
    { value: '10:00 pm', text: '10:00 pm' },
    { value: '10:30 pm', text: '10:30 pm' },
    { value: '11:00 pm', text: '11:00 pm' },
    { value: '11:30 pm', text: '11:30 pm' }
];

const isDev =
    process.env.NODE_ENV === 'development' ||
    process.env.ENVIRONMENT === 'maxwell' ||
    process.env.ENVIRONMENT === 'dev' ||
    process.env.ENVIRONMENT === 'phoenix';

// These translations are only used on acceptance or local dev:
const devLocales = isDev
    ? [
          { locale: 'en-US', name: 'English — Expanded', active: true },
          { locale: 'en-PigLatin', name: 'English — PigLatin', active: true },
          { locale: 'en-Id', name: 'English — Unique Ids', active: true }
      ]
    : [];
const devFlaggedLocales = isDev
    ? languages.filter((l) => !!l.activeInDev && !l.active)
    : [];

// This is the list used by the select element in Footer
export const locales = languages
    .filter((l) => !!l.active)
    .concat(devLocales)
    .concat(devFlaggedLocales);

export const supportedLocales = locales.map((l) => l.locale);
