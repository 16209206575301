import React from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import IntlMessageFormat from 'intl-messageformat';
import { parsePhoneNumber } from 'libphonenumber-js';
import axios from 'axios';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { parseCookie } from '@/helpers/dom';
import { createLogger } from '@/helpers/debug';
import { supportedLocales, locales } from '@/helpers/lists';
import { languages } from '@/helpers/languages';
import startOfWeek from '@/helpers/i18n-startOfWeek';
import features from '@/helpers/features';

const log = createLogger('i18n', true);

import english from '../../public/locales/en/en-US.json';
export const defaultLocale = 'United States – English//en-US';

const getLocalStorageUnits = () => {
    return window.localStorage.userPrefs &&
        JSON.parse(window.localStorage.userPrefs).distance
        ? JSON.parse(window.localStorage.userPrefs).distance
        : 'imperial';
};
const initialState = {
    // current language dictionary:
    language: english,
    // current language prefix-country:
    locale: defaultLocale,
    // computed from locale (prefix):
    prefix: 'en',
    // computed from locale (country):
    country: 'US',
    // imperial or metric, computed from country:
    units: getLocalStorageUnits(),
    PrefUnits: getLocalStorageUnits(),
    // whether to use 24 hour (24) or 12 hour clock (12):
    clock: '12',
    // text direction
    dir: 'ltr',
    // IntlMessageFormat cache:
    cache: {}
};
// extend dayjs to be able to parse 09:00 and not break the app
dayjs.extend(customParseFormat);
// current language context
const context = {
    ...initialState
};

const shouldUseDefault = (l) => !l || !l.includes('//');

/**
 * Given a string like xx-XX or xx_XX (prefix-country), returns an object of
 * the prefix (lowercase) and country (uppercase).
 */
const parseLocale = (l) => {
    const [name, lang] = (shouldUseDefault(l) ? defaultLocale : l).split('//');
    const parts = (lang || name).split(/-/);
    return {
        prefix: parts[0].toLowerCase(),
        country: (parts[1] || parts[0]).toUpperCase(),
        name
    };
};

/**
 * Main function to modify the current language context.
 *
 * @param locale (string) - the new language locale
 * @param language (object) - the static translation object
 */
const setContext = (locale, language) => {
    const { prefix, country } = parseLocale(locale);
    localStorage.setItem('locale', locale);

    // computed values:
    const units = country === 'US' ? 'imperial' : 'metric';
    const clock = country === 'US' ? '12' : '24';
    const dir = prefix === 'ar' ? 'rtl' : 'ltr';
    const startOfWeekDayName = startOfWeek[country.toUpperCase()];
    syncUserPrefs(country);

    // update context:
    Object.assign(context, {
        locale,
        prefix,
        country,
        units,
        language,
        clock,
        dir,
        startOfWeekDayName,
        cache: {}
    });

    setDayjsLocale(prefix);

    log('setContext', context);
};

/** Sync localStorage.userPrefs to current language unless user has customized prefs */
const syncUserPrefs = (country) => {
    if (!features.localStorage) return;
    const key = 'userPrefs';
    const isUS = country === 'US' || country.includes('-US');
    let obj = JSON.parse(localStorage.getItem(key)) || {};
    if (!obj.userSet) obj.distance = isUS ? 'imperial' : 'metric';
    if (!obj.userSet) obj.temperature = isUS ? 'fahrenheit' : 'celcius';
    localStorage.setItem(key, JSON.stringify(obj));
};

/** Returns the current context obj. Should favor using the functions below. */
const getContext = () => Object.freeze({ ...context });

/**
 * Given the Accept-Language string returns the first matching locale.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept-Language}
 */
const parseAcceptLanguage = (str) =>
    str
        .split(',')
        .map((e) => e.trim())
        .map((e) => {
            const parts = e.split(';q=');
            const q = parts.length > 1 ? parseFloat(parts[1], 10) : 1;
            return [parts[0].trim(), q];
        })
        .sort((a, b) => b[1] - a[1])
        .map((arr) => arr[0])
        .filter((e) => e.length);

/**
 * Set of public functions to get specific keys from the context:
 */
export const getLang = () => context.prefix;
export const getCountry = () => context.country;
export const isUS = () => context.country === 'US';
export const getLocale = () => context.locale;
export const getStartOfWeekDayName = () => context.startOfWeekDayName;
export const getStartOfWeekAdjustment = () => {
    const startOfWeekDayName = getStartOfWeekDayName();
    switch (startOfWeekDayName) {
        case 'monday': {
            return 0;
        }
        case 'sunday': {
            return 1;
        }
        case 'saturday': {
            return 2;
        }
        case 'friday': {
            return 3;
        }
    }
};
export const getUnits = () => {
    return context.units;
};

export const setPrefUnits = (value) => {
    context.PrefUnits = value;
};

export const getPrefUnits = () => {
    return context.PrefUnits;
};

const userPrefs = () => {
    if (!features.localStorage) return { temperature: null, distance: null };
    return JSON.parse(
        window.localStorage.userPrefs ||
            '{"temperature": null,"distance": null}'
    );
};

export const getTempUnits = () => userPrefs().temperature || context.units;

export const formatTemp = (temp) =>
    getTempUnits() === 'celcius' ? convertFtoC(temp) : temp;

export const convertFtoC = (temp) => ((temp - 32) * 5) / 9;

export const getMessage = (path, str) => {
    const lang = context.language;
    if (lang && lang[path] && lang[path][str]) return lang[path][str];
    const {
        location: { host }
    } = window;
    const isDev =
        host.includes('devmaps') ||
        host.includes('qamaps') ||
        host.includes('local');
    if (isDev) return `Missing String ${path} ${str}`;
    return str;
};
export const getDirection = () => context.dir;

/**
 * Sets the current locale from public/locales.
 * Thin wrapper that fetchs a JSON file then calls setContext.
 * Returns the new (or unchanged) context, false if the JSON file couldn't be
 * found.
 */
export const setLocale = (l) => {
    if (!l || !l.includes('//')) l = defaultLocale;
    if (context.locale === l) return Promise.resolve(getContext());
    // Use altLocale files where translation for country isn't available
    const { prefix, country, name } = parseLocale(l);
    const language = locales.filter((locale) => locale.name === name)[0] || {};
    let filePath = `${prefix}/${prefix}-${country}.json`;
    if (language.altLocale) {
        const parts = language.altLocale.split(/[-_]/);
        const prefix = parts[0].toLowerCase();
        filePath = `${prefix}/${language.altLocale}.json`;
    }

    return axios(`/locales/${filePath}`)
        .then((res) => res.data)
        .then((json) => {
            setContext(l, json);
            return getContext();
        })
        .catch(() => false);
};

/**
 * Used by the App on boot to restore the user's locale.
 * First looks at the localStorage.locale key, then the document.cookie, and
 * finally the browser's Accept-Language cookie (which is added to the html page
 * from the server).
 */
export const getCurrentLocale = (
    defaultLocale = 'United States – English//en-US'
) => {
    // TODO: get user's language from gigya
    // return 'en-US';
    if (!features.localStorage) return defaultLocale;
    if (localStorage.locale) {
        syncUserPrefs(localStorage.locale);
        return localStorage.locale;
    }
    if (!localStorage.locale) {
        localStorage.locale = defaultLocale;
        return localStorage.locale;
    }

    // read language from cookie
    const { language, country } = parseCookie(document.cookie);

    if (language && country) {
        const languageCountry = `${language.toLowerCase()}-${country.toUpperCase()}`;
        if (supportedLocales.includes(languageCountry)) {
            const selectedLanguage = languages.find(
                (lang) => languageCountry === lang.locale
            );
            const { name, locale } = selectedLanguage;
            log('getCurrentLocale found cookie:', locale);
            syncUserPrefs(country);
            setLocale(`${name}//${locale}`);
            return locale;
        }
    }

    // use Accept-Language or navigator.language
    const html = document.getElementsByTagName('html')[0];

    // grab Accept-Language cookie from html document
    if (html.getAttribute) {
        const langs = parseAcceptLanguage(html.getAttribute('data-lang'));
        log('getCurrentLocale using html data-lang tag:', langs);

        const lang = langs.find((lang) => supportedLocales.includes(lang));
        log('getCurrentLocale supported locale:', lang);
        syncUserPrefs(lang || defaultLocale);
        return !lang || lang === '*' ? defaultLocale : lang;
    }

    syncUserPrefs(defaultLocale);
    return navigator.language || defaultLocale;
};

/**
 * Helper functions for the translate method:
 * Adapted from: @see {@link https://github.com/yahoo/react-intl/blob/master/src/components/message.js}
 */
const uid = Math.floor(Math.random() * 0x10000000000).toString(16);

const generateToken = (() => {
    let counter = 0;
    return () => `ELEMENT-${uid}-${(counter += 1)}`;
})();

const splitWithMatchData = (str, regex) => {
    let lastMatchIndex = 0;
    let match;
    let parts = [];

    while ((match = regex.exec(str)) !== null) {
        const value = str.substring(lastMatchIndex, match.index);
        if (value.length) parts.push({ value, match: null });

        parts.push({ value: match[0], match });
        lastMatchIndex = regex.lastIndex;
    }

    const value = str.substring(lastMatchIndex, str.length);
    if (value.length) parts.push({ value, match: null });

    return parts;
};

const HTML_TAG_RE = /<([^>]+)>(.*)<\/([^>]+)>/gi;
const reactElementsFromTags = (str, params) => {
    const parts = splitWithMatchData(str, HTML_TAG_RE);

    return parts
        .map(({ match, value }, i) => {
            if (match) {
                const [text, tag, children] = match;
                return params[tag] ? params[tag](children) : text;
            }

            return <span key={i}>{value}</span>;
        })
        .map((el, i) => React.cloneElement(el, { key: i }));
};

const formatReactMessage = (messageFormat, params) => {
    const tokenizedValues = {};
    const elements = {};
    const tokenDelimiter = `@____@`;

    Object.keys(params).forEach((name) => {
        let value = params[name];

        if (React.isValidElement(value)) {
            let token = generateToken();
            tokenizedValues[name] = tokenDelimiter + token + tokenDelimiter;
            elements[token] = value;
        } else {
            tokenizedValues[name] = value;
        }
    });

    const formattedMessage = messageFormat.format(tokenizedValues);

    return formattedMessage
        .split(tokenDelimiter)
        .filter((part) => !!part)
        .map((part) => elements[part] || reactElementsFromTags(part, params)[0])
        .filter((item) => !!item.type);
};

/**
 * Translates a string given the prefix path and the interpolation params.
 *
 * Given the path (by convention defined as folder.ComponentName), returns a
 * function that translates strings. In the simplest use case, these strings
 * act as key-value replacements using the context.language object.
 *
 * @param str (string) - the translation key
 * @param params (object?) - optional parameters
 * @return (string|JSXElement) - if any value of a parameter is a function or
 * JSXElement, then a JSXElement will be returned. Otherwise returns a string.
 *
 * Note that this function should be called wrapped in another function so that
 * translations will be re-called when the language context changes.
 *
 * E.g. at the top level scope, do this:
 *   const getString = () => t('My String');
 * instead of:
 *   const strings = t('My String');
 * this also works:
 *   const doThings = () => {
 *     // ...stuff
 *     return t('My String');
 *  };
 */
export const translate =
    (path) =>
    (str, params = undefined) => {
        const message = getMessage(path, str);

        if (!message) {
            console.warn(
                `Missing translation for translate('${path}')("${str}")`
            );
            return;
        }

        // cache formatted messages
        if (params) {
            const { ...args } = params;
            const { cache } = context;
            const key = `${path}.${str}`;

            if (!cache[key]) {
                const localeArr = context.locale.split('//');
                const locale = localeArr[localeArr.length - 1];
                cache[key] = new IntlMessageFormat(message, locale, {});
            }

            if (
                Object.values(args).some(
                    (param) =>
                        React.isValidElement(param) ||
                        typeof param === 'function'
                )
            ) {
                const arr = Object.values(args);
                const emptyParams = arr.some(
                    (item) => item === undefined || item === null
                );
                if (!emptyParams) return formatReactMessage(cache[key], args);
                return;
            }

            return cache[key].format(args);
        }

        return message;
    };

/**
 * Helper function to translate properties on static objects.
 * Should try to avoid using this and prefer the translate function.
 */
export const proxyProp = (key, fn) => (obj) => {
    const str = obj[key];

    Object.defineProperty(obj, key, {
        get() {
            return fn(str);
        }
    });

    return obj;
};

/**
 * API functions to internationalize certain types of data. These functions
 * are context-aware.
 *
 * These functions all return strings so they can be used in utility functions
 * as well as React components.
 */

export const formatTime = (hhMM, isEventTime = false) => {
    const dayjsToUse = isEventTime
        ? dayjs(hhMM)
        : context.clock === '24'
          ? dayjs(hhMM, 'HH:mm')
          : dayjs(hhMM, 'h:mm');

    const formattedTime =
        context.clock === '24'
            ? dayjsToUse.format('HH:mm')
            : dayjsToUse.format('h:mm a');

    return formattedTime;
};

export const formatNumber = (num) => {
    return num.toLocaleString();
};

export const formatPhone = (number) => {
    try {
        return parsePhoneNumber(number, 'US').format(
            context.country === 'US' ? 'NATIONAL' : 'INTERNATIONAL'
        );
    } catch (e) {
        return number;
    }
};

export const formatPhoneUrl = (number) => {
    try {
        return parsePhoneNumber(number, 'US').getURI();
    } catch (e) {
        return 'javascript:void(0)';
    }
};

/**
 * built-in Multiple Locale Support strings
 */
export const formatDate = (date) => {
    return dayjs(date).format('LL');
};

const KM_PER_MILE = 1.609344;

const precisionRound = (number, precision = 1) => {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
};

export const formatSpeed = (mph) => {
    const units = userPrefs().distance || context.units;
    if (units === 'metric') {
        const kmp = mph > 0 ? mph * KM_PER_MILE : 0;
        return `${formatNumber(precisionRound(kmp))} kmph`;
    }

    return `${formatNumber(precisionRound(mph > 0 ? mph : 0))} mph`;
};

export const formatDistance = (mi) => {
    let precision = 1;
    if (mi >= 20) precision = 0;
    const units = userPrefs().distance || context.units;
    if (units === 'metric') {
        return `${formatNumber(
            precisionRound(mi * KM_PER_MILE, precision)
        )} km`;
    }

    return `${formatNumber(precisionRound(mi, precision))} mi`;
};

export const formatHDTimeRange = (hour) => {
    if (!hour.open) {
        return null;
    }
    const openDate = hour.open;
    if (!!openDate && !!hour.close) {
        const closeDate = hour.close;
        const time = `${formatTime(openDate, true)} - ${formatTime(
            closeDate,
            true
        )}`;
        return time;
    }
    return formatTime(openDate, true);
};

async function setDayjsLocale(prefix) {
    // current languages extracted from helpers/languages.js
    // "es","en","nl","fr","pt","cs","el","da","de","zh","it","ja","hu","no","ru","pl","fi","sv","th","vi","ar"
    switch (prefix) {
        case 'es':
            await import(`dayjs/locale/es`);
            break;
        case 'en':
            await import(`dayjs/locale/en`);
            break;
        case 'nl':
            await import(`dayjs/locale/nl`);
            break;
        case 'fr':
            await import(`dayjs/locale/fr`);
            break;
        case 'pt':
            await import(`dayjs/locale/pt`);
            break;
        case 'cs':
            await import(`dayjs/locale/cs`);
            break;
        case 'el':
            await import(`dayjs/locale/el`);
            break;
        case 'da':
            await import(`dayjs/locale/da`);
            break;
        case 'de':
            await import(`dayjs/locale/de`);
            break;
        case 'zh':
            await import(`dayjs/locale/zh`);
            break;
        case 'it':
            await import(`dayjs/locale/it`);
            break;
        case 'ja':
            await import(`dayjs/locale/ja`);
            break;
        case 'hu':
            await import(`dayjs/locale/hu`);
            break;
        case 'no':
            // Norwegian is not available, trying to import will fail.
            dayjs.locale('en');
            break;
        case 'ru':
            await import(`dayjs/locale/ru`);
            break;
        case 'pl':
            await import(`dayjs/locale/pl`);
            break;
        case 'fi':
            await import(`dayjs/locale/fi`);
            break;
        case 'sv':
            await import(`dayjs/locale/sv`);
            break;
        case 'th':
            await import(`dayjs/locale/th`);
            break;
        case 'vi':
            await import(`dayjs/locale/vi`);
            break;
        case 'ar':
            await import(`dayjs/locale/ar`);
            break;

        default:
            break;
    }
    // after import locale is done, set dayjs.locale()
    dayjs.locale(prefix);
}
