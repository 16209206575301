import dayjs from 'dayjs';
import { clamp, normalizeLatLng } from '@/helpers/functions';
import { pointFromProgress, progressFromPoint } from '@/helpers/map';
import { MapZoom } from '@/helpers/constants';
import { getRide } from '@/helpers/api';
import { getForecast as getWeatherForecast } from '@/helpers/api';
import { fetchPOI } from '@/store/poi';
import {
    centerRide,
    update as updateMap,
    centerZoom,
    centerPointZoom,
    clampZoom
} from '@/store/map';
import { safePush } from '@/store/routing';
import { selectRideCallback } from './index';
import { Routes, generatePath } from '@/helpers/routes';
import { rideLoadError, getCurrentRide } from './index';
import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import history from '@/helpers/history';
//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
const log = createLogger('Map Reducer preview.js', true);

/* */
// Types
export const UPDATE = 'rides/preview/UPDATE';
const RESET = 'rides/preview/RESET';

// radius in meters
const DEFAULT_SCRUB_ZOOM = 14;
const DEFAULT_ZOOM_INC = 2;

/* */
// Action Creators

const resetPreview = (data) => ({ type: RESET, data });
export const routing_unpreviewRide = resetPreview;

/** Sets an arbitrary field to value. */
export const update = (field, value) => ({
    type: UPDATE,
    data: { field, value }
});

/** Navigates to the ride preview page. */
export const previewRide = (rideOrRideId) => (dispatch) => {
    dispatch(update('currentRoutePoint', 0));
    dispatch(resetPreview());
    // navigate to preview view if we're not already there
    const id = rideOrRideId.shortId || rideOrRideId.id || rideOrRideId;
    dispatch(safePush(generatePath(Routes.RIDE_PREVIEW, { id })));
};

/** Sets the ride sidebar state.
    In the past there was a dropdown, that's why the const openDropdown.
    We need to refactor this in a future to make it more readable for future changes.
*/
export const openDropdown = (value) => update('dropdownOpen', value);
export const resetCurrentRoutePoint = () => update('currentRoutePoint', 0);

/**
 * Called when navigating to the preview page (so routing can manage the
 * url state).
 */
export const routing_previewRide = (rideId) => (dispatch, getState) => {
    getRide(rideId, getState)
        .then((ride) => {
            dispatch(previewRideCallback(ride));
        })
        .catch((err) => {
            console.error(err);
            dispatch(rideLoadError());
        });
};

export const previewRideCallback = (ride) => (dispatch, getState) => {
    const { currentRouteProgress } = getState().rides.preview;
    const { location } = history;
    // allow scrubber to restore from url
    const search = new URLSearchParams(location.search);
    const scrub = search.get('scrub');
    if (!scrub) {
        dispatch(resetPreview());
    }

    dispatch(update('rideId', ride.id));
    dispatch(selectRideCallback(ride)).then((rideWithData) => {
        const { distances, points } = rideWithData;
        if (!distances || !distances.length) return;
        const point = pointFromProgress(
            distances,
            points,
            currentRouteProgress
        );
        dispatch(update('currentRoutePoint', point));
        dispatch(update('currentRouteProgress', currentRouteProgress));

        dispatch(update('forceZoom', true));
    });
    if (ride) {
        const point = (ride.waypoints || ride.locationHistory)[0];
        const currentRoutePoint = normalizeLatLng(point);
        dispatch(update('currentRoutePoint', currentRoutePoint));
        if (currentRoutePoint) dispatch(fetchPOI(currentRoutePoint));
    }
};

export const recalculateRide = () => (dispatch, getState) => {
    dispatch(selectRideCallback(getCurrentRide(getState())));
};

export const resetScrub = () => (dispatch, getState) => {
    const state = getState();
    const { points, distances } = getCurrentRide(state);
    const { currentRouteProgress, initialMap } = state.rides.preview;

    if (currentRouteProgress === 0) {
        dispatch(centerRide(state.rides.selected));
    } else {
        const routePoint = pointFromProgress(
            distances,
            points,
            currentRouteProgress
        );

        const scrubZoom = getScrubZoom(initialMap);
        dispatch(centerZoom(routePoint, scrubZoom));
    }
};

// TODO: remove after testing
export const traverseRoute = (deltaY) => (dispatch, getState) => {
    const {
        rides: {
            preview: { initialScroll, currentRoutePoint }
        }
    } = getState();

    // wait until ride is loaded
    if (!currentRoutePoint) return;

    // capture user's initial scroll direction
    if (initialScroll === 0) {
        // wait until we've scrolled a significant amount
        if (Math.abs(deltaY) > 4) {
            dispatch(update('initialScroll', deltaY));
        }
    } else {
        dispatch(handleTraverseRoute(deltaY * Math.sign(initialScroll)));
    }
};

// TODO: remove after testing
/** Called when user scrolls in route preview, moves the dot along the path. */
export const handleTraverseRoute = (delta) => (dispatch, getState) => {
    const state = getState();
    const {
        rides: {
            preview: { currentRouteProgress }
        },
        map
    } = state;

    const selected = getCurrentRide(state);

    if (!selected) {
        log('No ride selected, cannot handleTraverseRoute!');
        return;
    }

    const { distances } = selected;

    if (!distances) {
        log('Distances not set yet.', selected);
        return;
    }

    const zoomFraction = map.zoom / (MapZoom.MAX - MapZoom.MIN);
    const SCALE = 1 / 10000 / zoomFraction;

    const maxDistance = distances[distances.length - 1];

    delta *= SCALE;

    const percent = (currentRouteProgress * maxDistance + delta) / maxDistance;

    setCurrentRouteProgress(percent)(dispatch, getState);
};

export const setRouteProgressToPoint =
    (point, isLast = false, minZoom = 0) =>
    (dispatch, getState) => {
        const state = getState();
        const ride = getCurrentRide(state);
        const { points, distances } = ride;

        const percent = isLast
            ? 100
            : progressFromPoint(distances, points, point);
        dispatch(setCurrentRouteProgress(percent));

        if (percent !== 0) {
            if (minZoom) {
                dispatch(clampZoom(minZoom));
            } else {
                const { initialMap } = state.rides.preview;
                dispatch(clampZoom(MapZoom.MIN, getScrubZoom(initialMap)));
            }
        }
    };

const getScrubZoom = (map) =>
    map ? map.zoom + DEFAULT_ZOOM_INC : DEFAULT_SCRUB_ZOOM;

export const setCurrentRouteProgress = (percent) => (dispatch, getState) => {
    const state = getState();
    const ride = getCurrentRide(state);
    const { points, distances } = ride;

    const { rides } = state;
    const { currentRouteProgress, forceZoom } = rides.preview;

    const newRouteProgress = clamp(percent, 0, 1);

    dispatch(update('currentRouteProgress', newRouteProgress));

    if (!points || !points.length || !distances || !distances.length) {
        log('Ride not loaded, setting currentRouteProgress...');
        return;
    }

    let { initialMap } = rides.preview;
    if (!initialMap) {
        initialMap = dispatch(centerRide(ride));
        dispatch(update('initialMap', initialMap));
    }

    const scrubZoom = Math.floor(getScrubZoom(initialMap));

    const newRoutePoint = pointFromProgress(
        distances,
        points,
        newRouteProgress
    );
    if (forceZoom) {
        dispatch(update('forceZoom', false));
        dispatch(updateMap('zoom', scrubZoom));
    }

    if (currentRouteProgress !== 0 && newRouteProgress === 0) {
        localStorage.setItem('center', JSON.stringify(newRoutePoint));
        dispatch(centerPointZoom(newRoutePoint));
    } else if (newRouteProgress !== 0) {
        localStorage.setItem('center', JSON.stringify(newRoutePoint));
        dispatch(centerPointZoom(newRoutePoint));
    }
    dispatch(update('currentRoutePoint', newRoutePoint));
};

const setForecast =
    (lat, lng, datetime, offset = 0) =>
    (dispatch) => {
        const { date, time } = datetime || {};

        const unixDatetime = (
            typeof time === 'undefined' ? dayjs(date) : dayjs(date).hour(time)
        )
            .add(offset, 'minutes')
            .unix();

        return getWeatherForecast(lat, lng, unixDatetime).then((weather) => {
            dispatch(update('forecast', weather));
        });
    };

export const updateForecast = () => (dispatch, getState) => {
    const { currentRoutePoint, datetime } = getState().rides.preview;
    dispatch(
        setForecast(currentRoutePoint.lat, currentRoutePoint.lng, datetime)
    );
};

/* */
// Reducer

export const initialState = {
    // keep track of ride being previewed
    rideId: null,

    // Current location within the ride preview
    currentRoutePoint: null,

    // Current data for the ride preview
    currentRouteData: null,

    // Position in the selected route as percent
    currentRouteProgress: 0,

    // Captures the user's natural scroll instinct
    initialScroll: 0,

    // Captures the initial map center and zoom
    initialMap: null,

    // Force app to zoom in after initial scrub
    forceZoom: false,

    // Controls if the ride sidebar is open
    dropdownOpen: true,

    // Weather information based on the currentRoutePoint
    forecast: null,

    // Date and time for traffic and weather data
    datetime: {
        date: undefined,
        time: undefined
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE: {
            const { field, value } = action.data;
            return {
                ...state,
                [field]: value
            };
        }

        case RESET: {
            return initialState;
        }

        case AUTH_INVALIDATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
