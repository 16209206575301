import { status } from '@/helpers/async';
import { getBookmarks, bookmarkRide, deleteBookmark } from '@/helpers/api';
import { auth, INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import { $private } from '@/helpers/test';

/* */
// Types
export const FETCH = 'bookmarks/FETCH';
export const SET_BOOKMARKED = 'bookmarks/SET_BOOKMARKED';
export const FILTER_UNBOOKMARKED = 'bookmarks/FILTER_UNBOOKMARKED';
export const CLEAR_BOOKMARKS = 'bookmarks/CLEAR';
export const REMOVE_BOOKMARK = 'bookmarks/REMOVE_BOOKMARK';

/* */
// Helpers
const lookupHashForBookmark = (bookmark) => ({
    rideId: bookmark.rideId,
    id: bookmark.id
});

const lookupHashForBookmarks = (rideBookmarks) =>
    rideBookmarks.reduce((memo, bookmark) => {
        memo[bookmark.rideId] = lookupHashForBookmark(bookmark);
        return memo;
    }, {});

const ridesSort = (a, b) => a.name > b.name;

/* */
// Selectors
export const isBookmarked = (rideId, state) => !!state.bookmarks.hash[rideId];

export const bookmarkedRides = (state) =>
    state.bookmarks.rides.filter((ride) => state.bookmarks.hash[ride.id]);

/* */
// Action Creators
const setRideBookmarked = (rideId, rideBookmark, ride) => {
    return {
        type: SET_BOOKMARKED,
        data: { rideId, rideBookmark, ride }
    };
};
$private(module, setRideBookmarked);

const didFetchBookmarks = (bookmarks) => ({
    type: FETCH,
    data: bookmarks,
    status: status.SUCCESS
});
$private(module, didFetchBookmarks);

export const fetchBookmarks = () => (dispatch) => {
    dispatch({ type: FETCH, status: status.REQUEST });

    getBookmarks()
        .then((resp) => resp.data.rideBookmarks)
        .then((bookmarks) => dispatch(didFetchBookmarks(bookmarks)))
        .catch((err) => {
            console.error(err);
            dispatch({ type: FETCH, status: status.FAILURE });
        });
};

export const setBookmark = (rideId, value, ride) => (dispatch) => {
    const cb = setBookmarkCallback(rideId, value, ride);
    return dispatch(auth(cb));
};

const setBookmarkCallback = (rideId, value, ride) => (dispatch, getState) => {
    const prevValue = getState().bookmarks.hash[rideId];

    if (value)
        bookmarkRide(rideId).then((bookmark) => {
            dispatch(
                setRideBookmarked(rideId, bookmark.data.rideBookmark, ride)
            );
        });

    if ((prevValue || {}).id) {
        return deleteBookmark(prevValue.id)
            .then((resp) =>
                dispatch(
                    setRideBookmarked(rideId, resp.data.rideBookmark, ride)
                )
            )
            .catch((e) => {
                if (process.env.NODE_ENV !== 'test') console.log(e);
                // we were wrong, undo it
                if (e.codeName === 'NO_SUCH_RIDE_BOOKMARK') {
                    dispatch(setRideBookmarked(rideId, false, ride));
                } else {
                    dispatch(setRideBookmarked(rideId, prevValue, ride));
                }
            });
    }
};

export const removeBookmark = (id) => ({ type: REMOVE_BOOKMARK, data: { id } });

export const filterUnbookmarked = () => ({ type: FILTER_UNBOOKMARKED });

export const clearBookmarks = () => ({ type: CLEAR_BOOKMARKS });

/* */
// Reducer
const initialState = {
    /** Easy lookup hash to check if a ride is bookmarked or not. */
    hash: {},
    /**
     * Array of bookmarked rides. Rides only get removed from this on
     * FILTER_UNBOOKMARKED to allow the user to re-bookmark an un-bookmarked
     * ride.
     */
    rides: []
};

export default (state = initialState, action) => {
    //console.log('action.type: ', action.type);
    switch (action.type) {
        case SET_BOOKMARKED: {
            const { rideId, rideBookmark } = action.data;
            const addRide = (rideBookmark || {}).ride;
            /*if(ride) {
                console.log('ride in case: ', ride);
            }
            state.rides.selected = ride;*/
            return {
                ...state,
                rides: addRide
                    ? state.rides
                          .filter((e) => e.id !== rideId)
                          .concat([addRide])
                          .sort(ridesSort)
                    : state.rides,
                hash: {
                    ...state.hash,
                    [rideId]:
                        rideBookmark && lookupHashForBookmark(rideBookmark)
                }
            };
        }

        case FETCH: {
            if (action.status === status.SUCCESS) {
                const bookmarks = action.data;
                return {
                    hash: lookupHashForBookmarks(action.data),
                    rides: bookmarks.map((bookmark) => bookmark.ride),
                    loading: false
                };
            }

            return { ...state, loading: action.status === status.REQUEST };
        }

        case FILTER_UNBOOKMARKED: {
            return {
                ...state,
                rides: bookmarkedRides({ bookmarks: state }).sort(ridesSort)
            };
        }

        case REMOVE_BOOKMARK: {
            const { id } = action.data;
            return {
                ...state,
                hash: {
                    ...state.hash,
                    [id]: false
                },
                rides: state.rides.filter((ride) => ride.id !== id)
            };
        }

        case AUTH_INVALIDATE:
        case CLEAR_BOOKMARKS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
