/******************************************************************************\
 * File: AvoidToggle.jsx
 *
 * Author: Gigster
 *
 * Description: Map footer
 *
 * Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/OptionsToggle.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import GearIcon from '@/components/common/icons/Gear';
import Tooltip from '@/components/common/Tooltip';
import Checkbox, { Label } from '@/components/common/form/Checkbox';
import Link from '@/components/common/header/Link';
import AvoidOptionsToggle from '@/components/mapListView/AvoidOptionsToggle';

import { translate } from '@/helpers/i18n';
const t = translate('mapListView.OptionsToggle');

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
// import style from '@/style/mapListView/CreateFooter.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
// const t = translate('mapListView.CreateFooter');

//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import { toggleFeature, getFeatureCheckboxes } from '@/store/edit_ride';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class AvoidToggle extends React.Component {
    getAvoidOptions = () => {
        const { featureCheckboxes, toggleFeature } = this.props;
        return featureCheckboxes(toggleFeature);
    };

    render() {
        const { showOptions, setShowOptions, isCreate } = this.props;
        const options = this.getAvoidOptions();
        const getSelected = () =>
            options.filter((i) => i.checked).map((i) => i.label);
        return (
            <AvoidOptionsToggle
                toggleCheckboxModal={() => true}
                displayTitle="Avoid"
                options={options}
                showTooltip={showOptions}
                setShowTooltip={setShowOptions}
                selected={getSelected()}
                isCreate={isCreate}
            />
        );
    }
}

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => ({
    featureCheckboxes: getFeatureCheckboxes(state)
});
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    toggleFeature: (feature) => dispatch(toggleFeature(feature))
});
//------------------------------------------------------------------------------
// Connect ---------------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(AvoidToggle);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
