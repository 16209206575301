/******************************************************************************\
 * File: ShareDropdown.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/header/ShareDropdown.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import ShareIcon from '@/components/common/icons/Share';
import SocialShare from '@/components/common/SocialShare';
import Tooltip from '@/components/common/form/Tooltip';
import { analyticsShareRideEvent } from '@/helpers/analytics';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { clickAway } from '@/helpers/hoc';
import { translate } from '@/helpers/i18n';
const t = translate('header.ShareDropdown');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ShareDropdown extends React.Component {
    state = {
        showDropdown: false
    };

    toggleDropdown = (event) => {
        this.setState({ showDropdown: !this.state.showDropdown });
        if (!!event) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    onClickAway = () => {
        const { showDropdown } = this.state;

        if (showDropdown) {
            this.setState({ showDropdown: false });
        }
    };

    render() {
        const {
            iconClassName,
            activeClassName,
            inactiveClassName,
            url,
            active,
            id
        } = this.props;

        const { showDropdown } = this.state;

        return (
            <Tooltip
                value={showDropdown ? '' : t('Share')}
                placement="s"
                noDelay>
                <div className={style.ShareDropdown}>
                    <ShareIcon
                        onClick={() => {
                            analyticsShareRideEvent();
                            !!active && this.toggleDropdown();
                        }}
                        className={classNames(iconClassName, {
                            [activeClassName]: showDropdown,
                            [inactiveClassName]: !active
                        })}
                        id={id ? id : undefined}
                    />

                    <div
                        className={classNames(style.dropdown, {
                            [style['open']]: showDropdown
                        })}>
                        <SocialShare iconClassName={iconClassName} url={url} />
                    </div>
                </div>
            </Tooltip>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default clickAway(ShareDropdown);
