/******************************************************************************\
 * File: i18n-startOfWeek.js
 *
 * Author: Gigster
 *
 * Description: Start of week per locale for internationalization
 *
 * Notes:
 \******************************************************************************/

export default {
    BD: 'friday',
    MV: 'friday',
    AE: 'saturday',
    AF: 'saturday',
    BH: 'saturday',
    DJ: 'saturday',
    DZ: 'saturday',
    EG: 'saturday',
    IQ: 'saturday',
    IR: 'saturday',
    JO: 'saturday',
    KW: 'saturday',
    LY: 'saturday',
    MA: 'saturday',
    OM: 'saturday',
    QA: 'saturday',
    SD: 'saturday',
    SY: 'saturday',
    AG: 'sunday',
    AR: 'sunday',
    AS: 'sunday',
    AU: 'sunday',
    BR: 'sunday',
    BS: 'sunday',
    BT: 'sunday',
    BW: 'sunday',
    BZ: 'sunday',
    CA: 'sunday',
    CN: 'sunday',
    CO: 'sunday',
    DM: 'sunday',
    DO: 'sunday',
    ET: 'sunday',
    GT: 'sunday',
    GU: 'sunday',
    HK: 'sunday',
    HN: 'sunday',
    ID: 'sunday',
    IE: 'sunday',
    IL: 'sunday',
    IN: 'sunday',
    JM: 'sunday',
    JP: 'sunday',
    KE: 'sunday',
    KH: 'sunday',
    KR: 'sunday',
    LA: 'sunday',
    MH: 'sunday',
    MM: 'sunday',
    MO: 'sunday',
    MT: 'sunday',
    MX: 'sunday',
    MZ: 'sunday',
    NI: 'sunday',
    NP: 'sunday',
    NZ: 'sunday',
    PA: 'sunday',
    PE: 'sunday',
    PH: 'sunday',
    PK: 'sunday',
    PR: 'sunday',
    PY: 'sunday',
    SA: 'sunday',
    SG: 'sunday',
    SV: 'sunday',
    TH: 'sunday',
    TN: 'sunday',
    TT: 'sunday',
    TW: 'sunday',
    UM: 'sunday',
    US: 'sunday',
    VE: 'sunday',
    VI: 'sunday',
    WS: 'sunday',
    YE: 'sunday',
    ZA: 'sunday',
    ZW: 'sunday',
    '001': 'monday',
    AD: 'monday',
    AI: 'monday',
    AL: 'monday',
    AM: 'monday',
    AN: 'monday',
    AT: 'monday',
    AX: 'monday',
    AZ: 'monday',
    BA: 'monday',
    BE: 'monday',
    BG: 'monday',
    BM: 'monday',
    BN: 'monday',
    BY: 'monday',
    CH: 'monday',
    CL: 'monday',
    CM: 'monday',
    CR: 'monday',
    CY: 'monday',
    CZ: 'monday',
    DE: 'monday',
    DK: 'monday',
    EC: 'monday',
    EE: 'monday',
    ES: 'monday',
    FI: 'monday',
    FJ: 'monday',
    FO: 'monday',
    FR: 'monday',
    GB: 'monday',
    GE: 'monday',
    GF: 'monday',
    GP: 'monday',
    GR: 'monday',
    HR: 'monday',
    HU: 'monday',
    IS: 'monday',
    IT: 'monday',
    KG: 'monday',
    KZ: 'monday',
    LB: 'monday',
    LI: 'monday',
    LK: 'monday',
    LT: 'monday',
    LU: 'monday',
    LV: 'monday',
    MC: 'monday',
    MD: 'monday',
    ME: 'monday',
    MK: 'monday',
    MN: 'monday',
    MQ: 'monday',
    MY: 'monday',
    NL: 'monday',
    NO: 'monday',
    PL: 'monday',
    PT: 'monday',
    RE: 'monday',
    RO: 'monday',
    RS: 'monday',
    RU: 'monday',
    SE: 'monday',
    SI: 'monday',
    SK: 'monday',
    SM: 'monday',
    TJ: 'monday',
    TM: 'monday',
    TR: 'monday',
    UA: 'monday',
    UY: 'monday',
    UZ: 'monday',
    VA: 'monday',
    VN: 'monday',
    XK: 'monday'
};
