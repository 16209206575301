/******************************************************************************\
* File: Print.jsx
*
* Author: Gigster
*
* Description: SVG icon of Print
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Print extends React.Component {
    render() {
        const { className, onClick, id } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg
                className={cn}
                width={18}
                height={18}
                viewBox="0 0 24 24"
                onClick={onClick}
                id={id ? id : undefined}
                data-testid={id ? id : undefined}>
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g>
                        <path d="M0 0H24V24H0z"></path>
                        <path
                            fill="#eee"
                            d="M17.47 2c.484 0 .895.35 1.02.862l.024.12.009.14-.001 3.268h1.658c.843 0 1.557.619 1.763 1.478l.03.154.02.159.007.162v7.991c0 .952-.64 1.759-1.519 1.925l-.148.021-.153.008-1.658-.001v2.59c0 .465-.266.872-.68 1.044l-.116.04-.123.03-.132.009H6.529c-.483 0-.894-.35-1.02-.862l-.023-.12-.009-.14v-2.591H3.82c-.843 0-1.557-.618-1.763-1.477l-.03-.154-.02-.159L2 16.334v-7.99c0-.952.64-1.759 1.519-1.925l.148-.022.153-.007h1.657V3.123c0-.465.267-.872.68-1.044l.117-.04.123-.03L6.529 2h10.942zm-1.052 12.575H7.581v5.179h8.837v-5.179zm3.477-5.94H4.104v7.406h1.373v-2.588c0-.507.318-.946.797-1.085l.123-.028.132-.01h10.942c.483 0 .894.349 1.02.861l.023.122.009.14-.001 2.588h1.373V8.635zm-3.477-4.39H7.581V6.39h8.837V4.245z"></path>
                    </g>
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Print;
