import {
    metersToMiles,
    secondsToMinutes,
    normalizeLatLng
} from '@/helpers/functions';
import { WaypointType, WaypointCategory } from '@/helpers/constants';
import { mapsInitialState, DID_CALCULATE_ROUTE } from './ride';

/** Prepares the ride to be sent to the api. */
export const rideForApi = (ride) => ({
    ...ride,
    ...mapsInitialState,
    boundingBox: null,
    points: ride.points,
    distances: ride.distances,
    length: ride.length,
    waypoints: ride.waypoints.map((p) => ({
        ...p,
        // TODO: Remove this when backend supports HOTEL category:
        ...(p.category === WaypointCategory.HOTEL
            ? { type: WaypointType.LOCATION, category: null }
            : {}),
        lon: p.lng,
        lng: null
    }))
});

/** Maps the result back to the store. */
export const rideForStore = (ride) =>
    ride
        ? {
              ...ride,
              waypoints: waypointsForStore((ride || {}).waypoints)
          }
        : null;

// backend stores every curated ride waypoint as type WAYPOINT, but we
// need the start and end waypoints to be type LOCATION
export const waypointsForStore = (waypoints) =>
    (waypoints || []).map((waypoint, i) => {
        const { id, ...rest } = waypoint;

        return {
            ...rest,
            id: id || i,
            ...normalizeLatLng(waypoint),
            type:
                (i === 0 || i === waypoints.length - 1) &&
                waypoint.type === WaypointType.WAYPOINT
                    ? WaypointType.LOCATION
                    : waypoint.type
        };
    });

export const transformRideResponse = (ride, waypoints) => {
    const { summary, travelTime, distance, ...rest } = ride;
    return {
        ...rest,
        duration: secondsToMinutes(travelTime),
        length: metersToMiles(distance),
        summary,
        waypoints
    };
};

/** Callback after recieving route shape. */
export const didCalculateRoute = (response, waypoints) => {
    const routes =
        (response &&
            response.routes.map((route) =>
                transformRideResponse(route, waypoints)
            )) ||
        [];

    return {
        type: DID_CALCULATE_ROUTE,
        data: {
            ride: routes[0],
            routes: response.routes
        }
    };
};
