/******************************************************************************\
* File: logging.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

const gitName = process.env.GIT_USER.name;

const gitUserMatches = (str) => (gitName || '').toLowerCase().indexOf(str) >= 0;

console.josh = function () {
    if (!gitUserMatches('josh')) return;
    console.log.apply(null, arguments);
};

console.esco = function () {
    if (!gitUserMatches('esco')) return;
    console.log.apply(null, arguments);
};

console.nick = function () {
    if (!gitUserMatches('nick')) return;
    console.log.apply(this, arguments);
};
