import shortid from 'shortid';
import { get, set, remove } from '@/helpers/persistor';
import { calculateRide } from '@/helpers/here';
import { createRide, centerRide, mapsInitialState } from './ride';
import { transformRideResponse } from './helpers';
import { restoreRideState } from './index';

/* */
// Types
const SET_ID = 'edit_ride/cache/SET_ID';
const REMOVE_ID = 'edit_ride/cache/REMOVE_ID';

/* */
// Helpers
const stripRide = (ride) => {
    return Object.keys(ride).reduce((memo, key) => {
        if (key in mapsInitialState) return memo;
        if (key === 'waypoints') {
            memo[key] = ride[key].map((waypoint) => {
                const { ...rest } = waypoint;
                return rest;
            });
        } else {
            memo[key] = ride[key];
        }
        return memo;
    }, {});
};

const packRide = (state) => {
    const {
        present: { ride }
    } = state;
    return stripRide(ride);
};

const isFirst = (past) => past.length == 1;

const setDirty = (past) => {
    past[0].meta.dirty = true;
    return past;
};

const pack = (state) => {
    const {
        present: { ride },
        past,
        future
    } = state;
    const newPast = isFirst(past) ? setDirty(past) : past;
    return {
        present: stripRide(ride),
        past: newPast.map((past) => stripRide(past.ride)),
        future: future.map((future) => stripRide(future.ride))
    };
};

const inflateRide = (ride) =>
    ride.waypoints.length >= 2
        ? calculateRide(ride).then((data) => ({
              ...transformRideResponse(data),
              ...ride
          }))
        : Promise.resolve(ride);

const unpack = (cached, cacheId) => (dispatch) => {
    // cached ride with no undo history
    if ((cached.waypoints || {}).length) {
        dispatch(createRide(cached.waypoints, cached));
        return;
    }

    // cached ride with undo history
    inflateRide(cached.present).then((present) => {
        dispatch(restoreRideState({ ...cached, present }, cacheId));
        dispatch(centerRide());
    });
};

const PREFIX = 'edit_ride';
const rideCache = {
    get: (id) => get(`${PREFIX}.${id}`, {}),
    set: (id, ride) => set(`${PREFIX}.${id}`, ride),
    remove: (id) => remove(`${PREFIX}.${id}`)
};

/* */
// Action Creators
export const setCacheId = (id) => {
    return { type: SET_ID, data: { id } };
};

const removeCacheId = (id) => ({ type: REMOVE_ID, data: { id } });

/** Caches the current ride being edited. */
export const cacheRide = () => (dispatch, getState) => {
    const { edit_ride } = getState();
    let { id } = edit_ride.present.cache;
    // create an id to cache by if one doesn't already exist
    if (!id) {
        id = shortid.generate().toString();
        dispatch(setCacheId(id));
    }

    // cache the ride as long as the ride isn't already saved
    if (!edit_ride.id) {
        const data = pack(edit_ride);
        try {
            rideCache.remove(id);
            rideCache.set(id, data);
        } catch (e) {
            // fallback to just caching the current ride
            console.log('failed caching ride with history, trying again.');
            try {
                rideCache.set(id, packRide(edit_ride));
            } catch (e) {
                console.error(e, 'when attempting to cache ride', data);
            }
        }
    }
};

/** Removes the cached ride from the cache. */
export const clearRide = () => (dispatch, getState) => {
    const { id } = getState().edit_ride.present.cache;
    rideCache.remove(id);
    dispatch(removeCacheId(id));
};

/** Restores a ride by it's cache id. */
export const restoreCachedRide = (cacheId) => (dispatch) => {
    const cached = rideCache.get(cacheId);
    if (cached) {
        dispatch(setCacheId(cacheId));
        dispatch(unpack(cached, cacheId));
    }
};

/* */
// Reducer
const initialState = { id: '' };

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ID: {
            const { id } = action.data;
            return { ...state, id };
        }

        case REMOVE_ID: {
            const { id } = action.data;
            return state.id === id ? { ...state, id: '' } : state;
        }

        default: {
            return state;
        }
    }
};
