/**
 * Redux async helpers.
 */

// Constants
const REQUEST = 'request';
const SUCCESS = 'succces';
const FAILURE = 'failure';

/** Request status can only be one of these: */
export const status = { REQUEST, SUCCESS, FAILURE };

/**
 * Wraps the promise returning a Thunk Action which will dispatch the
 * request status lifecycle actions.
 */
export const request =
    (type, promise, meta = {}, onSuccess = () => {}, onFailure = () => {}) =>
    (dispatch) => {
        dispatch({ type, status: REQUEST, meta });

        return promise
            .then((response) => {
                if (response === undefined) return;
                dispatch({
                    type,
                    status: SUCCESS,
                    response,
                    data: response.data || response,
                    meta
                });
                return onSuccess(response.data || response);
            })
            .catch((error) => {
                dispatch({ type, status: FAILURE, error, meta });
                onFailure(error);
                console.error(error);
                throw error;
            });
    };

/**
 * Counterpart to request wrapper. Sets up a reducer that will have loading, data
 * and error keys.
 */
const initialState = { loading: false, data: null, error: null };
export const reducer =
    (type) =>
    (state = initialState, action) => ({
        loading:
            action.type === type ? action.status === REQUEST : state.loading,
        data: action.type === type ? action.data || state.data : state.data,
        error: action.type === type ? action.error || null : state.error
    });

/** Attaches loading state information to an individual item in data. */
export const deleteLoadingState = () => (state, action) => {
    const { id } = action.meta;

    switch (action.status) {
        case status.REQUEST:
        case status.FAILURE: {
            const loading = action.status === status.REQUEST;

            return {
                ...state,
                data: (state.data || []).map((item) =>
                    item.id === id ? { ...item, loading } : item
                )
            };
        }

        case status.SUCCESS: {
            return {
                ...state,
                data: (state.data || []).filter((item) => item.id !== id)
            };
        }
    }
};
