/******************************************************************************\
 * File: NullState.jsx
 *
 * Author: Gigster
 *
 * Description: Null state to show when cards list or grid is empty
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/cards/NullState.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Link from '@/components/common/header/Link';
//------------------------------------------------------------------------------
// React Function --------------------------------------------------------------
const NullState = (props) => {
    const { className, title, subtitle, cta, links, component } = props;

    const cn = classNames(style.NullState, {
        [className]: className
    });

    const Component = component;

    return (
        <div className={cn}>
            {title && <p className={style.title}>{title}</p>}
            {!!links &&
                links.length > 0 &&
                links.map((link, index) => (
                    <div key={index}>
                        <p className={style.subtitle}>{link.subtitle}</p>
                        {link.cta && (
                            <Link
                                className={style.cta}
                                exact
                                text={link.cta.text}
                                to={link.cta.href}
                            />
                        )}
                        {!!link.component && <link.component width="114px" />}
                    </div>
                ))}
            {!links &&
                !!subtitle && <p className={style.subtitle}>{subtitle}</p> &&
                !!cta && <Link className={style.cta} {...cta} /> &&
                !!component && <Component width="114px" />}
        </div>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default NullState;
