import { status } from '@/helpers/async';
import { getBadges, getSharedBadge } from '@/helpers/api';

import createStore from '@/helpers/items';

import { $private } from '@/helpers/test';

const didFetchItems = (items) => {
    return {
        type: 'badges/FETCH',
        data: items,

        status: status.SUCCESS
    };
};

export const fetchMany = () => (dispatch) => {
    dispatch({ type: FETCH, status: status.REQUEST });
    return getBadges()
        .then((response) => response.data.badges)
        .then((items) => dispatch(didFetchItems(items)));
};

export const fetchShared = (args) => (dispatch) => {
    dispatch({ type: FETCH, status: status.REQUEST });
    return getSharedBadge(args)
        .then((response) => {
            let sharedAchievement = response.data.badge;
            sharedAchievement.nickName = response.data.challenger.nickName;
            return [sharedAchievement];
        })
        .then((items) => dispatch(didFetchItems(items)));
};

$private(module, didFetchItems);

const { FETCH: FETCH, initialState } = createStore(
    'badges',
    fetchMany,
    fetchShared
);

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH: {
            if (action.status === status.SUCCESS && !!action.data) {
                const items = action.data;
                return {
                    data: items,
                    selected: {},
                    loading: false
                };
            }
            return { ...state, loading: action.status === status.REQUEST };
        }

        default: {
            return state;
        }
    }
};
