/******************************************************************************\
 * File: Map.jsx
 *
 * Author: Gigster
 *
 * Description: Here map component
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { matchPath } from 'react-router-dom';
import Bowser from 'bowser';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/map/here/Map.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import UiControls from '@/components/common/map/here/UiControls';
import { setCurrentRouteProgress, getCurrentRide } from '@/store/rides';
import { fetchItems as fetchRDE } from '@/store/map';
import { fetchPOI } from '@/store/poi';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    normalizeEvent,
    hereBoundingBox,
    defaultBounds,
    boundsZoomLevel,
    boundsCenter
} from '@/helpers/map';
import { now, testSupportsPassive, normalizeBounds } from '@/helpers/functions';
import here, { langToHereLng, langToHereLocale } from '@/helpers/here';
import { collections } from '@/helpers/collections';
import { MapZoom } from '@/helpers/constants';
import { Routes } from '@/helpers/routes';
import { getPolylineLength } from '@/helpers/map';
import { parseCookie } from '@/helpers/dom';
import { analyticsWithData } from '@/helpers/analytics';

import { translate, getLocale } from '@/helpers/i18n';
const t = translate('common.map');

import { connect } from 'react-redux';
import { insertRidePointNearest } from '@/store/edit_ride';
import { WaypointType } from '@/helpers/constants';

//------------------------------------------------------------------------------
// Context ---------------------------------------------------------------------
import MapContext from '@/contexts/MapContext';
//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
import { getLang, getUnits, getPrefUnits } from '@/helpers/i18n';
const log = createLogger('HERE Map', false);

const loadTime = now();
let useRetina = false;
let didCheckPerf = false;
let currentHereBounds = {};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Map extends React.Component {
    static defaultProps = {
        inline: false,
        showUiControls: false,
        wheelZoomEnabled: false,
        map: { schemeOpts: {} }
    };

    state = {
        map: undefined
    };

    rasterTileLabelsLayer;
    trafficLayer;

    createTrafficLayer = (isCustomMapStyle = false) => {
        // All this logic is needed because this.trafficLayer was created as Singleton
        // to avoid a lot of memory consumption. This pattern ensures that a class
        // has only 1 instance and that instance is globally accessible

        // We need to validate first if the trafficLayer already exists
        if (!!this.trafficLayer) {
            // Validate if the current map is HD custom map
            if (isCustomMapStyle) {
                // we need to know if it is an HD Custom Map traffic layer
                // if so, we just return this.trafficLayer
                if (!!this.trafficLayer.tileSize) {
                    return this.trafficLayer;
                } else {
                    // if the existing traffic layer was not an HD custom map one, we need to create it
                    const trafficStyle = new H.map.render.harp.Style(
                        H.getStylePath() + '/harp/oslo/normal.day.json'
                    );
                    const trafficService = here.getTrafficVectorTileService();
                    this.trafficLayer = trafficService.createLayer(
                        trafficStyle,
                        {
                            engineType: H.Map.EngineType.HARP
                        }
                    );
                }
            } else {
                // if the existing traffic layer was from a Normal/Satellite maps
                // we just return this.trafficLayer
                if (!!this.trafficLayer.vector) {
                    return this.trafficLayer;
                } else {
                    // if not, we need to create the traffic layer for the Normal/Satellite maps
                    this.trafficLayer = here.createDefaultLayers({
                        engineType: H.Map.EngineType.HARP
                    });
                }
            }
        } else {
            // if this.trafficLayer is undefined
            // validate if the current map is HD custom map
            if (isCustomMapStyle) {
                // then create the HD custom map traffic layer
                const trafficStyle = new H.map.render.harp.Style(
                    H.getStylePath() + '/harp/oslo/normal.day.json'
                );
                const trafficService = here.getTrafficVectorTileService();
                this.trafficLayer = trafficService.createLayer(trafficStyle, {
                    engineType: H.Map.EngineType.HARP
                });
            } else {
                // if not, create the traffic layer for the Normal/Satellite maps
                this.trafficLayer = here.createDefaultLayers({
                    engineType: H.Map.EngineType.HARP
                });
            }
        }
        return this.trafficLayer;
    };

    componentDidMount() {
        if (!didCheckPerf) {
            // performance.now() is the time it took to get here,
            // used as a rough approximation for the speed of the network
            // upgrade to a retina map if connection seems fast enough
            const FAST_LOAD_MS = 3000;
            const perf = now();
            log('perf', perf, 'loaded in', loadTime, 'diff', perf - loadTime);
            if (perf <= FAST_LOAD_MS && window.devicePixelRatio > 1) {
                log(
                    'Fast network connection, upgrading to',
                    window.devicePixelRatio
                );

                useRetina = true;
            }

            didCheckPerf = true;
        }

        const { map } = this.state;
        const {
            map: { scheme, schemeOpts }
        } = this.props;

        // Pending to check how to add traffic layer with the new Map Raster tile
        if (map) {
            const defaultLayers = this.createTrafficLayer(
                this.isCustomMapStyle(scheme)
            );
            if ((schemeOpts || {}).traffic) {
                if (this.isCustomMapStyle(scheme)) {
                    map.addLayer(defaultLayers, 1);
                } else {
                    map.addLayer(defaultLayers.vector.traffic.map, 1);
                }
            } else {
                if (this.isCustomMapStyle(scheme)) {
                    map.removeLayer(defaultLayers);
                } else {
                    map.removeLayer(defaultLayers.vector.traffic.map);
                }
            }
        }

        this.createMap();
    }

    // Test via a getter in the options object to see if the passive property is accessed
    supportsPassive = false;

    zoomControl = null;

    // Convert lat lng to float / HereMaps and params return lat/lng as strings
    newCenter = (currCenter = null) => {
        let newCenter = currCenter;
        const convertToFloat = (num) => parseFloat(parseFloat(num).toFixed(8));
        // if currCenter is passed use it
        // if not use center from state
        if (!newCenter) {
            const { map } = this.state;
            newCenter = map && map.getCenter();
        }
        if (newCenter) {
            // convert center lat lng to float
            return {
                lat: convertToFloat(newCenter.lat),
                lng: convertToFloat(newCenter.lng)
            };
        }
    };

    // Include HD Custom Map Style globally, except for Japan
    allowCustomHDStyle = () => !getLocale().includes('Japan');
    isCustomMapStyle = (style) => style === 'custom';

    // Add Interactive Map Layer
    addIml = (map, platform, collection) => {
        const { catalogHrn, layerId } = collection;
        const service = platform.getIMLService();
        const imlProvider = new H.service.iml.Provider(
            service,
            catalogHrn,
            layerId
        );
        const onTap = (e) => {
            if ((e || {}).target && e.target.getData()) {
                const { properties } = e.target.getData();
                // FYI - The object property names are prefixed 'properties.xxx'
                // Do not try to destructure this, it will not work
                const id = properties['properties.shortId'];
                // current collections (catalogs) all point to prod
                const url = `https://maps.harley-davidson.com/map/rides/${id}/preview`;
                window.open(url, '_blank');
            }
        };

        // This adds the tap event listener to all displayed routes
        imlProvider.addEventListener('tap', onTap);
        const style = imlProvider.getStyle();
        const styleConfig = style.extractConfig(['iml']);
        // Merge the style configuration back
        style.mergeConfig(styleConfig);
        map.addLayer(new H.map.layer.TileLayer(imlProvider));
        // Documentation for this style property is unavailable
        // The Style Editor is likely the best way to determine the property chain for styles
        style.setProperty('layers.iml.lines.draw.lines.color', '#FF6600');
        style.setInteractive(['iml.lines'], true);
    };

    isWebGLSupported = () => {
        const canvas = document.createElement('canvas');
        let gl;

        try {
            gl =
                canvas.getContext('webgl', {
                    powerPreference: 'high-performance'
                }) ||
                canvas.getContext('experimental-webgl', {
                    powerPreference: 'high-performance'
                });
        } catch (e) {
            console.log('error', e);
        }

        let debugInfo;
        let renderer;
        if (gl) {
            debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
            vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
            renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
            document.cookie = `WebGLSupported=true`;
            return !!renderer;
        }
        document.cookie = `WebGLSupported=false`;
        return false;
    };

    createMap = () => {
        const {
            map: { center, zoom, scheme, myLocation },
            location,
            onClick,
            update
        } = this.props;

        this.supportsPassive = testSupportsPassive();
        const locale = langToHereLocale(getLang());

        // Expanded English is being used as a flag to test the here maps custom map styles
        let isHDCustomMapStyle =
            this.allowCustomHDStyle() && this.isCustomMapStyle(scheme);
        const engineType = H.Map.EngineType['HARP'];

        const mapVectorHarp = () => {
            const { hostname } = window.location;
            const json = '/hd-custom-style.json';
            // allow harp in local by using express server at port 8082 (does not run on https)
            const styleURL =
                hostname === 'local.harley-davidson.com'
                    ? `http://localhost:8082${json}`
                    : json;
            const style = new H.map.render.harp.Style(styleURL);
            return here.getOMVService().createLayer(style, {
                engineType,
                lg: langToHereLng(getLang())
            });
        };

        const isWebGLSupported = () => {
            const canvas = document.createElement('canvas');
            let gl;

            try {
                gl =
                    canvas.getContext('webgl', {
                        powerPreference: 'high-performance'
                    }) ||
                    canvas.getContext('experimental-webgl', {
                        powerPreference: 'high-performance'
                    });
            } catch (e) {
                console.log('error', e);
            }

            let debugInfo;
            let vendor;
            let renderer;
            if (gl) {
                debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
                vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
                renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
                document.cookie = `WebGLSupported=true`;
                return !!renderer;
            }
            document.cookie = `WebGLSupported=false`;
            return false;
        };

        // Set defaults
        let initialCenter = center;
        let initialZoom = Math.round(zoom);
        this.prevCenter = initialCenter;
        this.prevZoom = initialZoom;
        this.zoomBounds = null;

        // Check URL for initial center and zoom
        if (location && location.search) {
            const search = new URLSearchParams(location.search);
            if (search.get('pos')) {
                const [lat, lng, zoomSearch] = search
                    .get('pos')
                    .replace('z', '')
                    .split(',');

                // Update map center
                initialCenter =
                    JSON.parse(localStorage.getItem('center')) ??
                    this.newCenter({ lat: lat, lng: lng });

                // Prevent Fractional Zoom
                initialZoom = Math.round(Number.parseInt(zoomSearch));
            }
        } else if (!!myLocation) {
            update('center')(myLocation);
        }

        let mapSettings = {
            center: initialCenter,
            engineType: H.Map.EngineType.HARP,
            zoom: initialZoom,
            padding: { top: 50, left: 50, bottom: 50, right: 50 }
        };

        if (isHDCustomMapStyle) {
            mapSettings['engineType'] = engineType;
        }
        if (!isWebGLSupported()) {
            mapSettings['engineType'] =
                H.map.render.RenderEngine.EngineType.P2D;
            isHDCustomMapStyle = false;
        }

        // STARTS Label logic
        // initialize an instance of the Raster Tile Service providing the query parameters
        const rasterTileLabelsService = here.getRasterTileService({
            resource: 'label',
            queryParams: {
                lang: 'en',
                ppi: 100,
                features: 'pois:disabled'
            }
        });
        // Create an instance of the Raster Tile provider passing the raster tile service object above
        const rasterTileLabelsProvider = new H.service.rasterTile.Provider(
            rasterTileLabelsService,
            {
                engineType: H.Map.EngineType.HARP
            }
        );
        // Create a layer fetching tiles from the raster tile provider above
        this.rasterTileLabelsLayer = new H.map.layer.TileLayer(
            rasterTileLabelsProvider
        );
        // END Label logic

        // STARTS Background logic
        // initialize an instance of the Raster Tile Service providing the query parameters
        const rasterTileBackground = here.getRasterTileService({
            resource: 'background',
            queryParams: {
                lang: 'en',
                ppi: 400,
                style: scheme.includes('satellite')
                    ? 'explore.satellite.day'
                    : 'explore.day',
                features: 'pois:disabled'
            }
        });
        // Create an instance of the Raster Tile provider passing the raster tile service object above
        const rasterTileBackgroundProvider = new H.service.rasterTile.Provider(
            rasterTileBackground,
            {
                engineType: H.Map.EngineType.HARP
            }
        );
        // Create a layer fetching tiles from the raster tile provider above
        const rasterTileBackgroundLayer = new H.map.layer.TileLayer(
            rasterTileBackgroundProvider
        )
            .setMin(MapZoom.MIN)
            .setMax(MapZoom.MAX);
        // END Background logic

        // set the as raster tile background layer without labels as base map
        const map = new H.Map(
            this.mapRef,
            isHDCustomMapStyle ? mapVectorHarp() : rasterTileBackgroundLayer,
            mapSettings
        );

        // Raster Label+POIs layer at index 2 of the DataModel stack
        if (!isHDCustomMapStyle) map.addLayer(this.rasterTileLabelsLayer, 2);

        this.units = getUnits();
        this.lang = getLang();
        this.prevBounds = defaultBounds();
        this.prevScheme = scheme;

        // Add map behavior (zoom, pan)
        const behavior = new H.mapevents.Behavior(
            new H.mapevents.MapEvents(map)
        );
        // H.mapevents.Behavior.Feature
        behavior.disable(
            H.mapevents.Behavior.Feature.FRACTIONAL_ZOOM |
                H.mapevents.Behavior.Feature.HEADING |
                H.mapevents.Behavior.Feature.TILT
        );

        // Create the default UI components
        // Add map ui
        const ui = new H.ui.UI(map, {
            zoom: {
                fractionalZoom: false
            },
            scalebar: true,
            locale,
            unitSystem:
                getUnits() === 'metric'
                    ? H.ui.UnitSystem.METRIC
                    : H.ui.UnitSystem.IMPERIAL
        });

        this.zoomControl = ui
            .getControl('zoom')
            .setAlignment(H.ui.LayoutAlignment.RIGHT_BOTTOM)
            .addClass(style.zoomControl);

        /* Added for Interactive Map Layer Testing see caveats on HDM-1300 */
        if (location && location.search) {
            const search = new URLSearchParams(location.search);

            // check for add interactive map layer param
            if (!!search && !!here) {
                const collectionTag = search.get('addIml');
                const collection = collections.find(
                    (c) => c.tag === collectionTag
                );
                if (collection) {
                    const { catalogHrn, layerId } = collection;
                    if (catalogHrn && layerId)
                        this.addIml(map, here, collection);
                }
            }
        }

        this.skipInitialViewChange = true;
        this.isCreateInitialViewChange = true;
        this.initialViewChangeHandled = false;
        this.skipInitialPrintView = true;

        // Resize map when window is resized
        window.addEventListener(
            'resize',
            this.resizeMap,
            this.supportsPassive ? { passive: true } : false
        );

        map.addEventListener(
            'mapviewchange',
            this.handleMapChange,
            this.supportsPassive ? { passive: true } : false
        );

        map.addEventListener(
            'mapviewchangeend',
            this.handleViewChange,
            this.supportsPassive ? { passive: true } : false
        );

        map.addEventListener(
            'contextmenu',
            this.handleContextMenu,
            this.supportsPassive ? { passive: true } : false
        );

        onClick &&
            map.addEventListener(
                'pointerdown',
                onClick,
                this.supportsPassive ? { passive: true } : false
            );

        // Store everything in state
        this.setState({
            map,
            behavior,
            ui
        });
        update('zoom')(initialZoom);
        update('center')(initialCenter);
        return map;
    };

    destroyMap = (cb) => {
        const { map, behavior, ui } = this.state;
        ui && ui.dispose();
        behavior && behavior.dispose();
        setTimeout(() => {
            map && map.dispose();
            cb && cb();
        });
        this.setState({ map: null, behavior: null, ui: null });
    };

    // offroad ride specific functions
    // change in event handlers to make off road ride creation faster

    lastClick = { x: 0, y: 0 };
    onDrag = (val = true) => {
        this.drag = val;
    };
    isIconOrRoute = false;
    drag = false;
    hasOffRoadListeners = false;
    isContextMenu = false;
    onContextMenu = () => {
        this.isContextMenu = true;
    };

    onPointerDown = (evt) => {
        this.isIconOrRoute = !!evt.target.style;
        if (!this.isContextMenu && !this.isIconOrRoute) {
            const { map } = this.state;
            map.addEventListener(
                'pointermove',
                this.onDrag,
                this.supportsPassive ? { passive: true } : false
            );
            const { viewportX, viewportY } = evt.currentPointer;
            this.lastClick = { x: viewportX, y: viewportY };
        }
    };

    pointerUp = (evt) => {
        const { map } = this.state;
        const reset = () => {
            this.drag = false;
            this.lastClick = { x: 0, y: 0 };
            this.isIconOrRoute = false;
            this.isContextMenu = false;
        };

        if (!this.isContextMenu && !this.isIconOrRoute) {
            const dragThreshold = 50;
            const getXYDiff = (a, b) =>
                Math.abs(a.x - b.x) + Math.abs(a.y - b.y);

            // Waypoint exists on map
            if ((evt.target.P || {}).id) {
                reset();
                return;
            }

            const { viewportX, viewportY } = evt.currentPointer;
            const XYDiff = getXYDiff(this.lastClick, {
                x: viewportX,
                y: viewportY
            });

            const {
                currentRide: { waypoints }
            } = this.props;
            // if not dragging add new waypoint
            if (XYDiff < dragThreshold) {
                const coord = map.screenToGeo(viewportX, viewportY);
                this.props.insertRidePointNearest({
                    ...coord,
                    type:
                        waypoints.length === 0
                            ? WaypointType.LOCATION
                            : WaypointType.WAYPOINT
                });
            }
        }
        map.removeEventListener('pointermove', this.onDrag);
        reset();
    };

    setUpOffRoadClickListeners = (map) => {
        map.addEventListener(
            'contextMenu',
            this.onContextMenu,
            this.supportsPassive ? { passive: true } : false
        );
        map.addEventListener(
            'pointerdown',
            this.onPointerDown,
            this.supportsPassive ? { passive: true } : false
        );
        map.addEventListener(
            'pointerup',
            this.pointerUp,
            this.supportsPassive ? { passive: true } : false
        );
        return (this.hasOffRoadListeners = true);
    };

    removeOffRoadClickListeners = (map) => {
        map.removeEventListener('contextMenu', this.onContextMenu);
        map.removeEventListener('pointerdown', this.onPointerDown);
        map.removeEventListener('pointermove', this.onDrag);
        map.removeEventListener('pointerup', this.pointerUp);
        this.hasOffRoadListeners = false;
    };

    areBoundsValidNumbers = (bounds) => {
        for (const key in bounds) {
            const bound = bounds[key];
            for (const key in bound) {
                if (!Number.isFinite(bound[key])) {
                    return false;
                }
            }
        }
        return true;
    };

    getSearchBoundingBox(centerLat, centerLng, radiusMiles) {
        // Convert radius from miles to degrees (approximate)
        const radiusDegrees = radiusMiles / 69;

        // Calculate latitude boundaries
        const minLat = centerLat - radiusDegrees;
        const maxLat = centerLat + radiusDegrees;

        // Calculate longitude boundaries
        const minLng =
            centerLng - radiusDegrees / Math.cos((centerLat * Math.PI) / 180);
        const maxLng =
            centerLng + radiusDegrees / Math.cos((centerLat * Math.PI) / 180);

        // Return bounding box as an object
        return {
            minLat: minLat,
            maxLat: maxLat,
            minLng: minLng,
            maxLng: maxLng
        };
    }

    getSelectedRideBoundingBox = (selectedRide) =>
        new H.geo.Rect(
            selectedRide.ne.lat,
            selectedRide.sw.lng,
            selectedRide.sw.lat,
            selectedRide.ne.lng
        );

    componentDidUpdate = (prevProps) => {
        const {
            map: {
                myLocation,
                centerMyLocation,
                scheme: nextScheme,
                schemeOpts: nextSchemeOpts
            },
            wheelZoomEnabled: nextWheelZoomEnabled,
            update
        } = this.props;

        const {
            map: { scheme, schemeOpts, center: mapCenter },
            wheelZoomEnabled
        } = prevProps;

        const ridePreviews = [
            Routes.RIDE_CREATE,
            Routes.RIDE_CREATE_PREVIEW,
            Routes.SHARED_RIDE,
            Routes.RIDE_EDIT,
            Routes.RIDE_PREVIEW
        ];
        const { pathname } = window.location;
        const isPreview = ridePreviews.find(
            (route) => !!matchPath({ path: route, end: true }, pathname)
        );

        if (!!isPreview) {
            this.zoomControl.removeClass(style.zoomForMap);
        } else {
            this.zoomControl.addClass(style.zoomForMap);
        }

        const { map } = this.state;

        if (nextWheelZoomEnabled !== wheelZoomEnabled) {
            const { behavior } = this.state;
            if (behavior) {
                nextWheelZoomEnabled === false
                    ? behavior.disable(H.mapevents.Behavior.WHEELZOOM)
                    : behavior.enable(H.mapevents.Behavior.WHEELZOOM);
            }
        }
        // add / remove event listeners specific to offroad rides
        const { offRoad } = this.props;
        if (map) {
            const hereBoundingBox = this.getBoundingBox(map);
            currentHereBounds = hereBoundingBox;
            if (offRoad && !this.hasOffRoadListeners)
                this.setUpOffRoadClickListeners(map);
            if (!offRoad && this.hasOffRoadListeners)
                this.removeOffRoadClickListeners(map);
        }

        if (centerMyLocation) {
            update('centerMyLocation')(false);
            this.state.map.setCenter(myLocation, true);
        }

        // Add or remove traffic layer
        if (
            map &&
            (nextSchemeOpts || {}).traffic !== (schemeOpts || {}).traffic
        ) {
            const defaultLayers = this.createTrafficLayer(
                this.isCustomMapStyle(scheme)
            );
            if (nextSchemeOpts?.traffic) {
                if (this.isCustomMapStyle(scheme)) {
                    map.addLayer(defaultLayers, 1);
                } else {
                    map.addLayer(defaultLayers.vector.traffic.map, 1);
                }
            } else {
                if (this.isCustomMapStyle(scheme)) {
                    map.removeLayer(defaultLayers);
                } else {
                    map.removeLayer(defaultLayers.vector.traffic.map);
                }
            }
        }
        const shouldRecreateMap =
            this.allowCustomHDStyle() && nextScheme !== scheme;

        if (
            this.lang !== getLang() ||
            this.units !== getUnits() ||
            shouldRecreateMap
        ) {
            this.destroyMap(() => this.createMap());
        }

        if (map && this.schemeChanged()) {
            update('schemeChanged')(true);
            if (this.zoomBounds !== null) {
                let zoomBounds = Object.assign({}, this.zoomBounds);
                update('bounds')(zoomBounds);
                update('hereBounds')(zoomBounds);
                this.zoomBounds = null;
            }
        }

        if (map && this.boundsChanged()) {
            const {
                map: { bounds }
            } = prevProps;

            // Bounds adjustment
            const [mapCanvas, ...rest] =
                document.getElementsByTagName('canvas');
            if (
                !!bounds &&
                this.areBoundsValidNumbers(bounds) &&
                map &&
                !!mapCanvas
            ) {
                const hereBounds = hereBoundingBox([bounds.ne, bounds.sw]);
                const { rideSelected } = this.props;

                if (!!hereBounds) {
                    const {
                        search: { query, selectedResult },
                        map: {
                            bounds: searchedRideBounds,
                            center: mapCenter,
                            schemeChanged
                        },
                        edit_ride,
                        previewRideProgress
                    } = this.props;

                    const isCreatePage =
                        window.location.pathname.includes('/create');

                    const isMapRidesIdPage =
                        window.location.pathname.includes('/map/rides') ||
                        window.location.pathname.includes('/map');

                    /*
                        We need to refactor all this if and else if section
                        to be easier to read and understand in the future
                    */

                    // if the Map page is open after a search by location
                    if (query && !rideSelected) {
                        const urlParams = new URLSearchParams(
                            window.location.search
                        );
                        const isSearchedLocation =
                            urlParams.get('time') == null;
                        // Calculate bounds from a given center from the location searched
                        if (selectedResult !== null && isSearchedLocation) {
                            const { maxLat, maxLng, minLat, minLng } =
                                this.getSearchBoundingBox(
                                    selectedResult.position.lat,
                                    selectedResult.position.lng,
                                    25
                                );
                            const boundingBox = new H.geo.Rect(
                                maxLat,
                                minLng,
                                minLat,
                                maxLng
                            );
                            // Set the view of the map to the calculated bounding box
                            // and padding to ensure the location is visible on the map
                            map.getViewModel().setLookAtData({
                                bounds: boundingBox,
                                position: boundingBox.getCenter()
                            });
                        } else {
                            // Calculate bounds from a given center from the searched ride
                            const searchedRideBoundingBox = new H.geo.Rect(
                                searchedRideBounds.ne.lat,
                                searchedRideBounds.sw.lng,
                                searchedRideBounds.sw.lat,
                                searchedRideBounds.ne.lng
                            );
                            map.getViewModel().setLookAtData({
                                bounds: searchedRideBoundingBox,
                                position: searchedRideBoundingBox.getCenter()
                            });
                        }
                    } else if (
                        this.isCreate() &&
                        (edit_ride?.waypoints.length > 0 ||
                            edit_ride?.name.length > 0) &&
                        previewRideProgress <= 0
                    ) {
                        const editedRideBoundingBox =
                            this.getSelectedRideBoundingBox(searchedRideBounds);
                        map.getViewModel().setLookAtData({
                            bounds: editedRideBoundingBox,
                            position: editedRideBoundingBox.getCenter()
                        });
                    } else if (
                        (schemeChanged &&
                            (!!rideSelected ||
                                !!edit_ride.ride?.shortId ||
                                this.isCreate())) ||
                        (isMapRidesIdPage &&
                            previewRideProgress <= 0 &&
                            !this.isPreview() &&
                            !this.isEdit())
                    ) {
                        const lastUpdatedCenter =
                            JSON.parse(localStorage.getItem('center')) ??
                            mapCenter;

                        map.getViewModel().setLookAtData({
                            position: lastUpdatedCenter
                        });
                    } else {
                        if (rideSelected || this.isCreate() || this.isEdit()) {
                            if (previewRideProgress <= 0) {
                                const editedRideBoundingBox =
                                    this.getSelectedRideBoundingBox(
                                        searchedRideBounds
                                    );
                                map.getViewModel().setLookAtData({
                                    bounds: editedRideBoundingBox,
                                    position: editedRideBoundingBox.getCenter()
                                });
                            } else {
                                const lastUpdatedCenter =
                                    JSON.parse(
                                        localStorage.getItem('center')
                                    ) ?? mapCenter;
                                map.getViewModel().setLookAtData({
                                    position: lastUpdatedCenter
                                });
                            }
                        } else {
                            const editedRideBoundingBox =
                                this.getSelectedRideBoundingBox(
                                    searchedRideBounds
                                );
                            map.getViewModel().setLookAtData({
                                bounds: editedRideBoundingBox,
                                position: editedRideBoundingBox.getCenter()
                            });
                        }
                    }
                }
                this.prevBounds = bounds;
            }
        }

        if (this.state.ui && getPrefUnits() !== this.state.ui.getUnitSystem()) {
            this.state.ui.toggleUnitSystem();
        }
        if (!prevProps.createModalShowing && !!this.props.createModalShowing)
            this.props.setShowSearchThisArea(true);
    };

    componentWillUnmount = () => {
        // Remove window resize listener
        const {
            map: { schemeChanged },
            update
        } = this.props;
        window.removeEventListener('resize', this.resizeMap);
        if (!!schemeChanged) update('schemeChanged')(false);
    };

    isPreview = () => window.location.pathname.includes('/preview');
    isShare = () => window.location.pathname.includes('/share');
    isEdit = () => window.location.pathname.includes('/edit');
    isCreate = () => window.location.pathname.includes('/create');
    isCached = () => window.location.search.includes('cacheId');

    isMap = () => !!(window.location.pathname.match(/\/map/) || [])[0];

    getBoundingBox = (mapRef) => {
        if (!(mapRef || {}).getViewModel) return;
        const { bounds } = mapRef.getViewModel().getLookAtData();
        const topLeft = bounds.getBoundingBox().getTopLeft();
        const bottomRight = bounds.getBoundingBox().getBottomRight();
        const ne = { lat: topLeft.lat, lng: bottomRight.lng };
        const sw = { lat: bottomRight.lat, lng: topLeft.lng };
        return { ne, sw };
    };

    zoomChanged = () => {
        const { map } = this.state;
        const {
            map: { zoom }
        } = this.props;
        const currZoom = map && map.getZoom();
        return zoom !== currZoom;
    };

    centerChanged = () => {
        const {
            map: { center }
        } = this.props;
        const newCenter = this.newCenter();
        return (
            newCenter &&
            ((center || {}).lat !== newCenter.lat ||
                (center || {}).lng !== newCenter.lng)
        );
    };
    boundsChanged = () => {
        const {
            map: { bounds }
        } = this.props;
        return bounds !== this.prevBounds;
    };

    schemeChanged = () => {
        const {
            map: { scheme }
        } = this.props;
        return scheme !== this.prevScheme;
    };

    fetchMarkers = (center) => {
        const shouldFetchPOI =
            this.isCreate() ||
            this.isPreview() ||
            this.isShare() ||
            this.isEdit();

        if (shouldFetchPOI) {
            this.props.fetchPOI(center);
        } else {
            this.props.fetchRDE();
        }
    };

    setRadius = (map) => {
        const bounds = this.getBoundingBox(map);
        const radius = getPolylineLength([bounds.ne, bounds.sw]) / 2;
        const adjustedRadius = radius > 3000 ? 3000 : radius;
        this.props.update('radius')(adjustedRadius);
    };

    setBounds = (map) => {
        const bounds = this.getBoundingBox(map);
        bounds && this.props.update('hereBounds')(normalizeBounds(bounds));
        this.setCenter(bounds);
    };

    setCenter = (bounds) => {
        const center = boundsCenter(bounds);
        localStorage.setItem('center', JSON.stringify(center));
        center && this.props.update('center')(center);
    };

    handleMapChange = () => {
        const { map } = this.state;
        const { update, setShowSearchThisArea } = this.props;
        const urlParams = new URLSearchParams(window.location.search);
        const isCollection =
            (location || {}).pathname === Routes.MAP_COLLECTIONS;
        const isMapPageOnly = urlParams.get('pos') !== null;

        // Map loaded / location fetched / map zoomed / load rides
        // Handle Zoom Change
        if (
            map &&
            (this.isCreate() ||
                this.isPreview() ||
                this.isShare() ||
                this.isEdit() ||
                isMapPageOnly) &&
            this.zoomChanged()
        ) {
            const currZoom = map && map.getZoom();
            const newZoom = Math.round(currZoom);
            if (!isCollection && !!setShowSearchThisArea) {
                setShowSearchThisArea(true);
            }
            //change the bounds on zoom
            const bounds = this.getBoundingBox(map);
            if (this.zoomBounds !== bounds) {
                this.zoomBounds = bounds;
                update('zoomBounds')(bounds);
            }
            update('zoom')(newZoom);
        }
    };

    handleViewChange = (e, userSearch = false) => {
        const {
            map: { center },
            update,
            setShowSearchThisArea,
            showSearchThisArea,
            location,
            search: { selectedResult }
        } = this.props;
        const isCollection =
            (location || {}).pathname === Routes.MAP_COLLECTIONS;

        const { map } = this.state;
        const currZoom = map && map.getZoom();
        const newZoom = Math.round(currZoom);

        if (map && (this.mapRef || !!e)) {
            this.setBounds(map); // change this in the future to be render just 1 time when the view change.
            if (this.skipInitialViewChange) {
                let skipDefaultBoundsInHdPOIsRequest = true;
                // Trying to fix search by location from the side panel in Map page (WIP)
                // if(!!selectedResult){
                //     skipDefaultBoundsInHdPOIsRequest = false;
                //     this.fetchMarkers(selectedResult.position.lat, selectedResult.position.lng, 200);
                // }
                if (skipDefaultBoundsInHdPOIsRequest) {
                    setTimeout(() => {
                        const centerFromHereBounds =
                            boundsCenter(currentHereBounds);
                        //this.fetchMarkers(centerFromHereBounds);
                    }, 500);
                    skipDefaultBoundsInHdPOIsRequest = false;
                }
                this.skipInitialViewChange = false;
                const mapDetail = {
                    zoom: newZoom,
                    center: center
                };
                map.getViewModel().setLookAtData(mapDetail);
                this.prevCenter = center;
                this.prevZoom = newZoom;
                return;
            }

            if (!this.initialViewChangeHandled) {
                this.initialViewChangeHandled = true;
                return;
            }

            // Handle Center Change
            if (this.centerChanged()) {
                if (!isCollection && !!setShowSearchThisArea) {
                    setShowSearchThisArea(true);
                    // update center
                    const newCenter = this.newCenter();
                    localStorage.setItem('center', JSON.stringify(newCenter));
                    this.prevCenter = newCenter;
                    update('center')(newCenter);
                }
            }

            this.setRadius(map);

            if (userSearch) {
                if (showSearchThisArea) {
                    if (!!setShowSearchThisArea) setShowSearchThisArea(false);
                    this.fetchMarkers(center);
                }
            }

            // Created ride is cached and shown first time
            if (this.isCached() && this.isCreateInitialViewChange) {
                this.isCreateInitialViewChange = false;
                if (!!setShowSearchThisArea) setShowSearchThisArea(false);
            }

            // TODO: Test implementation of Aeris weather api
            // Add the following to index.html.js
            // <script defer src="https://cdn.aerisapi.com/sdk/js/latest/aerisweather.min.js"></script>
            // <link rel="stylesheet" href="https://cdn.aerisapi.com/sdk/js/latest/aerisweather.css">
            // this.overlayWeather(map);

            if (e && e.target && e.target.storeContent) {
                e.target.storeContent(function (req) {
                    if (req.getState() === H.util.Request.State.COMPLETE) {
                        log('Map content stored.');
                    } else if (req.getState() === H.util.Request.State.ERROR) {
                        log('Map content not stored.');
                    }
                });
            }
        }
    };

    handleContextMenu = (event) => {
        const { onContextMenu } = this.props;
        const { map } = this.state;

        // only fire the event if we're tapping the map
        if (event.target !== map) return;

        onContextMenu && onContextMenu(normalizeEvent(event, map));
        event.preventDefault();
    };

    resizeMap = () => {
        const { map } = this.state;

        if (map) {
            map.getViewPort().resize();
        }
    };

    getSize = () => {
        return (
            this.mapRef && {
                width: this.mapRef.offsetWidth,
                height: this.mapRef.offsetHeight
            }
        );
    };

    onClickSearchThisArea = (e) => this.handleViewChange(e, true);

    overlayWeather = (map) => {
        // Create a Tile Provider / Request tiles
        const tileProvider = new H.map.provider.ImageTileProvider({
            // Determine opts for aeris weather / opacity and zoom levels
            min: 12,
            max: 15,
            opacity: 0.2,
            getURL: function (column, row, zoom) {
                const url = `https://maps.aerisapi.com/eNdvh2gz4B2XWXTBMmv1o_w8GePLv83vND5gneoqxW4nSkot0giks08GbpX8Kb/radar/${zoom}/${column}/${row}/current.png`;
                return url;
            }
        });
        // Create layer for weather tiles
        const overlayLayer = new H.map.layer.TileLayer(tileProvider, {
            opacity: 0.2
        });
        map.addLayer(overlayLayer);
    };

    render() {
        const {
            className,
            innerRef,
            map: _map,
            children,
            onWheel,
            showUiControls,
            tall,
            update,
            onCenterLocation,
            onZoom,
            isMobile,
            style: _style
        } = this.props;

        const { value: rideSibarStatus } =
            JSON.parse(localStorage.getItem('ride.sidebar')) || '';
        const isRide = this.isPreview() || this.isCreate();

        const searchStyle = classNames(style.search, {
            [style.mapSearch]: !!isRide,
            [style.searchMobile]: isMobile,
            [style.isSideBarOpened]:
                rideSibarStatus == 'open' || rideSibarStatus == '',
            [style.isSideBarClosed]: rideSibarStatus == 'close',
            [style.isEditRidePage]: !!this.isEdit()
        });

        const { print } = _map;

        const cn = classNames(style.Map, {
            [className]: className,
            [style['tall']]: tall
        });

        const { map, behavior, ui } = this.state;
        const {
            map: { schemeOpts, scheme }
        } = this.props;

        if (!!map && (schemeOpts || {}).traffic) {
            const defaultLayers = this.createTrafficLayer(
                this.isCustomMapStyle(scheme)
            );
            if (this.isCustomMapStyle(scheme)) {
                map.addLayer(defaultLayers, 1);
            } else {
                map.addLayer(defaultLayers.vector.traffic.map, 1);
            }
        } else {
            if (!!map) {
                const defaultLayers = this.createTrafficLayer(
                    this.isCustomMapStyle(scheme)
                );
                if (this.isCustomMapStyle(scheme)) {
                    map.removeLayer(defaultLayers);
                } else {
                    map.removeLayer(defaultLayers.vector.traffic.map);
                }
            }
        }

        // Send Analytics to track support for non WebGL Browsers HDM-2290
        const { WebGLAnalyticsTracked } = parseCookie(document.cookie);
        const { MapScheme, WebGLSupported } = parseCookie(document.cookie);
        const nAgt = navigator.userAgent;
        const userAgentInfo = Bowser.parse(window.navigator.userAgent);
        userAgentInfo.isWebGLSupported = WebGLSupported;
        if (
            userAgentInfo.browser.name.includes('Chrome') &&
            userAgentInfo.os.name.includes('OS')
        ) {
            const engineVersion = nAgt.split('/')[2].split(' ')[0];
            userAgentInfo.engine.name = 'WebKit';
            userAgentInfo.engine.version = engineVersion;
        }
        userAgentInfo.mapScheme = scheme.split('.')[0];
        let browserData = {
            browser: userAgentInfo.browser,
            engine: userAgentInfo.engine,
            isWebGLSupported: userAgentInfo.isWebGLSupported,
            mapScheme: userAgentInfo.mapScheme,
            os: userAgentInfo.os,
            platform: userAgentInfo.platform
        };
        if (!WebGLAnalyticsTracked || scheme != MapScheme) {
            analyticsWithData('non webgl browser', browserData);
        }

        document.cookie = `WebGLAnalyticsTracked=true`;
        if (
            userAgentInfo.mapScheme != MapScheme &&
            userAgentInfo.mapScheme !== 'custom'
        )
            document.cookie = `MapScheme=${scheme}`;
        if (window.hd) {
            window.hd.browserData = browserData;
        }
        window.browserData = browserData;

        return (
            <div className={cn} style={_style}>
                {showUiControls && !print && (
                    <UiControls
                        className={style.uiControls}
                        onCenter={onCenterLocation}
                        onZoom={onZoom}
                        zoom={_map.zoom}
                        onUpdateMap={update}
                        scheme={_map.scheme}
                        schemeOpts={_map.schemeOpts}
                    />
                )}

                <div
                    className={style.inner}
                    ref={(ref) => {
                        if (!!ref) this.mapRef = ref;
                        innerRef && innerRef(ref);
                    }}
                    onWheel={onWheel}>
                    {_map.showSearchThisArea && !print && (
                        <div
                            className={searchStyle}
                            onClick={this.onClickSearchThisArea}>
                            {t('Search this area')}
                        </div>
                    )}
                    {/* Ensure children have the map context: */}
                    <MapContext.Provider value={{ map, behavior, ui }}>
                        {this.state.map && children}
                    </MapContext.Provider>
                </div>
            </div>
        );
    }
}

//ldgonzalezmedina check this out could be causing problems too
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    const edit_ride = state.edit_ride.present;
    return {
        showSearchThisArea: state.map.showSearchThisArea,
        edit_ride: edit_ride.ride,
        currentRide: getCurrentRide(state),
        map: state.map,
        search: state.search,
        currentRoutePoint: state.rides.preview.currentRoutePoint,
        rideSelected: state.rides.selected,
        previewRideProgress: state.rides.preview.currentRouteProgress
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchRDE: () => dispatch(fetchRDE()),
        fetchPOI: (center) => dispatch(fetchPOI(center)),
        insertRidePointNearest: (waypoint, nearest) => {
            dispatch(insertRidePointNearest(waypoint, nearest));
            if (ownProps.currentRouteProgress > 0)
                dispatch(setCurrentRouteProgress(0));
        }
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(Map);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
