/******************************************************************************\
 * File: SearchBar.jsx
 *
 * Author: Gigster
 *
 * Description: Searchbar
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/search/SearchBar.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import BaseSearchBar from './BaseSearchBar';
import { selectResult, changeSearch, setItems } from '@/store/search';
import { locationSearch } from '@/helpers/here';
import { Routes } from '@/helpers/routes';
import { searchRides } from '@/helpers/api';
import { previewRide } from '@/store/rides/preview';
import { flatten, isDevQA } from '@/helpers/functions';
import { currentUserId } from '@/store/auth';

import { getRideCheckboxData } from '@/helpers/checkboxes';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { translate } from '@/helpers/i18n';
const t = translate('search.SearchBar');

//------------------------------------------------------------------------------
// React Component -------------------------------------------------------------

const rideTypeDetail = (ride, userId, isHighlighted) => {
    if (!ride) return;
    const { offRoad } = ride;
    let imgPath = null;
    const rideSubType =
        ride.userId !== userId && ride.type === 'CUSTOM'
            ? 'SHARED'
            : ride.subType;
    const rideType = getRideCheckboxData().find(
        (val) => val.value === rideSubType
    );
    if (!rideType) return;
    if (!!offRoad)
        imgPath = isHighlighted
            ? '/img/icon-route-type-off-road-dark.svg'
            : '/img/icon-route-type-off-road-medium-dark.svg';

    return (
        <span>
            {rideType.type} • {rideType.label}
            {!!offRoad && !!imgPath && (
                <span>
                    {' '}
                    •
                    <img
                        src={imgPath}
                        style={{ height: 16, verticalAlign: 'sub' }}
                    />
                </span>
            )}
        </span>
    );
};
const renderItem =
    (userId) =>
    (item, { query, isHighlighted }) => {
        const image =
            item.type === 'CUSTOM'
                ? isHighlighted
                    ? '/img/icon-rides-my-rides-hover.svg'
                    : '/img/icon-rides-my-rides.svg'
                : isHighlighted
                  ? '/img/icon-rides-recommended-hover.svg'
                  : '/img/icon-rides-recommended.svg';

        const label = ({ label }) => {
            const matches = match(label, query);
            const parts = parse(label, matches);
            return (
                <span className={style.labelText}>
                    {parts.map((part, index) => {
                        const style = part.highlight
                            ? { fontWeight: '600' }
                            : {};
                        return (
                            <span style={style} key={index}>
                                {part.text}
                            </span>
                        );
                    })}
                </span>
            );
        };

        return item.dataType === 'ride' ? (
            <div
                style={{ height: '48px', paddingLeft: '16px' }}
                className={classNames(
                    style.dropdownItem,
                    style.flex,
                    style.noPadding,
                    {
                        [style['highlight']]: !!isHighlighted
                    }
                )}>
                {!!item.img && (
                    <span className={style.typeIcon}>
                        <img src={image} className="icon-rides-recommended" />
                    </span>
                )}
                <div className={style.rideLabel}>
                    <div>
                        {label(item, isHighlighted)} <br />
                    </div>
                    <div className={style.resultSubtitle}>
                        {rideTypeDetail(item, userId, isHighlighted)}
                    </div>
                </div>
            </div>
        ) : (
            <div
                className={classNames(style.dropdownItem, style.flex, {
                    [style['highlight']]: !!isHighlighted
                })}>
                <div className={style.left}>
                    {!!item.img && (
                        <span className={style.typeIcon}>
                            <img src={item.img} />
                        </span>
                    )}
                    <div>{label(item)}</div>
                </div>
            </div>
        );
    };

const EmptyComponent = ({ value }) => (
    <div
        className={classNames(style.dropdownItem, style.darkText)}
        style={{ cursor: 'default' }}>
        {t('empty', {
            link: (text) => <a href={Routes.RIDE_CREATE}>{text}</a>,
            t: 'There are no rides here. Be the first to <link>create one!</link>'
        })}
    </div>
);

const NoopComponent = () => <div />;

class SearchBar extends React.Component {
    onChange = (event, { newValue }) => {
        this.props.onChange(newValue);
    };

    onSuggestionSelected = (event, { suggestion }) => {
        if (!!(suggestion || {}).dataType && suggestion.dataType === 'ride')
            return this.props.onSelectRide(suggestion);
        this.props.onSelect(suggestion);
    };

    labelItem = (item) => {
        if (item.address) {
            const { label } = item.address;
            return { ...item, label };
        }
        if (item.waypoints || item.rideAvoidances) {
            return {
                ...item,
                label: item.name,
                dataType: 'ride',
                img: '/img/icon-helmet-gray.svg'
            };
        }
        return item;
    };

    onSuggestionsFetchRequestedDebounced = debounce((obj) => {
        debounce.cancel;
        if ((obj || {}).value.length > 1) {
            this.onSuggestionsFetchRequested(obj);
        } else {
            this.props.setItems([]);
        }
    }, 300);

    onSuggestionsFetchRequested = ({ value }) => {
        const { setItems, mapCenter } = this.props;
        const at = `${mapCenter.lat},${mapCenter.lng}`;
        const promises = [];

        promises.push(
            searchRides(value.toLowerCase()).then((res) =>
                res.data.rides.map(this.labelItem)
            )
        );

        promises.push(
            locationSearch(value, { at }).then((res) =>
                res.data.items
                    .filter(
                        (i) =>
                            i.resultType === 'locality' ||
                            i.resultType === 'administrativeArea'
                    )
                    .map(this.labelItem)
            )
        );

        return Promise.all(promises).then((providerResults) => {
            const results = flatten(providerResults);
            return setItems(results);
        });
    };

    onSuggestionsClearRequested = () => {};

    render() {
        const {
            className,
            value,
            items,
            userId,
            medium,
            locale,
            hasId = false
        } = this.props;
        const placeholderText = t('Enter a Ride Name or Location');
        const placeholderShort = placeholderText.length > 32;
        return (
            <BaseSearchBar
                id="rides"
                placeholder={placeholderText}
                onSuggestionsFetchRequested={
                    this.onSuggestionsFetchRequestedDebounced
                }
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                renderSuggestion={renderItem(userId)}
                getSuggestionValue={(item) => item.label}
                suggestions={items}
                onSuggestionSelected={this.onSuggestionSelected}
                {...this.props}
                className={classNames({
                    [className]: !!className,
                    [style['empty']]: !(value || {}).length,
                    [style['medium']]: medium,
                    [style['placeholderShort']]: placeholderShort
                })}
                onChange={this.onChange}
                onSubmit={this.onSuggestionSelected}
                locale={locale}
                hasId={hasId}
                shouldRenderSuggestions={(value) => true}
            />
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => ({
    value: state.search.query || '',
    items: state.search.items || [],
    mapCenter: state.map.center,
    userId: currentUserId(state)
});
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange: (query) => dispatch(changeSearch(query)),
    setItems: (items) => dispatch(setItems(items)),
    onSelect: (result) => dispatch(selectResult(result)),
    onSelectRide: (rideId) => dispatch(previewRide(rideId))
});
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
