import dayjs from 'dayjs';

const name = (r1, r2) => {
    return r1.eventName.toLowerCase() > r2.eventName.toLowerCase()
        ? 1
        : r1.eventName.toLowerCase() === r2.eventName.toLowerCase()
        ? 0
        : -1;
};

const name_rev = (r1, r2) => {
    return r1.eventName.toLowerCase() < r2.eventName.toLowerCase()
        ? 1
        : r1.eventName.toLowerCase() === r2.eventName.toLowerCase()
        ? 0
        : -1;
};

const start_date = (r1, r2) => {
    return dayjs(r2.startDate) - dayjs(r1.startDate);
};

const start_date_rev = (r1, r2) => {
    return dayjs(r1.startDate) - dayjs(r2.startDate);
};

const sort =
    (func) =>
    ({ hash, events }) => {
        return { hash: hash, events: (events || []).slice().sort(func) };
    };

export const ALPHANUMERIC = 'alphanumeric';
export const ALPHANUMERIC_REV = 'alphanumeric_rev';
export const START_DATE = 'start_date';
export const START_DATE_REV = 'start_date_rev';

export const sorts = {
    [ALPHANUMERIC]: sort(name),
    [ALPHANUMERIC_REV]: sort(name_rev),
    [START_DATE]: sort(start_date),
    [START_DATE_REV]: sort(start_date_rev)
};
