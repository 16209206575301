import { stripTrailingSlash } from '@/helpers/functions';
import { Redirects } from '@/shared/routing';
import history from '@/helpers/history';

/* */
// Action Creators

/** Navigates to url if we're not already there. */
export const safePush = (url) => () => {
    history.push(url);
};

const sharedDealerOrEvent = (from, search) =>
    from.includes('share/event') && search === '';

/**
 * Watches the url and makes modifications.
 */
export const updateUrl = (location) => () => {
    let { pathname } = location;

    if (location.location !== undefined) {
        pathname = location.location.pathname;
    }
    // strip trailing slash
    {
        const stripPath = stripTrailingSlash(pathname);

        if (stripPath.length && stripPath.length < pathname.length) {
            return history.replace({ ...location, pathname: stripPath });
        }
    }

    // redirect from old urls to new ones
    for (let from in Redirects) {
        const to = Redirects[from];

        // wild redirects
        if (from.endsWith('*')) {
            from = from.substring(0, from.length - 1);
            if (pathname.startsWith(from) && !pathname.startsWith(to)) {
                if (sharedDealerOrEvent(from, location.search))
                    location.search = 'sidebar=1';
                const redirect = to + pathname.substring(from.length);
                return history.replace({ ...location, pathname: redirect });
            }
            // exact match
        } else if (pathname === from) {
            return history.replace({ ...location, pathname: to });
        }
    }
};
