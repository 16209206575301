/******************************************************************************\
 * File: DirectionsList.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/directions/DirectionsList.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Item from './Item';
import Distance, { Stats } from './Distance';
import NumberIcon from '@/components/common/icons/NumberIcon';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import turnIcons from '@/helpers/turn-icons';
import { prettySeconds, prettyMeters, getAttr } from '@/helpers/functions';
import { getMarkerProps } from '@/helpers/markers';
import { WaypointType } from '@/helpers/constants';
import { translate } from '@/helpers/i18n';
const t = translate('directions.DirectionsList');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const stripDescription = (str) =>
    str.replace(/\.\s<span class="distance-description">.*<\/span>/, '');

const stripArrivalStr = (str) => str.replace(/Arrive at ([^\.]*)\./, '').trim();

class DirectionsList extends React.Component {
    shouldNameWaypoint = (j, maneuvers) =>
        j === 0 || j === maneuvers.length - 1;

    waypointName = (waypoint, isStart) => {
        const { name, address } = waypoint || {};
        if (!address) return;

        const label = isStart ? t('Start at') : t('Arrive at');
        const addressLabel = name ? `${name}<br/>${address}<br/>` : address;

        return `${label} ${addressLabel}`;
    };

    legHTML = (leg) => {
        const { length, travelTime } = leg;
        const stats = `${prettyMeters(length)} / ${prettySeconds(
            leg.travelTime
        )}`;
        const rideSegment = t('Ride Segment:');
        return `<div class="${style.legInfo}">${rideSegment} ${stats}</div>`;
    };

    instructionHTML = (instruction, i, j) => {
        const { waypoints, directions } = this.props;

        const legInfo =
            directions.length > 1 && j === 0 ? this.legHTML(directions[i]) : '';

        const description = stripDescription(instruction) + legInfo;

        if (!waypoints) {
            return description;
        }

        const locations = waypoints.filter(
            (e) => e.type !== WaypointType.WAYPOINT
        );

        const maneuvers = directions[i].maneuvers;

        const name = this.shouldNameWaypoint(j, maneuvers)
            ? this.waypointName(locations[j === 0 ? i : i + 1], j === 0)
            : null;

        return name
            ? `${name}<br/>${stripArrivalStr(description)}`
            : description;
    };

    getIcon(maneuver, i, legs) {
        const { waypoints, print } = this.props;
        const isLast = i === legs.length - 1;
        const actionRef = !maneuver.direction
            ? maneuver.action
            : `${maneuver.direction}${maneuver.action
                  .substr(0, 1)
                  .toUpperCase()}${maneuver.action.substr(1)}`;

        if (waypoints && actionRef === 'arrive' && !isLast) {
            const locations = waypoints.filter(
                (e) => e.type !== WaypointType.WAYPOINT
            );
            const {
                type,
                icon: IconComponent,
                number
            } = getMarkerProps(locations[i + 1], i + 1, locations);
            const props =
                type === 'NUMBER'
                    ? { fill: print && '#eee' }
                    : { fill: print && '#929292', stroke: print & '#929292' };

            return <IconComponent children={number} {...props} />;
        }
        return turnIcons(actionRef);
    }

    render() {
        const {
            directions,
            highlightPosition,
            waypoints,
            className,
            print,
            onClickDirection
        } = this.props;

        const cn = classNames(style.DirectionsList, {
            [className]: !!className,
            [style['print']]: !!print
        });

        return (
            <div className={cn} ref={(el) => (this.$list = el)}>
                {(directions || []).map((leg, i, legs) => (
                    <div key={`leg-${i}`}>
                        {(leg || {}).maneuvers.map((e, j, mans) => (
                            <div
                                key={`maneuver-${i}-${j}`}
                                className={style.Direction}
                                data-last={j === mans.length - 1}>
                                <Item
                                    largeIcon
                                    highlight={
                                        highlightPosition === e.position ||
                                        (!highlightPosition &&
                                            i === 0 &&
                                            j === 0)
                                    }
                                    className={style.item}
                                    print={print}
                                    onClick={
                                        onClickDirection
                                            ? () => onClickDirection(e)
                                            : undefined
                                    }
                                    name={
                                        <div
                                            className={style.instruction}
                                            dangerouslySetInnerHTML={{
                                                __html: this.instructionHTML(
                                                    e.instruction,
                                                    i,
                                                    j
                                                )
                                            }}
                                        />
                                    }
                                    right={
                                        print &&
                                        !!e.length && (
                                            <Stats
                                                className={style.stats}
                                                length={e.length}
                                                time={e.duration}
                                            />
                                        )
                                    }
                                    icon={this.getIcon(e, i, legs)}
                                />
                                {!!e.length && !print && (
                                    <Distance
                                        className={style.distance}
                                        length={e.length}
                                        style={{ marginLeft: 46 }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    }
}

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default DirectionsList;
