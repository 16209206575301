import { useEffect, useRef } from 'react';
// This custom hook is meant to replace the click away function on src/helpers/hoc.js
export const useOutsideClick = (callback) => {
    const ref = useRef();
    useEffect(() => {
        const handleClick = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
        document.addEventListener('click', handleClick, true);
        return () => {
            document.removeEventListener('click', handleClick, true);
        };
    }, [ref]);
    return ref;
};
