import { flatten } from '@/helpers/functions';

import { translate } from '@/helpers/i18n';
const t = translate('edit_ride.options');

/* */
// Types
export const TOGGLE = 'edit_ride/options/TOGGLE';

const avoidanceMap = {
    avoidHighways: ['controlledAccessHighway'],
    avoidTolls: ['tollRoad'],
    avoidFerries: ['ferry'],
    avoidUnpavedRoads: ['dirtRoad'],
    avoidTunnels: ['tunnel']
};

const getLabels = () => ({
    avoidHighways: t('Highways'),
    avoidTolls: t('Tolls'),
    avoidFerries: t('Ferries'),
    avoidUnpavedRoads: t('Unpaved Roads'),
    avoidTunnels: t('Tunnels')
});

/* */
// Helpers
export const rideFeaturesForApi = (features) =>
    flatten(
        Object.keys(features || {})
            .filter((key) => features[key])
            .map((key) => avoidanceMap[key])
    ).map((feature) => feature);

const getCheckboxes =
    (inverted = false) =>
    (state) =>
    (toggleFn) => {
        const isCreateOrEdit =
            window.location.pathname.includes('/create') ||
            window.location.pathname.includes('/edit') ||
            window.location.pathname.includes('/create/preview');

        const { rideAvoidances: options } = isCreateOrEdit
            ? state.edit_ride.present.ride
            : state.rides.selected
            ? state.rides.selected
            : { rideAvoidances: {} };

        const labels = getLabels();

        return Object.entries(options).map(([key, value]) => ({
            key,
            label: labels[key],
            checked: inverted ? !value : value,
            onChange: toggleFn ? () => toggleFn(key) : undefined
        }));
    };

export const getFeatureCheckboxes = getCheckboxes(false);

/* */
// Action Creators
export const toggleFeature = (feature) => ({
    type: TOGGLE,
    data: { feature }
});

/* */
// Reducer

export const initialState = {
    avoidHighways: false,
    avoidTolls: false,
    avoidFerries: false,
    avoidTunnels: false,
    avoidUnpavedRoads: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE:
            //eslint-disable-next-line
            const { feature } = action.data;
            return {
                ...state,
                [feature]: !state[feature]
            };

        default:
            return state;
    }
};
