/******************************************************************************\
* File: MyLocation.jsx
*
* Author: Gigster
*
* Description: My location map icon
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class MyLocation extends React.Component {
    static defaultProps = {
        color: '#1F8AFF'
    };

    render() {
        const { color } = this.props;

        return (
            <div
                style={{
                    height: '24px',
                    width: '24px',
                    margin: '-12px 0px 0px -12px'
                }}
            >
                <svg
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                    viewBox="0 0 24 24"
                >
                    <defs>
                        <circle id="b" cx="12" cy="12" r="6.493" />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                        <use fill="#000" xlinkHref="#b" />
                        <use fill={color} stroke="#FFF" xlinkHref="#b" />
                    </g>
                </svg>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default MyLocation;
