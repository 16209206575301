//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { request } from '@/helpers/async';
import history from '@/helpers/history';
import { calculateRide } from '@/helpers/here';
import api from '@/helpers/api';
import { centerRide as mapCenterRide, update as updateMap } from '@/store/map';
import { pick, diff } from '@/helpers/functions';
import { auth } from '@/store/auth';
import { CREATE_RIDE_ERROR, setError } from '@/store/error';
import { Routes, ridePath } from '@/helpers/routes';
import { showSaveModal } from '@/store/edit_ride/meta';
import { isRideRecorded } from '@/store/rides';
import { updateRide } from '@/store/edit_ride';
import { getPolylineLength, getPointsDistances } from '@/helpers/map';
import { analyticsWithData } from '@/helpers/analytics';

//------------------------------------------------------------------------------
// Internal --------------------------------------------------------------------
import { default as waypointsReducer, createWaypoint } from './waypoints';
import { didCalculateRoute } from './helpers';
import { CHANGE_ROUTE } from './routes';
import {
    initialState as optionsInitialState,
    default as optionsReducer
} from './options';
import { translate } from '@/helpers/i18n';
const t = translate('edit_ride.ride');

/* */
// Types
export const DID_CALCULATE_ROUTE = 'edit_ride/DID_CALCULATE_ROUTE';
export const LOAD = 'edit_ride/LOAD';
export const SAVE = 'edit_ride/SAVE';
export const DIRTY = 'edit_ride/DIRTY';
export const UPDATE = 'edit_ride/UPDATE';
export const BULK_UPDATE = 'edit_ride/BULK_UPDATE';
const CLEAR_MAPS_FIELDS = 'edit_ride/CLEAR_MAPS_FIELDS';

/* */
// Helpers
import { rideForApi, rideForStore, waypointsForStore } from './helpers';
import { clearRide as clearCachedRide } from './cache';

/** Sets an arbitrary field to a value. */
export const update = (field, value) => ({
    type: UPDATE,
    data: { field, value }
});

const isRideNamed = (ride) =>
    ride.name !== initialState.name && (ride.name || '').trim().length;

/** Suggests a name for the ride (if the ride is unsaved). */
export const defaultName = (ride) =>
    ride.id || isRideNamed(ride)
        ? ride.name
        : defaultNameForWaypoints(ride.waypoints);

export const didNameRide = (state) => {
    const { ride } = state.edit_ride.present;
    return isRideNamed(ride);
};

export const canSaveRide = (state) => {
    const {
        edit_ride: {
            present: { ride, meta }
        }
    } = state;

    const rideEvent =
        !!ride.event || ride.hasEvent
            ? !!(ride.event || {}).rideDate &&
              !!ride.dealerId & !!ride.event.startTime &&
              !ride.event.linkError
            : true;
    const canSave =
        meta.dirty &&
        !meta.saving &&
        !!ride &&
        ((!!ride.waypoints && ride.waypoints.length > 1) ||
            (!!ride.locationHistory && ride.locationHistory.length > 1)) &&
        rideEvent;

    return canSave;
};

export const defaultNameForWaypoints = (waypoints = []) => {
    const fields = ['Country', 'State', 'City', 'Street'];
    const lastWaypoint = waypoints[waypoints.length - 1];
    const [start, end] = [waypoints[0], lastWaypoint].map(
        (e) => (e || {}).addressComponents && pick(e.addressComponents, fields)
    );

    const hasStart = start && Object.keys(start).length > 0;
    const hasEnd = end && Object.keys(end).length > 0;

    if (!hasStart || !hasEnd) {
        if (!hasEnd) {
            return (lastWaypoint || {}).address
                ? t('rideTo', { end: lastWaypoint.address, t: 'Ride to {end}' })
                : initialState.name;
        }

        return t('rideTo', {
            end: `${end.City}, ${end.State}`,
            t: 'Ride to {end}'
        });
    }

    const delta = diff(start, end);
    const hasFields = fields
        .filter((key) => delta[key])
        .slice(0, 2)
        .reverse();

    const from = hasFields
        .map((key) => start[key])
        .filter((e) => e)
        .join(', ');
    const to = hasFields
        .map((key) => end[key])
        .filter((e) => e)
        .join(', ');

    return from
        ? t('rideBetween', { start: from, end: to, t: '{start} to {end}' })
        : t('rideTo', { end: to, t: 'Ride to {end}' });
};

/* */
// Action Creators

export const clearMapsFields = () => ({ type: CLEAR_MAPS_FIELDS });

/** Use this method to begin creating / editing a ride. */
export const loadRide =
    (ride, undoable = true) =>
    (dispatch) => {
        dispatch({ type: LOAD, data: { ride: rideForStore(ride) }, undoable });
    };

/** Centers the ride in the map. */
const _centerRide = () => (dispatch, getState) => {
    const { past } = getState().edit_ride;
    const { ride } = getState().edit_ride.present;
    const { waypoints } = ride;
    if (waypoints.length < 1) return;
    if (waypoints.length === 1) {
        return dispatch(updateMap('center', waypoints[0]));
    }
    if ((past || []).length < 3) dispatch(mapCenterRide(ride, { top: 300 }));
};

let isCentering = false;
export const centerRide = () => (dispatch, getState) => {
    if (isCentering) return;
    _centerRide()(dispatch, getState);
    isCentering = true;
    setTimeout(() => (isCentering = false), 400);
};

/**
 * Given a list of points (returned from here/autocomplete), creates
 * a new ride.
 */
export const createRide =
    (rawWaypoints, rideData = {}, eager = true) =>
    (dispatch) => {
        // eagerly load the ride
        const waypoints = waypointsForStore(rawWaypoints);
        if (eager) dispatch(loadRide({ ...rideData, waypoints }, false));

        /**
         * Because backend doesn't save waypoint id's or addressComponents,
         * we re-create the waypoints to give us these fields:
         */
        const { offRoad } = rideData;
        if (offRoad || isRideRecorded(rideData)) {
            dispatch(loadRide({ ...rideData, waypoints }));
            if (offRoad) {
                const length = getPolylineLength(waypoints);
                const distances = getPointsDistances(waypoints);
                dispatch(updateRide('points', waypoints));
                dispatch(updateRide('length', length));
                dispatch(updateRide('distances', distances));
            }
            return dispatch(centerRide());
        }
        return Promise.all(waypoints.map(createWaypoint)).then((waypoints) =>
            calculateRide({ ...rideData, waypoints }).then((data) => {
                dispatch(loadRide({ ...rideData, waypoints }));
                dispatch(didCalculateRoute(data, waypoints));
                dispatch(centerRide());
            })
        );
    };

const stripHtml = (html = '') => html.replace(/(<([^>]+)>)/gi, '');

/** Like createRide but unsets id so ride can be forked. */
export const forkRide = (ride) =>
    createRide(ride.waypoints, {
        ...ride,
        name: t('rideCopy', { name: ride.name, t: 'Copy of {name}' }),
        description: stripHtml(ride.description),
        type: 'CUSTOM',
        subType: 'PLANNED',
        dealerId: null,
        id: null,
        shortId: null,
        photos: [],
        userId: null
    });

/**
 * If the ride is dirty (has unsaved changes) and is not already saving,
 * and the user is logged in, it either saves the existing ride or creates
 * a new ride.
 * If the user is not logged in, it prompts them to log in.
 */
export const saveRide =
    (modalCheck = true) =>
    (dispatch, getState) => {
        const {
            edit_ride: {
                present: { ride: originalRide, meta }
            }
        } = getState();
        let ride = null;

        // allow name and description editing for RECORDED rides
        if (
            getState().rides.selected &&
            isRideRecorded(getState().rides.selected)
        ) {
            ride = getState().rides.selected;
            ride.name = originalRide.name;
            ride.description = originalRide.description;
        } else {
            // check if there are changes to persist
            if (!canSaveRide(getState())) return;

            if (
                modalCheck &&
                (!didNameRide(getState()) || !originalRide.id) &&
                !meta.saveModal
            ) {
                return dispatch(showSaveModal(true));
            }
            ride = rideForApi(originalRide);
        }

        const { id } = ride;
        const cb = saveRideCallback(originalRide, ride, id);
        return dispatch(auth(cb));
    };

const saveRideCallback = (originalRide, ride, id) => (dispatch) => {
    const imported = (ride || {}).imported;
    ride.durationSecs = ride.duration * 60;
    return dispatch(
        request(
            SAVE,
            id
                ? api.put(`/ride/${id}?includeLocationHistory=true`, ride)
                : api.post(
                      `/ride?includeLocationHistory=true&imported=${!!imported}`,
                      ride
                  ),
            { id: id || null },
            (resp) => {
                // copy here maps fields back to ride
                const {
                    points,
                    leg,
                    legs,
                    summary,
                    distances,
                    traffic,
                    waypoints
                } = originalRide;

                const newRide = {
                    ...resp.ride,
                    points,
                    leg,
                    legs,
                    summary,
                    distances,
                    traffic,
                    waypoints: resp.ride.waypoints || waypoints
                };

                dispatch(clearCachedRide());
                dispatch(loadRide(newRide));

                if (id) {
                    const editPath = ridePath(Routes.RIDE_EDIT, newRide);
                    history.replace(editPath);
                }

                // change url when first creating the ride
                if (!id) {
                    const { ride } = resp;
                    const editPath = ridePath(Routes.RIDE_EDIT, ride);
                    history.replace(editPath);
                }

                const WaypointsAndStops =
                    checkNumberOfWaypointsOrLocationsInRide(newRide);
                const totalWaypointsAndStops = {
                    waypoints: WaypointsAndStops.waypoints,
                    stops: WaypointsAndStops.stops,
                    rideShortId: newRide.shortId
                };
                analyticsWithData(
                    `number of waypoints and stops`,
                    totalWaypointsAndStops
                );
                window.hd.waypointsAndStops = totalWaypointsAndStops;
                window.waypointsAndStops = totalWaypointsAndStops;
                return newRide;
            },
            () => dispatch(setError(CREATE_RIDE_ERROR))
        )
    );
};

const checkNumberOfWaypointsOrLocationsInRide = (savedRide) => {
    let waypointsAndLocation = {
        waypoints: 0,
        stops: 0
    };
    for (let i = 0; i < savedRide.waypoints.length; i++) {
        if (
            savedRide.waypoints[i].type == 'LOCATION' ||
            savedRide.waypoints[i].type == 'POI'
        )
            waypointsAndLocation.stops = waypointsAndLocation.stops + 1;
        else if (savedRide.waypoints[i].type == 'WAYPOINT')
            waypointsAndLocation.waypoints = waypointsAndLocation.waypoints + 1;
    }
    return waypointsAndLocation;
};

/** Sets the ride name. */
export const bulkUpdate = (data) => ({ type: BULK_UPDATE, data });

/* */
// Reducer

// props that are not saved to the backend
export const mapsInitialState = {
    // Here maps routing applied to waypoints
    points: [],
    // Driving directions
    leg: [],
    distances: [],
    // ride summary
    summary: null,
    mode: null,
    routes: null,
    shape: [],
    traffic: [],
    _lastWaypointAction: 0
};

export const initialState = {
    // Name of the ride
    name: '',
    // Description of ride
    description: '',
    // Route distance
    length: 0,
    // Route time
    duration: 0,
    // User-defined waypoints
    waypoints: [],
    dealerId: null,
    eventId: null,
    event: null,
    // Default ride data (required by backend)
    difficulty: 'NEW_TO_RIDING',
    style: 'CRUISER',
    privacy: 'PRIVATE',
    type: 'CUSTOM',
    rideAvoidances: optionsInitialState,
    ...mapsInitialState
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE: {
            const { field, value } = action.data;
            return { ...state, [field]: value };
        }

        case BULK_UPDATE: {
            return { ...state, ...action.data };
        }

        case LOAD: {
            const { ride: loadRide } = action.data;
            const rideAvoidances = {
                ...initialState.rideAvoidances,
                ...(loadRide.rideAvoidances || {})
            };
            return { ...initialState, ...loadRide, rideAvoidances };
        }

        case CLEAR_MAPS_FIELDS: {
            return { ...state, ...mapsInitialState };
        }

        case CHANGE_ROUTE:
        case DID_CALCULATE_ROUTE: {
            const { ride } = action.data;
            return {
                ...state,
                ...mapsInitialState,
                ...ride,
                length:
                    typeof ride !== 'undefined' &&
                    typeof ride.length !== 'undefined'
                        ? ride.length
                        : state.length,
                duration:
                    typeof ride !== 'undefined' &&
                    typeof ride.duration !== 'undefined'
                        ? ride.duration
                        : state.duration
            };
        }

        default: {
            const time = action.time || state._lastWaypointAction;

            return {
                ...state,
                rideAvoidances: optionsReducer(state.rideAvoidances, action),
                waypoints:
                    time >= state._lastWaypointAction
                        ? waypointsReducer(state.waypoints, action)
                        : state.waypoints,
                _lastWaypointAction: time
            };
        }
    }
};
