import { matchPath } from 'react-router-dom';
import dayjs from 'dayjs';

import history from '@/helpers/history';
import queryMiddleware from '@/helpers/query-middleware';
import { Routes } from '@/helpers/routes';
import { setCurrentRouteProgress, updateRidePreview } from '@/store/rides';
import { initialState as previewInitialState } from '@/store/rides/preview';
import { centerZoom, initialState, update, updateSidebar } from '@/store/map';
import { changeSearch } from '@/store/search';
import { datetimeToDayjs, toArray, trunc } from '@/helpers/functions';
import { restoreCachedRide } from '@/store/edit_ride/cache';
import { LocationSource } from '@/helpers/constants';

export const matchRoute = (path, args = { exact: false }) =>
    matchPath({ path, ...args }, window.location.pathname);

export const match = (location, paths, args = { end: false }) =>
    toArray(paths).some((path) =>
        matchPath({ path, ...args }, location.pathname)
    );

export const centerZoomToString = (center, zoom) =>
    center && center.lat && center.lng
        ? `${center.lat.toFixed(8)},${center.lng.toFixed(8)}${
              zoom ? ',' + trunc(zoom, 2) + 'z' : ''
          }`
        : '';

export const centerZoomFromString = (str) => {
    const parts = str.split(',');
    const zoom = parts[2] || '';

    return {
        center: parts.length > 1 && {
            lat: parseFloat(parts[0], 10),
            lng: parseFloat(parts[1], 10)
        },
        zoom:
            (zoom.startsWith('z') || zoom.endsWith('z')) &&
            parseFloat(parts[2].replace(/z/g, ''), 10)
    };
};

const params = {
    // ride preview scrubber
    scrub: {
        when: (location) => match(location, Routes.RIDE_PREVIEW),
        action: (value) => setCurrentRouteProgress(value),
        selector: (state) => state.rides.preview.currentRouteProgress,
        toString: (val) => (val * 100).toFixed(1),
        toValue: (str) => (parseFloat(str, 10) || 0) / 100,
        defaultValue: 0
    },
    // ride preview datetime
    time: {
        when: (location) => match(location, Routes.RIDE_PREVIEW),
        action: (value) => updateRidePreview('datetime', value),
        selector: (state) => state.rides.preview.datetime,
        toString: (val) => datetimeToDayjs(val).unix(),
        toValue: (str) => {
            const value = parseInt(str, 10);
            const startOfToday = dayjs().startOf('day').unix();
            const date = dayjs.unix(value);

            // ensure time is at least today
            return value > startOfToday
                ? {
                      date: date.clone().startOf('day'),
                      time: date.hour()
                  }
                : previewInitialState.datetime;
        },
        defaultValue: previewInitialState.datetime
    },
    // map print page
    print: {
        when: (location) => match(location, Routes.MAP),
        action: (value) => update('print', value),
        selector: (state) => state.map.print,
        toString: (val) => (val ? '1' : '0'),
        toValue: (str) => str === '1',
        defaultValue: false
    },
    // map search query
    q: {
        when: (location) => !match(location, [Routes.RIDE_PREVIEW]),
        action: (value) => changeSearch(value),
        selector: (state) => state.search.query,
        toString: (val) => encodeURIComponent(val),
        toValue: (str) => decodeURIComponent(str),
        defaultValue: ''
    },
    // map center and zoom
    pos: {
        when: (location) =>
            match(location, Routes.MAP, { end: false }) &&
            !match(
                location,
                [
                    Routes.RIDE_CREATE,
                    Routes.RIDE_EDIT,
                    Routes.RIDE_PREVIEW,
                    Routes.MAP_RIDE,
                    Routes.MAP_DEALER,
                    Routes.MAP_EVENT
                ],
                { end: true }
            ),
        action: (data) =>
            centerZoom(
                (data || {}).center,
                (data || {}).zoom,
                LocationSource.POS
            ),
        selector: (state) => {
            const { center, zoom } = state.map;
            return { center, zoom };
        },
        toString: (value) =>
            value &&
            encodeURIComponent(centerZoomToString(value.center, value.zoom)),
        toValue: (str) => centerZoomFromString(decodeURIComponent(str)),
        defaultValue: { center: initialState.center, zoom: initialState.zoom }
    },
    // ride create cache
    cacheId: {
        when: (location) =>
            match(location, [Routes.RIDE_CREATE, Routes.RIDE_CREATE_PREVIEW]),
        action: (cacheId) => restoreCachedRide(cacheId),
        selector: (state) => state.edit_ride.present.cache.id,
        toString: (value) => `${value}`,
        toValue: (string) => string,
        defaultValue: ''
    },
    // dealers and events sidebar open state
    sidebar: {
        when: (location) =>
            match(location, [
                Routes.MAP_DEALER,
                Routes.MAP_EVENT,
                Routes.SHARED_EVENT
            ]),
        action: () => {
            if (match(window.location, Routes.MAP_DEALER)) {
                return updateSidebar('dealer');
            } else if (
                match(window.location, [Routes.MAP_EVENT, Routes.SHARED_EVENT])
            ) {
                return updateSidebar('event');
            }
        },
        selector: (state) => !!state.map.sidebar,
        toString: (value) => (value ? '1' : '0'),
        toValue: (string) => string === '1',
        defaultValue: false
    }
};

/* */
// Enhancer
export default queryMiddleware(history, params, { debounce: 400 });
