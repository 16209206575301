/******************************************************************************\
 * File: UiControls.jsx
 *
 * Author: Gigster
 *
 * Description: Map ui controls
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/map/here/UiControls.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Locate from '@/components/common/icons/Locate';
import MapLayer from '@/components/common/map/icons/MapLayer';
import { getLang, getUnits } from '@/helpers/i18n';
//App context
import AppContext from '@/contexts/AppContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class UiControls extends React.Component {
    render() {
        const {
            className,
            style: _style,
            scheme,
            schemeOpts,
            onUpdateMap,
            onCenter,
            onZoom,
            id
        } = this.props;

        const { isMobile } = this.context;

        const cn = classNames(style.UiControls, {
            [className]: className
        });

        const isJapan = getLang() === 'ja';
        return (
            <div className={cn} style={_style}>
                <div
                    style={{
                        position: 'absolute',
                        bottom: '70px',
                        right: '-8px'
                    }}>
                    {!isMobile && onZoom && (
                        <div>
                            {onCenter && (
                                <div className={style.group}>
                                    <div
                                        className={style.icon}
                                        onClick={() => onCenter()}>
                                        <Locate id={id} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {!isMobile &&
                    !isJapan &&
                    onUpdateMap &&
                    scheme &&
                    schemeOpts && (
                        <div
                            className={style.left}
                            style={{
                                position: 'absolute',
                                right: '30px',
                                bottom: '0px'
                            }}>
                            <div className={style.group}>
                                <MapLayer
                                    value={scheme}
                                    opts={schemeOpts}
                                    onChange={onUpdateMap('scheme')}
                                    onChangeOpts={onUpdateMap('schemeOpts')}
                                />
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        scheme: state.map.scheme,
        schemeOpts: state.map.schemeOpts
    };
};

UiControls.contextType = AppContext;

//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps)(UiControls);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
