import { translate } from '@/helpers/i18n';
const t = translate('homeView.footerLinks');
export default (isUS = false) =>
    [
        {
            title: t('Terms of Use'),
            link: 'https://www.harley-davidson.com/us/en/footer/utility/terms-of-use.html',
            shouldDisplay: true
        },
        {
            title: t('Privacy Policy'),
            link: 'https://www.harley-davidson.com/us/en/footer/utility/privacy-policy.html',
            shouldDisplay: true
        },
        {
            title: t('We Care About You'),
            link: 'https://www.harley-davidson.com/us/en/footer/utility/we-care-about-you.html',
            shouldDisplay: true
        },
        {
            title: t('Contact Us'),
            link: 'https://www.harley-davidson.com/us/en/customer-service/contact-us.html',
            shouldDisplay: true
        },
        {
            title: t('Safety Recall Information'),
            link: 'https://www.harley-davidson.com/us/en/tools/service-recalls.html',
            shouldDisplay: isUS
        },
        {
            title: t('About Harley-Davidson'),
            link: 'https://investor.harley-davidson.com/our-strategy/default.aspx ',
            shouldDisplay: true
        },
        {
            title: t('Policy For User-Submitted Content'),
            link: 'https://www.harley-davidson.com/us/en/footer/user-content-policy.html',
            shouldDisplay: true
        },
        {
            title: t('Careers'),
            link: 'https://www.harley-davidson.com/us/en/about-us/careers.html',
            shouldDisplay: true
        },
        {
            title: t('Harley-Davidson Financial Services'),
            link: 'https://www.harley-davidson.com/us/en/tools/h-d-financial-services.html',
            shouldDisplay: true
        },
        {
            title: t('Web Accessibility'),
            link: 'https://www.harley-davidson.com/us/en/footer/utility/web-accessibility.html',
            shouldDisplay: true
        },
        {
            title: t('Sign Up for Email'),
            link: 'https://www.harley-davidson.com/us/en/tools/email-subscribe-global.html',
            shouldDisplay: true
        }
    ].filter((i) => !!i.shouldDisplay);
