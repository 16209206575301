const { Env, currentEnv } = require('./constants');

// print current environment
exports.printEnv = () => {
    if (currentEnv !== Env.PRODUCTION) {
        if (typeof process.env.ENVIRONMENT === 'undefined') {
            console.warn(`Warning: ENVIRONMENT is not set!'`);
        }

        console.log(
            `App running on env '${currentEnv}' 🏍`,
            (process.env.GIT_SHA || '').substr(0, 8)
        );
    }
};
