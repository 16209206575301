/******************************************************************************\
* File: Wrench.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Wrench extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 16 16" onClick={onClick}>
                <title>Group</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="Group-7"
                        transform="translate(-1.000000, -537.000000)"
                        fillRule="nonzero"
                        fill="#FF6602"
                    >
                        <g
                            id="Group"
                            transform="translate(1.000000, 537.000000)"
                        >
                            <path
                                d="M15.4845093,3.08975717 L12.2525093,6.28975717 C12.2525093,6.28975717 11.2925093,6.28975717 10.3005093,5.32975717 C9.34050934,4.36975717 9.34050934,3.37775717 9.34050934,3.37775717 L12.5725093,0.14575717 C10.7485093,-0.30224283 8.54050934,0.30575717 7.19650934,1.64975717 C5.18050934,3.66575717 6.97250934,6.22575717 6.46050934,6.73775717 C3.64450934,9.68175717 0.732509343,11.9217572 0.668509343,12.0177572 C-0.195490657,12.8817572 -0.227490657,14.2577572 0.604509343,15.0577572 C1.43650934,15.8897572 2.81250934,15.8257572 3.64450934,14.9937572 C3.74050934,14.8977572 6.14050934,11.8577572 8.92450934,9.07375717 C9.43650934,8.56175717 11.9005093,10.4497572 13.9805093,8.40175717 C15.2925093,7.08975717 15.9325093,4.91375717 15.4845093,3.08975717 Z M2.33250934,14.2897572 C1.82050934,14.2897572 1.40450934,13.8737572 1.40450934,13.3617572 C1.40450934,12.8177572 1.82050934,12.4017572 2.33250934,12.4017572 C2.84450934,12.4017572 3.26050934,12.8177572 3.26050934,13.3297572 C3.26050934,13.8417572 2.84450934,14.2897572 2.33250934,14.2897572 Z"
                                id="Shape"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
export default Wrench;
