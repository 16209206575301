/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <path
                    d="M25.2 12.715h-3.526v24.728H25.2zm5.8 0h-3.523v24.728H31z"
                    opacity="0.4"></path>
                <path
                    fillRule="nonzero"
                    d="M16.216 16.124l4.46-5.304-13.118.114 1.898 13.229 4.443-5.374 4.821 4.073a9.226 9.226 0 012.937 7.321l-.008 7.252h3.527v-6.99a12.82 12.82 0 00-4.128-10.238l-4.832-4.083z"></path>
            </g>
        </svg>
    );
}

export default Icon;
