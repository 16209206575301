/******************************************************************************\
 * File: is-mobile.jsx
 *
 * Author: Gigster
 *
 * Description: Mobile browser detection
 *
 * Notes:
 \******************************************************************************/

const userAgentMatch = (regexp) => () => navigator.userAgent.match(regexp);

const Devices = {
    Android: userAgentMatch(/Android/i),
    BlackBerry: userAgentMatch(/BlackBerry/i),
    iOS: userAgentMatch(/iPhone|iPad|iPod/i),
    Opera: userAgentMatch(/Opera Mini/i),
    Windows: userAgentMatch(/IEMobile/i)
};

export default {
    ...Devices,
    isTouchDevice: () =>
        'ontouchstart' in document.documentElement ||
        !!navigator.userAgent.match(/iPad/i),
    any: () => Object.keys(Devices).some((k) => Devices[k]())
};
