/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <circle
                    cx="20"
                    cy="24"
                    r="11.5"
                    fillOpacity="0.2"
                    stroke="#FFF"></circle>
                <circle cx="20" cy="24" r="3.6"></circle>
            </g>
        </svg>
    );
}

export default Icon;
