/******************************************************************************\
* File: Marker.jsx
*
* Author: Gigster
*
* Description: Here map marker
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import throttle from 'lodash/throttle';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { createHereMarker, normalizeEvent } from '@/helpers/map';
import {
    latLngEqual,
    normalizeLatLng as normalize,
    shallowEqual
} from '@/helpers/functions';

//MapContext
import MapContext from '@/contexts/MapContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Marker extends React.Component {
    static defaultProps = {
        position: { lat: 0, lng: 0 },
        componentProps: {}
    };

    componentDidMount = () => {
        this.addMarker(this.props);
    };

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.addMarker(nextProps);
        this.updateMarker(nextProps);
    };

    componentWillUnmount = () => {
        this.removeMarker();
    };

    // Adds marker to the map if it doesn't already exist
    addMarker = (props) => {
        const { position } = props;
        const { map } = this.context;
        // Map isn't ready yet or Marker already exists, do nothing
        if (!map || this.marker) return;

        // If lat / lng aren't available do nothing
        if (!position || !position.lat || !position.lng) return;

        this.marker = createHereMarker(props, 'Marker');

        // Add marker to map
        map.addObject(this.marker);

        if (props.draggable) {
            map.addEventListener('dragstart', this.handleDragStart);
            map.addEventListener('drag', this.handleDrag);
            map.addEventListener('dragend', this.handleDragEnd);
        }
        return this.marker;
    };

    // Remove marker from map
    removeMarker = () => {
        const { map } = this.context;

        // No marker or map
        if (!map || !this.marker) return;

        // Remove marker from map
        if (!!this.marker) {
            //console.log('Marker in Marker.jsx in map/here: ', this.marker);
            map.removeObject(this.marker);
        }

        map.removeEventListener('dragstart', this.handleDragStart);
        map.removeEventListener('drag', this.handleDrag);
        map.removeEventListener('dragend', this.handleDragEnd);

        // unset reference
        this.marker = null;
    };

    // Update marker given new props
    updateMarker = (nextProps) => {
        if (!this.marker) return;

        const { position: nextPosition } = nextProps;
        const { position: currPosition } = this.props;

        if (!latLngEqual(normalize(currPosition), normalize(nextPosition))) {
            this.setPosition(nextPosition);
        }

        const { component: nextComponent, componentProps: nextComponentProps } =
            nextProps;
        const { component, componentProps } = this.props;

        if (
            (nextComponent && nextComponent !== component) ||
            !shallowEqual(componentProps, nextComponentProps)
        ) {
            this.removeMarker();
            this.addMarker(nextProps);
        }
    };

    handleDragStart = (event) => {
        if (event.target !== this.marker) return;
        // disable the default draggability of the underlying map
        this.context.behavior.disable();

        const { onDragStart } = this.props;
        onDragStart && onDragStart(normalizeEvent(event, this.context.map));
    };

    handleDragEnd = (event) => {
        if (event.target !== this.marker) return;
        // re-enable the default draggability of the underlying map
        this.context.behavior.enable();

        const { onDragEnd } = this.props;
        const normalizedEvent = normalizeEvent(event, this.context.map);
        onDragEnd && onDragEnd({ ...normalizedEvent, isDragging: true });
    };

    currPosition = {};
    throttledPosition = { pageX: 0, pageY: 0 };

    throttleUpdate = throttle(() => {
        const { previewRidePoint } = this.props;
        const exceeded = this.thresholdExceeded(this.currPosition);
        if (exceeded) previewRidePoint && previewRidePoint(this.currPosition);
    }, 2000);

    thresholdExceeded = (curr, threshold = 15) => {
        const { pageX, pageY } = this.throttledPosition;
        const x = Math.abs(pageX - curr.pageX);
        const y = Math.abs(pageY - curr.pageY);
        const exceeded = x > threshold || y > threshold;
        if (exceeded)
            this.throttledPosition = { pageX: curr.pageX, pageY: curr.pageY };
        return exceeded;
    };

    handleDrag = (event) => {
        if (event.target !== this.marker) return;

        const { onDrag } = this.props;
        const _event = normalizeEvent(event, this.context.map);
        onDrag && onDrag(_event);

        this.currPosition = _event;
        this.throttleUpdate();

        if (!event.defaultPrevented) {
            this.setPosition(_event.geocoord);
        }
    };

    // Update position of underlying marker
    setPosition = (position) => {
        if (this.marker && !!position)
            this.marker.setGeometry(normalize(position));
    };

    render() {
        // React component won't actually handle rendering
        return null;
    }
}

//use context api
Marker.contextType = MapContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Marker;
