/******************************************************************************\
 * File: Reverse.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Reverse extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 12 16" onClick={onClick}>
                <g fill="#FFF" fillRule="evenodd">
                    <path d="M2.25 11.58V6h3v5.58H7.5L3.75 16 0 11.58h2.25z" />
                    <path d="M9.75 4.42V10h-3V4.42H4.5L8.25 0 12 4.42H9.75z" />
                </g>
            </svg>
        );
    }
}
export default Reverse;
