import React, { Component } from 'react';
/******************************************************************************\
* File: EventMarker.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Bubble from '@/components/common/map/icons/Bubble';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PoiCluster extends Component {
    render() {
        const { className, onClick, img, count } = this.props;

        const cn = classNames({
            [className]: className
        });

        return (
            <div
                style={{
                    position: 'relative',
                    margin: '-12px 0 0 -13px',
                    lineHeight: 0
                }}
            >
                <img style={{ width: 26, height: 26 }} src={img} alt="" />
                {count > 1 && <Bubble count={count} />}
            </div>
        );
    }
}
export default PoiCluster;
