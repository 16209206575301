/******************************************************************************\
* File: Restaurant.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Restaurant extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [style['no-stroke']]: true,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 7 11" onClick={onClick}>
                <title>restaurant</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <path
                        d="M5.36361354,5.17619738 L4.99361354,9.30519738 C4.94161354,9.90319738 5.07161354,10.3311974 5.71761354,10.3311974 C6.27761354,10.3311974 6.32661354,9.59419738 6.32661354,9.45119738 L6.32661354,0.231197384 C6.32661354,0.0531973835 6.28961354,0.0161973835 6.11161354,0.0161973835 C5.22161354,0.0161973835 4.39161354,0.738197384 4.39161354,1.62919738 L4.39161354,4.53219738 C4.39161354,4.88819738 4.67961354,5.17719738 5.03661354,5.17719738 L5.36361354,5.17619738 Z M0.10761354,0.300197384 C0.114082503,0.140647624 0.245937277,0.0149873703 0.40561354,0.0161973835 C0.56561354,0.0161973835 0.69561354,0.142197384 0.70161354,0.301197384 L0.77861354,2.76019738 C0.780624456,2.88253229 0.87223537,2.98479564 0.99361354,3.00019738 C1.05886586,3.00747382 1.1241999,2.9872383 1.17391605,2.94435339 C1.22363219,2.90146847 1.25323593,2.83981109 1.25561354,2.77419738 L1.33361354,0.313197384 C1.32723077,0.203523217 1.38211524,0.0993352993 1.47617438,0.0425722711 C1.57023352,-0.014190757 1.68799356,-0.014190757 1.7820527,0.0425722711 C1.87611184,0.0993352993 1.93099631,0.203523217 1.92461354,0.313197384 L2.00261354,2.77319738 C2.00444604,2.8391644 2.03381516,2.90135077 2.08359708,2.94467244 C2.13337899,2.98799411 2.19902561,3.00849319 2.26461354,3.00119738 C2.38636288,2.98574123 2.47809626,2.88291451 2.47961354,2.76019738 L2.55661354,0.300197384 C2.56284842,0.144848314 2.68820753,0.0207995708 2.84361354,0.0161973835 C3.00261354,0.0161973835 3.13361354,0.142197384 3.13861354,0.301197384 L3.24661354,3.74119738 C3.25820608,4.07454256 3.08827316,4.3879999 2.80261354,4.56019738 L2.09561354,4.92119738 L2.34561354,9.36619738 C2.34561354,9.91619738 2.18061354,10.3301974 1.66661354,10.3301974 C1.11661354,10.3301974 0.90761354,9.87619738 0.90761354,9.37519738 C0.90761354,9.07019738 1.14961354,4.92119738 1.14961354,4.92119738 L0.44361354,4.56019738 C0.158017303,4.38757055 -0.0115548848,4.07368975 0.000613540083,3.74019738 L0.10761354,0.300197384 Z"
                        id="restaurant"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        );
    }
}
export default Restaurant;
