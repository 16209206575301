/******************************************************************************\
 * File: RouteType.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Tooltip from '@/components/common/form/Tooltip';
import { updateRide, updateRouteShape } from '@/store/edit_ride';
import { translate } from '@/helpers/i18n';
import { getPointsDistances } from '@/helpers/map';
import { MAX_WAYPOINTS } from '@/helpers/here';
import { createMaxWaypointsError, setError } from '@/store/error';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/directions/Dropdown.scss';

const t = translate('modal.uploadModal');

class RouteType extends React.Component {
    iconRender = (
        isActive,
        text,
        value,
        icon,
        exceedsMaxWaypoints = false,
        count
    ) => {
        const { onChangeRouteType, isHeader, ride, rideCopy, setError, cb } =
            this.props;
        const inactiveStyle = isHeader
            ? style.inactiveIcon
            : style.inactiveIconGrey;
        const errorMsg = createMaxWaypointsError((count = 0));
        const fullErrMsg = {
            id: 'routing_max_waypoints',
            message: `On-road ${errorMsg.message.toLowerCase()}`
        };
        return (
            <span
                className={classNames(
                    isActive ? style.activeIcon : inactiveStyle,
                    style.icon
                )}>
                <Tooltip value={text} placement="s" noDelay multiline={true}>
                    <a
                        onClick={
                            exceedsMaxWaypoints
                                ? () => {
                                      setError(fullErrMsg);
                                  }
                                : () => {
                                      if (!!cb) cb(value);
                                      onChangeRouteType(value, ride);
                                  }
                        }>
                        <img className={style.icon} src={icon} />
                    </a>
                </Tooltip>
            </span>
        );
    };

    render() {
        const { isOffRoadRoute, rideCopy, ride, isHeader, cb } = this.props;
        const { waypoints, locationHistory } = ride;
        const count =
            (waypoints || []).length || (locationHistory || []).length;
        const exceedsMaxWaypoints = count > MAX_WAYPOINTS;
        const onRoadImg = !exceedsMaxWaypoints
            ? '/img/icon-route-type-on-road.svg'
            : '/img/icon-route-type-on-road-disabled.svg';
        const offRoadImg = '/img/icon-route-type-off-road.svg';

        const onRoadTooltip = !exceedsMaxWaypoints
            ? t('On-road Routing')
            : `${t('On-road Routing')} (up to ${MAX_WAYPOINTS} waypoints)`;
        return (
            <div
                className={
                    isHeader ? style.routeType : style.routeTypeNoBorder
                }>
                <span
                    style={{
                        paddingTop: '6px',
                        textTransform: 'uppercase'
                    }}>
                    {t('Route Type')}
                </span>
                {this.iconRender(
                    !isOffRoadRoute,
                    onRoadTooltip,
                    null,
                    onRoadImg,
                    exceedsMaxWaypoints,
                    count
                )}
                {this.iconRender(
                    isOffRoadRoute,
                    t('Off-road Routing'),
                    true,
                    offRoadImg
                )}
            </div>
        );
    }
}

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        ride: state.edit_ride.present.ride
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onChangeRouteType: (isOffroad, ride) => {
            dispatch(updateRide('offRoad', isOffroad));
            dispatch(
                updateRouteShape((ride || {}).id, {
                    isOffroad: !!isOffroad
                })
            );
        },
        setError: (error) => {
            dispatch(setError(error));
        }
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(RouteType);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
