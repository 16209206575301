/******************************************************************************\
 * File: Card.jsx
 *
 * Author: Gigster
 *
 * Description: Base class for Ride, Dealer and Event cards
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/cards/Card.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Link from '@/components/common/header/Link';
import Title from '@/components/common/Title';
import ArrowLink from '@/components/common/ArrowLink';

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
const log = createLogger('Card', true);

//App context
import AppContext from '@/contexts/AppContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Card extends React.Component {
    addDefaultSrc = (e) => {
        e.target.src = '/img/image-route-thumbnail-placeholder.jpg';
    };

    render() {
        const {
            className,
            onClick,
            title,
            to,
            image,
            overlay,
            selected,
            large,
            wide,
            offRoad,
            Action,
            actionProps,
            Detail,
            detailProps,
            footerLinks,
            fixedWidth,
            typeDetail,
            shortImage,
            hasImages,
            onClickImages,
            isSidePanel,
            isDealer,
            isEvent,
            isTall,
            idLabel,
            rowIndex,
            ...rest
        } = this.props;

        const hasImage = !!(image || overlay || large);

        const { isMobile } = this.context;
        const cn = classNames(style.Card, {
            [style['selected']]: selected,
            [style['large']]: large,
            [style['wide']]: wide,
            [style['fixedWidth']]: fixedWidth,
            [style['hasImage']]: hasImage,
            [style['shortImage']]: shortImage,
            [style['isSidePanel']]: isSidePanel,
            [style['isDealer']]: isDealer,
            [style['isEvent']]: isEvent,
            [className]: className
        });

        const footerStyle = isTall ? { marginTop: '-32px' } : {};
        const { isHome } = detailProps;

        return (
            <div className={cn} {...rest}>
                {hasImage && (
                    <Link
                        className={style.CardImage}
                        plain
                        to={to}
                        onClick={onClick}
                        id={
                            idLabel
                                ? `${idLabel + '-image-' + rowIndex}`
                                : undefined
                        }
                        data-testid={
                            idLabel
                                ? `${idLabel + '-image-' + rowIndex}`
                                : undefined
                        }>
                        {image && (
                            <img onError={this.addDefaultSrc} src={image} />
                        )}
                        {overlay && (
                            <div className={style.overlay}>
                                <img src={overlay} />
                            </div>
                        )}
                        {hasImages && (
                            <div className={style.hasImages}>
                                <img
                                    src={hasImages}
                                    className={style.img}
                                    onClick={onClickImages}
                                />
                            </div>
                        )}
                    </Link>
                )}

                <div
                    className={style.CardDetail}
                    onClick={isMobile ? onClick : undefined}>
                    {typeDetail && typeDetail}
                    {!!isMobile && !!Action && <Action {...actionProps} />}
                    {!isEvent && (
                        <div className={style.header}>
                            <Link
                                className={style.title}
                                plain
                                to={to}
                                onClick={onClick}
                                title={title}
                                id={
                                    idLabel
                                        ? `${idLabel + '-title-' + rowIndex}`
                                        : undefined
                                }
                                data-testid={
                                    idLabel
                                        ? `${idLabel + '-title-' + rowIndex}`
                                        : undefined
                                }>
                                <Title text={title} />
                            </Link>
                            {!wide && offRoad && !!isHome && (
                                <img
                                    src={'/img/icon-route-type-off-road.svg'}
                                    style={{
                                        height: '28px',
                                        padding: '0 8px 1px',
                                        marginTop: '-2px'
                                    }}
                                />
                            )}
                            {!!Action && !isMobile && (
                                <Action {...actionProps} />
                            )}
                        </div>
                    )}

                    <Detail {...detailProps} />
                    <div className={style.footer} style={footerStyle}>
                        {(footerLinks || [])
                            .filter((e) => e)
                            .map((link, i) => (
                                <ArrowLink
                                    key={i}
                                    className={style.LinkText}
                                    {...link}
                                    id={
                                        idLabel
                                            ? `${
                                                  idLabel +
                                                  '-' +
                                                  link.text.toLowerCase() +
                                                  '-' +
                                                  rowIndex
                                              }`
                                            : undefined
                                    }
                                    data-testid={
                                        idLabel
                                            ? `${
                                                  idLabel +
                                                  '-' +
                                                  link.text.toLowerCase() +
                                                  '-' +
                                                  rowIndex
                                              }`
                                            : undefined
                                    }
                                />
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}

Card.contextType = AppContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Card;
