/******************************************************************************\
 * File: BasicAuth.jsx
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Input from '@/components/common/form/Input';
//------------------------------------------------------------------------------
// BasicAuth Functional Component -----------------------------------------------------------------
const BasicAuth = ({
    url = '/auth',
    required = true,
    persist = true,
    persistKey = 'authorization',
    children
}) => {
    const [authed, setAuthed] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!required) return;

        const token = getToken();
        if (token) {
            auth(token).catch((err) => setToken(''));
        }
    }, []);

    const submit = (e) => {
        e.preventDefault();
        const inputs = e.target.getElementsByTagName('input');
        auth(toToken(inputs.auth_user.value, inputs.auth_password.value)).catch(
            (err) => {
                throw err;
            }
        );
    };

    const auth = (token) => {
        setLoading(true);

        return axios
            .post(url, {}, { headers: { Authorization: `Basic ${token}` } })
            .then((resp) => {
                setAuthed(true);
                setLoading(false);
                setToken(token);
            })
            .catch((err) => {
                setLoading(false);
                throw err;
            });
    };

    const getToken = () => {
        if (!persist || !window.localStorage) return null;
        return window.localStorage.getItem(persistKey);
    };

    const setToken = (value) => {
        if (!persist || !window.localStorage) return;
        window.localStorage.setItem(persistKey, value);
    };

    const toToken = (user, password) => {
        return window.btoa(`${user}:${password}`);
    };

    const badgeSharing =
        window.location.search.includes('b=') &&
        window.location.search.includes('s=');

    if (badgeSharing) {
        return children;
    }
    if (required && !authed) {
        return loading ? null : <LoginForm onSubmit={submit} />;
    }

    return children;
};

//------------------------------------------------------------------------------
// LoginForm Subcomponent ------------------------------------------------------
const LoginForm = ({ onSubmit }) => (
    <form
        id="basic_auth"
        onSubmit={onSubmit}
        style={{ maxWidth: '400px', margin: '20px auto' }}>
        <Input placeholder="User" name="auth_user" />
        <Input placeholder="Password" name="auth_password" type="password" />
        <button type="submit" style={{ width: '100%' }}>
            Login
        </button>
    </form>
);

LoginForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
};

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default BasicAuth;
