/******************************************************************************\
* File: Form.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/form/Form.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Form extends React.Component {
    render() {
        const { className, error, onSubmit, children, isSubmitting } =
            this.props;

        const cn = classNames(style.Form, {
            [style['animate']]: error && !isSubmitting,
            [className]: className
        });

        return (
            <div className={cn}>
                {!!error && <div className={style.error}>{error}</div>}
                <form onSubmit={onSubmit}>{children}</form>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Form;
