/******************************************************************************\
 * File: RouteScenicMarker.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RouteScenicMarker extends React.Component {
    render() {
        const { className, onClick, highlight, isOnRoute } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <div
                style={{
                    ...style,
                    height: '40px',
                    width: '37px',
                    margin: '-40px 0 0 -19px'
                }}>
                <svg
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                    viewBox={!isOnRoute ? '0 0 31 43' : '0 0 30 43'}>
                    <g fill="none" fillRule="evenodd">
                        {!isOnRoute && (
                            <circle
                                cx="15"
                                cy="15"
                                r="14"
                                fill="#000"
                                stroke="#C7C7C7"
                                strokeWidth="1"></circle>
                        )}
                        {highlight && <path d="M0 0h30v30H0z" opacity=".6" />}
                        {isOnRoute && (
                            <path
                                fill={'#f60'}
                                d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
                            />
                        )}
                        <path
                            d="M7,21 L11.0613333,14.0975 L14.1946667,16.6975 L17.4746667,11 L23,21 L7,21 Z M12,12 C10.8954305,12 10,11.1045695 10,10 C10,8.8954305 10.8954305,8 12,8 C13.1045695,8 14,8.8954305 14,10 C14,11.1045695 13.1045695,12 12,12 Z"
                            fill={!isOnRoute || highlight ? '#fff' : '#000'}
                            fillRule="nonzero"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}
export default RouteScenicMarker;
