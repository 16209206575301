/******************************************************************************\
 * File: TrafficLine.jsx
 *
 * Author: Gigster
 *
 * Description: Traffic Line component formed with Abstracted Lines
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Line from '@/components/common/map/Line';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class TrafficLine extends React.Component {
    static defaultProps = {
        style: {
            lineWidth: 5
        }
    };

    static defaultColors = {
        heavy: '#E72734',
        medium: '#FECE2F',
        light: '#64B975',
        default: '#808080'
    };

    getTrafficColor = (quality) => {
        return (
            TrafficLine.defaultColors[quality] ||
            TrafficLine.defaultColors['default']
        );
    };

    render() {
        const { traffic, style: styleProp, sections, ...rest } = this.props;
        return traffic.map((t, i) => (
            <Line
                {...rest}
                points={t.points}
                sections={sections}
                style={{
                    ...styleProp,
                    strokeColor: this.getTrafficColor(t.quality)
                }}
                key={i}
            />
        ));
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default TrafficLine;
