import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import { MAX_WAYPOINTS } from '@/helpers/here';

import { translate, proxyProp } from '@/helpers/i18n';
const t = translate('error.index');
const tMessage = proxyProp('message', t);

/* */
// Types
export const SET_ERROR = 'error/SET';
export const CLEAR_ERROR = 'error/CLEAR';

t('Not Connected. Please Try Again Later.');
export const NETWORK_ERROR = tMessage({
    id: 'offline',
    message: 'Not Connected. Please Try Again Later.',
    persist: true,
    dismissable: false
});

t('Something went wrong.');
export const AUTH_ERROR = tMessage({
    id: 'auth',
    message: 'Something went wrong.'
});

export const ACCOUNT_ERROR = tMessage({
    id: 'auth',
    message: 'Account Temporarily Locked Out.'
});

t('An error occurred while saving your ride.');
export const CREATE_RIDE_ERROR = tMessage({
    id: 'create_ride',
    message: 'An error occurred while saving your ride.'
});

t("We couldn't find a ride.");
export const ROUTING_ERROR = tMessage({
    id: 'routing',
    message: "We couldn't find a ride."
});

export const ROUTE_DISCONNECTED_ERROR = tMessage({
    id: 'routing',
    message: "We couldn't find a ride to that destination."
});

export const ROUTE_NO_END_POINT_ERROR = tMessage({
    id: 'routing',
    message: "We couldn't find a ride to that destination."
});

t("We couldn't find that ride.");
export const RIDE_ERROR = tMessage({
    id: 'ride',
    message: "We couldn't find that ride."
});

t("We couldn't find that dealer.");
export const DEALER_ERROR = tMessage({
    id: 'dealer',
    message: "We couldn't find that dealer."
});

t("We couldn't find that event.");
export const EVENT_ERROR = tMessage({
    id: 'event',
    message: "We couldn't find that event."
});

t('Session timed out.');
export const SESSION_TIMEOUT_ERROR = tMessage({
    id: 'session',
    message: 'Session timed out.'
});

export const createMaxWaypointsError = (count = 0) => ({
    id: 'routing_max_waypoints',
    message: t('maxWaypointsError', {
        max: MAX_WAYPOINTS,
        count,
        t: 'Rides can have up to {max} points and you have {count}.'
    }),
    dismissable: count < MAX_WAYPOINTS
});

/* */
// Action Creators

export const handleRoutingError = (e) => {
    const routingErrMap = {
        NGEO_ERROR_GRAPH_DISCONNECTED: ROUTE_DISCONNECTED_ERROR,
        NGEO_ERROR_ROUTE_NO_END_POINT: ROUTE_NO_END_POINT_ERROR
    };

    const getErrorCode = (e) => {
        if (e.code && e.code.key === 'error_code') return e.code;
        if ((e.additionalData || {}).length)
            return e.additionalData.find((e) => e.key === 'error_code');
    };
    const error_code = getErrorCode(e);
    const errValue = routingErrMap[(error_code || {}).value] || ROUTING_ERROR;
    return errValue;
};

export const setError = (data) => ({
    type: SET_ERROR,
    data: { ...data, createdAt: Date.now() }
});

export const clearError = ({ id }) => ({ type: CLEAR_ERROR, data: { id } });

export const monitorNetworkStatus = () => (dispatch) => {
    if (!window) return;

    // Initial status
    const online =
        typeof navigator.onLine === 'undefined' || navigator.onLine === true;
    dispatch(online ? clearError(NETWORK_ERROR) : setError(NETWORK_ERROR));

    // Watch for changes
    window.addEventListener('offline', () => dispatch(setError(NETWORK_ERROR)));
    window.addEventListener('online', () =>
        dispatch(clearError(NETWORK_ERROR))
    );
};

export const checkWaypointAction =
    (actionType, warnBefore = 10) =>
    (dispatch, getState) => {
        const { waypoints } = getState().edit_ride.present.ride;

        const count = (waypoints || []).length;
        const nextCount =
            count +
            (actionType === 'add' ? 1 : actionType === 'remove' ? -1 : 0);

        const isValid = nextCount <= MAX_WAYPOINTS || actionType === 'remove';

        if (
            (count !== nextCount && nextCount >= MAX_WAYPOINTS - warnBefore) ||
            nextCount >= MAX_WAYPOINTS
        ) {
            const error = createMaxWaypointsError(isValid ? nextCount : count);
            dispatch(setError(error));
        } else if (nextCount < MAX_WAYPOINTS - warnBefore) {
            dispatch(clearError(createMaxWaypointsError()));
        }

        return isValid;
    };

/* */
// Reducer
const initialState = {
    errors: [],
    location: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR: {
            const { data: error } = action;
            return { ...state, errors: [error].concat(state.errors) };
        }

        case CLEAR_ERROR: {
            const { id } = action.data;
            return {
                ...state,
                errors: state.errors.filter((error) => error.id !== id)
            };
        }

        case '@@router/LOCATION_CHANGE': {
            const prevLocation = state.location;
            const location = action.payload.pathname;

            return {
                errors:
                    prevLocation !== location
                        ? state.errors.filter((error) => error.persist === true)
                        : state.errors,
                location
            };
        }

        case AUTH_INVALIDATE:
        default: {
            return state;
        }
    }
};
