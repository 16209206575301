/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <path
                fill="#FFF"
                d="M7.5 37.09V38h3.613V19.935a6.323 6.323 0 1112.645 0v7.895h-7.096L25.581 38 34.5 27.83h-7.111l-.002-.669-.016-7.226c0-5.487-4.448-9.935-9.936-9.935-5.487 0-9.935 4.448-9.935 9.935V37.09z"></path>
        </svg>
    );
}

export default Icon;
