/******************************************************************************\
 * File: DisplayRows.jsx
 *
 * Author: Gigster
 *
 * Description: DisplayRows
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
// import style from '@/style/common/cards/DisplayRows.scss';
import style from '@/style/myAccountView/SavedView.scss';
//------------------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import DisplayRow from '@/components/common/cards/DisplayRow';
import BookmarkIcon from '@/components/common/icons/Bookmark';
import Spinner from '@/components/common/loading/Spinner';
import SectionTitle from '@/components/homeView/SectionTitle';
import Select from '@/components/common/cards/Select';
import NullState from '@/components/common/cards/NullState';
import Loading from '@/components/common/loading/Loading';
//App context
import AppContext from '@/contexts/AppContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class DisplayRows extends React.Component {
    static defaultProps = {
        label: '',
        large: true,
        itemProps: {},
        defaultSort: 0
    };

    constructor(props) {
        super(props);
        const sort = props.sorts
            ? this.getSort(props.sorts, props.sortKey) || props.sorts[0]
            : undefined;
        this.state = {
            sort
        };
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.sortKey !== this.props.sortKey) {
            this.setState({
                sort: this.getSort(this.props.sorts, this.props.sortKey)
            });
        }
    };

    getSort = (sorts, sortKey) => {
        return sorts.find((sort) => sort.key === sortKey);
    };

    getSortedItems = () => {
        const { items, sortMap } = this.props;
        const { sort } = this.state;
        return sort ? sortMap[sort.key](items) : items;
    };

    onSortChange = (option) => {
        const { onSortChange } = this.props;
        onSortChange && onSortChange(option);
        this.setState({ sort: option });
    };

    render() {
        const {
            label,
            labelKey,
            sorts,
            itemCount,
            deleteEventBookmark,
            deleteBookmark,
            addBookmark,
            title,
            Item,
            onDelete,
            rideCheckboxData,
            onRequestToggleSidebar,
            nullStateData,
            currentuserid,
            isLoading
        } = this.props;
        const { isMobile } = this.context;

        const { sort } = this.state;
        const cn = classNames(style[labelKey]);
        const sortedItems = this.getSortedItems();
        const items = sortedItems[labelKey] || sortedItems;
        return (
            (
                <div>
                    {!isMobile && (
                        <SectionTitle size="medium-large" text={title} />
                    )}
                    <div>
                        {isMobile && (
                            <div
                                className={style.left}
                                onClick={onRequestToggleSidebar}>
                                <div className={style.iconContainer}>
                                    <div className={style.bar1} />
                                    <div className={style.bar2} />
                                    <div className={style.bar3} />
                                </div>
                            </div>
                        )}
                        <div className={style.right}>
                            <div className={style.ItemCount}>
                                {`(${items.length}) ${label}`}
                            </div>
                            <Select
                                className={'sorte'}
                                selected={sort}
                                options={sorts}
                                onSelect={this.onSortChange}
                            />
                        </div>
                        {isLoading && <Loading loading={isLoading} />}

                        {!isLoading && itemCount === 0 && (
                            <div style={{ paddingLeft: '-160px' }}>
                                <NullState
                                    title={nullStateData.title}
                                    links={nullStateData.links}
                                />
                            </div>
                        )}
                        {itemCount > 0 && (
                            <div>
                                {items.map((item, i) =>
                                    labelKey === 'events' ? (
                                        <DisplayRow
                                            className={cn}
                                            label={label}
                                            item={item}
                                            labelKey={labelKey}
                                            key={
                                                item.eventId || item.rideId || i
                                            }
                                            icon={BookmarkIcon}
                                            deleteEventBookmark={
                                                deleteEventBookmark
                                            }
                                            deleteBookmark={deleteBookmark}
                                            isMobile={isMobile}
                                            onDelete={onDelete}
                                        />
                                    ) : (
                                        <Item
                                            item={item}
                                            key={item.id}
                                            large={isMobile}
                                            wide={!isMobile}
                                            isMobile={isMobile}
                                            deleteBookmark={deleteBookmark}
                                            addBookmark={addBookmark}
                                            editable={
                                                item.userId === currentuserid
                                            }
                                            onDelete={onDelete}
                                            currentuserid={currentuserid}
                                            rideCheckboxData={rideCheckboxData}
                                        />
                                    )
                                )}
                            </div>
                        )}
                    </div>
                </div>
            ) ||
            (![false, undefined].includes(sortedItems.loading) && (
                <div className={style.center}>
                    <Spinner />
                </div>
            ))
        );
    }
}

DisplayRows.contextType = AppContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default DisplayRows;
