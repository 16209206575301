/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <path
                    fillRule="nonzero"
                    d="M20 17c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8v-3zm0 3v-3c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8z"></path>
                <path
                    fillRule="nonzero"
                    d="M17.3 25.368l-.1-.1V23.9l.1-.1h5.472l.1.1-.004.82-2.34 7.872-.096.072h-1.728l-.095-.131 2.357-7.165z"></path>
                <path
                    fillOpacity="0.4"
                    fillRule="nonzero"
                    d="M20 17c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8v-3zm0 3v-3c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8z"></path>
                <path d="M18.5 11h3v9H20a1.5 1.5 0 01-1.5-1.5V11zM20 36h1.5v9h-3v-7.5A1.5 1.5 0 0120 36z"></path>
                <path d="M13.5 12L20 3l6.5 9z"></path>
            </g>
        </svg>
    );
}

export default Icon;
