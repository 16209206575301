import { v4 as uuid } from 'uuid';
import { prettyAddress, arraySwap } from '@/helpers/functions';
import { WaypointType } from '@/helpers/constants';
import { latlngToAddress } from '@/helpers/here';

/* */
// Types
export const INSERT_WAYPOINT = 'edit_ride/INSERT_WAYPOINT';
export const REMOVE_WAYPOINT = 'edit_ride/REMOVE_WAYPOINT';
export const EDIT_WAYPOINT = 'edit_ride/EDIT_WAYPOINT';
export const PREVIEW_WAYPOINT = 'edit_ride/PREVIEW_WAYPOINT';
export const SET_WAYPOINTS = 'edit_ride/SET_WAYPOINTS';
export const SWAP_WAYPOINTS = 'edit_ride/SWAP_WAYPOINTS';

export const types = [
    INSERT_WAYPOINT,
    REMOVE_WAYPOINT,
    EDIT_WAYPOINT,
    PREVIEW_WAYPOINT,
    SET_WAYPOINTS,
    SWAP_WAYPOINTS
];

/* */
// Helpers
/** Removes dots on the start and the end of state (not between waypoints). */
const tieUpLooseEnds = (state) => {
    const i = state.findIndex(
        (waypoint) => waypoint.type !== WaypointType.WAYPOINT
    );
    const j = state
        .slice()
        .reverse()
        .findIndex((waypoint) => waypoint.type !== WaypointType.WAYPOINT);
    return i >= 0 ? state.slice(i, state.length - j) : [];
};

const formatHereAddress = (address) => {
    const { label, title } = address;

    if (typeof label === 'string') return label;
    if (typeof title === 'string') return title;

    if (address.Street || address.City) {
        return prettyAddress({
            address1: `${address.HouseNumber || ''} ${
                address.Street || ''
            }`.trim(),
            city: address.City,
            stateCode: address.State,
            postalCode: address.PostalCode
        });
    }

    return `${address.lat}, ${address.lng}`;
};

const ensureAddress = (data) => {
    const { position } = data;
    const lat = (data || {}).lat || (position || {}).lat;
    const lng = (data || {}).lng || (position || {}).lng;
    const { isOnRoute, offRoad } = data;
    // Dragging when using onRoad moves marker to address lat / lng
    const useAddressLatLng = isOnRoute && !offRoad;
    return data.address
        ? Promise.resolve(data)
        : latlngToAddress({
              lat,
              lng,
              isDragging: isOnRoute
          }).then((addressComponents) => ({
              ...data,
              label: data.title,
              name: data.name,
              address: formatHereAddress(addressComponents),
              addressComponents,
              lat: useAddressLatLng ? addressComponents.lat : lat,
              lng: useAddressLatLng ? addressComponents.lng : lng
          }));
};
export const isStartOrEnd = (id, state) => {
    return (
        state &&
        state.length &&
        (state[0].id === id || state[state.length - 1].id === id)
    );
};

export const createWaypoint = (data) => {
    return ensureAddress({
        ...data,
        id: !!data && data.id ? data.id : uuid(),
        type: Object.values(WaypointType).includes(data.type)
            ? data.type
            : WaypointType.LOCATION
    });
};
/* */
// Action Creators
export const addWaypoint = (data) =>
    insertWaypoint(Number.MAX_SAFE_INTEGER, data);

export const insertWaypoint =
    (index, data, offRoad = false) =>
    (dispatch) => {
        const insertPoint = (waypoint, index) => {
            const newWaypoint = {
                type: INSERT_WAYPOINT,
                data: { waypoint, index }
            };
            dispatch(newWaypoint);
            return newWaypoint;
        };

        if (offRoad && data.type === WaypointType.WAYPOINT) {
            const waypoint = {
                ...data,
                id: uuid()
            };
            return insertPoint(waypoint, index);
        }

        return createWaypoint(data).then((waypoint) =>
            insertPoint(waypoint, index)
        );
    };

export const addPenultimateWaypoint = (data) => insertWaypoint(-1, data);

export const removeWaypoint = (id, i, offRoad) => {
    return {
        type: REMOVE_WAYPOINT,
        data: {
            id: (id || {}).id || id,
            i: (id || {}).i || i,
            offRoad: (id || {}).offRoad || offRoad
        }
    };
};

export const editWaypoint =
    (id, edits, offRoad = false) =>
    (dispatch) => {
        const didEditPosition = edits.lat || edits.lng;
        const overrides =
            didEditPosition && !edits.address ? { address: null } : {};

        const time = Date.now();
        const initialAction = {
            type: EDIT_WAYPOINT,
            time,
            data: { id, edits }
        };

        if (!didEditPosition) {
            return dispatch(initialAction);
        }

        dispatch({ ...initialAction, undoable: false });

        return ensureAddress({ ...edits, ...overrides, offRoad }).then(
            (editsWithAddress) => {
                return dispatch({
                    type: EDIT_WAYPOINT,
                    time,
                    data: { id, edits: editsWithAddress }
                });
            }
        );
    };

export const previewWaypoint =
    (id, edits, offRoad = false) =>
    (dispatch) => {
        const didEditPosition = edits.lat || edits.lng;

        const time = Date.now();
        const initialAction = {
            type: PREVIEW_WAYPOINT,
            time,
            data: { id, edits },
            undoable: false
        };
        if (!didEditPosition) return;
        if (offRoad) return dispatch(initialAction);
        dispatch({ ...initialAction });
    };

export const setWaypoint = (id, edits) => ({
    type: EDIT_WAYPOINT,
    time: Date.now(),
    data: { id, edits }
});

export const setWaypoints = (data) => ({
    type: SET_WAYPOINTS,
    data
});

export const swapWaypoints = (a, b) => ({
    type: SWAP_WAYPOINTS,
    data: { a, b }
});

/* */
// Reducer
export const initialState = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case INSERT_WAYPOINT: {
            const { waypoint, index } = action.data;
            return state
                .slice(0, index)
                .concat([waypoint])
                .concat(state.slice(index));
        }

        case PREVIEW_WAYPOINT: {
            const { id, edits } = action.data;

            return state.map((point) =>
                point.id === id ? { ...point, ...edits } : point
            );
        }

        case EDIT_WAYPOINT: {
            const { id, edits } = action.data;

            return state.map((point) =>
                point.id === id ? { ...point, ...edits } : point
            );
        }

        case REMOVE_WAYPOINT: {
            const { id, offRoad, i } = action.data;
            const includesId = !!state.find((o) => o.id === id);
            if (offRoad) {
                if (includesId) return state.filter((point) => point.id !== id);
                if (!!i && !includesId)
                    return state.filter((_, wp) => wp !== i);
            }
            if (!!id && includesId) state.filter((point) => point.id !== id);
            if (i) state.filter((_, wp) => wp !== i);
            return tieUpLooseEnds(state.filter((point) => point.id !== id));
        }

        case SET_WAYPOINTS: {
            return action.data;
        }

        case SWAP_WAYPOINTS: {
            const { a, b } = action.data;
            const aIndex = state.indexOf(a);
            const bIndex = state.indexOf(b);
            return aIndex >= 0 && bIndex >= 0
                ? arraySwap(state, aIndex, bIndex)
                : state;
        }

        default: {
            return state;
        }
    }
};
