/******************************************************************************\
* File: Loading.jsx
*
* Author: Gigster
*
* Description: Loading screen
*
* Notes: Absolutely positioned and meant to be in a relative parent
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/loading/Loading.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Spinner from './Spinner';
//------------------------------------------------------------------------------
// React Function -----------------------------------------------------------------
const Loading = (props) => {
    const { className, loading } = props;
    const cn = classNames(style.Loading, {
        [className]: className
    });

    if (!loading) return null;

    return (
        <div className={cn}>
            <Spinner className={style.spinner} />
        </div>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Loading;
