/******************************************************************************\
* File: StartFlag.jsx
*
* Author: Gigster
*
* Description:
*
* Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class AddLocation extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [style['no-stroke']]: true,
            [className]: !!className
        });

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16">
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g fill="#FFF" transform="translate(-27 -419)">
                        <g transform="translate(0 66)">
                            <g transform="translate(8 201)">
                                <g transform="translate(0 139.5)">
                                    <g transform="translate(19 12.5)">
                                        <path d="M5.067 2.91c2.798 0 5.066 2.186 5.066 4.884 0 1.8-1.689 4.534-5.066 8.206C1.689 12.328 0 9.593 0 7.794 0 5.096 2.268 2.91 5.067 2.91zm0 2.93c-1.12 0-2.027.875-2.027 1.954 0 1.08.907 1.955 2.027 1.955 1.119 0 2.026-.875 2.026-1.955 0-1.079-.907-1.954-2.026-1.954zM12.743 0c.4 0 .724.326.724.727l-.001 1.818h1.81c.367 0 .67.274.717.629l.007.099a.726.726 0 01-.724.727h-1.81v1.818a.726.726 0 01-.625.72l-.098.007a.726.726 0 01-.724-.727V4h-1.81a.725.725 0 01-.717-.629l-.006-.098c0-.402.324-.728.724-.728h1.809V.727c0-.368.272-.672.626-.72z"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
export default AddLocation;
