import { persist } from '@/helpers/persistor';
import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';

/* */
// Types
export const SET_ALL = 'tutorial/SET_ALL';
export const DISABLE = 'tutorial/DISABLE';
export const UPDATE = 'tutorial/UPDATE';

/* */
// Action Creators
export const completeStep = (type) => (key) => ({
    type: DISABLE,
    data: { type, key }
});

/** Sets an arbitrary map field to value. */
export const update = (field, value) => ({
    type: UPDATE,
    data: { field, value }
});

export const setAll = (key) => (value) => ({
    type: SET_ALL,
    data: { key, value }
});

export const completeCreateStep = (key) => (dispatch, getState) => {
    dispatch(completeStep('create')(key));
    dispatch(persist('tutorial.create', getState().tutorial.create));
};

/* */
// Reducer
export const initialState = {
    create: {
        search: true,
        map: true,
        mobileCTA: true
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE: {
            const { field, value } = action.data;

            return {
                ...state,
                [field]: value
            };
        }

        case SET_ALL: {
            const { key } = action.data;

            return {
                ...state,
                [action.data]: Object.keys(state[key]).reduce(
                    (memo, stateKey) => {
                        memo[stateKey] = true;
                        return memo;
                    },
                    {}
                )
            };
        }

        case DISABLE: {
            const { type, key } = action.data;

            return {
                ...state,
                [type]: {
                    ...state[type],
                    [key]: false
                }
            };
        }

        case AUTH_INVALIDATE:
        default:
            return state;
    }
};
