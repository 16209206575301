/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <path
                fill="#FFF"
                d="M8.73 27.567v6.923L8.732 38h3.508V27.567a7.121 7.121 0 017-7.148h4.157v6.91l9.887-8.633L23.397 10v6.91h-4.138c-5.853.068-10.542 4.823-10.529 10.657z"></path>
        </svg>
    );
}

export default Icon;
