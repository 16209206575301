/******************************************************************************\
* File: Waypoint.jsx
*
* Author: Gigster
*
* Description: Route end map icon finish flag
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Waypoint extends React.Component {
    render() {
        const { style } = this.props;

        return (
            <div
                style={{
                    ...style,
                    height: '26px',
                    width: '26px',
                    margin: '-13px 0 0 -13px'
                }}>
                <svg
                    style={{
                        height: '100%',
                        width: '100%'
                    }}
                    viewBox="0 0 26 26">
                    <circle
                        fill={style && style.hoverIndicator ? '#FFF' : '#F60'}
                        stroke={style && style.hoverIndicator ? '#F60' : '#FFF'}
                        strokeWidth="2"
                        cx="13"
                        cy="13"
                        r="6.9936899"
                    />
                </svg>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Waypoint;
