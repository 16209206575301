/******************************************************************************\
 * File: evCharging.js
 *
 * Author: Gigster
 *
 * Description: evCharging helpers
 *
 * Notes:
\******************************************************************************/
import { translate, formatTime } from '@/helpers/i18n';
const t = translate('sidebar.ChargingStationDetails');

const twentyfoursevenObj = {
    0: {
        period_begin: '00:00:00',
        period_end: '24:00:00',
        weekday: 0,
        endday: 6
    }
};

export const transformRanges = (twentyfourseven, daysHours) => {
    if (!twentyfourseven && !daysHours) return;
    if (twentyfourseven) return twentyfoursevenObj;
    const { regular_hours } = daysHours || {};
    let startDay,
        prevBegin,
        prevEnd = null;
    return regular_hours[0].reduce((output, daysHours) => {
        const { period_begin, period_end, weekday } = daysHours;
        const hoursMatch = prevBegin === period_begin && prevEnd === period_end;

        if (hoursMatch) {
            output[startDay].endday = weekday;
            return output;
        }
        output[weekday] = {
            period_begin,
            period_end,
            weekday,
            endday: weekday
        };
        startDay = weekday;
        prevBegin = period_begin;
        prevEnd = period_end;
        return output;
    }, {});
};

export const isTwentyFourSeven = ({ regular_hours }) =>
    regular_hours &&
    regular_hours.every(
        (dh) => dh.period_begin === '00:00:00' && dh.period_end === '24:00:00'
    );

export const dayNames = [
    t('Sunday'),
    t('Monday'),
    t('Tuesday'),
    t('Wednesday'),
    t('Thursday'),
    t('Friday'),
    t('Saturday')
];

export const daysHours = ({ weekday, endday, period_end, period_begin }) => {
    const startDay = dayNames[weekday];
    const endDay = dayNames[endday];
    const start = formatTime(period_begin);
    const end = formatTime(period_end);
    return `${startDay} — ${endDay}: ${start} — ${end}`;
};

export const getEvseLevelCounts = (evses) =>
    evses.reduce((obj, evse) => {
        if (!evse.level) return obj;
        if (!obj[evse.level])
            obj[evse.level] = {
                count: 0,
                supplierName: evse.supplierName
            };
        obj[evse.level].count += evse.numberOfConnectors || 1;
        return obj;
    }, {});

export const dcAmpsVoltsToWatts = (amperage, voltage) => {
    const amps = parseInt(`${amperage}`.replace('A', ''), 10);
    const volts = parseInt(`${voltage}`.replace('V DC', '').split('-')[0], 10);
    return `${Math.round((amps * volts) / 1000)} kW`;
};

export const acAmpsVoltsToWatts = (amperage, voltage) => {
    const amps = parseInt(`${amperage}`.replace('A', ''), 10);
    const volts = parseInt(`${voltage}`.replace('V AC', '').split('-')[0], 10);
    return `${Math.round((amps * volts) / 1000)} kW`;
};
