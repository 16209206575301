/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <path
                    fillOpacity="0.4"
                    fillRule="nonzero"
                    d="M20 17c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8v-3zm0 3v-3c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8z"></path>
                <path
                    fillRule="nonzero"
                    d="M22.912 30.124c0 1.676-1.19 2.68-2.92 2.68-1.936 0-2.992-1.016-2.992-2.764l.1-.1h1.584l.1.1c0 .836.355 1.196 1.184 1.196.729 0 1.16-.461 1.16-1.184 0-.889-.474-1.244-1.653-1.256l-.099-.1v-1.368l.1-.1c1.066 0 1.448-.275 1.448-1.016 0-.572-.378-.944-1.016-.944-.533 0-.922.376-.944.912l-.1.096H17.28l-.1-.1c.012-1.56 1.12-2.476 2.74-2.476 1.684 0 2.788.916 2.788 2.452 0 .766-.344 1.486-.924 1.871.778.378 1.128 1.083 1.128 2.101z"></path>
                <path
                    fillRule="nonzero"
                    d="M20 17c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8v-3zm0 3v-3c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8z"></path>
                <path d="M18.5 11h3v9H20a1.5 1.5 0 01-1.5-1.5V11zM20 36h1.5v9h-3v-7.5A1.5 1.5 0 0120 36z"></path>
                <path d="M13.5 12L20 3l6.5 9z"></path>
            </g>
        </svg>
    );
}

export default Icon;
