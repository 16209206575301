import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import isBetween from 'dayjs/plugin/isBetween';
import style from '@/style/common/form/DatePicker.scss';

import CalendarIcon from '@/components/common/icons/Calendar';
import AngleIcon from '@/components/common/icons/Angle';

import { clickAway } from '@/helpers/hoc';
import { translate, getLocale, getStartOfWeekAdjustment } from '@/helpers/i18n';

const t = translate('form.DatePicker');

class DatePicker extends React.Component {
    static defaultProps = {
        placeholder: t('Date')
    };

    state = {
        month: dayjs().startOf('month'),
        showModal: false
    };

    onClickAway = () => {
        const { showModal } = this.state;

        if (showModal) {
            this.closeModal();
        }
    };

    onClick = () => {
        const { showModal } = this.state;

        if (!showModal) {
            this.openModal();
        }
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    onChange = (day) => {
        this.props.onChange(day);
        this.closeModal();
    };

    nextMonth = () => {
        this.setState({ month: dayjs(this.state.month).add(1, 'month') });
    };

    prevMonth = () => {
        this.setState({ month: dayjs(this.state.month).subtract(1, 'month') });
    };

    getDays = () => {
        const { value } = this.props;
        const { month } = this.state;

        const dayOfWeekStarted =
            month.$d.getDay() == 0 ? 7 : month.$d.getDay() - 1;
        const firstDay = dayjs(month).day()
            ? dayjs(month).startOf('isoWeek').subtract(dayOfWeekStarted, 'day')
            : month;

        const days = [];

        do {
            days.push([]);
            for (let i = 0; i < 7; i++) {
                const day = dayjs(firstDay).add(
                    (days.length - 1) * 7 + i,
                    'day'
                );
                days[days.length - 1].push({
                    day,
                    isSameMonth: day.month() === month.month(),
                    isToday: dayjs().isSame(day, 'day'),
                    isSelected: value && dayjs(value).isSame(day, 'day')
                });
            }
        } while (
            dayjs(days[days.length - 1][6].day)
                .add(1, 'day')
                .month() === month.month()
        );

        return days;
    };

    render() {
        const { className, value, placeholder, onChange, limitDays, wide } =
            this.props;

        const { month, showModal } = this.state;
        const startOfWeekAdjustment = getStartOfWeekAdjustment();
        const standardDayHeaders = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
        const dayHeaders = standardDayHeaders.splice(
            -startOfWeekAdjustment,
            startOfWeekAdjustment
        );
        const adjustedDayHeaders = [...dayHeaders, ...standardDayHeaders];

        const days = this.getDays();
        let today = dayjs();
        let limit = today.clone().add(limitDays || 730, 'day');
        limit = limit.toISOString();
        today = today.toISOString();

        const cn = classNames(style.DatePicker, {
            [className]: className,
            [style['wideInput']]: wide
        });
        dayjs.extend(isBetween);
        return (
            <div className={cn} onClick={this.onClick}>
                <CalendarIcon className={style.icon} />
                {(value && (
                    <p
                        className={classNames(style.text, {
                            [style['wide']]: wide
                        })}>
                        {value.format('MM/DD/YYYY')}
                    </p>
                )) || (
                    <p
                        className={classNames(style.placeholder, {
                            [style['wide']]: wide
                        })}>
                        {placeholder}
                    </p>
                )}
                {showModal && (
                    <div
                        className={classNames(style.calendar, {
                            [style['wide']]: wide
                        })}>
                        <div className={style.month_selector}>
                            <AngleIcon
                                className={style.angle}
                                direction="left"
                                onClick={this.prevMonth}
                            />
                            <p className={style.month}>
                                {month.format('MMM YYYY')}
                            </p>
                            <AngleIcon
                                className={style.angle}
                                direction="right"
                                onClick={this.nextMonth}
                            />
                        </div>

                        <div className={style.day_names}>
                            {adjustedDayHeaders.map((day, i) => (
                                <p
                                    className={style.day_name}
                                    key={`dayHeader-${i}`}>
                                    {day}
                                </p>
                            ))}
                        </div>

                        {days.map((week, i) => (
                            <div className={style.week} key={i}>
                                {week.map((day) => {
                                    const disabled =
                                        !day.isSameMonth ||
                                        !day.day.isBetween(
                                            today,
                                            limit,
                                            'day',
                                            '[]'
                                        );
                                    return (
                                        <p
                                            className={classNames(style.day, {
                                                [style.disabled]: disabled,
                                                [style.today]:
                                                    day.isSameMonth &&
                                                    day.isToday,
                                                [style.selected]: day.isSelected
                                            })}
                                            onClick={
                                                day.isSelected ||
                                                !day.isSameMonth ||
                                                disabled
                                                    ? undefined
                                                    : () => {
                                                          this.onChange(
                                                              day.day
                                                          );
                                                      }
                                            }
                                            key={day.day}>
                                            {day.isSameMonth
                                                ? day.day.date()
                                                : ''}
                                        </p>
                                    );
                                })}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default clickAway(DatePicker);
