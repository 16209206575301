/******************************************************************************\
 * File: Event.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/cards/Event.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Card from '@/components/common/cards/Card';
import Bookmark from '@/components/common/icons/Bookmark';
import Link from '@/components/common/header/Link';
import { selectItemById, previewEvent } from '@/store/events';
import { updateSidebar, centerPointZoom } from '@/store/map';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    shortenString,
    prettyHourRange,
    formattedDateRange,
    displayRowDate,
    prettyDate
} from '@/helpers/functions';
import { setEventBookmark, isEventBookmarked } from '@/store/eventBookmarks';
import { Routes, generatePath } from '@/helpers/routes';
import { analyticsWithData } from '@/helpers/analytics';
import { translate } from '@/helpers/i18n';
const t = translate('cards.Event');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const Detail = ({
    event,
    isBookmarked,
    name,
    startDate,
    endDate,
    eventHours,
    isSidePanel,
    onClickBookmark,
    eventHost,
    idLabel,
    rowIndex
}) => {
    const formattedDate = formattedDateRange(startDate, endDate).toUpperCase();

    const getFormattedDateRange = (start, end, narrow = true) => {
        const startDate = displayRowDate(start);
        const endDate = displayRowDate(end);
        const cnStartDate = classNames({
            [style['startDate']]: !!narrow
        });
        const cnEndDate = classNames({
            [style['endDate']]: !!narrow
        });

        const startDateFormatted = (
            <div className={cnStartDate}>
                <div className={style.month}>{startDate[0]}</div>
                <div className={style.day}>{startDate[1]}</div>
            </div>
        );

        const endDateFormatted =
            start !== end ? (
                <div className={cnEndDate}>
                    <div className={style.month}>{endDate[0]}</div>
                    <div className={style.day}>{endDate[1]}</div>
                </div>
            ) : (
                ''
            );

        const cn = classNames({
            [style['dates']]: !narrow,
            [style['narrowDates']]: !!narrow
        });

        return (
            <div className={cn}>
                {startDateFormatted}
                {endDateFormatted && <div className={style.hypen}>-</div>}
                {endDateFormatted}
            </div>
        );
    };

    return (
        <div className={style.Detail}>
            <div>
                {isSidePanel && (
                    <div style={{ display: 'flex' }}>
                        <div>
                            {getFormattedDateRange(startDate, endDate, true)}
                        </div>
                        <div className={style.eventDetail}>
                            <div
                                className={style.eventTitle}
                                id={
                                    idLabel
                                        ? `${idLabel + '-title-' + rowIndex}`
                                        : undefined
                                }
                                data-testid={
                                    idLabel
                                        ? `${idLabel + '-title-' + rowIndex}`
                                        : undefined
                                }>
                                {name}
                            </div>
                            <EventAction
                                event={event}
                                isBookmarked={isBookmarked}
                                onClickBookmark={onClickBookmark}
                                idLabel={idLabel}
                                rowIndex={rowIndex}
                            />
                            <div>{eventHost}</div>
                            {eventHours[0] && (
                                <div>
                                    {prettyHourRange(
                                        eventHours[0]
                                    ).toUpperCase()}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {!isSidePanel && (
                    <div>
                        <div>{formattedDate}</div>
                        {eventHours[0] && (
                            <div>
                                {prettyHourRange(eventHours[0]).toUpperCase()}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const EventAction = ({
    event,
    isBookmarked,
    onClickBookmark,
    isSidePanel,
    idLabel,
    rowIndex
}) => {
    const cn = classNames(style.Bookmark, {
        [style.isSidePanel]: isSidePanel
    });
    const id = idLabel ? `${idLabel + '-favorite-' + rowIndex}` : undefined;
    const dataId = idLabel ? `${idLabel + '-favorite-' + rowIndex}` : undefined;
    return (
        <Link
            className={cn}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClickBookmark(event);
                const onOff = !isBookmarked ? 'on' : 'off';
                if (!isBookmarked)
                    analyticsWithData('star event', {
                        value: onOff,
                        location: 'sidebar list view',
                        eventId: event.eventId,
                        dealerId: event.dealerId
                    });
            }}
            id={id}
            data-testid={dataId}>
            <Bookmark active={isBookmarked} />
        </Link>
    );
};

class Event extends React.Component {
    render() {
        const {
            className,
            item: event,
            setEventBookmark,
            onSelect,
            previewEvent,
            openSidebar,
            onHover,
            isBookmarked,
            partiallyExpanded,
            didSelectItem,
            forkable,
            isSidePanel,
            idLabel,
            rowIndex,
            centerPointZoom,
            ...rest
        } = this.props;

        const cn = classNames(style.Event, {
            [classNames]: !!className,
            [style['isSidePanel']]: isSidePanel
        });

        const { eventName: name } = event;

        const toggleBookmark = () => {
            const { event, deleteBookmark, addBookmark, isBookmarked, item } =
                this.props;
            if (!!deleteBookmark && !!isBookmarked) deleteBookmark(event.id);
            this.props.setBookmark(!isBookmarked);
        };

        const shortTitle = shortenString(name, 35);
        const getDealerName = (event) => {
            const eventHostName = !!event.eventActivities[0].address.name
                ? event.eventActivities[0].address.name
                : event.eventHostDescription;
            return shortenString(eventHostName, 30) || '';
        };
        const eventHost = getDealerName(event);
        const activities = event.eventActivities || [];
        const { hours: eventHours, address } = activities[0];
        const eventPosition = { lat: address.latitude, lng: address.longitude };
        const eventMarkerPosition = eventPosition;

        return (
            <Card
                to={generatePath(Routes.MAP_EVENT, { id: event.eventId })}
                className={cn}
                onClick={onSelect}
                title={shortTitle}
                Detail={Detail}
                detailProps={{
                    event: event,
                    name: shortTitle,
                    eventHost: eventHost,
                    startDate: event.startDate,
                    isBookmarked: isBookmarked,
                    endDate: event.endDate,
                    eventHours: eventHours,
                    onClickBookmark: toggleBookmark,
                    isSidePanel: isSidePanel,
                    idLabel,
                    rowIndex
                }}
                footerLinks={[
                    {
                        text: t('Details'),
                        onClick: () => {
                            previewEvent(event);
                            openSidebar('event');
                            centerPointZoom(eventMarkerPosition);
                        }
                    }
                ]}
                onMouseEnter={() => partiallyExpanded && onHover()}
                {...rest}
                large={false}
                isEvent={true}
                isSidePanel={isSidePanel}
                isTall={event.startDate != event.endDate}
                idLabel={idLabel}
                rowIndex={rowIndex}
            />
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        isBookmarked: isEventBookmarked(ownProps.item.eventId, state)
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    const { item, didSelectItem } = ownProps;
    const latlng = (item.eventActivities || [])[0] || {};
    const HOVER_ZOOM = 10;
    return {
        setBookmark: (value) => dispatch(setEventBookmark(item.eventId, value)),
        onSelect: () => {
            dispatch(selectItemById(item));
            didSelectItem && didSelectItem(item);
        },
        previewEvent: () => {
            dispatch(previewEvent(item));
            didSelectItem && didSelectItem(item);
        },
        openSidebar: (value, data = null) =>
            dispatch(updateSidebar(value, data)),
        centerPointZoom: (point) => dispatch(centerPointZoom(point)),
        onHover: () => {}
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(Event);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
