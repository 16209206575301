/******************************************************************************\
 * File: ProtectedRoute.jsx
 *
 * Author: Gigster
 *
 * Description: Ensures a user is authenticated before accessing the path
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useEffect } from 'react';
import * as mapActions from '@/store/map';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';
import { isAuthenticated } from '@/store/auth';
import { Routes } from '@/helpers/routes';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    const update = rest.update;
    useEffect(() => {
        // Clear selected data when navegating to protected routes
        update('selectedData')(null);
    }, []);
    return rest.isAuthenticated ? (
        <Component />
    ) : (
        <Navigate to={{ pathname: Routes.HOME }} />
    );
};

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        isAuthenticated: isAuthenticated(state)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        update: (field) => (value) => dispatch(mapActions.update(field, value))
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
