/******************************************************************************\
 * File: Checkbox.jsx
 *
 * Author: Gigster
 *
 * Description: Checkbox component
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/form/Checkbox.scss';
//------------------------------------------------------------------------------
// React Functional component -----------------------------------------------------------------

const TickIcon = () => (
    <svg viewBox="0 0 26 22">
        <g
            id="H-D-UI-Kit"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g
                transform="translate(-1042.000000, -2163.000000)"
                id="Checkboxes-&amp;-Radio-Buttons"
                fill="#000000">
                <g transform="translate(1030.000000, 1841.000000)">
                    <g
                        id="Check-Box---White"
                        transform="translate(0.000000, 309.000000)">
                        <g
                            id="Black-Tick"
                            transform="translate(12.000000, 13.000000)">
                            <g id="Page-1-Copy-9">
                                <path
                                    d="M25.7097286,2.60175385 L12.7097286,21.2171385 C12.4070143,21.6503692 11.9055857,21.9346769 11.3447286,21.9905231 C11.2778714,21.9972923 11.2091571,22.0006769 11.1423,22.0006769 C10.6538714,22.0006769 10.1803,21.8229846 9.8293,21.5048308 L0.543585714,13.0432923 C-0.1807,12.3816 -0.1807,11.3103692 0.543585714,10.6503692 C1.26972857,9.98867692 2.4453,9.98867692 3.16958571,10.6503692 L10.8191571,17.6209846 L22.5767286,0.784215385 C23.1264429,-0.0044 24.2760143,-0.237938462 25.1395857,0.264676923 C26.0050143,0.767292308 26.2594429,1.81313846 25.7097286,2.60175385"
                                    id="Fill-1"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

const Checkbox = ({
    className,
    checked = false,
    dark,
    disabled,
    onChange,
    ...rest
}) => {
    const handleChange = (event) => {
        onChange && onChange(!checked);
        event.preventDefault();
        event.stopPropagation();
    };

    const cn = classNames(style.Checkbox, {
        [className]: !!className,
        [style['checked']]: checked,
        [style['clickable']]: !!onChange,
        [style['dark']]: dark,
        [style['disabled']]: disabled
    });

    return (
        <div className={cn} onMouseDown={handleChange} {...rest}>
            <TickIcon />
        </div>
    );
};

export const Label = ({
    className,
    label,
    children,
    onChange,
    checked,
    ...rest
}) => (
    <div
        className={classNames(style.Label, {
            [style.clickable]: !!onChange,
            [className]: !!className
        })}
        onMouseDown={() => {
            onChange && onChange(!checked);
        }}
        {...rest}>
        <div className={style.text}>{label}</div>
        {children}
    </div>
);

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Checkbox;
