import history from '@/helpers/history';
import * as api from '@/helpers/api';
import createStore from '@/helpers/items';
import { once } from '@/helpers/functions';
import { Routes, dealerUrl } from '@/helpers/routes';
import { setError, DEALER_ERROR } from '@/store/error';
import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import { safePush } from '@/store/routing';
import { transformDealer } from '@/store/poi/helpers';
import { adjustRadius } from '@/helpers/functions';

const transformDealerData = (dealers) =>
    dealers.map((dealer) => {
        const { latitude, longitude, dealerId, regularHours } = dealer;
        return {
            ...dealer,
            ...transformDealer(dealer),
            latitude,
            longitude,
            dealerId,
            regularHours
        };
    });

const fetchMany = (lat, lng, radius, zoom) => {
    const adjustedRadius = adjustRadius(radius);
    if (!radius || (radius < 0.7 && !zoom)) return Promise.reject();
    return api
        .getDealers(lat, lng, adjustedRadius)
        .then((response) => transformDealerData(response.data.dealers));
};

const { FETCH, SELECT, fetchItems, selectItem, reducer } = createStore(
    'dealers',
    fetchMany
);

/* */
// Types
export { FETCH, SELECT };
export const SHOW_SIDEBAR = 'dealers/SHOW_SIDEBAR';
const SET_CODES = 'dealers/SET_CODES';

/* */
// Selectors
export const selectPrograms = (state, programCodes = []) =>
    programCodes
        .map((code) => (state.dealers.codes || [])[code])
        .filter((e) => e);

export const selectProgramsByType = (state, programCodes = []) => {
    const programs = selectPrograms(state, programCodes);
    return programs.reduce((memo, e) => {
        const key = e.type.toLowerCase();
        if (!(key in memo)) memo[key] = [];
        memo[key].push(e);
        return memo;
    }, {});
};

/* */
// Action Creators
export { fetchItems, selectItem };

export const routing_selectDealer = (dealerId) => (dispatch, getState) => {
    api.getDealer(dealerId, getState)
        .then((dealer) => {
            selectItem(dealer)(dispatch, getState);
        })
        .catch(() => {
            history.replace(Routes.MAP_DEALERS);
            dispatch(setError(DEALER_ERROR));
        });
};
//in case its usefull to deselect the dealer ( can be replicabe for rides and events )
export const deselectDealer = () => (dispatch, getState) => {
    selectItem(null)(dispatch, getState);
};

export const selectItemById = (dealerOrDealerId) => (dispatch) => {
    if (!dealerOrDealerId) return dispatch(safePush(dealerUrl()));
    dispatch(
        safePush(dealerUrl(dealerOrDealerId.dealerId || dealerOrDealerId))
    );
};

export const previewDealer = (dealerOrDealerId) => (dispatch) => {
    const url = dealerUrl(dealerOrDealerId.dealerId || dealerOrDealerId);
    dispatch(safePush(`${url}?sidebar=1`));
};

const createCodeMap = (codes) =>
    codes.reduce((m, e) => ((m[e.code] = e), m), {});

export const fetchDealerCodes = once(
    () => (dispatch) =>
        api.getDealerCodes().then((resp) =>
            dispatch({
                type: SET_CODES,
                data: createCodeMap(resp.data.dealerCodes)
            })
        )
);

/* */
// Reducer
export default (state, action) => {
    switch (action.type) {
        case SET_CODES:
            return { ...state, codes: action.data };

        case AUTH_INVALIDATE:
        default:
            return reducer(state, action);
    }
};
