/******************************************************************************\
 * File: Share.jsx
 *
 * Author: Gigster
 *
 * Description: SVG icon of Share
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Share extends React.Component {
    render() {
        const { className, onClick, active, id } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg
                className={cn}
                viewBox="0 0 18 18"
                onClick={onClick}
                id={id ? id : undefined}
                data-testid={id ? id : undefined}>
                <g id="Preview" strokeWidth="1" fillRule="evenodd">
                    <path d="M14.728299,11.9023261 C13.8710607,11.8973753 13.043972,12.2191761 12.4148806,12.802468 C12.2461329,12.9590927 12.094935,13.1341703 11.9648867,13.3241003 L6.29991404,10.1150943 C6.6104098,9.39768124 6.6104098,8.5830528 6.29991404,7.86518962 L11.9563369,4.68768865 C12.5858783,5.58558021 13.6177142,6.1157638 14.7147992,6.10541217 C16.4526755,6.18057402 17.9228055,4.8326115 17.9984044,3.09398738 C17.9988544,3.08048525 17.9993044,3.06743319 17.9997544,3.05348099 C17.9462051,1.31485688 16.493175,-0.052008625 14.7548487,0.0015498192 C14.7458488,0.0015498192 14.7372989,0.00199989016 14.728299,0.00244996112 C12.9899728,-0.0655107537 11.5256927,1.28875276 11.4572937,3.02737688 C11.4568437,3.03592823 11.4568437,3.04492965 11.4563937,3.05348099 C11.4586437,3.42613975 11.5333426,3.79474786 11.6773407,4.13860208 L6.00741803,7.32015369 C5.37337668,6.44296539 4.3532406,5.92808421 3.27145536,5.93888591 C1.53267909,5.8709252 0.0688490606,7.22563879 0.00089998772,8.9642629 L0,9.00386915 C0.0530992755,10.7429433 1.50612945,12.1093588 3.24490572,12.0562504 C3.25345561,12.0558003 3.26245549,12.0558003 3.27145536,12.0553503 C4.3532406,12.0657019 5.37337668,11.5508207 6.00741803,10.6736324 L11.6728407,13.8641854 C11.5306427,14.2098399 11.4568437,14.5797983 11.4563937,14.9533572 C11.4599936,15.7729364 11.8060389,16.5529093 12.4108307,17.1051464 C13.7239127,18.2982845 15.7281854,18.2982845 17.0412675,17.1051464 C18.2328512,16.0290268 18.3264499,14.1904869 17.2505146,12.998699 C17.1843655,12.9248873 17.1146165,12.8551263 17.0412675,12.7889659 C16.4121761,12.205674 15.5850873,11.8838732 14.728299,11.888824" />
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Share;
