/******************************************************************************\
 * File: mobile.jsx
 *
 * Author: Gigster
 *
 * Description: Mobile Helpers
 *
 * Notes:
\******************************************************************************/

// Universal links may need help to display
// Wait until page loads and scroll to display
export const universalLinkScroll = (delay) =>
    setTimeout(() => {
        window.scrollTo(0, -100);
    }, delay);
