/******************************************************************************\
 * File: Line.jsx
 *
 * Author: Gigster
 *
 * Description: Here map line
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { normalizeBounds, normalizeLatLng } from '@/helpers/functions';
import { bindHereProps, updateHereProps } from '@/helpers/map';

//MapContext
import MapContext from '@/contexts/MapContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Line extends React.Component {
    static defaultProps = {
        points: [],
        style: {
            lineWidth: 5,
            strokeColor: '#FF6600'
        }
    };

    componentDidMount() {
        this.update(this.props);
        bindHereProps(this.polyline, this.props);
    }
    componentDidUpdate = (prevProps) => {
        const { points: nextPoints, style: nextStyle } = this.props;
        const { points, style } = prevProps;

        if (nextPoints !== points) {
            this.update(this.props);
        }

        if (nextStyle !== style) {
            this.setStyle(nextStyle);
        }

        updateHereProps(this.polyline, prevProps, this.props);
    };

    componentWillUnmount() {
        this.removeLine();
    }

    removeLine = () => {
        const { map } = this.context;
        if (!map || !this.polyline) return;
        this.polyline.dispose();
        this.polyline = null;
    };

    update = (props) => {
        const { style, sections, points, offRoad } = props;
        this.drawPoints(points, sections, style, offRoad);

        this.polyline && this.outputData();
    };

    /** Redraw the points on the map. */
    drawPoints = (points, sections, style, offRoad) => {
        const { map } = this.context;
        // Make sure we have at least one point to draw
        if (
            !map ||
            (sections && !sections.length && sections.length < 1) ||
            (points && !points.length && points.length < 1)
        ) {
            this.removeLine();
            return;
        }
        if (!!sections && offRoad !== true) {
            const { onClick } = this.props;
            const lineStrings = [];
            sections.forEach((section) => {
                // convert Flexible Polyline encoded string to geometry
                lineStrings.push(
                    H.geo.LineString.fromFlexiblePolyline(section.polyline)
                );
            });
            const multiLineString = new H.geo.MultiLineString(lineStrings);
            // Create the polyline for the route
            if (this.polyline) {
                // If the routePolyline we just set has the new geometry
                this.polyline.setGeometry(multiLineString);
            } else {
                // If routePolyline is not yet defined, instantiate a new H.map.Polyline
                this.polyline = new H.map.Polyline(multiLineString, {
                    style: style
                });
            }
            onClick && this.polyline.addEventListener('tap', onClick);
            //this.removeLine();
            // Add the polyline to the object provider of the  newly created object layer
            //lop.getRootGroup().addObject(this.polyline);
            map.addObject(this.polyline);
        } else {
            const [first] = points;
            if (first.points) {
                const newpPoints = points.map((pts) => pts.points);
                points = [].concat.apply([], newpPoints);
            }
            // Initialize a linestring and add all the points to it:
            const line = new H.geo.LineString();
            points.forEach((p) => line.pushPoint(normalizeLatLng(p)));
            // draw the polyline
            if (this.polyline) {
                this.polyline.setGeometry(line);
            } else {
                const { onClick } = this.props;
                this.polyline = new H.map.Polyline(line, {
                    style
                });
                onClick && this.polyline.addEventListener('tap', onClick);
                map.addObject(this.polyline);
            }
        }
    };
    setStyle = (style) => this.polyline && this.polyline.setStyle(style);

    getBounds = () => {
        const boundingBox = this.polyline.getBoundingBox();
        const topLeft = boundingBox.getTopLeft();
        const bottomRight = boundingBox.getBottomRight();
        const ne = { lat: topLeft.lat, lng: bottomRight.lng };
        const sw = { lat: bottomRight.lat, lng: topLeft.lng };
        return { ne, sw };
    };

    outputData = () => {
        const { onDataChange } = this.props;
        //if (!this.polyline) return;
        const bounds = this.getBounds();

        onDataChange &&
            onDataChange({
                bounds: bounds && normalizeBounds(bounds)
            });
    };

    render() {
        return null;
    }
}
Line.contextType = MapContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Line;
