/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <path d="M10.724 9.5h3.489v28.638h-3.489z" opacity="0.4"></path>
                <path
                    fillRule="nonzero"
                    d="M16.356 19.303l-.001.644.001 3.422a11.494 11.494 0 005.889 9.96l8.245 5.139 1.845-2.953-8.238-5.135a8.108 8.108 0 01-4.263-6.89l.003-3.545v-.642h6.854L18.096 9.5 9.5 19.303h6.856z"></path>
                <path
                    d="M16.355 19.294h3.485v18.843h-3.485z"
                    opacity="0.4"></path>
            </g>
        </svg>
    );
}

export default Icon;
