/******************************************************************************\
 * File: HDLogo.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class HDLogo extends React.Component {
    render() {
        const { className, ...rest } = this.props;

        const cn = classNames(style.Icon, {
            [style['no-stroke']]: true,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 18 18" {...rest}>
                <g fill="none" fillRule="evenodd">
                    <path
                        fill="#FFF"
                        d="M3.873 9.71h10.254V8.072H3.873v1.636zM9 12.838c-1.737-.442-3.06-1.667-3.06-1.667.394-.24.527-.548.572-.74h4.976c.045.192.178.5.572.74 0 0-1.323 1.225-3.06 1.667zm0-7.666c1.58 1.19 3.071 1.111 3.071 1.111-.483.498-.564.895-.573 1.066H6.502c-.01-.171-.09-.568-.573-1.066 0 0 1.491.08 3.071-1.111zm5.85 2.177h-2.57c.015-.414.542-.612.542-.612V5.672C9.952 5.513 9 4.05 9 4.05s-.952 1.463-3.822 1.622v1.066s.527.198.542.612H3.15v3.082h2.562c-.146.532-.876.666-.876.666C6.926 13.293 9 13.644 9 13.644s2.073-.351 4.164-2.546c0 0-.73-.134-.876-.666h2.562V7.35z"
                    />
                </g>
            </svg>
        );
    }
}

export default HDLogo;
