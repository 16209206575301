import { setAccountData as setGigyaAccountData } from '@/helpers/gigya';
import { persist, get as getLocalStore } from '@/helpers/persistor';
import { makeEmailPrivate } from '@/helpers/functions';
import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import { ALPHANUMERIC } from '@/helpers/rides';
import { getDealer } from '@/helpers/api';
import { searchValueToWaypoint } from '@/helpers/here';

/* */
// Types
const SET_PROFILE = 'user/SET_PROFILE';
const UPDATE_PROFILE = 'user/UPDATE_PROFILE';
const SET_ACCOUNT_DATA = 'user/SET_ACCOUNT_DATA';
const UPDATE_ACCOUNT_DATA = 'user/UPDATE_ACCOUNT_DATA';
const SET_SORT_KEY = 'user/SET_SORT_KEY';
const UPDATE = 'user/UPDATE';

/* */
// Action Creators
const update = (key, value) => ({ type: UPDATE, data: { key, value } });

export const setProfile =
    (profile, remember = true) =>
    (dispatch, getState) => {
        if (remember) dispatch(persist('user.profile', profile));
        dispatch({ type: SET_PROFILE, data: { profile } });
    };

const isEvBikeOwner = ({ bikesOwned }) =>
    bikesOwned.some(
        (bike) => bike.model === 'ELW' || bike.model === 'Livewire'
    );

const isEBikeOwner = ({ bikesOwned }) =>
    bikesOwned.some((bike) => bike.type === 'ebike');

export const setAccountData =
    (data, remember = true) =>
    (dispatch) => {
        data.evBikeOwner = data.bikesOwned && isEvBikeOwner(data);
        data.eBikeOwner = data.bikesOwned && isEBikeOwner(data);
        if (remember) dispatch(persist('user.data', data));
        dispatch({ type: SET_ACCOUNT_DATA, data });

        dispatch(update('dealer', null));
        dispatch(update('home', null));

        if (data.preferredDealer && !data.dealer) {
            getDealer(data.preferredDealer).then(
                (dealer) => dealer && dispatch(update('dealer', dealer))
            );
        }
        const { additionalAddresses } = data;
        const addressObj = (additionalAddresses || []).find((a) => a.default);
        if (Object.keys(addressObj || {}).length) {
            const country = addressObj.country || 'United States';
            const keys = ['line1', 'city', 'state', 'zip', 'country'];

            const address = keys
                .map((k) => addressObj[k])
                .filter((e) => e)
                .join(' ')
                .trim();

            address.length &&
                searchValueToWaypoint(`${address} ${country}`).then(
                    (res) => res && dispatch(update('home', res))
                );
        }
    };

export const setMyDealer = (dealerId, isMyDealer) => (dispatch) => {
    const id = isMyDealer ? null : dealerId;
    dispatch(updateAccountData('preferredDealer', id));

    getDealer(dealerId).then(
        (dealer) => dealer && dispatch(update('dealer', dealer))
    );
};

export const updateAccountData =
    (field, value, remember = true) =>
    (dispatch) => {
        setGigyaAccountData({ [field]: value }).then((data) => {
            if (remember) dispatch(persist('user.data', data));
            dispatch({ type: UPDATE_ACCOUNT_DATA, data });
        });
    };

const sortKeyAction = (key, value) => ({
    type: SET_SORT_KEY,
    data: { key, value }
});

export const setSortKey = (key, value) => (dispatch, getState) => {
    dispatch(sortKeyAction(key, value));
    dispatch(persist('user.saved', getState().user.saved));
};

/* */
// Reducer
const initialState = {
    // gigya profile
    profile: getLocalStore('user.profile') || {},
    // gigya user data object
    data: {},

    // dealer from data.preferredDealer
    dealer: null,

    // home from data.address
    home: null,

    // saved rides page
    saved: {
        sort: {
            bookmarks: ALPHANUMERIC,
            eventBookmarks: ALPHANUMERIC,
            rides: ALPHANUMERIC
        }
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE: {
            const { profile } = action.data;
            return { ...state, profile };
        }

        case UPDATE_PROFILE: {
            return {
                ...state,
                profile: { ...state.profile, ...action.data }
            };
        }

        case SET_ACCOUNT_DATA: {
            const data = action.data;
            return { ...state, data };
        }

        case UPDATE_ACCOUNT_DATA: {
            return {
                ...state,
                data: { ...state.data, ...action.data }
            };
        }

        case SET_SORT_KEY: {
            const { key, value } = action.data;
            return {
                ...state,
                saved: {
                    ...state.saved,
                    sort: { ...state.saved.sort, [key]: value }
                }
            };
        }

        case UPDATE: {
            const { key, value } = action.data;
            return { ...state, [key]: value };
        }

        case AUTH_INVALIDATE: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
