/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <path
                fill="#FFF"
                d="M19.828 15.58L24.52 10l-13.8.12 1.997 13.917 4.674-5.653 5.072 4.285a9.706 9.706 0 013.09 7.702L25.544 38h3.71v-7.355a13.488 13.488 0 00-4.343-10.77l-5.083-4.295z"></path>
        </svg>
    );
}

export default Icon;
