import Promise from 'bluebird';
Promise.config({ cancellation: true });

import { getEvents, getDealers, getHereEv } from '@/helpers/api';
import {
    discoverExplore,
    POI_CATEGORIES,
    getChargingStations
} from '@/helpers/here';
import { dedupe } from '@/helpers/functions';
import { persist } from '@/helpers/persistor';
import { WaypointCategory, ChargingStationCategory } from '@/helpers/constants';

import {
    transformPOI,
    transformHereEVChargers,
    transformEAEVChargers,
    transformDealer,
    transformEvent,
    CONNECTORS
} from './helpers';
import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';

import { translate } from '@/helpers/i18n';
const t = translate('poi.index');
const METERS_IN_MILE = 1609.344;
/* */
// Types
const FETCH_POI = 'poi/FETCH_POI';
const FETCH_CHARGING_STATIONS = 'poi/FETCH_CHARGING_STATIONS';
const FETCH_HARLEY = 'poi/FETCH_HARLEY';
const CLEAR_POI = 'poi/CLEAR_POI';
const SET_ENABLED = 'poi/SET_ENABLED';
const SET_POI_CATEGORY = 'poi/SET_POI_CATEGORY';
const SET_DISABLED_CATEGORIES = 'poi/SET_DISABLED_CATEGORIES';
const SET_CHARGING_STATION = 'poi/SET_CHARGING_STATION';
const SET_CHARGING_STATIONS = 'poi/SET_CHARGING_STATIONS';

/* */
// Constants
const getCheckboxNames = () => ({
    [WaypointCategory.DEALER]: t('Dealers'),
    [WaypointCategory.EVENT]: t('Events'),
    [WaypointCategory.SCENIC]: t('Scenic Spots'),
    [WaypointCategory.HOTEL]: t('Hotels'),
    [WaypointCategory.GAS_STATION]: t('Gas Stations'),
    [WaypointCategory.RESTAURANT]: t('Restaurants'),
    HOTEL: t('Hotels')
});

// powerfeedtypes / Networks
const getChargingStationTypes = () => ({
    [ChargingStationCategory.LEVEL_1]: t('Level 1'),
    [ChargingStationCategory.LEVEL_2]: t('Level 2'),
    [ChargingStationCategory.LEVEL_3]: t('Level 3'),
    [ChargingStationCategory.CHARGEPOINT]: t('ChargePoint Network'),
    [ChargingStationCategory.ELECTRIFY_AMERICA]: t('Electrify America Network'),
    [ChargingStationCategory.INNOGY]: t('Innogy Network'),
    [ChargingStationCategory.OTHER_NETWORK]: t('Other Networks')
});

/* */
// Selectors
export const selectPOIMarkers = (state, key = 'points') =>
    (state[key] || [])
        .filter((point) => !state.disabledCategories[point.type])
        .map((point) => ({ ...point, img: point.icon }));

export const getPOICheckboxes = (state) => (toggleFn) => {
    const { disabledCategories } = state;

    function onChange(key) {
        return function onChangeHandler() {
            toggleFn(key);
        };
    }

    const checkboxNames = getCheckboxNames();
    return Object.keys(checkboxNames).map((category) => ({
        label: checkboxNames[category],
        checked: !disabledCategories[category],
        onChange: onChange(category)
    }));
};

export const getChargingStationCheckboxes = (state) => (toggleFn) => {
    const { chargingStations } = state;

    function onChange(key) {
        return function onChangeHandlerEV() {
            toggleFn(key);
        };
    }

    const checkboxNames = getChargingStationTypes();
    return Object.keys(checkboxNames).map((category) => ({
        label: checkboxNames[category],
        checked: !chargingStations[category],
        onChange: onChange(category)
    }));
};

/* */
// Action Creators
const didFetchPOI = (points, coords) => ({
    type: FETCH_POI,
    data: { points, coords }
});

const didFetchChargingStations = (points, coords) => ({
    type: FETCH_CHARGING_STATIONS,
    data: { points, coords }
});

const didFetchHarley = (points, coords) => ({
    type: FETCH_HARLEY,
    data: { points, coords }
});

const requireLatLong = (coords) =>
    Object.keys(coords).every((key) => coords[key] !== undefined);

const fetchPOICategories = (categories, coords, size, boundingBox = null) =>
    Promise.all(
        categories.map((category) =>
            discoverExplore({
                at: `${coords.lat},${coords.lng}`,
                categories: category,
                in: `bbox:${boundingBox}`,
                // FETCH 10 FEWER SCENIC POI
                limit:
                    size > 100
                        ? 100
                        : category === '350-3500'
                          ? size - 10
                          : size
            }).then((resp) =>
                resp.data.items
                    .filter(
                        (item) => !item.title.match(/Harley(\s|-)Davidson/i)
                    )
                    .map(transformPOI)
            )
        )
    ).then((responses) => {
        return [].concat.apply([], responses);
    });

const fetchEventsAndDealers = (
    coords,
    radiusInMiles,
    bounds,
    descriptor = { events: true, dealers: true }
) => {
    const latForEvents = `${bounds.sw.lng.toFixed(1)},${bounds.sw.lat.toFixed(1)}`;
    const lngForEvents = `${bounds.ne.lng.toFixed(1)},${bounds.ne.lat.toFixed(1)}`;
    return Promise.all(
        [
            descriptor.events
                ? getEvents(
                      coords.lat || latForEvents,
                      coords.lng || lngForEvents,
                      radiusInMiles
                  ).then((resp) => resp.data.events.map(transformEvent))
                : null,
            descriptor.dealers
                ? getDealers(coords.lat, coords.lng, radiusInMiles).then(
                      (resp) => resp.data.dealers.map(transformDealer)
                  )
                : null
        ].filter((e) => e)
    ).then((responses) => {
        return [].concat.apply([], responses);
    });
};

let lastPOIPromise = null;
let lastEventsDealersPromise = null;

/**
 * Fetches POIs based on the current map zoom with percent coverage.
 */
export const fetchPOI = () => (dispatch, getState) => {
    const { disabledCategories, chargingStations } = getState().poi;
    if (lastPOIPromise && lastPOIPromise.isPending()) {
        lastPOIPromise.cancel();
    }
    const {
        hereBounds: bounds,
        center,
        radius: radiusInMiles
    } = getState().map;
    if (!bounds) return;

    const { sw, ne } = bounds;
    const bbox = `${sw.lng},${sw.lat},${ne.lng},${ne.lat}`;

    const enabledCategories = Object.keys(disabledCategories).filter(
        (key) => !disabledCategories[key]
    );
    const poiCategories = enabledCategories
        .map((category) => POI_CATEGORIES[category])
        .filter((c) => c !== undefined);

    if (requireLatLong(center) && poiCategories.length > 0) {
        fetchPOICategories(poiCategories, center, 30, bbox).then((poi) =>
            dispatch(didFetchPOI(dedupe(poi, 'id'), center))
        );
    }
    // fetch harley dealers and events
    // decoupled from POI fetch so whichever finishes first can be displayed
    if (
        !disabledCategories[WaypointCategory.EVENT] ||
        !disabledCategories[WaypointCategory.DEALER]
    ) {
        if (lastEventsDealersPromise && lastEventsDealersPromise.isPending()) {
            lastEventsDealersPromise.cancel();
        }

        lastEventsDealersPromise = fetchEventsAndDealers(
            center,
            !!radiusInMiles ? radiusInMiles : 3000,
            bounds,
            {
                events: !disabledCategories[WaypointCategory.EVENT],
                dealers: !disabledCategories[WaypointCategory.DEALER]
            }
        ).then((pois) => dispatch(didFetchHarley(pois, center)));
    }

    // User owns bike (ELW || Livewire) && VIN
    const { user } = getState();
    if (user && user.data.evBikeOwner && !!chargingStations) {
        // powerfeedtypes are converted to connectortypes
        // and passed to here maps ev api
        const powerFeedTypesToConnectorIds = (types) =>
            Object.entries(CONNECTORS)
                .filter((pair) => !types[pair[0]])
                .map((pair) => pair[1].join(','))
                .join(',');

        const connectortypeIds = powerFeedTypesToConnectorIds(chargingStations);

        if (connectortypeIds.length < 1) {
            dispatch(didFetchChargingStations([], center));
            return;
        }
        const selectedLevels = Object.keys(chargingStations)
            .filter((k) => !chargingStations[k] && k.includes('LEVEL_'))
            .map((level) => parseInt(level.slice(-1)));
        const selectedVendors = Object.keys(chargingStations).filter(
            (k) => !chargingStations[k] && !k.includes('LEVEL_')
        );

        if (selectedVendors.length < 1 || selectedLevels.length < 1) return;

        const radiusInMeters = radiusInMiles * METERS_IN_MILE;
        const MAX_RADIUS_IN_METERS = 200000;
        const stations = getChargingStations({
            ...center,
            // 200000 is the maximum radius in meters for retrieving charging stations according to https://www.here.com/docs/bundle/ev-charge-points-api-developer-guide/page/topics/resource-stations.html#d5878e56
            radius: !!radiusInMeters
                ? Math.min(radiusInMeters, MAX_RADIUS_IN_METERS)
                : MAX_RADIUS_IN_METERS,
            connectortype: connectortypeIds
        })
            .then((data) => {
                if (!Array.isArray(data.data.evStations?.evStation)) return [];
                const allStations = data.data.evStations?.evStation.map(
                    transformEAEVChargers
                );
                return allStations;
            })
            .catch((err) => {
                console.error(err);
            });

        const hereEv = getHereEv({
            ...center,
            radius: radiusInMeters > 200000 ? 200000 : radiusInMeters,
            connectortype: connectortypeIds
        })
            .then((stations) => {
                if (!Array.isArray(stations)) return [];
                return stations.map(transformHereEVChargers);
            })
            .catch((err) => console.log('getHereEv', err));

        Promise.all([stations, hereEv]).then((vals) => {
            dispatch(
                didFetchChargingStations([...vals[0], ...vals[1]], center)
            );
        });
    }
};

const transformDealerChargers = (chargers) => {
    return chargers.reduce(
        (obj, charger) => {
            obj.notes = `${obj.notes} ${charger.notes}`;
            obj.supplierName = charger.supplierName;
            obj.operator = charger.operator;
            obj.totalNumberOfConnectors += charger.totalNumberOfConnectors;
            charger.evses.map((evs) => obj.evses.push(evs));
            return obj;
        },
        {
            totalNumberOfConnectors: 0,
            evses: [],
            operator: {},
            supplierName: '',
            notes: ''
        }
    );
};

export const fetchHereEv = ({
    lat,
    lng,
    radius = 20000,
    connectortype = null,
    dealer = false
}) =>
    getHereEv({
        lat,
        lng,
        radius,
        connectortype
    }).then((stations) => {
        if (
            !!stations &&
            !!stations.response &&
            !!stations.response.status &&
            stations.response.status === 404
        )
            return [];
        const transformedChargers = stations.map(transformHereEVChargers);
        if (!dealer) return transformedChargers;
        const transformedDealerChargers =
            transformDealerChargers(transformedChargers);
        return transformedDealerChargers;
    });

export const togglePOI = (enabled) => ({
    type: SET_ENABLED,
    data: { enabled }
});

const setPoiCategory = (category, enabled) => ({
    type: SET_POI_CATEGORY,
    data: { category, enabled }
});

export const toggleCategory = (category) => (dispatch, getState) => {
    const { disabledCategories } = getState().poi;
    // fetch poi any option is enabled (not disabled)
    const isFalse = (value) => value === true;
    const shouldFetch = Object.values(disabledCategories).find(isFalse);
    const enable = !!disabledCategories[category];
    dispatch(setPoiCategory(category, enable));
    dispatch(
        persist('poi.disabledCategories', getState().poi.disabledCategories)
    );
    if (shouldFetch) dispatch(fetchPOI());
};

export const toggleChargingStation = (category) => (dispatch, getState) => {
    const { chargingStations } = getState().poi;
    // fetch poi any option is enabled (not disabled)
    const isFalse = (value) => value === true;
    const shouldFetch = Object.values(chargingStations).find(isFalse);
    const enable = !!chargingStations[category];

    dispatch(setChargingStation(category, enable));
    dispatch(persist('poi.chargingStations', getState().poi.chargingStations));
    if (shouldFetch) dispatch(fetchPOI());
};

const setChargingStation = (category, enabled) => ({
    type: SET_CHARGING_STATION,
    data: { category, enabled }
});

export const setDisabledCategories = (disabled) => ({
    type: SET_DISABLED_CATEGORIES,
    data: { disabled }
});

export const setChargingStations = (disabled) => ({
    type: SET_CHARGING_STATIONS,
    data: { disabled }
});

/* */
// Reducer
const initialState = {
    // the poi data
    points: [],

    // events and dealers
    harley: [],

    cs: [],

    // the center of the last fetched POIS
    lastCoords: null,

    // Whether or not POIs are enabled
    enabled: true,

    // Map of disabled Waypoint categories
    disabledCategories: {
        [WaypointCategory.GAS_STATION]: true,
        [WaypointCategory.SCENIC]: true,
        [WaypointCategory.RESTAURANT]: true,
        [WaypointCategory.HOTEL]: true,
        [WaypointCategory.DEALER]: false,
        [WaypointCategory.EVENT]: true
    },

    // Map of disabled Waypoint categories
    chargingStations: {
        [ChargingStationCategory.LEVEL_1]: false,
        [ChargingStationCategory.LEVEL_2]: false,
        [ChargingStationCategory.LEVEL_3]: false,
        [ChargingStationCategory.CHARGEPOINT]: false,
        [ChargingStationCategory.ELECTRIFY_AMERICA]: false,
        [ChargingStationCategory.INNOGY]: false,
        [ChargingStationCategory.OTHER_NETWORK]: false
    },

    // here POI categories to search over
    poiCategories: [
        POI_CATEGORIES.PETROL_STATION,
        POI_CATEGORIES.EAT_DRINK,
        POI_CATEGORIES.NATURAL_GEOGRAPHICAL,
        POI_CATEGORIES.LEISURE_OUTDOOR,
        POI_CATEGORIES.HOTEL
    ]
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_POI: {
            const { points, coords } = action.data;
            return { ...state, points, lastCoords: coords };
        }

        case FETCH_CHARGING_STATIONS: {
            const { points, coords } = action.data;
            return { ...state, cs: points, lastCoords: coords };
        }

        case FETCH_HARLEY: {
            const { points } = action.data;
            return { ...state, harley: points };
        }

        case CLEAR_POI: {
            const { enabled } = state;
            return { ...state, ...initialState, enabled };
        }

        case SET_ENABLED: {
            const { enabled } = action.data;
            return { ...state, enabled };
        }

        case SET_POI_CATEGORY: {
            const { category, enabled } = action.data;
            return {
                ...state,
                disabledCategories: {
                    ...state.disabledCategories,
                    [category]: !enabled
                }
            };
        }

        case SET_CHARGING_STATION: {
            const { category, enabled } = action.data;
            return {
                ...state,
                chargingStations: {
                    ...state.chargingStations,
                    [category]: !enabled
                }
            };
        }

        case SET_DISABLED_CATEGORIES: {
            const { disabled } = action.data;

            return {
                ...state,
                disabledCategories: {
                    ...initialState.disabledCategories,
                    ...disabled
                }
            };
        }

        case SET_CHARGING_STATIONS: {
            const { disabled } = action.data;

            return {
                ...state,
                chargingStations: {
                    ...initialState.chargingStations,
                    ...disabled
                }
            };
        }

        case AUTH_INVALIDATE:
        default: {
            return state;
        }
    }
};
