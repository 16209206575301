import history from '@/helpers/history';
import * as api from '@/helpers/api';
import createStore from '@/helpers/items';
import { Routes, eventUrl } from '@/helpers/routes';
import { setError, EVENT_ERROR } from '@/store/error';
import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import { safePush } from '@/store/routing';
import { transformEvent } from '@/store/poi/helpers';

const transformEventData = (events) =>
    events.map((event) => {
        const {
            latitude,
            longitude,
            eventName,
            eventActivities,
            eventId,
            eventHostDescription
        } = event;
        return {
            ...event,
            ...transformEvent(event),
            latitude,
            longitude,
            eventName,
            eventActivities,
            eventId,
            eventHostDescription
        };
    });

const SET_DEALER_EVENTS = 'events/SET_DEALER_EVENTS';

const fetchMany = (lat, lng, radius, zoom) => {
    if (!radius || (radius < 0.7 && !zoom)) return Promise.reject();
    return api.getEvents(lat, lng, radius).then((response) => {
        return transformEventData(response.data.events);
    });
};

const fetchManyWithParams = (params) => (dispatch) => {
    if (typeof params !== 'object') return Promise.reject();
    api.getEventsWithParams(params).then((response) =>
        dispatch({
            type: SET_DEALER_EVENTS,
            data: response.data.events
        })
    );
};

const { FETCH, SELECT, fetchItems, selectItem, reducer } = createStore(
    'events',
    fetchMany
);

/* */
// Types
export { FETCH, SELECT };
export const SHOW_SIDEBAR = 'events/SHOW_SIDEBAR';

/* */
// Action Creators
export { selectItem, fetchItems, fetchManyWithParams };

export const routing_selectEvent = (eventId) => (dispatch, getState) => {
    api.getEvent(eventId, getState)
        .then((event) => {
            const eventActivities = (event.eventActivities || [])[0] || {};
            const latlng = eventActivities.address.latitude
                ? eventActivities.address
                : eventActivities || {};

            selectItem({ ...latlng, ...event })(dispatch, getState);
        })
        .catch(() => {
            history.replace(Routes.MAP_EVENTS);
            dispatch(setError(EVENT_ERROR));
        });
};

export const selectItemById = (eventOrEventId) => (dispatch) => {
    if (!eventOrEventId) return dispatch(safePush(eventUrl()));
    dispatch(safePush(eventUrl(eventOrEventId.eventId || eventOrEventId)));
};

export const previewEvent =
    (eventOrEventId, isDealer = 0) =>
    (dispatch) => {
        let url = eventUrl(eventOrEventId.eventId || eventOrEventId);
        url = isDealer ? `${url}?sidebar=1&isDealer=1` : `${url}?sidebar=1`;
        dispatch(safePush(url));
    };

/* */
// Reducer
export default (state, action) => {
    switch (action.type) {
        case AUTH_INVALIDATE:
        case SET_DEALER_EVENTS:
            return { ...state, events: action.data };
        default:
            return reducer(state, action);
    }
};
