/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF">
                <path d="M24.915 15.584l-4.681-5.563 13.766.12-1.992 13.872-4.662-5.635-5.06 4.272a9.671 9.671 0 00-3.083 7.677l.01 7.605H15.51V30.6a13.44 13.44 0 014.332-10.736l5.072-4.281z"></path>
                <path
                    fillOpacity="0.4"
                    d="M8.736 14.51l-2.431 2.794 6.108 5.32a9.671 9.671 0 013.083 7.678l-.009 7.604h3.7v-7.331a13.44 13.44 0 00-4.331-10.736l-6.12-5.33z"></path>
            </g>
        </svg>
    );
}

export default Icon;
