import React from 'react';

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="29"
        viewBox="0 0 29 29"
    >
        <path
            fill="#FFF"
            fillRule="nonzero"
            d="M14.5 0C6.492 0 0 6.491 0 14.5c0 6.143 3.822 11.39 9.216 13.502-.127-1.147-.242-2.906.05-4.158.264-1.132 1.7-7.208 1.7-7.208s-.433-.868-.433-2.153c0-2.015 1.169-3.521 2.623-3.521 1.236 0 1.835.93 1.835 2.042 0 1.243-.792 3.103-1.201 4.827-.342 1.443.723 2.621 2.147 2.621 2.577 0 4.558-2.717 4.558-6.64 0-3.471-2.494-5.899-6.057-5.899-4.125 0-6.546 3.095-6.546 6.292 0 1.246.48 2.584 1.079 3.309.118.143.135.27.1.416l-.402 1.644c-.064.266-.21.322-.486.194-1.811-.843-2.944-3.49-2.944-5.617 0-4.574 3.323-8.775 9.581-8.775 5.03 0 8.94 3.585 8.94 8.375 0 4.998-3.15 9.019-7.525 9.019-1.47 0-2.85-.763-3.323-1.665l-.904 3.447c-.327 1.26-1.21 2.84-1.802 3.802 1.358.419 2.8.646 4.294.646C22.508 29 29 22.508 29 14.5 29 6.491 22.508 0 14.5 0z"
        />
    </svg>
);
