/******************************************************************************\
 * File: RideEdit.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import style from '@/style/mapListView/sidebar/RideEdit.scss';
import Modal, {
    Header,
    Title,
    Subtitle,
    Button
} from '@/components/common/Modal';
import { saveRide } from '@/store/edit_ride';
import Input from '@/components/common/form/Input';
import Textarea from '@/components/common/form/Textarea';
import Form from '@/components/common/form/Form';
import { defaultName, bulkUpdate } from '@/store/edit_ride';
import RouteType from '@/components/mapListView/RouteType';
import { RideSubtype } from '@/helpers/constants';

import { translate } from '@/helpers/i18n';
const t = translate('mapListView.SaveRideModal');
const Spacer = ({ children }) => (
    <div style={{ width: '100%', marginBottom: '16px' }}>{children}</div>
);
//------------------------------------------------------------------------------
// Form ------------------------------------------------------------------------
const RideEditFormik = withFormik({
    mapPropsToValues: (props) => ({
        name: defaultName(props.ride),
        description: props.ride.description || '',
        ride: props.ride,
        offRoad: props.offRoad
    }),
    validate: (values, props) => {
        let errors = {};
        if ((values || {}).name && !(values.name.trim() || {}).length) {
            errors.name = t('Please name your ride.');
        }
        return errors;
    },
    handleSubmit: (values, { props, setErrors, setSubmitting }) => {
        const data = {
            name: values.name,
            description: values.description
        };
        props.bulkUpdate(data);
        props.onSubmit();
    }
})(
    ({
        handleSubmit,
        bulkUpdate,
        setFieldValue,
        values,
        errors,
        isSubmitting,
        inputRef
    }) => {
        if (!(values.name.trim() || {}).length)
            errors.name = t('Please name your ride.');
        return (
            <Form
                onSubmit={handleSubmit}
                error={errors.name}
                isSubmitting={isSubmitting}>
                <div className={style.formWrapper}>
                    {values.ride &&
                        values.ride.subtype === RideSubtype.RECORDED && (
                            <RouteType
                                points={values.ride.waypoints}
                                isOffRoadRoute={values.offRoad}
                                isHeader={true}
                                ride={values.ride}
                                cb={(val) => setFieldValue('offRoad', val)}
                            />
                        )}
                    <div className={style.inputWrapper}>
                        <div className={style.inputTitle}>{t('Name')}</div>
                        <Input
                            ref={inputRef}
                            plain
                            name="name"
                            placeholder={t('My Awesome Ride')}
                            onChange={(e) => {
                                const name = e.target.value;
                                setFieldValue('name', name);
                            }}
                            value={values.name}
                        />
                    </div>
                    <div style={{ flex: 1, paddingTop: '24px' }}>
                        <div
                            className={style.inputTitle}
                            style={{ height: '100%' }}>
                            {t('Description')}
                            <Textarea
                                name="description"
                                placeholder={t('Describe your ride...')}
                                maxLength={10000}
                                height={'100%'}
                                className={style.textArea}
                                onChange={(e) => {
                                    setFieldValue(
                                        'description',
                                        e.target.value
                                    );
                                }}
                                value={values.description}
                            />
                        </div>
                    </div>
                    <div>
                        <Button
                            positive
                            right={true}
                            disabled={errors.name}
                            onClick={handleSubmit}
                            bottom={true}>
                            {t('Save Ride')}
                        </Button>
                    </div>
                </div>
            </Form>
        );
    }
);
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RideEdit extends React.Component {
    onAfterOpen = () => {
        const { onAfterOpen } = this.props;
        onAfterOpen && onAfterOpen();

        this.$input && this.$input.select();
    };

    onSubmit = () => {
        this.props.saveRide();
    };

    render() {
        const { ride, smallTitle, bulkUpdate, offRoad, ...rest } = this.props;

        const label = t('Name Your Ride');
        return (
            <div>
                <Header>
                    <Title className={style.subTitle}>{label}</Title>
                </Header>
                <RideEditFormik
                    onSubmit={this.onSubmit}
                    ride={ride}
                    offRoad={offRoad}
                    bulkUpdate={bulkUpdate}
                    inputRef={(el) => (this.$input = el)}
                />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => ({});
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        bulkUpdate: (data) => dispatch(bulkUpdate(data)),
        saveRide: () => dispatch(saveRide())
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(RideEdit);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
