/******************************************************************************\
 * File: Footer.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/homeView/Footer.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import Logo from '@/components/common/header/Logo';
import FacebookIcon from '@/components/common/icons/social/Facebook';
import InstagramIcon from '@/components/common/icons/social/Instagram';
import TwitterIcon from '@/components/common/icons/social/Twitter';
import YouTubeIcon from '@/components/common/icons/social/YouTube';
import PinterestIcon from '@/components/common/icons/social/Pinterest';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import packageJson from '@/../package.json';
import getFooterLinks from './footer-links';
import { locales } from '@/helpers/lists';
import { getRegionalOffice } from '@/helpers/hdOffices';
import { COMPANY_NAME } from '@/helpers/constants';
import { translate, getLocale, defaultLocale, isUS } from '@/helpers/i18n';
const t = translate('homeView.Footer');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Footer extends React.Component {
    static Select = ({ value, placeholder, onChange, customOption }) => (
        <select
            className={style.Select}
            onChange={(e) => onChange(e.target.value, 'user')}
            value={value}>
            {placeholder ||
                (customOption && customOption.customDisplay && (
                    <option
                        selected="SELECTED"
                        value={`${customOption.name}//${customOption.locale}`}>
                        {customOption ? customOption.name : placeholder}
                    </option>
                ))}
            {locales.map(({ locale, name, customDisplay }) => (
                <option key={name} value={`${name.trim()}//${locale}`}>
                    {customDisplay || name}
                </option>
            ))}
        </select>
    );

    static Icon = ({ href, icon: IconComponent, label }) => {
        return (
            <div className={style.social}>
                <a href={href} target="_blank" role="button" aria-label={label}>
                    <IconComponent />
                </a>
            </div>
        );
    };

    render() {
        const {} = this.props;

        const locale = getLocale();
        const links = getFooterLinks(isUS());
        const customOption = locales.find(({ name }) =>
            locale.includes(`${name}//`)
        );
        return (
            <div className={style.Footer}>
                <div className={style.TextLogo}>
                    <div className={style.locale}>
                        {COMPANY_NAME}{' '}
                        <span className={style.Orange}>
                            {getRegionalOffice(
                                locale || defaultLocale || customOption.name
                            )}
                        </span>
                    </div>
                    <Footer.Select
                        value={locale || defaultLocale}
                        customOption={customOption}
                        onChange={window.setLocale}
                    />
                </div>
                <div className={style.socialIcons}>
                    <Footer.Icon
                        href="https://www.facebook.com/harley-davidson"
                        icon={FacebookIcon}
                        label={'Facebook'}
                    />
                    <Footer.Icon
                        href="https://twitter.com/harleydavidson"
                        icon={TwitterIcon}
                        label={'Twitter'}
                    />
                    <Footer.Icon
                        href="https://www.instagram.com/harleydavidson"
                        icon={InstagramIcon}
                        label={'Instagram'}
                    />
                    <Footer.Icon
                        href="https://www.youtube.com/harleydavidson"
                        icon={YouTubeIcon}
                        label={'YouTube'}
                    />
                    <Footer.Icon
                        href="https://www.pinterest.com/harleydavidson/"
                        icon={PinterestIcon}
                        label={'Pinterest'}
                    />
                </div>
                <div>
                    <a
                        className={style.website}
                        href="https://www.harley-davidson.com">
                        www.harley-davidson.com
                    </a>
                </div>
                <div className={style.links}>
                    {links.map((e, i, a) => (
                        <a
                            href={e.link}
                            target="_blank"
                            key={i}
                            className={style.link}>
                            {e.title}
                        </a>
                    ))}
                </div>
                <div className={style.copyright}>
                    <Logo className={style.logo} />
                    <p>
                        {t('copyright', {
                            copy: <span key={1}>&copy;</span>,
                            year: new Date().getFullYear(),
                            t: '{copy} {year} H-D or its affiliates. HARLEY-DAVIDSON, HARLEY, H-D, and the Bar and Shield Logo are among the trademarks of H-D U.S.A., LLC. Third-party trademarks are the property of their respective owners.'
                        })}
                    </p>
                    <span
                        style={{
                            fontSize: '8px'
                        }}>{`V${packageJson.version}`}</span>
                    <br />
                </div>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Footer;
