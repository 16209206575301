import React from 'react';

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="27"
        viewBox="0 0 40 27"
    >
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M16 19.286V7.714L26.392 13.5 16 19.286zm23.164-15.07c-.46-1.66-1.815-2.966-3.536-3.41C32.508 0 20 0 20 0S7.492 0 4.372.806c-1.72.444-3.076 1.75-3.536 3.41C0 7.224 0 13.5 0 13.5s0 6.276.836 9.284c.46 1.66 1.815 2.966 3.536 3.41C7.492 27 20 27 20 27s12.508 0 15.628-.806c1.72-.444 3.076-1.75 3.536-3.41C40 19.776 40 13.5 40 13.5s0-6.276-.836-9.284z"
        />
    </svg>
);
