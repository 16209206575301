/******************************************************************************\
 * File: SocialShare.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import Clipboard from 'clipboard';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/SocialShare.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import FacebookIcon from '@/components/common/icons/Facebook';
import TwitterIcon from '@/components/common/icons/Twitter';
import LinkIcon from '@/components/common/icons/Link';
import Tooltip from '@/components/common/form/Tooltip';
import { analyticsShareRideEvent } from '@/helpers/analytics';
import { translate } from '@/helpers/i18n';
const t = translate('common.SocialShare');
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
function fallbackMessage(action) {
    let actionMsg = '';
    let actionKey = action === 'cut' ? 'X' : 'C';
    if (/iPhone|iPad/i.test(navigator.userAgent)) {
        actionMsg = 'No support :(';
    } else if (/Mac/i.test(navigator.userAgent)) {
        actionMsg = 'Press ⌘-' + actionKey + ' to ' + action;
    } else {
        actionMsg = 'Press Ctrl-' + actionKey + ' to ' + action;
    }
    return actionMsg;
}

const windowPopup = (url, width = 500, height = 300) => {
    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;
    return window.open(
        url,
        '',
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=YES, copyhistory=no, width=' +
            width +
            ', height=' +
            height +
            ', top=' +
            top +
            ', left=' +
            left
    );
};

const socialSharePopup = (type, text = '', url) => {
    const encodedUrl = encodeURIComponent(url);

    switch (type) {
        case 'facebook':
            return windowPopup(
                'https://www.facebook.com/sharer/sharer.php?u=' + encodedUrl
            );
        case 'twitter':
            return windowPopup(
                'https://twitter.com/intent/tweet/?text=' +
                    encodeURIComponent(text) +
                    '&url=' +
                    encodedUrl
            );
        default:
            console.error('socialShare', type, 'is not supported.');
    }
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const SocialShare = (props) => {
    const [msg, setMsg] = useState(null);
    const [clipboard] = useState(new Clipboard('[data-clipboard-text]'));

    const showTooltip = (msg) => setMsg(msg);

    const hideTooltip = () => setMsg(null);

    useEffect(() => {
        clipboard.on('success', () => showTooltip(t('Copied')));
        clipboard.on('error', (e) => showTooltip(fallbackMessage(e.action)));
        return () => {
            clipboard.destroy();
        };
    }, []);

    const {
        className,
        iconClassName,
        mediumClassName,
        text,
        url,
        showCaptions
    } = props;

    const cn = classNames(style.SocialShare, {
        [className]: !!className
    });

    const mediumClass = classNames(style.medium, {
        [mediumClassName]: !!mediumClassName
    });

    return (
        <div className={cn}>
            <Tooltip value="Facebook" placement="w" noDelay>
                <div
                    className={mediumClass}
                    onClick={(e) => {
                        analyticsShareRideEvent('facebook');
                        socialSharePopup('facebook', text, url);
                    }}>
                    <FacebookIcon className={iconClassName} />
                    {showCaptions && (
                        <span className={style.caption}>Facebook</span>
                    )}
                </div>
            </Tooltip>
            <Tooltip value="Twitter" placement="w" noDelay>
                <div
                    className={mediumClass}
                    onClick={(e) => {
                        analyticsShareRideEvent('twitter');
                        socialSharePopup('twitter', text, url);
                    }}>
                    <TwitterIcon className={iconClassName} />
                    {showCaptions && (
                        <span className={style.caption}>Twitter</span>
                    )}
                </div>
            </Tooltip>
            <Tooltip
                value={msg || t('Copy Web Link')}
                placement="w"
                noDelay
                onMouseLeave={hideTooltip}
                onBlur={hideTooltip}>
                <div
                    className={mediumClass}
                    data-clipboard-text={url}
                    onClick={() => analyticsShareRideEvent('copy link')}>
                    <LinkIcon className={iconClassName} />
                    {showCaptions && (
                        <span className={style.caption}>
                            {t('Copy Web Link')}
                        </span>
                    )}
                </div>
            </Tooltip>
        </div>
    );
};

SocialShare.defaultProps = {
    text: t('Check out this ride!')
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SocialShare;
