import { status } from '@/helpers/async';
import {
    getEventBookmarks,
    bookmarkEvent,
    deleteEventBookmark
} from '@/helpers/api';
import { auth, INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import { $private } from '@/helpers/test';

/* */
// TODO: SPECIFIC Types
export const FETCH = 'eventBookmarks/FETCH';
export const SET_BOOKMARKED = 'eventBookmarks/SET_BOOKMARKED';
export const FILTER_UNBOOKMARKED = 'eventBookmarks/FILTER_UNBOOKMARKED';
export const CLEAR_BOOKMARKS = 'eventBookmarks/CLEAR';
export const REMOVE_BOOKMARK = 'eventBookmarks/REMOVE_BOOKMARK';

/* */
// Helpers
const lookupHashForBookmark = (bookmark) => ({
    eventId: bookmark,
    id: bookmark.id
});

const lookupHashForBookmarks = (eventBookmarks) =>
    eventBookmarks.reduce((memo, bookmark) => {
        memo[bookmark.eventId] = lookupHashForBookmark(bookmark);
        return memo;
    }, {});

// TODO: SPECIFIC Types
const eventsSort = (a, b) => a.startDate > b.startDate;

/* */
// Selectors
export const isBookmarked = (rideId, state) =>
    !!state.eventBookmarks.hash[rideId];

export const isEventBookmarked = (eventId, state) => {
    return !!state?.eventBookmarks?.hash[eventId];
};

export const bookmarkedEvents = (state) =>
    state.eventBookmarks.events.filter(
        (event) => state.eventBookmarks.hash[event.id]
    );

/* */
// Action Creators
const setEventBookmarked = (eventId, isBookmarked) => ({
    type: SET_BOOKMARKED,
    data: { eventId, isBookmarked }
});
$private(module, setEventBookmarked);

const didFetchEventBookmarks = (bookmarks) => ({
    type: FETCH,
    data: bookmarks,
    status: status.SUCCESS
});
$private(module, didFetchEventBookmarks);

export const fetchEventBookmarks = () => (dispatch) => {
    dispatch({ type: FETCH, status: status.REQUEST });
    getEventBookmarks()
        .then((resp) => resp.data.events)
        .then((bookmarks) => dispatch(didFetchEventBookmarks(bookmarks)))
        .catch((err) => {
            console.error(err);
            dispatch({ type: FETCH, status: status.FAILURE });
        });
};

export const setEventBookmark = (id, value) => (dispatch) => {
    const cb = setEventBookmarkCallback(id, value);
    dispatch(auth(cb));
};

const setEventBookmarkCallback = (id) => (dispatch, getState) => {
    const prevValue = getState().eventBookmarks.hash[id];

    // anticipate the api call succeeding
    // dispatch(setEventBookmarked(id, value));
    return (
        prevValue ? deleteEventBookmark(prevValue.id || id) : bookmarkEvent(id)
    )
        .then(() => dispatch(setEventBookmarked(id, !prevValue)))
        .catch((e) => {
            if (process.env.NODE_ENV !== 'test') console.log(e);
            // we were wrong, undo it
            if (
                e.codeName === 'NO_SUCH_EVENT_BOOKMARK' ||
                e.codeName === 'NO_SUCH_EVENT_BOOKMARK'
            ) {
                return dispatch(setEventBookmarked(id, false));
            } else {
                return dispatch(setEventBookmarked(id, prevValue));
            }
        });
};

export const removeEventBookmark = (id) => ({
    type: REMOVE_BOOKMARK,
    data: { id }
});

export const filterUnbookmarkedEvents = () => ({ type: FILTER_UNBOOKMARKED });

export const clearBookmarks = () => ({ type: CLEAR_BOOKMARKS });

/* */
// Reducer
const initialState = {
    /** Easy lookup hash to check if a event is bookmarked or not. */
    hash: {},
    /**
     * Array of bookmarked events. Events only get removed from this on
     * FILTER_UNBOOKMARKED to allow the user to re-bookmark an un-bookmarked
     * event.
     */
    events: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_BOOKMARKED: {
            const { eventId, isBookmarked } = action.data;
            const addEvent = isBookmarked;
            return {
                ...state,
                events: addEvent
                    ? state.events
                          .filter((e) => e.eventId !== eventId)
                          .concat([{ eventId }])
                          .sort(eventsSort)
                    : state.events,
                hash: {
                    ...state.hash,
                    [eventId]: addEvent && lookupHashForBookmark(eventId)
                }
            };
        }

        case FETCH: {
            if (action.status === status.SUCCESS && !!action.data) {
                const bookmarks = action.data;
                return {
                    hash: lookupHashForBookmarks(action.data),
                    events: bookmarks
                        .map((bookmark) => bookmark)
                        .sort(eventsSort),
                    loading: false
                };
            }

            return { ...state, loading: action.status === status.REQUEST };
        }

        case FILTER_UNBOOKMARKED: {
            return {
                ...state,
                events: bookmarkedEvents({ bookmarks: state }).sort(eventsSort)
            };
        }

        case REMOVE_BOOKMARK: {
            const { id } = action.data;
            return {
                ...state,
                hash: {
                    ...state.hash,
                    [id]: false
                },
                events: state.events.filter((event) => event.eventId !== id)
            };
        }

        case AUTH_INVALIDATE:
        case CLEAR_BOOKMARKS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
};
