/******************************************************************************\
 * File: constants.js
 *
 * Author: Gigster
 *
 * Description: Node.JS code shared between the frontend and backend.
 *
 * Notes:
 \******************************************************************************/

// deploy environments
const Env = (exports.Env = {
    ACCEPTANCE: 'dev',
    STAGING: 'qa',
    PRODUCTION: 'prod'
});

const apiUrlForEnv = (env) => {
    if (process.env.API_URL) {
        return process.env.API_URL;
    }

    if (process.env.NODE_ENV === 'development') {
        const s = process.env.HTTPS ? 's' : '';
        return `http${s}://local.harley-davidson.com:8080/api`;
    }

    switch (env) {
        case Env.PRODUCTION:
            return 'https://maps.harley-davidson.com/api';
        case Env.STAGING:
            return 'https://qamaps.harley-davidson.com/api';
        case Env.ACCEPTANCE:
        default:
            return 'https://devmaps.harley-davidson.com/api';
    }
};

const apiEventsUrlForEnv = (env) => {
    switch (env) {
        case Env.PRODUCTION:
            return 'https://www.harley-davidson.com/';
        case Env.STAGING:
            return 'https://qa.harley-davidson.com/';
        case Env.ACCEPTANCE:
        default:
            return 'https://dev.harley-davidson.com/';
    }
};

const trackingUrlForEnv = (env) => {
    if (process.env.NO_ANALYTICS) {
        return false;
    }
    switch (env) {
        case Env.PRODUCTION:
            return 'https://assets.adobedtm.com/1063865e0018/65a5668a766b/launch-70248429c94a.min.js';
        case Env.STAGING:
            return 'https://assets.adobedtm.com/1063865e0018/65a5668a766b/launch-75dde736d500-development.min.js';
        case Env.ACCEPTANCE:
            return 'https://assets.adobedtm.com/1063865e0018/65a5668a766b/launch-75dde736d500-development.min.js';
        default:
            return false;
    }
};

const urlForOsanoEnv = (env) => {
    if (process.env.NO_ANALYTICS) {
        return false;
    }
    switch (env) {
        case Env.PRODUCTION:
            return 'https://cmp.osano.com/AzytgnSbqoHcM3Ido/067de5fe-b46f-4770-8a31-c01098bd4da7/osano.js';
        case Env.STAGING:
            return 'https://cmp.osano.com/AzytgnSbqoHcM3Ido/a8f3aeb4-3fce-4cd8-8ae1-c33b5a9dd928/osano.js';
        case Env.ACCEPTANCE:
            return 'https://cmp.osano.com/AzytgnSbqoHcM3Ido/a8f3aeb4-3fce-4cd8-8ae1-c33b5a9dd928/osano.js';
        default:
            return false;
    }
};

const currentEnv = process.env.ENVIRONMENT || Env.ACCEPTANCE;

exports.appStoreLinks = [
    {
        link: 'https://itunes.apple.com/us/app/harley-davidson/id1292723595?mt=8',
        img: '/img/app-store-badge.png'
    },
    {
        link: 'https://play.google.com/store/apps/details?id=com.harley_davidson.ride_planner',
        img: '/img/google-play-badge.png'
    }
];

exports.COMPANY_NAME = 'Harley-Davidson';

exports.SITE_NAME = 'Harley-Davidson Ride Planner';

exports.currentEnv = currentEnv;

exports.baseURL = apiUrlForEnv(currentEnv);

exports.baseEventsURL = apiEventsUrlForEnv(currentEnv);

exports.feURL = apiUrlForEnv(currentEnv).split('/api')[0];

exports.trackingURL = trackingUrlForEnv(currentEnv);

exports.HERE_MAPS_ID = process.env.HERE_MAPS_ID;

exports.HERE_MAPS_CODE = process.env.HERE_MAPS_CODE;

exports.HERE_MAPS_API_KEY = process.env.HERE_MAPS_API_KEY;

exports.HD_ALT_LOGO = '/img/hd-alt-logo.png';

exports.trackingOsanoURL = urlForOsanoEnv(currentEnv);

exports.OPERATORS = {
    CHARGEPOINT: 'CHARGEPOINT',
    CHARGEPOINT_NETWORK: 'CHARGEPOINT',
    ELECTRIFY_AMERICA: 'ELECTRIFY_AMERICA',
    INNOGY: 'INNOGY',
    OTHER_NETWORK: 'OTHER_NETWORK'
};

exports.CONNECTOR_STANDARDS = [
    {
        id: 5,
        level: 1,
        type: 'Domestic plug/socket type B (NEMA 5-15)',
        standard: 'DOMESTIC_B',
        description: 'Standard/Domestic household, type "B", NEMA 5-15, 3 pins',
        image: 'nema515',
        name: 'NEMA 5-15 & 5-50'
    },
    {
        id: 6,
        level: 1,
        type: 'Domestic plug/socket type B (NEMA 5-20)',
        image: 'nema520',
        name: 'NEMA 5-20'
    },
    {
        id: 7,
        level: 1,
        type: 'Domestic plug/socket type D (BS 546 (3 pin))',
        name: 'UK WALL PLUG TYPE D'
    },
    {
        id: 8,
        level: 1,
        type: 'Domestic plug/socket type E (CEE 7/5)',
        standard: 'DOMESTIC_E',
        description: 'Standard/Domestic household, type "E", CEE 7/5 3 pins',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 9,
        level: 1,
        type: 'Domestic plug/socket type F (CEE 7/4 (Schuko))',
        standard: 'DOMESTIC_F',
        description:
            'Standard/Domestic household, type "F", CEE 7/4, Schuko, 3 pins',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 10,
        level: 1,
        type: 'Domestic plug/socket type E+F (CEE 7/7)',
        standard: 'DOMESTIC_G',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 11,
        level: 1,
        type: 'Domestic plug/socket type G (BS 1363, IS 401 & 411, MS 58)',
        standard: 'DOMESTIC_G',
        description:
            'Standard/Domestic household, type "G", BS 1363, Commonwealth, 3 pins',
        image: 'ukwall',
        name: 'UK WALL PLUG TYPE G'
    },
    {
        id: 12,
        level: 1,
        type: 'Domestic plug/socket type H (SI 32)',
        standard: 'DOMESTIC_H',
        description: 'Standard/Domestic household, type "H", SI-32, 3 pins'
    },
    {
        id: 13,
        level: 1,
        type: 'Domestic plug/socket type I (AS/NZS 3112)',
        standard: 'DOMESTIC_I',
        description: 'Standard/Domestic household, type "I", AS 3112, 3 pins',
        image: 'wallaunz',
        name: 'WALL (AU/NZ)'
    },
    {
        id: 14,
        level: 1,
        type: 'Domestic plug/socket type I (CPCS-CCC)',
        image: 'wallaunz',
        name: 'WALL (AU/NZ)'
    },
    {
        id: 15,
        level: 1,
        type: 'Domestic plug/socket type I (IRAM 2073)',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 16,
        level: 1,
        type: 'Domestic plug/socket type J (SEV 1011) (T13)',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 17,
        level: 1,
        type: 'Domestic plug/socket type J (SEV 1011) (T15)',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 18,
        level: 1,
        type: 'Domestic plug/socket type J (SEV 1011) (T23)',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 19,
        level: 1,
        type: 'Domestic plug/socket type J (SEV 1011) (T25)',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 20,
        level: 1,
        type: 'Domestic plug/socket type K (Section 107-2-D1)',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 21,
        level: 1,
        type: 'Domestic plug/socket type K (Thailand TIS 166 - 2549)'
    },
    {
        id: 22,
        level: 1,
        type: 'Domestic plug/socket type L (CEI 23-16/VII)',
        standard: 'DOMESTIC_L',
        description:
            'Standard/Domestic household, type "L", CEI 23-16-VII, 3 pins',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 23,
        level: 1,
        type: 'Domestic plug/socket type M (South African 15 A/250 V)'
    },
    { id: 24, level: 1, type: 'Domestic plug/socket type IEC 60906-1 (3 pin)' },
    {
        id: 42,
        level: 1,
        type: 'Domestic plug/socket type J (SEV 1011) (T13, T23)',
        image: 'walleuro',
        name: 'WALL (EURO)'
    },
    {
        id: 29,
        name: 'JEVS G 105 (CHAdeMO)',
        level: 3,
        description: 'JEVS G 105 (CHAdeMO)'
    },
    {
        id: 30,
        level: 2,
        type: 'IEC 62196-2 type 1 (SAE J1772)',
        standard: 'IEC_62196_T1',
        description: 'IEC 62196 Type 1 "SAE J1772"',
        image: 'portj1772',
        name: 'PORT J1772'
    },
    {
        id: 31,
        level: 2,
        type: 'IEC 62196-2 type 2 (Mennekes)',
        standard: 'IEC_62196_T2',
        image: 'type2',
        name: 'TYPE 2 CHINA BG/T (IEC 62196 MENNEKES)'
    },
    {
        id: 33,
        level: 3,
        type: 'IEC 62196-2 type 1 combo (SAE J1772)',
        standard: 'IEC_62196_T1_COMBO',
        image: 'saecomboccs',
        name: 'SAE COMBO CCS'
    },
    {
        id: 34,
        level: 3,
        type: 'IEC 62196-2 type 2 combo (Mennekes)',
        standard: 'IEC_62196_T2_COMBO',
        image: 'type2ccscombo',
        name: 'TYPE 2 CCS COMBO'
    },
    {
        id: 43,
        name: 'Tesla Connector',
        type: 'Tesla Connector',
        level: 4
    }
];

// HD promo modal content
exports.SHOW_CONTENT = {
    daytonabikeweek: {
        headerImage: '/img/hd-alt-logo.png',
        image: '/img/show-modal-bg-daytona-2022.png',
        mobileImage: '/img/show-modal-bg-daytona-2022.png',
        title: 'Daytona Bike Week',
        subtitle: 'United We Ride',
        subtitleStyle: {
            color: '#FA6600'
        },
        // dates: 'March 5th - 14th, 2021',
        contentDisplayEndDateTime: '2022-04-14T23:59:59',
        imageDivStyle: {
            clipPath: 'none'
        },
        imageStyle: {
            height: '100%',
            minWidth: '128%',
            objectFit: 'cover'
            // marginLeft: '100px'
        },
        mobileStyle: {
            minHeight: '100%',
            width: '120%',
            position: 'unset'
        },
        descriptionStyle: {
            paddingRight: '2px'
        },
        description: `As the official motorcycle of the 81st Anniversary of Daytona Bike Week, Harley-Davidson invites you to kick off riding season at the Daytona rally. <br /><br />
            Make the most of your time in Daytona by downloading the Harley-Davidson™ App. Stay updated on the lineup of H-D demos, live entertainment, races, local rides and easily check-in at the H.O.G.® tent located at the Daytona International Speedway.`
    },
    hometownrally: {
        headerImage: '/img/hd-alt-logo.png',
        image: '/img/show-modal-desktop-Hometown-Rally-2022@3x.png',
        mobileImage: '/img/show-modal-mobile-Hometown-Rally-2022@2x.png',
        imageDivStyle: {
            clipPath: 'none',
            backgroundColor: 'black',
            image: {
                height: '100%'
            },
            width: '100%'
        },
        mobileStyle: {
            height: '100%',
            width: '100%',
            position: 'unset'
        },
        title: '2022 Hometown Rally',
        subtitle: 'September 1 - 5, 2022 | Milwaukee, WI',
        description: `Join us at Hometown Rally in Milwaukee this Labor Day weekend for a celebration of all things Harley®. 
        Begin your weekend with a visit to The Harley-Davidson® Museum which will serve as a central rally point for all the 
        events taking place at that downtown site and six surrounding Harley-Davidson dealerships. From there, a two-wheeled 
        adventure is yours for the making. 
        <br /><br />
        Make the most of your time at the Hometown Rally by downloading the Harley-Davidson™ App. Stay updated on the lineup 
        of H-D demos, events and entertainment, find local rides and easily check-in at the H.O.G.® tent located at the 
        Harley-Davidson Museum.`,
        //<br /><br />
        //Hometown Rally™ details and schedules will be finalized and revealed throughout the summer. Check for updates at <a href='http://h-d.com/hometownrally?fbclid=IwAR0YIIbrJya4vaAUNX6xjN1VQEiMg5lMr8YEcdNJNF6A82Sv0pW_ZmWulOo' target='_blank'>H-D.com/hometownrally</a>, where links to dealer sites for specific info on those schedules and events will also be posted.`,
        contentDisplayEndDateTime: '2022-09-05T23:59:59'
    },
    sturgis: {
        headerImage: '/img/hd-alt-logo.png',
        image: '/img/show-modal-Sturgis-2022@2x.png',
        mobileImage: '/img/show-modal-Sturgis-2022-mobile@2x.png',
        imageDivStyle: {
            clipPath: 'none',
            image: {
                height: '100%'
            },
            width: '100%'
        },
        minAdjust: false,
        mobileStyle: {
            objectFit: 'cover',
            height: '100%',
            width: 'auto',
            position: 'unset'
        },
        title: 'sturgis motorcycle rally',
        subtitle: 'August 6 - 13, 2022',
        // SAFARI Requires T or will return NaN
        contentDisplayEndDateTime: '2022-08-14T23:59:59',
        description: `It’s the 82nd anniversary of the Sturgis Motorcycle Rally. Harley-Davidson's roots run deep in this town and the legendary event. Enjoy the unmatched scenic riding in and around Sturgis, SD. We encourage our community to ride free and ride sage.
            <br /><br />
            Make the most of your time at the rally by downloading the Harley-Davidson™ App. Stay updated on the lineup of H-D events, demos, entertainment, check-in at the H.O.G. tent and find the best local rides.
            `
    },
    'rally-weekend': {
        headerImage: '/img/hd-alt-logo.png',
        image: '/img/show-modal-mke-photo@2x.png',
        imageStyle: {
            maxHeight: '114%',
            width: '100%',
            maxWidth: 'initial'
        },
        // shortMarginLeft: '-224px',
        mobileStyle: {
            minHeight: '100%',
            width: '100%',
            position: 'unset'
        },
        mobileImage: '/img/show-modal-mke-photo-mobile@2x.jpg',
        title: 'Rally Weekend',
        subtitle: 'Rally through riding',
        // SAFARI Requires T or will return NaN
        contentDisplayEndDateTime: '2020-09-09T23:59:59',
        description: `The Rally experience is rooted in riding to the rally and the spectacular routes in the surrounding area. Create life-long memories as you ride to Rally Weekend and enjoy awesome riding in and around the Milwaukee area.
            <br /><br />
            Make the most of your time by downloading the Harley-Davidson™ App. Check out the routes around Southeastern Wisconsin, find the nearest Harley-Davidson dealership, and visit the Harley-Davidson Museum for patio dining, a self-guided tour or walking tour of the grounds.`
    },
    letsride: {
        useTranslations: true,
        headerImage: '/img/hd-alt-logo.png',
        image: {
            en: '/img/show-modal-lets-ride-2021@2x.jpg',
            fr: '/img/show-modal-lets-ride-2021-french@2x.jpg'
        },
        imageStyle: {
            maxHeight: '114%',
            width: '100%',
            maxWidth: 'initial'
        },
        // shortMarginLeft: '-224px',
        mobileStyle: {
            minHeight: '100%',
            width: '100%',
            position: 'unset'
        },
        noClip: true,
        mobileImage: {
            en: '/img/show-modal-lets-ride-2021-mobile@2x.jpg',
            fr: '/img/show-modal-lets-ride-2021-mobile-french@2x.jpg'
        },
        title: 'Let’s Ride Challenge',
        subtitle: 'July 23  - September 30, 2021',
        // SAFARI Requires T or will return NaN
        contentDisplayEndDateTime: '2021-09-30T23:59:59',
        description: `Harley-Davidson® is celebrating summer by giving you a chance to win big. Enter daily, complete activities, and track miles using the Harley-Davidson® App to unlock your chance to win a prize. <a href='https://harleydavidson.review.eprize.com/letsridechallenge/' target='_blank'>Click here</a> to learn more and register.
        <br /><br />
        Make your miles and check-ins count by downloading the Harley-Davidson® App. Miles and check-ins recorded in the Let’s Ride Challenge on the H-D app will count towards entries for prizes.  Be sure to log into the app with the same email address that you signed up for the <a href='https://harleydavidson.review.eprize.com/letsridechallenge/' target='_blank'>Let’s Ride Challenge</a>.`
    },
    homecoming: {
        useTranslations: true,
        headerImage: '/img/hd-alt-logo.png',
        image: '/img/show-modal-homecoming-2022@2x.png',
        imageStyle: {
            objectFit: 'cover',
            width: '128%',
            marginRight: '48px'
            // maxWidth: 'initial',
        },
        mobileStyle: {
            objectFit: 'cover',
            height: '100%',
            width: 'auto',
            position: 'unset'
        },
        noClip: true,
        mobileImage: '/img/show-modal-homecoming-2022-mobile@2x.png',
        title: 'Download the Harley-Davidson® App',
        subtitle: 'Track your miles, earn entries to win epic prizes.',
        // SAFARI Requires T or will return NaN
        contentDisplayEndDateTime: '2022-12-31T23:59:59',
        description: `Harley-Davidson® is revving up for the 120th anniversary by giving you a chance to win big. Miles and check-ins recorded on the Harley-Davidson® App will count towards entries for prizes in The Road to Harley-Davidson Homecoming Challenge™. Visit H-D.com/ride for full prize details. 
        <br /><br />
        To start earning entries:
        <ul style="list-style:unset; margin-left: 14px;">
            <li>Sign up for The Road to Harley-Davidson Homecoming™ Challenge on H-D.com/ride</li>
            <li>Download the Harley-Davidson® App - Be sure to log into the app with the same email address that you used to sign up for The Road to Harley-Davidson Homecoming ™ Challenge</li>
            <li>Join the riding challenge on the app</li>
            <li>Start tracking your miles</li>
        </ul>
        `
    },
    app: {
        useTranslations: true,
        headerImage: '/img/hd-alt-logo.png',
        image: '/img/challenge-apr24-hero.jpg',
        imageStyle: {
            maxHeight: '114%',
            width: '100%',
            maxWidth: 'initial'
        },
        // shortMarginLeft: '-224px',
        mobileStyle: {
            minHeight: '100%',
            width: '100%',
            position: 'unset'
        },
        noClip: true,
        mobileImage: '/img/challenge-apr24-hero-mobile.jpg',
        title: 'Harley-Davidson® App',
        // SAFARI Requires T or will return NaN
        contentDisplayEndDateTime: '2032-12-31T23:59:59',
        description: `The Harley-Davidson® App gives you access to the best tools to get the most out of your time on the road.
        <br /><br />
        Plan your route and hit the road with GPS navigation or create a route of your own and record your ride. While you’re at it, find a dealer, view upcoming events, and participate in riding challenges.`
    },
    mobileApp: {
        useTranslations: true,
        headerImage: '/img/hd-alt-logo.png',
        image: '/img/challenge-apr24-hero.jpg',
        imageStyle: {
            maxHeight: '114%',
            width: '100%',
            maxWidth: 'initial'
        },
        // shortMarginLeft: '-224px',
        mobileStyle: {
            minHeight: '100%',
            width: '100%',
            position: 'unset'
        },
        noClip: true,
        mobileImage: '/img/challenge-apr24-hero-mobile.jpg',
        title: 'Harley-Davidson® App',
        // SAFARI Requires T or will return NaN
        contentDisplayEndDateTime: '2032-12-31T23:59:59',
        description: `The Harley-Davidson® App gives you access to the best tools to get the most out of your time on the road.
        <br /><br />
        Plan your route and hit the road with GPS navigation or create a route of your own and record your ride. While you’re at it, find a dealer, view upcoming events, and participate in riding challenges.`
    },
    'content/fallbacktest': {
        headerImage: '/img/hd-alt-logo.png',
        image: '/img/show-modal-desktop-Hometown-Rally-2021@2x.jpg',
        mobileImage: '/img/show-modal-mobile-Hometown-Rally-2021@2x.jpg',
        imageDivStyle: {
            clipPath: 'none',
            backgroundColor: 'black',
            image: {
                height: '100%'
            }
        },
        mobileStyle: {
            minHeight: '100%',
            width: '100%',
            position: 'unset'
        },
        fallbackURL: () => {
            const el = document.getElementById('fallback');
            if (el) {
                console.log('el', el);
                el.click();
            }
        },
        title: 'Hometown FALLBACK URL TEST',
        subtitle: 'June 2 - 26, 2022',
        description: `Testing fallback linksand revealed throughout the summer. Check for updates at <a href='http://h-d.com/fallbackURL' target='_blank' id='fallback'>H-D.com/fallbackURL</a>, where links direct you to Mobile if mobile available or HD if using Desktop.`,
        contentDisplayEndDateTime: '2022-09-06T23:59:59'
    }
};
