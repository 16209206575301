/******************************************************************************\
 * File: hoc.js
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/
import React from 'react';
import ReactDOM from 'react-dom';
import { getComponentName } from '@/helpers/react';
import PropTypes from 'prop-types';

/** HOC that allows the component to be closed with escape. */
export const escapable = (Component) => {
    class WrappedCompnent extends React.Component {
        static displayName = `Escapable(${getComponentName(Component)})`;

        componentDidUpdate(prevProps) {
            const { isOpen } = prevProps;
            if (this.props.isOpen !== isOpen) {
                if (isOpen) {
                    this.onAfterOpen();
                } else {
                    this.onAfterClose();
                }
            }
        }

        componentWillUnmount() {
            this.onAfterClose();
        }

        onAfterOpen = () => {
            document.addEventListener('keyup', this.onKeyUp, false);
            this.props.onAfterOpen && this.props.onAfterOpen();
        };

        onKeyUp = (e) => {
            if (e.key === 'Escape')
                this.props.onRequestClose && this.props.onRequestClose();
        };

        onAfterClose = () => {
            document.removeEventListener('keyup', this.onKeyUp, false);
            this.props.onAfterClose && this.props.onAfterClose();
        };

        render() {
            return <Component {...this.props} onAfterOpen={this.onAfterOpen} />;
        }
    }
    WrappedCompnent.propTypes = {
        isOpen: PropTypes.bool,
        onAfterOpen: PropTypes.func,
        onRequestClose: PropTypes.func,
        onAfterClose: PropTypes.func
    };
    return WrappedCompnent;
};

/** HOC that allows the component to be closed with escape. */
export const clickAway = (Component) => {
    class WrappedCompnent extends React.Component {
        static displayName = `ClickAway(${getComponentName(Component)})`;
        componentDidMount() {
            typeof window.ontouchstart === 'undefined'
                ? document.addEventListener(
                      'mousedown',
                      this.handleClickOutside
                  )
                : document.addEventListener(
                      'touchstart',
                      this.handleClickOutside
                  );
        }

        componentWillUnmount() {
            typeof window.ontouchstart === 'undefined'
                ? document.removeEventListener(
                      'mousedown',
                      this.handleClickOutside
                  )
                : document.removeEventListener(
                      'touchstart',
                      this.handleClickOutside
                  );
        }

        handleClickOutside = (event) => {
            // call child method if the user has clicked away
            if (
                this.$child &&
                !this.$child.contains(event.target) &&
                this.__wrappedInstance &&
                typeof this.__wrappedInstance.onClickAway === 'function'
            ) {
                this.__wrappedInstance.onClickAway(event);
            }
        };

        render() {
            const { innerRef, ...rest } = this.props;
            return (
                <Component
                    {...rest}
                    ref={(child) => {
                        this.__wrappedInstance = child;
                        //eslint-disable-next-line
                        this.$child = ReactDOM.findDOMNode(child);
                        innerRef && innerRef(child);
                    }}
                />
            );
        }
    }
    WrappedCompnent.propTypes = {
        innerRef: PropTypes.any
    };
    return WrappedCompnent;
};
