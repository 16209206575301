/******************************************************************************\
 * File: math.js
 *
 * Author: Gigster
 *
 * Description: Math helpers
 *
 * Notes:
 \******************************************************************************/
/**
 * t = current time, b = start value, c = change in value, d = duration
 */
export const easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const lerp = (v0, v1, t) => {
    return (1 - t) * v0 + t * v1;
};

export const lerpPoint = (p0, p1, t) => {
    return {
        lat: lerp(p0.lat, p1.lat, t),
        lng: lerp(p0.lng, p1.lng, t)
    };
};

export const quadBezier = (v0, v1, v2, t) => {
    return (1 - t) * (1 - t) * v0 + 2 * (1 - t) * t * v1 + t * t * v2;
};

export const quadBezierPoint = (p0, p1, p2, t) => {
    return {
        lat: quadBezier(p0.lat, p1.lat, p2.lat, t),
        lng: quadBezier(p0.lng, p1.lng, p2.lng, t)
    };
};

/**
 * Given a function and precision, returns a function that when called with any
 * args will append t [0 - 1] to the end of the arg list. Calls fn * precision
 * times and returns the array.
 */
export const generatePoints =
    (fn, precision) =>
    (...args) => {
        const points = [];

        for (let i = 0; i < precision; i++) {
            const point = fn(...args, i / precision);
            points.push(point);
        }

        return points;
    };

export const quadBezierBetweenPoints = (p0, p1, scalex, scaley, precision) => {
    const dx_lng = p1.lng - p0.lng;
    const dy_lat = p1.lat - p0.lat;

    const radius = Math.sqrt(dx_lng * dx_lng + dy_lat * dy_lat) / 2;
    const angle = Math.atan2(dy_lat, dx_lng);

    // get the mid point
    const pmid = {
        lat: p0.lat + Math.sin(angle) * radius,
        lng: p0.lng + Math.cos(angle) * radius
    };

    // create p2 as the perpendicular from the midpoint
    const perp = angle + Math.PI / 2;
    const p2 = {
        lat: pmid.lat + Math.sin(perp) * radius * scaley,
        lng: pmid.lng + Math.cos(perp) * radius * scalex
    };

    return generatePoints(quadBezierPoint, precision)(p0, p2, p1);
};

export const dottedArray = (array, dash, gap) => {
    if (dash < 1) dash = 1;
    if (gap < 0) gap = 0;

    const skip = dash + gap;
    const result = [];

    for (let i = 0, n = array.length; i < n; i += skip) {
        result.push(array.slice(i, i + dash));
    }

    return result;
};
