import { hereTypeToCategory } from '@/helpers/here';
import { prettyAddress, isEVDealer } from '@/helpers/functions';
import { WaypointCategory } from '@/helpers/constants';
const { CONNECTOR_STANDARDS, OPERATORS } = require('../../shared/constants');
/* */
// Constants
export const POI_CATEGORY_IMAGE = {
    [WaypointCategory.GAS_STATION]: '/img/gas-marker.svg',
    [WaypointCategory.CHARGING_STATION]: '/img/charging-station-marker.svg',
    [WaypointCategory.RESTAURANT]: '/img/restaurant-marker.svg',
    [WaypointCategory.SCENIC]: '/img/scenic-marker.svg',
    [WaypointCategory.HOTEL]: '/img/hotel-marker.svg'
};

export const CONNECTORS = {
    LEVEL_1: [
        5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
        24, 42
    ],
    LEVEL_2: [30, 31],
    LEVEL_3: [33, 34]
};
/* */
// Helpers
export const transformPOI = (item) => {
    const { position, name, label, title, id, address, href } = item;
    const category = (
        (item.categories
            ? item.categories.find((category) =>
                  hereTypeToCategory(category.id)
              )
            : item.category) || {}
    ).id;

    const type = hereTypeToCategory(category);
    const icon = POI_CATEGORY_IMAGE[type];

    //  //add open / hours / phone?
    // switch position to access?
    return {
        id,
        icon,
        component: type,
        href,
        position,
        type,
        category,
        title,
        name,
        label,
        body: (address || {}).label,
        markerType: 'poi'
    };
};

function arrayFromMask(mask) {
    // mask must be between -2147483648 and 2147483647
    if (mask > 0x7fffffff || mask < -0x80000000)
        throw new TypeError('arrayFromMask - out of range');
    const arr = [];
    for (let nShifted = mask; nShifted; ) {
        arr.push(Boolean(nShifted & 1));
        nShifted >>>= 1;
    }
    return arr;
}

const open24x7Display = {
    days: arrayFromMask(127),
    from: '00:00:00',
    to: '24:00:00'
};

const daysHours = (regularOpeningHours) => {
    return regularOpeningHours.map(({ daymask, period }) => {
        const [first] = period;
        return { days: arrayFromMask(daymask), from: first.from, to: first.to };
    })[0];
};

export const transformChargerDetails = (item) => {
    if (!item.connectors) return;
    return item.connectors
        .map((connector) => {
            const obj = {
                connectorCount: connector.chargingPoint.numberOfConnectors,
                connectorName: connector.connectorType.name,
                powerFeedType: (connector.powerFeedType || {}).name,
                supplierName: connector.supplierName || item.brand
            };
            if (!connector || !connector.connectorDetails) return obj;
            const { openingHours, open24x7 } = connector.connectorDetails;
            if (open24x7) return { ...obj, ...open24x7Display };
            if (!openingHours || !openingHours.regularOpeningHours) return obj;
            return { ...obj, ...daysHours(openingHours.regularOpeningHours) };
        })
        .filter((c) => c);
};

export const transformHereEVChargers = (item) => {
    // make request when charge levels are toggled?
    const {
        name,
        id,
        position: { latitude, longitude },
        entrancePosition,
        supplierName,
        phone,
        notes,
        opening_times,
        operator,
        evses
    } = item;
    const position = { lat: latitude, lng: longitude };
    const type = 'CHARGING_STATION';
    /*ldgonzalezmedina, I have comment out this cuz it was duplicated and overwrite ( this icon was assigned first on return )*/
    //const icon = POI_CATEGORY_IMAGE[type];

    const formatUSAddress = (item) => {
        const { city, address, state, country, postal_code } = item;
        // remove undefined from address
        const empty = [NaN, undefined, null];
        const cityState = [`${city},`, state, postal_code]
            .filter((item) => !empty.includes(item))
            .join(' ');
        return {
            formatted: `${address} ${cityState}`,
            address,
            cityState,
            country
        };
    };
    const { formatted, address, cityState } = formatUSAddress(item);
    return {
        id,
        position,
        address,
        cityState,
        location: position,
        entrancePosition,
        type,
        notes,
        title: name,
        name,
        body: formatted,
        supplierName: supplierName || '',
        markerType: 'poi',
        phone,
        opening_times,
        operator,
        evses,
        icon: '/img/charging-station-marker.svg'
    };
};

export const transformEAEVChargers = (item) => {
    const {
        name,
        id,
        position: { latitude, longitude },
        connectors: { connector, connectorDetails }
    } = item;
    const position = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
    const type = 'CHARGING_STATION';
    const formatUSAddress = (item) => {
        const { city, state, country, postalCode } = item;
        // remove undefined from address
        const empty = [NaN, undefined, null];
        const cityState = [`${city},`, state, postalCode]
            .filter((item) => !empty.includes(item))
            .join(' ');
        return {
            formatted: `${cityState}`,
            cityState,
            country
        };
    };
    const { formatted, cityState } = formatUSAddress(item.address);
    const transformedEvses = connector.map((connectorItem) => {
        // LEVEL added for filtering
        let level = null;

        level = (
            CONNECTOR_STANDARDS.find((item) => {
                return item.id === Number(connectorItem.connectorType.id);
            }) || {}
        ).level;
        return {
            level,
            connector: connectorItem.connectorType.name
        };
    });

    return {
        id,
        position: { latitude, longitude },
        cityState,
        location: position,
        type,
        title: name,
        name,
        body: formatted,
        supplierName: OPERATORS.ELECTRIFY_AMERICA,
        markerType: 'poi',
        opening_times: connectorDetails?.openingHours?.regularOpeningHours,
        evses: transformedEvses,
        icon: '/img/charging-station-marker.svg'
    };
};

export const transformRide = (ride) => {
    const { id, name: title, waypoints, locationHistory } = ride;
    const getPosition = ({ latitude, longitude, ...rest }) => {
        return {
            lon: longitude,
            lat: latitude,
            ...rest
        };
    };
    const position =
        !!waypoints && waypoints.length > 0 ? waypoints[0] : locationHistory[0];

    return {
        ...ride,
        id,
        position: getPosition(position),
        title,
        markerType: 'ride'
    };
};

export const transformDealer = (dealer) => {
    const {
        id,
        dealerName: title,
        address1,
        city,
        stateCode,
        postalCode,
        dealerName,
        dealerId,
        openingTimes
    } = dealer;
    const position = { lat: dealer.latitude, lng: dealer.longitude };
    const evDealer = isEVDealer(dealer);
    return {
        id,
        dealerId,
        position,
        title,
        dealerName,
        address1,
        city,
        stateCode,
        postalCode,
        body: prettyAddress(dealer) || '',
        markerType: 'dealer',
        type: WaypointCategory.DEALER,
        category: 'dealers',
        isEVDealer: evDealer,
        openingTimes,
        icon: evDealer ? '/img/dealer-ev-marker.svg' : '/img/dealer-marker.svg'
    };
};

export const transformEvent = (event) => {
    const {
        eventName: title,
        eventActivities,
        startDate,
        endDate,
        //eventHours,
        eventId,
        eventHostDescription
    } = event;

    const { latitude, longitude } = event.eventActivities[0].address;
    const activities = event.eventActivities || [];
    const { hours: eventHours } = activities[0];
    const eventHostName = eventActivities[0].address.name
        ? eventActivities[0].address.name
        : eventHostDescription;

    const position = { lat: latitude, lng: longitude };
    return {
        position,
        title,
        body: eventActivities.length ? prettyAddress(eventActivities[0]) : '',
        startDate,
        endDate,
        eventHours,
        eventActivities,
        markerType: 'event',
        type: WaypointCategory.EVENT,
        category: 'events',
        icon: '/img/event-marker.svg',
        eventId,
        eventHostName
    };
};
