import { matchPath } from 'react-router-dom';
import dayjs from 'dayjs';
import { transformRide } from '@/store/poi/helpers';

import * as api from '@/helpers/api';
import {
    request,
    reducer as asyncReducer,
    deleteLoadingState
} from '@/helpers/async';
import { update as updateMap } from '@/store/map';
import { calculateRide } from '@/helpers/here';
import { memoize, convertToLatLng, adjustRadius } from '@/helpers/functions';

import { getPointsDistances } from '@/helpers/map';
import createStore from '@/helpers/items';
import previewReducer from './preview';
import { centerRide } from '@/store/map';
export {
    traverseRoute,
    handleTraverseRoute,
    setCurrentRouteProgress,
    routing_previewRide,
    routing_unpreviewRide,
    previewRide,
    openDropdown,
    updateForecast,
    previewRideCallback,
    update as updateRidePreview,
    resetScrub,
    resetCurrentRoutePoint
} from './preview';
import { rideUrl } from '@/helpers/routes';
import { setError, handleRoutingError } from '@/store/error';
import { Routes, isType } from '@/helpers/routes';
import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import { clearRide } from '@/store/edit_ride';
import { createWaypoint } from '@/store/edit_ride/waypoints';
import { rideForStore, waypointsForStore } from '@/store/edit_ride/helpers';
import { WaypointType, RideSubtype, Ridetype } from '@/helpers/constants';
import { rideTypeData } from '@/helpers/checkboxes';
import history from '@/helpers/history';
import { LOCATION_CHANGE } from '../../helpers/constants';

const SET_DEALER_RIDES = 'events/SET_DEALER_RIDES';

const isRecorded = (ride) => ride.subType === RideSubtype.RECORDED;
const isPlanned = (ride) => ride.subType === RideSubtype.PLANNED;
const isDealerRide = (ride) => ride.type === RideSubtype.DEALER;
const isCuratedRide = (ride) => ride.type === Ridetype.CURATED;
const isCustomRide = (ride) => ride.type === Ridetype.CUSTOM;
const hasPhotos = (ride) => ride.photos.length > 0;

const formattedDate = (dateTime) =>
    `${dayjs(dateTime).format('MMM D, YYYY').toUpperCase()} ${dayjs(
        dateTime
    ).format('h:mma')}`;

const rideTransform = (ride) => {
    return {
        ...ride,
        isRecordedRide: isRecorded(ride),
        rideSubType: rideTypeData().find((t) => t.value === ride.subType),
        modifiedTimeFormatted: ride.modifiedTime
            ? formattedDate(ride.modifiedTime)
            : null,
        isPlannedRide: isPlanned(ride),
        isDealerRide: isDealerRide(ride),
        isCuratedRide: isCuratedRide(ride),
        isCustomRide: isCustomRide(ride),
        hasPhotos: hasPhotos(ride)
    };
};

const transformRideData = (rides) =>
    rides.map((ride) => {
        const { latitude, longitude, rideName, rideId } = ride;
        return {
            ...transformRide(ride),
            latitude,
            longitude,
            rideName,
            rideId
        };
    });

const ridesTransform = (rides) => rides.map((ride) => rideTransform(ride));

const fetchMany = (lat, lng, radius) => {
    let adjustedRadius = adjustRadius(radius);
    const userSession = (state) => state.auth.session;
    const includeBookmarked = !!userSession ? true : false;
    if (!adjustedRadius) return Promise.reject();
    if (adjustedRadius < 200) adjustedRadius = 200;
    return api
        .getRides(lat, lng, adjustedRadius, false, includeBookmarked)
        .then((response) => transformRideData(response.data.rides));
};

const fetchManyWithParams = (params) => (dispatch) => {
    if (typeof params !== 'object') return Promise.reject();
    const dealerId = params.dealerId;
    const type = params.tag ? 'rides/FETCH' : SET_DEALER_RIDES;
    api.getRidesWithParams(params).then((response) =>
        dispatch({
            type: type,
            data: !dealerId
                ? transformRideData(response.data.rides)
                : transformRideData(
                      response.data.rides.filter(
                          (ride) => ride.dealerId === dealerId
                      )
                  )
        })
    );
};

const {
    SELECT: SELECT_RIDE,
    fetchItems: fetchRides,
    reducer,
    initialState
} = createStore('rides', fetchMany);

export { fetchRides, fetchManyWithParams };

/* */
// Types
const DELETE_RIDE = 'rides/DELETE_RIDE';
const FETCH_CUSTOM = 'rides/FETCH_CUSTOM';
export const LOADED_RIDE = 'rides/LOADED_RIDE';
const UPDATE = 'rides/UPDATE';

/* */
// Selectors
export const getCurrentRide = (state) => {
    const { pathname } = (history || {}).location || {};

    if (
        pathname &&
        (isType(pathname, ['CREATE', 'EDIT']) ||
            matchPath(
                {
                    path: Routes.RIDE_CREATE_PREVIEW,
                    end: true
                },
                pathname
            ))
    ) {
        return state.edit_ride.present.ride || {};
    }

    return state.rides.selected || {};
};

/* */
// Helpers
const memo_calcuateRide = memoize(
    calculateRide,
    (args) =>
        JSON.stringify(args[0].waypoints) +
        JSON.stringify(args[0].rideAvoidances)
);

export const isRideRecorded = (ride) => isRecorded(ride);

/* */
// Action Creators
export const update = (field, value) => ({
    type: UPDATE,
    data: { field, value }
});

export const selectRide = (id, navigate) => (dispatch, getState) => {
    if (!id) return navigate(rideUrl());
    api.getRide(id, getState)
        .then((ride) => {
            navigate(rideUrl(ride.shortId || ride.id));
        })
        .catch((err) => {
            console.log('error', err);
            dispatch(rideLoadError());
        });
};

export const rideLoadError = () => (dispatch) => {
    history.replace(Routes.MAP_RIDES);
    dispatch({ type: LOADED_RIDE });
    dispatch(update('rideNotFound', true));
};

const selectRideAction = (ride) => ({
    type: SELECT_RIDE,
    data: rideForStore(ride)
});

export const routing_selectRide = (rideId) => (dispatch, getState) => {
    api.getRide(rideId, getState)
        .then((ride) => {
            selectRideCallback(ride)(dispatch, getState);
            const transformedRide = ride.position ? ride : transformRide(ride);
            dispatch(updateMap('selectedData', transformedRide));
        })
        .catch((err) => {
            console.error(err);
            dispatch(rideLoadError());
        });
};

export const selectCollectionRide = (rideId) => (dispatch) => {
    dispatch(update('selectedCollectionRide', rideId));
};

/** Callback gets fired when we have a ride to put in the state. */
export const selectRideCallback = (ride) => (dispatch, getState) => {
    const promise = Promise.resolve();
    const { schemeChanged } = getState().map;
    const selectCenterReturn = (ride) => {
        dispatch(selectRideAction(ride));
        dispatch(centerRide(ride));
        dispatch({ type: LOADED_RIDE });
        return ride;
    };

    if (!ride) return promise;

    const shouldUseLocationHistory = ({ waypoints, locationHistory }) =>
        (!waypoints || waypoints.length === 0) &&
        locationHistory &&
        locationHistory.length > 1;

    const useLocationHistory = (ride) => {
        // allow locationHistory to act as waypoints:
        return waypointsForStore(
            ride.locationHistory.map((waypoint) => ({
                ...waypoint,
                type: WaypointType.WAYPOINT
            }))
        );
    };
    dispatch(selectRideAction(ride));
    dispatch(updateMap('hoverRide', null));
    if (!!ride && !!ride.offRoad)
        return promise.then(() => {
            const pts = (
                ride.points ||
                ride.waypoints ||
                ride.locationHistory
            ).map(convertToLatLng);
            const distances = getPointsDistances(pts);
            if (shouldUseLocationHistory(ride))
                ride.waypoints = useLocationHistory(ride);
            const newRide = {
                ...ride,
                distances,
                points: pts
            };
            return selectCenterReturn(newRide);
        });
    if (shouldUseLocationHistory(ride)) {
        ride.waypoints = useLocationHistory(ride);

        // load address data for the start and end locations:
        const { length } = ride.waypoints;
        return promise.then(() =>
            Promise.all([
                createWaypoint(ride.waypoints[0]),
                createWaypoint(ride.waypoints[length - 1])
            ])
                .then(([start, end]) => {
                    ride.waypoints[0] = start;
                    ride.waypoints[length - 1] = end;

                    const { locationHistory } = ride;

                    const points = locationHistory.map((e) => ({
                        lat: e.latitude,
                        lng: e.longitude
                    }));

                    const distances = getPointsDistances(points);

                    return {
                        ...ride,
                        points,
                        distances,
                        traffic: []
                    };
                })
                .then((rideWithData) => {
                    return selectCenterReturn(rideWithData);
                })
        );
    }

    if ((ride.waypoints || []).length) {
        return promise.then(() =>
            memo_calcuateRide(ride)
                .then((routingData) => {
                    const { points, legs, traffic, actions, sections } =
                        routingData;
                    const distances = getPointsDistances(points);

                    const rideWithData = {
                        ...ride,
                        actions,
                        distances,
                        points,
                        leg: legs,
                        legs,
                        traffic,
                        sections
                    };
                    dispatch(selectRideAction(rideWithData));
                    //On first load it should center and after it should not center
                    if (!schemeChanged) {
                        dispatch(centerRide(rideWithData));
                    }
                    dispatch({ type: LOADED_RIDE });
                    return rideWithData;
                })
                .catch((e) => dispatch(setError(handleRoutingError(e))))
        );
    }

    return promise;
};

/** Removes the selected ride from state. */
export const routing_unselectRide = () => (dispatch) => {
    dispatch(selectRideAction(null));
};

/** Called when a user hovers over a ride to draw the preview line. */
export const hoverRide = (rideId) => (dispatch, getState) => {
    const { selected } = getState().rides;
    if ((selected || {}).id !== rideId)
        api.getRide(rideId, getState).then((ride) => {
            if (ride.offRoad)
                return dispatch(
                    updateMap('hoverRide', {
                        ...ride,
                        points: ride.points || ride.waypoints
                    })
                );
            return memo_calcuateRide(ride).then(({ points }) =>
                dispatch(updateMap('hoverRide', { ...ride, points }))
            );
        });
};

/** Called when a user hovers away from a ride to remove the preview line. */
export const unhoverRide = (rideId) => (dispatch, getState) => {
    const { selected } = getState().rides;
    if ((selected || {}).id !== rideId) dispatch(updateMap('hoverRide', null));
};

const addExtraRideData = (ride) => ({
    ...ride,
    modifiedTimeMS: new Date(ride.modifiedTime).getTime()
});

export const fetchCustomRides = () =>
    request(
        FETCH_CUSTOM,
        api
            .getCustomRides()
            .then((rides) => (rides || []).map(addExtraRideData))
            .then((rides) => ridesTransform(rides))
            .then((rides) =>
                rides.sort((a, b) => b.modifiedTimeMS - a.modifiedTimeMS)
            )
    );

export const deleteCustomRide = (id) => (dispatch, getState) => {
    const { ride } = getState().edit_ride.present;
    if (ride.id === id) {
        dispatch(clearRide());
    }
    return dispatch(request(DELETE_RIDE, api.deleteRide(id), { id }));
};

/* */
// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE: {
            const { field, value } = action.data;

            return {
                ...state,
                [field]: value
            };
        }

        case DELETE_RIDE: {
            const { id } = action.meta;
            return {
                ...state,
                custom: deleteLoadingState(id)(state.custom, action)
            };
        }

        case AUTH_INVALIDATE: {
            return {
                ...state,
                data: state.data.filter((ride) => ride.privacy === 'PUBLIC'),
                preview:
                    state.selected === null ||
                    (state.selected.privacy === 'PRIVATE' &&
                        state.selected.id !== undefined)
                        ? previewReducer(undefined, action)
                        : state.preview,
                custom: asyncReducer(FETCH_CUSTOM)(undefined, action)
            };
        }

        case SET_DEALER_RIDES:
            return { ...state, rides: action.data };

        case LOCATION_CHANGE: {
            const {
                location: { pathname }
            } = action.payload;
            const includesIdInPath =
                !!pathname &&
                pathname.indexOf(
                    (state?.selected || state?.rides?.selected || {}).shortId
                ) >= 0;
            const isRideCreatePreview =
                !!pathname && pathname.indexOf(Routes.RIDE_CREATE_PREVIEW) >= 0;
            return includesIdInPath || isRideCreatePreview
                ? state
                : { ...state, selected: null };
        }

        default: {
            return {
                ...reducer(state, action),
                preview: previewReducer(state.preview, action),
                custom: asyncReducer(FETCH_CUSTOM)(state.custom, action),
                ridesFiltered: state.ridesFiltered,
                ridesFilters: state.ridesFilters
            };
        }
    }
};
