/******************************************************************************\
* File: AnimLine.jsx
*
* Author: Gigster
*
* Description: Here map line
*
* Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import { colorComponents } from '@/helpers/functions';
import Line from '@/components/common/map/here/Line';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class AnimLine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alpha: 0
        };
    }

    componentDidMount() {
        this.request = window.requestAnimationFrame(this.step);
    }

    componentWillUnmount() {
        if (this.request) window.cancelAnimationFrame(this.request);
    }

    step = () => {
        const { alpha } = this.state;
        this.setState({ alpha: alpha + 0.05 });
        if (alpha < 1) this.request = window.requestAnimationFrame(this.step);
    };

    strokeColor = (strokeColor, alpha) => {
        const c = colorComponents(strokeColor);
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${alpha})`;
    };

    render() {
        const { props } = this;
        const { alpha } = this.state;

        const styledProps = {
            ...props,
            style: {
                ...props.style,
                strokeColor: this.strokeColor(
                    (props.style || {}).strokeColor,
                    alpha
                )
            }
        };

        return <Line {...styledProps} />;
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default AnimLine;
