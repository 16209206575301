/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <path
                fill="#FFF"
                d="M32.5 37.09V38h-3.613V19.935a6.323 6.323 0 10-12.645 0v7.895h7.095L14.42 38 5.5 27.83h7.11l.002-.669.017-7.226c0-5.487 4.448-9.935 9.935-9.935s9.936 4.448 9.936 9.935V37.09z"></path>
        </svg>
    );
}

export default Icon;
