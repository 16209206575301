/******************************************************************************\
 * File: RouteRestaurantMarker.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RouteRestaurantMarker extends React.Component {
    render() {
        const { className, onClick, highlight, isOnRoute } = this.props;
        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <div
                style={{
                    ...style,
                    height: '40px',
                    width: '37px',
                    margin: '-40px 0 0 -19px'
                }}>
                <svg
                    style={{ height: '100%', width: '100%' }}
                    className={cn}
                    viewBox={!isOnRoute ? '0 0 31 43' : '0 0 30 43'}>
                    <g fill="none" fillRule="evenodd">
                        {!isOnRoute && (
                            <circle
                                cx="14"
                                cy="14"
                                r="14"
                                fill="#000"
                                stroke="#C7C7C7"
                                strokeWidth="1"></circle>
                        )}
                        <path d="M0 0h30v30H0z" opacity=".6" color="orange" />
                        {isOnRoute && (
                            <path
                                fill="#F60"
                                d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
                            />
                        )}
                        <path
                            fill={!isOnRoute || highlight ? '#fff' : '#000'}
                            d="M15.233 8.362v5.745c0 .026-.004.051-.008.076.005.046.008.092.008.138v.156c0 .71-.56 1.286-1.25 1.286h-.311v6.67c0 .51-.403.925-.9.925a.912.912 0 0 1-.898-.925v-6.67h-.312c-.69 0-1.25-.576-1.25-1.286v-.156c0-.046.003-.092.008-.138-.003-.025-.008-.05-.008-.076V8.362c0-.323.255-.585.568-.585.314 0 .568.262.568.585v4.68c.037-.004.075-.006.114-.006h.643V8.362c0-.323.254-.585.568-.585.313 0 .568.262.568.585v4.674h.643c.038 0 .076.002.113.006v-4.68c0-.323.255-.585.568-.585.314 0 .568.262.568.585zm4.311.23v7.541a.64.64 0 0 1-.063.275v6.025c0 .51-.402.925-.898.925a.912.912 0 0 1-.9-.925v-5.429c-.541-.127-.93-.468-.93-.87V8.591c0-.51.625-.925 1.396-.925.77 0 1.395.414 1.395.925z"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}
export default RouteRestaurantMarker;
