/******************************************************************************\
* File: turnicons/index.js
*
* Author: Gigster
*
* Description: Helper function to retrive turn icons
*
* Notes: 
\******************************************************************************/
import React from 'react';
import hereActionNamesToIcon from './mapping';

const map = (name) => hereActionNamesToIcon[name] || name;

/**
 * Given the heremaps maneuver action string, returns an svg or undefined.
 */

const TurnIcon = (name, props = {}) => {
    const Icon = map(name);
    if (!Icon) return <span />;
    return <Icon {...props} />;
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default TurnIcon;
