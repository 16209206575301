/******************************************************************************\
 * File: Ride.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Ride extends React.Component {
    render() {
        const { className, onClick, isActive } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className,
            [style['no-stroke']]: true
        });
        return (
            <svg className={cn} viewBox="0 0 24 24" onClick={onClick}>
                <g fill="none" fillRule="evenodd">
                    <path
                        fill={isActive ? '#ff6600' : '#FFFFFF'}
                        d="M5.028 8.457c-1.028 0-1.861-.855-1.861-1.91 0-1.054.833-1.909 1.861-1.909s1.862.855 1.862 1.91c0 1.054-.834 1.909-1.862 1.909m2.984-5.113h.001a4.025 4.025 0 0 0-5.794-.016l-.016.016C.766 4.824.588 7.604 1.82 9.29l3.286 4.894 3.287-4.887c1.232-1.686 1.054-4.474-.382-5.953M22.183 8.143l-1.908.297-.23-1.549 1.909-.297.229 1.55zm-1.41 3.673l-.228-1.549 1.908-.296.229 1.549-1.908.296zM18.096 8.78l-2.052.32-.229-1.55 2.052-.319.23 1.549zm.325 1.752l-.251-1.697 2.052-.319.25 1.697-2.051.319zm-2.106.394l2.052-.319.229 1.55-2.052.318-.23-1.549zm-2.333-1.44l2.008-.312.251 1.697-1.997.31-.262-1.694zm1.83 11.861l-1.143-7.41 9.298-1.446L22.9 5.277 13.556 6.73l-.213-1.378-1.114.18 2.477 16.054.013-.002 1.13-.02-.038-.218zM4.994 20.41c-1.412 0-2.098-.583-2.098-1.783s.686-1.784 2.096-1.784l4.959.022c1.844 0 2.683-1.285 2.683-2.48 0-.653-.23-1.257-.646-1.702-.333-.355-.95-.778-1.998-.778H8.638l-.815 1.157H9.99c1.407 0 1.516 1.013 1.516 1.323 0 .64-.408 1.323-1.553 1.323l-4.959-.022c-2.02 0-3.226 1.1-3.226 2.94 0 1.842 1.206 2.942 3.226 2.942h8.856l-.18-1.157H4.994z"
                    />
                </g>
            </svg>
        );
    }
}
export default Ride;
