/******************************************************************************\
 * File: MapCommon.jsx
 *
 * Author: Gigster
 *
 * Description: MapCommon
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
import withRouter from '@/helpers/hooks';
import history from '@/helpers/history';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/Map.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import MarkerCluster from '@/components/common/map/here/MarkerCluster';
import Marker from '@/components/common/map/here/Marker';
import InfoBubble from '@/components/common/map/here/InfoBubble';
import PoiCluster from '@/components/common/map/icons/PoiCluster';
import ConfirmModal from '@/components/common/ConfirmModal';
import DealerSidebar from '@/components/mapListView/sidebar/DealerSidebar';
import EventSidebar from '@/components/mapListView/sidebar/EventSidebar';
import ChargingStationSidebar from '@/components/mapListView/sidebar/ChargingStationSidebar';
import RideEditSidebar from '@/components/mapListView/sidebar/RideEditSidebar';
import ImagesSidebar from '@/components/mapListView/sidebar/ImagesSidebar';
import RouteRestaurantMarker from '@/components/common/map/icons/RouteRestaurantMarker';
import RouteScenicMarker from '@/components/common/map/icons/RouteScenicMarker';
import RouteHotelMarker from '@/components/common/map/icons/RouteHotelMarker';
import RouteGasStationMarker from '@/components/common/map/icons/RouteGasStationMarker';
import RouteDealerMarker from '@/components/common/map/icons/RouteDealerMarker';
import RouteEventMarker from '@/components/common/map/icons/RouteEventMarker';
//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import { invalidateAuth } from '@/store/auth';
import {
    insertRidePointNearest,
    removeRidePoint,
    setConfirm,
    clearRide,
    showSaveModal,
    saveRide
} from '@/store/edit_ride';
import {
    update as updateMap,
    updateSidebar,
    setZoomToMarker,
    setImageIndex,
    setImages,
    setShowImagesSidebar
} from '@/store/map';
import { selectPOIMarkers } from '@/store/poi';
import { editWaypoint, isStartOrEnd } from '@/store/edit_ride/waypoints';
import {
    update as updateRides,
    previewRide,
    setCurrentRouteProgress,
    resetCurrentRoutePoint
} from '@/store/rides';
import { previewEvent, routing_selectEvent } from '@/store/events';
import { previewDealer, routing_selectDealer } from '@/store/dealers';
import { editRide } from '@/store/edit_ride';
import { setMyDealer } from '@/store/user';
import { setEventBookmark, isEventBookmarked } from '@/store/eventBookmarks';
import { setBookmark, isBookmarked } from '@/store/bookmarks';
import { isAuthenticated, auth, currentUserId } from '@/store/auth';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { analyticsPOIEvent, dataForAnalytics } from '@/helpers/analytics';
import { renderMarkers } from '@/helpers/info-bubble';
import { WaypointType, WaypointCategory } from '@/helpers/constants';
import { isType, Routes } from '@/helpers/routes';
import { getAttr, normalizeLatLng } from '@/helpers/functions';
import { poiToWaypoint, hereMapsCategoryToIcon } from '@/helpers/here';
import { analyticsWithData } from '@/helpers/analytics';
import { match } from '@/helpers/url';
//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
import { translate } from '@/helpers/i18n';
//App context
import AppContext from '@/contexts/AppContext';

const t = translate('views.MapCommon');
const log = createLogger('Map Common', true);
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class MapCommon extends React.Component {
    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    requestedDetails = null;

    onContentAction = (action) => {
        const { selectedData: data, location } = this.props;
        const { pathname } = location;

        if (!data) return;

        const index =
            (action.event && getAttr(action.event.target, 'data-index')) ||
            action?.event?.target?.parentElement?.dataset?.index ||
            action?.event?.target?.farthestViewportElement?.parentElement
                ?.dataset?.index ||
            0;

        const markers = Array.isArray(data) ? data : [data];
        const marker = markers[index];

        log('onContentAction', action, 'index', index, markers, marker);

        const callAnalytics = (marker) =>
            analyticsPOIEvent(dataForAnalytics(marker));

        if (
            action.type === 'more' &&
            isType(pathname, ['CREATE', 'EDIT', 'PREVIEW'])
        ) {
            callAnalytics(marker);
            this.requestedDetails = true;
        }

        const handlers = {
            poi: this.handlePOIAction,
            dealer: this.handleDealerAction,
            event: this.handleEventAction,
            waypoint: this.handleWaypointAction,
            ride: this.handleRideAction
        };

        const handler = handlers[marker.markerType];
        if (handler) {
            handler(action, marker);
        } else {
            log(`No handler for markerType: '${marker.markerType}'!`);
        }
    };

    evChargingStationToWaypoint = (data) => {
        const { id, body, name, location } = data;
        const { lat, lng } = location;
        return {
            address: body,
            id,
            lat,
            lng,
            name,
            category: 'CHARGING_STATION',
            type: 'CHARGING_STATION'
        };
    };
    handlePOIAction = (action, data) => {
        const { pathname } = this.props.location;

        switch (action.type) {
            case 'add':
                data.type === 'CHARGING_STATION'
                    ? this.props.insertRidePointNearest(
                          this.evChargingStationToWaypoint(data)
                      )
                    : poiToWaypoint(data).then((waypoint) =>
                          this.props.insertRidePointNearest({
                              ...waypoint,
                              clustering: null,
                              category: hereMapsCategoryToIcon(data.category),
                              type: WaypointType.POI
                          })
                      );
                this.props.hideSelectedData();
                break;

            case 'remove':
                this.props.removeRidePoint(data.id);
                this.props.hideSelectedData();
                break;
            case 'more':
                if (isType(pathname, ['CREATE', 'EDIT', 'PREVIEW'])) {
                    if (typeof data === 'object')
                        this.props.openSidebar('chargingStation', data);
                }
                break;
            default:
                log(
                    '[Map Common]',
                    `Unhandled action "${action.type}" from "${data.markerType}" marker`
                );
        }
    };

    handleRideAction = (action, data) => {
        const { setRideBookmark, isAuthenticated, auth, onEditRide } =
            this.props;

        switch (action.type) {
            case 'select':
                this.props.setSelectedData(data);
                break;
            case 'preview':
                this.props.previewRide(data);
                break;
            case 'markAsFavorite':
                if (!data.isBookmarked)
                    analyticsWithData(`star ride`, {
                        location: 'Info Bubble Map Common view ',
                        dealerId: data.dealerId || ''
                    });
                isAuthenticated
                    ? setRideBookmark(data.id, !data.isBookmarked)
                    : auth(() => setRideBookmark(data.id, !data.isBookmarked));
                break;
            case 'customize':
                onEditRide(data);
                break;
            default:
                console.log(
                    `Unhandled action "${action.type}" from "${data.markerType}" marker`
                );
        }
    };

    handleDealerAction = (action, data) => {
        const { pathname } = this.props.location;
        const { setMyDealer, isAuthenticated, auth, preferredDealer } =
            this.props;

        switch (action.type) {
            case 'select':
                this.props.setSelectedData(data);
                break;
            case 'more':
                if (isType(pathname, ['CREATE', 'EDIT', 'PREVIEW'])) {
                    this.props.selectDealer(data.id);
                    this.props.openSidebar('dealer');
                } else {
                    this.props.selectDealer(data.id);
                    this.props.previewDealer(data);
                    this.props.openSidebar('dealer');
                }
                break;
            case 'add':
                const { insertRidePointNearest } = this.props;
                // Waypoints and POI cannot be at the exact lat, lng
                // add a cm to lat to keep POI showing
                insertRidePointNearest({
                    address: data.body,
                    category: WaypointCategory.DEALER,
                    dealerId: data.dealerId,
                    id: data.dealerId,
                    lat: data.position.lat + 0.0000001,
                    lng: data.position.lng,
                    name: data.dealerName,
                    type: 'POI'
                });
                this.props.hideSelectedData();
                break;
            case 'markAsFavorite':
                const isBookmarked = data?.dealerId === preferredDealer;
                if (!isBookmarked)
                    analyticsWithData(`star dealer`, {
                        location: 'Info Bubble Map Common view ',
                        id: data.dealerId,
                        dealerId: data.dealerId
                    });
                isAuthenticated
                    ? setMyDealer(data.dealerId, isBookmarked)
                    : auth(() => setMyDealer(data.dealerId, isBookmarked));
                break;
            default:
                log(
                    `Unhandled action "${action.type}" from "${data.markerType}" marker`
                );
        }
    };

    handleEventAction = (action, data) => {
        const { pathname } = this.props.location;
        const { setEventBookmark, isAuthenticated, auth } = this.props;

        switch (action.type) {
            case 'select':
                this.props.setSelectedData(data);
                break;
            case 'more':
                if (isType(pathname, ['CREATE', 'EDIT', 'PREVIEW'])) {
                    this.props.selectEvent(data.eventId);
                    this.props.openSidebar('event');
                } else {
                    this.props.previewEvent(data);
                    this.props.openSidebar('event');
                }
                break;
            case 'add':
                const { position, eventName, eventId, title, body } = data;
                this.props.insertRidePointNearest({
                    lat: position.lat + 0.0000001,
                    lng: position.lng,
                    name: eventName,
                    type: WaypointType.POI,
                    category: WaypointCategory.EVENT,
                    id: eventId,
                    name: title,
                    label: title,
                    address: body
                });
                this.props.hideSelectedData();
                break;
            case 'markAsFavorite':
                if (!data.isBookmarked)
                    analyticsWithData(`star event`, {
                        location: 'Info Bubble Map Common view ',
                        eventId: data.eventId,
                        dealerId: data.dealerId
                    });
                isAuthenticated
                    ? setEventBookmark(data.eventId, data.isBookmarked)
                    : auth(() =>
                          setEventBookmark(data.eventId, data.isBookmarked)
                      );
                break;
            default:
                log(
                    `Unhandled action "${action.type}" from "${data.markerType}" marker`
                );
        }
    };

    handleWaypointAction = (action, data) => {
        const {
            editWaypoint,
            removeRidePoint,
            editingRide: { offRoad }
        } = this.props;
        switch (action.type) {
            case 'blur':
                editWaypoint(
                    data.id,
                    { ...data, name: action.target.value },
                    offRoad
                );
                break;
            case 'keydown':
                if (action.key === 'Enter') action.target.blur();
                break;
            case 'remove':
                removeRidePoint({ id: data.id, i: undefined, offRoad });
                this.props.hideSelectedData();
                break;
            case 'convert':
                editWaypoint(
                    data.id,
                    {
                        type:
                            data.type === WaypointType.WAYPOINT
                                ? WaypointType.LOCATION
                                : WaypointType.WAYPOINT
                    },
                    offRoad
                );
                this.props.hideSelectedData();
                break;
            default:
                log(
                    `Unhandled action "${action.type}" from "${data.markerType}" marker`
                );
        }
    };

    getBookmarks = (markerType) => {
        let itemsBookmarked = {};
        switch (markerType) {
            case 'dealer':
                itemsBookmarked.preferredDealer = !!this.props.preferredDealer
                    ? this.props.preferredDealer
                    : [];
                itemsBookmarked.eventsBookmarked = !!this.props.eventBookmarks
                    ? this.props.eventBookmarks
                    : [];
                return itemsBookmarked;
            case 'event':
                itemsBookmarked.preferredDealer = !!this.props.preferredDealer
                    ? this.props.preferredDealer
                    : [];
                itemsBookmarked.eventsBookmarked = !!this.props.eventBookmarks
                    ? this.props.eventBookmarks
                    : [];
                return itemsBookmarked;
            case 'ride':
                if (!this.props.bookmarks) return [];
                return this.props.bookmarks;
        }
    };

    getBubbleContentTemplate = (meta) => {
        const { pathname } = this.props.location;
        const { setMyDealer, selectedData } = this.props;
        const markerType = Array.isArray(selectedData)
            ? selectedData[0].markerType
            : selectedData.markerType;

        const isCreatePreview = matchPath(
            {
                path: Routes.RIDE_CREATE_PREVIEW
            },
            pathname
        );
        const isCreate =
            isCreatePreview || isType(pathname, ['CREATE', 'EDIT']);
        const isPreview = isType(pathname, ['PREVIEW']);
        const {
            ride: { waypoints },
            editingRide: { offRoad },
            userId,
            rideTags
        } = this.props;

        const canConvert =
            !Array.isArray(meta) &&
            !isStartOrEnd(meta.id, waypoints) &&
            meta.type !== WaypointType.POI;
        return renderMarkers(meta, {
            canConvert,
            canRemove:
                !isCreatePreview || (isCreatePreview && waypoints.length >= 1),
            route: isCreate ? 'create' : isPreview ? 'preview' : 'default',
            offRoad,
            userId,
            setMyDealer,
            isBookmarked: this.props.isBookmarked,
            infoBubbleItemsBookmarked: this.getBookmarks(markerType),
            rideTags
        });
    };

    getPopupPosition = (marker) => {
        const getPosition = (marker) =>
            marker && normalizeLatLng(marker.position || marker);

        return getPosition(Array.isArray(marker) ? marker[0] : marker);
    };

    renderPOICluster = (item, count) => {
        const { icon } = item;
        return {
            component: PoiCluster,
            componentProps: {
                img: icon,
                count
            }
        };
    };

    getPOI(key) {
        const {
            poi,
            location: { pathname },
            editingRide
        } = this.props;

        const isEdit = isType(pathname, ['CREATE', 'EDIT']);
        const isPreview = isType(pathname, ['PREVIEW']);

        if (poi.enabled) {
            if (isPreview) {
                return selectPOIMarkers(poi, key);
            }

            if (isEdit) {
                return selectPOIMarkers(poi, key).filter(
                    (poi) =>
                        !editingRide.waypoints.find(
                            (w) =>
                                poi.position.lat === w.lat &&
                                poi.position.lng === w.lng
                        )
                );
            }
        }

        return [];
    }

    render() {
        const {
            className,
            map,
            eventSelected,
            eventBookmarks,
            dealerSelected,
            poi,
            ride,
            editingRide,
            location,
            pushUrl,
            navigate,
            selectedData,
            onMarkerClick,
            onBubbleChange,
            confirm,
            clearRide,
            setConfirm,
            saveModal,
            showSaveModal,
            saveRide,
            rideNotFound,
            updateRideNotFound,
            setShowImagesSidebar,
            setImageIndex,
            setImages,
            openSidebar,
            closeSidebar,
            isBookmarked,
            isAuthenticated,
            auth,
            setEventBookmark,
            setRideBookmark,
            preferredDealer,
            mapSidebar,
            mapCenter,
            mapPrint,
            showImagesSidebar,
            imageIndex,
            sidebarData,
            images,
            onResetCurrentRoutePoint,
            ...rest
        } = this.props;
        const { isMobile } = this.context;

        const cn = classNames(style.Content, {
            [className]: className
        });

        const poiMarkers = this.getPOI();

        const harleyMarkers = this.getPOI('harley');

        const allChargingStationMarkers = this.getPOI('cs');
        const chargingStations = poi.chargingStations;

        const activeEVCheckBoxes = Object.keys(chargingStations || {}).filter(
            (key) => !chargingStations[key]
        );

        let chargingStationMarkers = [];

        const isPreview = () =>
            matchPath({ path: Routes.RIDE_PREVIEW }, location.pathname);

        const hideHDPOIForMobile = isPreview() && isMobile;

        if (activeEVCheckBoxes.length > 0) {
            const includesActiveBrands = (activeEVCheckBoxes) => (el) =>
                el.indexOf(activeEVCheckBoxes) !== -1;

            // set value of activeEVCheckBoxes on includesActiveBrands
            includesActiveBrands(activeEVCheckBoxes);

            chargingStationMarkers = !!allChargingStationMarkers.length
                ? allChargingStationMarkers.filter((marker) =>
                      activeEVCheckBoxes.includes(marker.supplierName) ? 1 : 0
                  )
                : [];
        }

        const position = this.getPopupPosition(selectedData);

        const POI_CATEGORY_COMPONENT = {
            '/img/gas-marker.svg': RouteGasStationMarker,
            '/img/restaurant-marker.svg': RouteRestaurantMarker,
            '/img/scenic-marker.svg': RouteScenicMarker,
            '/img/hotel-marker.svg': RouteHotelMarker
        };

        const activeCategories = Object.keys(WaypointCategory).filter(
            (category) => !poi.disabledCategories[category]
        );

        const filteredPoiMarkers = poiMarkers.filter((marker) =>
            activeCategories.includes(marker.type)
        );

        return (
            <div className={cn}>
                <ConfirmModal
                    isOpen={!!confirm}
                    title={t('Do you want to save your ride?')}
                    positiveText={t('Save Ride')}
                    onPositive={() => setConfirm('', 'confirm')}
                    negativeText={t('Nope')}
                    onNegative={() => {
                        clearRide();
                        onResetCurrentRoutePoint();
                        setConfirm('', 'deny');
                    }}
                    onRequestClose={() => setConfirm('', 'cancel')}
                />

                <ConfirmModal
                    portalClassName="ReactModalPortal force-open" // Force to open on page load
                    isOpen={!!rideNotFound}
                    title={t("That Ride Doesn't Exist")}
                    subtitle={t(
                        'Don’t let that stop you from creating one though.'
                    )}
                    positiveText={t('Create a Ride')}
                    onPositive={() => {
                        updateRideNotFound(false);
                        navigate(Routes.RIDE_CREATE);
                    }}
                    onRequestClose={() => {
                        updateRideNotFound(false);
                    }}
                />

                {!mapPrint && (
                    <DealerSidebar
                        portalClassName="ReactModalPortal force-open" // Force to open on page load
                        dealer={dealerSelected}
                        history={history}
                        mapCenter={mapCenter}
                        isOpen={
                            //condition to open on MAP_DEALER
                            !!(
                                match(window.location, [Routes.MAP_DEALER]) &&
                                dealerSelected &&
                                (selectedData ||
                                    match(window.location, [Routes.MAP_DEALER]))
                            ) ||
                            //condition to open on MAP_PREVIEW
                            !!(
                                match(window.location, [Routes.RIDE_PREVIEW]) &&
                                dealerSelected &&
                                mapSidebar === 'dealer' &&
                                this.requestedDetails
                            )
                        }
                        onRequestClose={() => {
                            const comeFromEventPath = this.props.location.state
                                ?.from
                                ? matchPath(
                                      {
                                          path: Routes.MAP_EVENT
                                      },
                                      this.props.location.state?.from
                                  )
                                : null;
                            closeSidebar();
                            this.props.clearSelectedData();
                            const filteredTypes =
                                JSON.parse(
                                    localStorage.getItem('filteredTypes')
                                ) || [];
                            const goToMap =
                                filteredTypes.filter(
                                    (x) => x === 'events' || x === 'hd-dealer'
                                ).length === 2;
                            const dealerPath = matchPath(
                                {
                                    path: Routes.MAP_DEALER
                                },
                                location.pathname
                            );
                            if (comeFromEventPath) {
                                const eventId =
                                    this.props.location.state.eventId;
                                return setTimeout(() => {
                                    navigate({
                                        pathname: `/map/events/${eventId}`
                                    }),
                                        this.props.openSidebar('event');
                                    100;
                                });
                            }
                            if (goToMap && dealerPath) {
                                return setTimeout(
                                    () => navigate(Routes.MAP),
                                    200
                                );
                            }
                            if (dealerPath) {
                                return setTimeout(
                                    () => navigate(Routes.MAP_DEALERS),
                                    100
                                );
                            }
                        }}
                    />
                )}
                {(showImagesSidebar || imageIndex) && (
                    <ImagesSidebar
                        name={'Ride Photos'}
                        setShowImagesSidebar={setShowImagesSidebar}
                        setImages={setImages}
                        setImageIndex={setImageIndex}
                        images={images}
                        imageIndex={imageIndex}
                        isMobile={isMobile}
                        isOpen={showImagesSidebar}
                        vertical={true}
                        openSidebar={openSidebar}
                        onRequestClose={() => {
                            setShowImagesSidebar();
                            closeSidebar();
                        }}
                    />
                )}
                {!mapPrint && (
                    <ChargingStationSidebar
                        portalClassName="ReactModalPortal force-open" // Force to open on page load
                        chargingStation={sidebarData || {}}
                        history={history}
                        mapCenter={mapCenter}
                        isOpen={
                            !!(
                                mapSidebar === 'chargingStation' &&
                                (sidebarData || {})
                            )
                        }
                        onRequestClose={closeSidebar}
                    />
                )}
                {!mapPrint && (
                    <EventSidebar
                        portalClassName="ReactModalPortal force-open" // Force to open on page load
                        event={eventSelected}
                        eventBookmarks={eventBookmarks}
                        isOpen={
                            //condition to open on MAP_EVENT
                            !!(
                                match(window.location, [
                                    Routes.MAP_EVENT,
                                    Routes.SHARED_EVENT
                                ]) &&
                                eventSelected &&
                                mapSidebar === 'event'
                            ) ||
                            //condition to open on MAP_PREVIEW
                            !!(
                                match(window.location, [Routes.RIDE_PREVIEW]) &&
                                eventSelected &&
                                mapSidebar === 'event' &&
                                this.requestedDetails
                            )
                        }
                        onRequestClose={() => {
                            closeSidebar();
                            this.props.clearSelectedData();
                            const filteredTypes =
                                JSON.parse(
                                    localStorage.getItem('filteredTypes')
                                ) || [];
                            const goToMap =
                                filteredTypes.filter(
                                    (x) => x === 'events' || x === 'hd-dealer'
                                ).length === 2;
                            const eventPath = matchPath(
                                {
                                    path: Routes.MAP_EVENT
                                },
                                location.pathname
                            );
                            if (goToMap && eventPath) {
                                setTimeout(() => navigate(Routes.MAP), 200);
                            }
                            if (eventPath) {
                                setTimeout(
                                    () => navigate(Routes.MAP_EVENTS),
                                    150
                                );
                            }
                        }}
                        history={history}
                    />
                )}
                {!mapPrint && (
                    <RideEditSidebar
                        ride={editingRide}
                        name={editingRide.name}
                        description={editingRide.description}
                        offRoad={editingRide.offRoad}
                        portalClassName="ReactModalPortal force-open" // Force to open on page load
                        isOpen={!!(mapSidebar === 'ride') || saveModal}
                        onRequestClose={() => {
                            closeSidebar();
                            showSaveModal(false, 'cancel');
                        }}
                        onSave={() => showSaveModal(false, 'save')}
                        history={history}
                    />
                )}
                {!hideHDPOIForMobile &&
                    !!filteredPoiMarkers.length &&
                    filteredPoiMarkers.map((marker, idx) => (
                        <Marker
                            position={marker.position}
                            component={POI_CATEGORY_COMPONENT[marker.icon]}
                            onClick={() => onMarkerClick(marker, map)}
                            componentProps={{
                                style: { pointerEvents: 'none' },
                                highlight: false
                            }}
                            key={`poi-${idx}`}
                        />
                    ))}

                {!hideHDPOIForMobile &&
                    !!harleyMarkers.length &&
                    harleyMarkers.map((marker, idx) => (
                        <Marker
                            position={marker.position}
                            component={
                                marker.type === 'DEALER'
                                    ? RouteDealerMarker
                                    : RouteEventMarker
                            }
                            onClick={() => onMarkerClick(marker, map)}
                            zIndex={99}
                            componentProps={{
                                style: { pointerEvents: 'none' },
                                isEVDealer: marker.isEVDealer,
                                highlight: false
                            }}
                            key={`hd-${idx}`}
                        />
                    ))}

                {chargingStationMarkers.length > 0 && (
                    <MarkerCluster
                        key="cs"
                        lifetime={1}
                        zIndex={1}
                        render={this.renderPOICluster}
                        onMarkerClick={onMarkerClick}
                        items={chargingStationMarkers}
                    />
                )}

                {position && (
                    <InfoBubble
                        position={position}
                        innerHTML={this.getBubbleContentTemplate(selectedData)}
                        bookmarks={this.props.bookmarks}
                        eventBookmarks={this.props.eventBookmarks}
                        preferredDealer={this.props.preferredDealer}
                        setMyDealer={this.props.setMyDealer}
                        isBookmarked={this.props.isBookmarked}
                        isAuthenticated={this.props.isAuthenticated}
                        onAction={this.onContentAction}
                        onStateChange={onBubbleChange}
                        selectedData={selectedData}
                        location={location}
                        pushUrl={pushUrl}
                    />
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        poi: state.poi,
        editingRide: state.edit_ride.present.ride,
        selectedData: state.map.selectedData,
        mapSidebar: state.map.sidebar,
        mapCenter: state.map.center,
        mapPrint: state.map.print,
        showImagesSidebar: state.map.showImagesSidebar,
        imageIndex: state.map.imageIndex,
        images: state.map.images,
        sidebarData: state.map.sidebarData,
        confirm: state.edit_ride.present.meta.confirm,
        saveModal: state.edit_ride.present.meta.saveModal,
        ride: state.edit_ride.present.ride,
        bookmarks: state?.bookmarks,
        rideNotFound: state.rides.rideNotFound,
        eventSelected: state.events.selected,
        eventBookmarks: state?.eventBookmarks,
        dealerSelected: state.dealers.selected,
        userId: currentUserId(state),
        isBookmarked:
            state?.map?.selectedData?.dealerId ===
                (state.user.data || {}).preferredDealer ||
            isEventBookmarked(state?.map?.selectedData?.eventId, state) ||
            isBookmarked(state?.map?.selectedData?.id, state),
        isAuthenticated: isAuthenticated(state),
        preferredDealer: state?.user?.data?.preferredDealer,
        rideTags: state.map.rideTags
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        editWaypoint: (id, edits, offRoad) =>
            dispatch(editWaypoint(id, edits, offRoad)),
        removeRidePoint: (data) => {
            dispatch(removeRidePoint(data));
            dispatch(setCurrentRouteProgress(0));
        },
        previewRide: (ride) => dispatch(previewRide(ride)),
        previewEvent: (event) => dispatch(previewEvent(event)),
        selectEvent: (id) => dispatch(routing_selectEvent(id)),
        previewDealer: (dealer) => dispatch(previewDealer(dealer)),
        selectDealer: (id) => dispatch(routing_selectDealer(id)),
        setMyDealer: (dealerId, isMyDealer) =>
            dispatch(setMyDealer(dealerId, isMyDealer)),
        setEventBookmark: (eventId, value) =>
            dispatch(setEventBookmark(eventId, value)),
        setRideBookmark: (rideId, value) =>
            dispatch(setBookmark(rideId, value)),
        setSelectedData: (data) => dispatch(setZoomToMarker(data)),
        hideSelectedData: () => dispatch(updateMap('selectedData', null)),
        insertRidePointNearest: (waypoint) => {
            dispatch(insertRidePointNearest(waypoint));
        },
        clearSelectedData: () => dispatch(updateMap('selectedData', null)),
        setImageIndex: (index) => {
            dispatch(setImageIndex(index));
        },
        setShowImagesSidebar: () => {
            dispatch(setImageIndex(null));
            dispatch(setImages([]));
            dispatch(setShowImagesSidebar(false));
        },
        setImages: (images) => dispatch(setImages(images)),
        setConfirm: (value, reason) => dispatch(setConfirm(value, reason)),
        clearRide: () => dispatch(clearRide()),
        showSaveModal: (value, reason) =>
            dispatch(showSaveModal(value, reason)),
        saveRide: () => dispatch(saveRide()),
        invalidateAuth: () => dispatch(invalidateAuth()),
        updateRideNotFound: (value) =>
            dispatch(updateRides('rideNotFound', value)),
        closeSidebar: () => dispatch(updateSidebar()),
        openSidebar: (value, data = null) =>
            dispatch(updateSidebar(value, data)),
        auth: (cb) => dispatch(auth(cb)),
        onEditRide: (ride) => dispatch(editRide(ride)),
        onResetCurrentRoutePoint: () => {
            dispatch(resetCurrentRoutePoint());
            dispatch(setCurrentRouteProgress(0));
        }
    };
};
MapCommon.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(MapCommon);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
