/******************************************************************************\
* File: Select.jsx
*
* Author: Gigster
*
* Description: Dropdown select component
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/form/Select.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { clickAway } from '@/helpers/hoc';
import { translate } from '@/helpers/i18n';
const t = translate('form.Select');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Select extends React.Component {
    static defaultProps = {
        placeholder: t('Select'),
        options: []
    };

    state = {
        showModal: false
    };

    onClickAway = () => {
        const { showModal } = this.state;

        if (showModal) {
            this.closeModal();
        }
    };

    onClick = () => {
        const { showModal } = this.state;

        if (!showModal) {
            this.openModal();
        }
    };

    openModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    onChange = (value) => {
        this.closeModal();

        if (value !== this.props.value) this.props.onChange(value);
    };

    getValueText = () => {
        const { value, options } = this.props;

        return (options.find((option) => option.value === value) || {}).text;
    };

    render() {
        const {
            className,
            value,
            options,
            wide,
            placeholder,
            icon: Icon
        } = this.props;

        const { showModal } = this.state;

        const text = this.getValueText();

        const cn = classNames(style.Select, {
            [className]: className,
            [style['wideInput']]: wide
        });

        return (
            <div className={cn} onClick={this.onClick}>
                {Icon && <Icon className={style.icon} />}

                {(text && (
                    <p
                        className={classNames(style.text, {
                            [style['wide']]: wide
                        })}>
                        {text}
                    </p>
                )) || (
                    <p
                        className={classNames(style.placeholder, {
                            [style['wide']]: wide
                        })}>
                        {placeholder}
                    </p>
                )}

                {showModal && (
                    <div
                        className={classNames(style.dropdown, {
                            [style['wide']]: wide
                        })}>
                        {options.map((option, i) => (
                            <p
                                className={classNames(style.option, {
                                    [style.selected]: value === option.value
                                })}
                                onClick={() => {
                                    this.onChange(option.value);
                                }}
                                key={i}>
                                {option.text}
                            </p>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default clickAway(Select);
