/******************************************************************************\
* File: Restaurant.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Hotel extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [style['no-stroke']]: true,
            [className]: !!className
        });

        return (
            <svg
                className={cn}
                viewBox="0 0 17 12"
                width="17"
                height="12"
                onClick={onClick}>
                <title>hotel</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd">
                    <path
                        d="M0 10.8655V1.13435C0.00323158 0.507277 0.484368 0 1.07706 0C1.67207 0 2 0.510709 2 1.14071V7H15V3.27679C15 2.77055 15.3315 2.36037 15.9251 2.36037C16.5152 2.36037 16.9944 2.76572 17 3.26784V11.0925C16.9944 11.5944 16.5152 12 15.9251 12C15.3315 12 15 11.5896 15 11.0835V9H2V10.8591C2 11.4871 1.67526 11.9966 1.08297 12H1.07115C0.481175 11.9966 0.00322083 11.4905 0 10.8655ZM6 5.06643V2.54966C6 2.34328 6.22367 2.1758 6.49993 2.1758C6.77603 2.1758 7 2.34328 7 2.54966V5.64521C7 5.8339 6.81341 5.98989 6.57089 6.01568L6.57073 6.01612H6.56662C6.5448 6.01829 6.52254 6.01942 6.49993 6.01942C6.4773 6.01942 6.45503 6.01829 6.4332 6.01612H3.69884C3.47851 6.01612 3.3 5.8498 3.3 5.52946C3.3 5.20965 3.48146 5.06643 3.70179 5.06643H6ZM12.3227 2.63425C13.3151 2.63425 14.1196 3.25064 14.1196 4.0111V6.09353C14.1196 6.0962 14.1194 6.07047 14.1191 6.04474C14.1188 6.01902 14.1185 5.99329 14.1185 5.99596H8.01119V2.63425H12.3227ZM4.15 4.57464C4.84021 4.57464 5.4 4.015 5.4 3.32464C5.4 2.63427 4.84021 2.07464 4.15 2.07464C3.45964 2.07464 2.9 2.63427 2.9 3.32464C2.9 4.015 3.45964 4.57464 4.15 4.57464Z"
                        id="hotel"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        );
    }
}
export default Hotel;
