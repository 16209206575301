/******************************************************************************\
 * File: RouteEventMarker.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
import Bubble from '@/components/common/map/icons/Bubble';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RouteEventMarker extends React.Component {
    static defaultProps = {
        fillColor: '#fff'
    };

    render() {
        const {
            className,
            onClick,
            count,
            highlight,
            isMarker,
            isOnRoute,
            useFillColor,
            fillColor,
            dark,
            isEVDealer,
            style: styleProp
        } = this.props;

        const cn = classNames(style.Icon, 'event-marker', {
            [style['clickable']]: onClick,
            [className]: className
        });
        const color = isOnRoute ? '#000' : fillColor;

        return (
            <div
                style={{
                    height: '40px',
                    width: '37px',
                    margin: isOnRoute ? '-40px 0 0 -19px' : '-16px 0 0 -19px'
                }}>
                <svg
                    style={{ height: '100%', width: '100%' }}
                    className={cn}
                    viewBox={!isOnRoute ? '0 0 31 43' : '0 0 30 43'}>
                    <g fill="none" fillRule="evenodd">
                        {isOnRoute || useFillColor ? (
                            <path
                                fill={useFillColor ? fillColor : '#F60'}
                                d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
                            />
                        ) : (
                            <circle
                                cx="15"
                                cy="15"
                                r="14"
                                fill={isEVDealer ? '#056aaf' : '#000'}
                                stroke="#C7C7C7"
                                strokeWidth="1"></circle>
                        )}
                        <path
                            stroke={!isOnRoute || highlight ? '#fff' : '#000'}
                            stroke-linecap="square"
                            stroke-width="1.875"
                            d="M11.719 9.815v.935M18.281 9.815v.935"></path>
                        <path
                            stroke={!isOnRoute || highlight ? '#fff' : '#000'}
                            stroke-width=".938"
                            d="M9.844 10.752h10.313v9.345H9.844z"></path>
                        <path
                            fill={!isOnRoute || highlight ? '#fff' : '#000'}
                            d="M10.313 10.283h9.375v2.804h-9.375zM11.25 14.022h1.875v1.87H11.25zM11.25 16.826h1.875v1.87H11.25zM14.063 14.022h1.875v1.87h-1.875zM14.063 16.826h1.875v1.87h-1.875zM16.875 14.022h1.875v1.87h-1.875zM16.875 16.826h1.875v1.87h-1.875z"
                        />
                    </g>
                </svg>
            </div>
        );
    }
}
export default RouteEventMarker;
