/******************************************************************************\
 * File: routes.js
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/
import { matchPath } from 'react-router-dom';

import { MapViewTypes, DrawerState } from '@/helpers/constants';
import { Routes } from '@/shared/routing';
import generatePath from '@/helpers/generatePath';

export { Routes, generatePath };

const rideUrlId = (ride) => ride.shortId || ride.id;

export const ridePath = (route, ride) =>
    generatePath(route, { id: rideUrlId(ride) });

export const rideSharePath = (shortId) =>
    shortId
        ? window.location.origin +
          generatePath(Routes.SHARED_RIDE, { id: shortId })
        : '';

export const match = (pathname, props = {}, paths = []) =>
    paths.some((path) => matchPath({ path, ...props }, pathname));

export const rideUrl = (id) =>
    id ? generatePath(Routes.MAP_RIDE, { id }) : Routes.MAP_RIDES;

export const shareChallengeUrl = (id) =>
    id ? generatePath(Routes.SHARED_CHALLENGE, { id }) : Routes.CHALLENGE;

export const dealerUrl = (id) =>
    id ? generatePath(Routes.MAP_DEALER, { id }) : Routes.MAP_DEALERS;

export const eventUrl = (id) =>
    id ? generatePath(Routes.MAP_EVENT, { id }) : Routes.MAP_EVENTS;

export const shareEventUrl = (id) =>
    id ? generatePath(Routes.SHARED_EVENT, { id }) : Routes.MAP_EVENTS;

export const getType = (path) => {
    if (matchPath({ path: Routes.RIDE_CREATE, end: true }, path))
        return MapViewTypes.CREATE;

    if (matchPath({ path: Routes.MAP_RIDE, end: true }, path))
        return MapViewTypes.RIDE;

    if (matchPath({ path: Routes.RIDE_PREVIEW, end: true }, path))
        return MapViewTypes.PREVIEW;

    if (matchPath({ path: Routes.RIDE_CREATE_PREVIEW, end: true }, path))
        return MapViewTypes.PREVIEW;

    if (matchPath({ path: Routes.SHARED_RIDE, end: true }, path))
        return MapViewTypes.PREVIEW;

    if (matchPath({ path: Routes.RIDE_EDIT, end: true }, path))
        return MapViewTypes.EDIT;

    if (matchPath({ path: Routes.MAP, end: false }, path))
        return MapViewTypes.MAP;

    if (matchPath({ path: Routes.SHARED_EVENT, end: true }, path))
        return MapViewTypes.SHARED_EVENT;

    return 'Unknown Type';
};

export const isType = (path, types) => {
    const type = getType(path);
    const isType =
        (Array.isArray(types) && types.some((t) => MapViewTypes[t] === type)) ||
        (typeof types === 'string' && MapViewTypes[types] === type);

    return isType;
};

/** Note: also update src/style/mapListView/MapListView.scss */
export const computedFooterHeight = (path, drawerState) => {
    if (isType(path, ['PREVIEW'])) return 120;

    if (isType(path, ['MAP', 'RIDE', 'DEALER', 'EVENT']))
        return drawerState === DrawerState.CLOSED ? 60 : 240;

    return 60;
};

export const hasParam = (search, key) =>
    search.includes(`?${key}`) || search.includes(`&${key}`);

/*
export const getParamValue = (search, key) => {
    const data = queryString.parse(search);
    const str = data[key];
    const param = params[key];
    return param && param.toValue ? param.toValue(str) : str;
};
*/
