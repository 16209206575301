/******************************************************************************\
* File: ShoppingCart.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ShoppingCart extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 14 14" onClick={onClick}>
                <title>Group</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="Group-7"
                        transform="translate(-1.000000, -328.000000)"
                        fillRule="nonzero"
                        fill="#FF6602"
                    >
                        <g
                            id="Group"
                            transform="translate(1.000000, 328.000000)"
                        >
                            <circle
                                id="Oval"
                                cx="5.6875"
                                cy="12.6875"
                                r="1.16666667"
                            />
                            <circle
                                id="Oval"
                                cx="10.0916667"
                                cy="12.6875"
                                r="1.16666667"
                            />
                            <path
                                d="M12.0458333,8.54583333 C12.1916667,8.54583333 12.3083333,8.45833333 12.3375,8.34166667 L14,2.45 C14.0583333,2.30416667 13.9708333,2.12916667 13.7958333,2.1 L13.7083333,2.1 L3.20833333,2.1 C3.20833333,2.07083333 3.20833333,2.04166667 3.17916667,2.04166667 L2.625,0.525 C2.56666667,0.320833333 2.39166667,0.204166667 2.21666667,0.145833333 L2.21666667,0.145833333 L0.554166667,0.145833333 C0.233333333,0.175 0,0.4375 0,0.729166667 C0,1.05 0.233333333,1.28333333 0.554166667,1.3125 L1.72083333,1.3125 L2.0125,2.04166667 L3.7625,8.3125 C3.7625,8.37083333 3.79166667,8.4 3.82083333,8.42916667 L3.12083333,10.1208333 C3.00416667,10.3833333 3.15,10.7041667 3.4125,10.8208333 L3.4125,10.8208333 L3.44166667,10.8208333 C3.5,10.85 3.5875,10.8791667 3.675,10.8791667 L11.8416667,10.8791667 C12.1625,10.8791667 12.425,10.6166667 12.425,10.2958333 C12.425,9.975 12.1625,9.7125 11.8416667,9.7125 L4.4625,9.7125 L4.95833333,8.54583333 L12.0458333,8.54583333 L12.0458333,8.54583333 Z"
                                id="Shape"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
export default ShoppingCart;
