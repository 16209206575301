/******************************************************************************\
 * File: NoItems.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/NoItems.scss';
import ArrowLink from '@/components/common/ArrowLink';
import Spinner from '@/components/common/loading/Spinner';
import { translate } from '@/helpers/i18n';
const t = translate('mapListView.NoItems');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class NoItems extends React.Component {
    render() {
        const { loading, text, action, to, onClick } = this.props;

        return (
            <div className={style.NoItems}>
                {loading ? (
                    <Spinner />
                ) : (
                    <div className={style.wrap}>
                        <div className={style.title}>{text}</div>
                        {(!!onClick || !!to) && (
                            <ArrowLink
                                text={action || t('Search')}
                                to={to}
                                onClick={onClick}
                                plain={to}
                                target={
                                    to && !to.startsWith('/') ? '_blank' : ''
                                }
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default NoItems;
