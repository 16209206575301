/******************************************************************************\
* File: Bookmark.jsx
*
* Author: Gigster
*
* Description: Toggleable Bookmark Icon
*
* Notes: Can be used as controlled or uncontrolled component
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import Bookmark_ from '@/components/common/icons/Bookmark';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const Bookmark = (props) => {
    const { className, disabled, onToggle } = props;
    const [active, setActive] = useState(props.active || false);

    const isActive = useCallback(() => {
        return typeof props.active !== 'undefined' ? props.active : active;
    }, [active, props.active]);

    const handleToggle = (e) => {
        const isActiveValue = !isActive();
        setActive(isActiveValue);
        onToggle && onToggle(isActiveValue);
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <Bookmark_
            className={classNames(className, {
                inactiveClassName: !!disabled
            })}
            onClick={handleToggle}
            active={active}
        />
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Bookmark;
