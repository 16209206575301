/******************************************************************************\
 * File: DealerDetails.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/sidebar/DealerSidebar.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import ArrowLink from '@/components/common/ArrowLink';
import ArrowRight from '@/components/common/icons/ArrowRight';

// import Link from '@/components/common/header/Link';
import { ActionBar, Action } from '@/components/mapListView/sidebar/ActionBar';
import Section from '@/components/mapListView/sidebar/Section';
import Info from '@/components/mapListView/sidebar/Info';
import Inline from '@/components/mapListView/sidebar/Inline';
import ShoppingCart from '@/components/common/icons/ShoppingCart';
import Wrench from '@/components/common/icons/Wrench';
import Folder from '@/components/common/icons/Folder';
import { setMyDealer } from '@/store/user';
import Sidebar from '@/components/common/Sidebar';
import Event from '@/components/common/cards/Event';
import DisplayRow from '@/components/common/cards/DisplayRow';
import Ride from '@/components/common/cards/Ride';
import { fetchEventBookmarks } from '@/store/eventBookmarks';
import Tooltip from '@/components/common/form/Tooltip';
import { handleRoutingError, setError } from '@/store/error';
import history from '@/helpers/history';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    normalizeLatLng,
    latLngValid,
    capitalize,
    usCityStateZip,
    prettyMeters,
    ocpiPrettyHourRange,
    phoneUrl,
    getOpenState,
    slugify,
    prettyPhone,
    __,
    responsiveToggle,
    isEVDealer,
    uniqueObjArray
} from '@/helpers/functions';
import { Routes } from '@/helpers/routes';
import { CONNECTOR_STANDARDS } from '@/shared/constants';
import { centerZoomToString } from '@/helpers/url';
import { crs } from '@/helpers/map';
import Bookmark from '@/components/common/Bookmark';
import { RideSubtype, Ridetype } from '@/helpers/constants';
import { getEvseLevelCounts } from '@/helpers/evCharging';
import { analyticsBookmarkEvent } from '@/helpers/analytics';

//------------------------------------------------------------------------------
// Store -----------------------------------------------------------------------
import { isAuthenticated, auth } from '@/store/auth';
import { navigateToPoint } from '@/store/edit_ride';
import { selectProgramsByType } from '@/store/dealers';
import {
    fetchManyWithParams as fetchManyEventsWithParams,
    routing_selectEvent
} from '@/store/events';
import { fetchManyWithParams as fetchManyRidesWithParams } from '@/store/rides';
import { fetchHereEv } from '@/store/poi';
//App context
import AppContext from '@/contexts/AppContext';
import { translate } from '@/helpers/i18n';
const t = translate('sidebar.DealerDetails');
//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
const DEBUG = true;
const log = (...args) => console.log('[Dealer Details]', ...args);
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class DealerDetails extends React.Component {
    static defaultProps = {
        isExpanded: true
    };

    state = {
        isHoursOpen: false,
        isRidesOpen: false,
        dealerId: true,
        evses: []
    };

    componentDidMount = () => {
        const { initialLoad } = this.state;
        const { dealer } = this.props;
        if (initialLoad && dealer) this.setState({ dealerId: dealer.dealerId });
    };

    componentDidUpdate = (prevProps, prevState) => {
        const { dealer, user } = prevProps;
        const { dealerId } = prevState;
        const newDealer = dealer.dealerId !== dealerId ? dealer : undefined;
        if (newDealer) {
            this.fetchData(newDealer);
            if (this.shouldFetchEVData(dealer, user)) {
                this.fetchEVData(dealer);
            } else {
                this.setState({ evses: [] });
            }
            this.setState({ dealerId: dealer.dealerId });
        }
    };

    componentWillUnmount() {
        this.setState({ evses: [], dealerId: null });
    }
    shouldFetchEVData = (dealer, user) =>
        dealer && isEVDealer(dealer) && user && user.data.evBikeOwner;

    fetchEVData = (dealer) => {
        fetchHereEv({
            lat: dealer.latitude,
            lng: dealer.longitude,
            radius: 200,
            connectorIds: null,
            dealer: true
        }).then((evses) => {
            this.setState({ evses: evses });
        });
    };

    fetchData = (dealer) => {
        const {
            fetchManyEventsWithParams,
            fetchManyRidesWithParams,
            fetchEventBookmarks,
            isAuthenticated
        } = this.props;
        fetchManyEventsWithParams({
            dealerId: dealer.dealerId,
            excludeDealerInfo: true
        });
        fetchManyRidesWithParams({
            dealerId: dealer.dealerId,
            type: Ridetype.CURATED,
            subType: RideSubtype.DEALER
        });
        if (isAuthenticated) fetchEventBookmarks();
    };
    static iconForProgram = (title) => {
        switch (title) {
            case 'service':
                return Wrench;

            case 'program':
                return Folder;

            default:
            case 'product':
                return ShoppingCart;
        }
    };

    isEventsOpen = () => this.props.location.search.includes('events=1');

    toggleEvents = () => {
        const { navigate } = this.props;
        const { pathname } = history.location;
        if (this.isEventsOpen()) {
            //Pending: this navigate with params probably does not work
            navigate(`${pathname}?sidebar=1`);
        } else {
            navigate(`${pathname}?sidebar=1&events=1`);
        }
    };

    toggleRides = () => this.setState({ isRidesOpen: !this.state.isRidesOpen });

    toggleHours = () => this.setState({ isHoursOpen: !this.state.isHoursOpen });

    getNumColumns = (mobile, tablet, desktop, fallback = 1) => {
        const { screenSize } = this.context;
        return responsiveToggle(screenSize, fallback, mobile, tablet, desktop);
    };

    dealerAddress = (dealer, isEventsOpen = false) => (
        <div className={style.address}>
            <div dangerouslySetInnerHTML={{ __html: dealer.address1 }} />
            <div dangerouslySetInnerHTML={{ __html: usCityStateZip(dealer) }} />
            {!isEventsOpen && dealer.phone && (
                <div>{prettyPhone(dealer.phone)}</div>
            )}
        </div>
    );

    evDealer = () => {
        return (
            <div className={style.evDealer}>
                <img src="/img/icon-hd-logo-ev.svg" />
                <span>{t('Electric Motorcycle Dealer')}</span>
            </div>
        );
    };

    dealerDetails = (dealer, distance = null) => {
        const { isAuthenticated, isMyDealer, auth } = this.props;
        const { dealerName: name, dealerHours: hours, timeZone } = dealer || {};
        const openState = getOpenState(hours, timeZone);
        const isEventsOpen = this.isEventsOpen();
        return (
            <Info header={name} className="noPadding">
                {isEVDealer(dealer) && this.evDealer()}
                {distance && (
                    <Inline className={style.inlineOpenState}>
                        {openState && (
                            <span
                                className={style.openState}
                                style={{ marginTop: '-20px' }}>
                                <span
                                    className={classNames(style.indicator, {
                                        [style[slugify(openState)]]: true
                                    })}
                                />
                                {openState}
                            </span>
                        )}
                        {distance && (
                            <span className={style.offsetTop}>
                                {prettyMeters(distance)} {t('away')}
                            </span>
                        )}
                    </Inline>
                )}
                {!isEventsOpen && (
                    <div style={{ marginTop: '-20px', paddingBottom: '20px' }}>
                        <span className={style.bookmark}>
                            <Tooltip
                                value={
                                    !!isMyDealer
                                        ? t('My Dealer')
                                        : t('Make My Dealer')
                                }
                                placement="sw"
                                noDelay>
                                <Bookmark
                                    active={!!isMyDealer}
                                    className={
                                        !!isMyDealer
                                            ? style['active']
                                            : style['inactive']
                                    }
                                    onToggle={() => {
                                        if (!isMyDealer)
                                            analyticsBookmarkEvent(
                                                'star dealer'
                                            );
                                        isAuthenticated
                                            ? this.props.setMyDealer(
                                                  dealer.dealerId,
                                                  isMyDealer
                                              )
                                            : auth(() =>
                                                  this.props.setMyDealer(
                                                      dealer.dealerId,
                                                      isMyDealer
                                                  )
                                              );
                                    }}
                                />
                            </Tooltip>
                        </span>
                    </div>
                )}
                {this.dealerAddress(dealer, isEventsOpen)}
            </Info>
        );
    };

    hoursObj = (regularHours) =>
        regularHours.reduce(
            (hours, rh) => {
                const { weekday, periodBegin, periodEnd } = rh;
                hours[weekday].push({ periodBegin, periodEnd });
                return hours;
            },
            { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [] }
        );

    dayHours = (hoursObj) => {
        const days = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
        ];
        const keys = Object.keys(hoursObj);
        return keys.map((k) => (
            <div key={k} className={style.Row} style={{ paddingBottom: '8px' }}>
                <div className={style.day}>
                    {t(capitalize(days[parseInt(k)]))}:
                </div>
                {hoursObj[k].length > 0 ? (
                    <div className={style.closed}>
                        {hoursObj[k]
                            .map((hours) => ocpiPrettyHourRange(hours, '—'))
                            .join(' ')}
                    </div>
                ) : (
                    <div className={style.closed}>{t('Closed')}</div>
                )}
            </div>
        ));
    };

    regularHours = ({ regularHours }) => {
        const { isHoursOpen } = this.state;
        const today = dayjs();
        const hoursObj = this.hoursObj(regularHours);
        const todaysHours = hoursObj[today.day()];
        return (
            <Section
                title={t('Hours of Operation')}
                selected={`${t('Today')}: ${
                    todaysHours.length > 0
                        ? todaysHours
                              .map((hours) => ocpiPrettyHourRange(hours, '—'))
                              .join(' ')
                        : t('Closed')
                }`}
                onClick={this.toggleHours}
                expanded={isHoursOpen}
                className="noPadding">
                {isHoursOpen && (
                    <div className={classNames(style.Table, style.Hours)}>
                        {this.dayHours(hoursObj)}
                    </div>
                )}
            </Section>
        );
    };
    toggle = (prop) => this.setState({ [prop]: !this.state[prop] });

    displayNotes = () => (
        <Section
            subtitle={t('Additional Information')}
            expanded={true}
            className="notesSection">
            <div className={style.shortNotes}>
                <div style={{ marginBottom: '24px' }}>
                    {t(
                        'Charge ports are accessible 24hrs with active H-D connect subscription.'
                    )}
                </div>
                <div>
                    {t(
                        '$1.00 session fee, $35/min to charge. Move within ten minutes afterward to avoid the $0.40 idle fee. MC and Visa accepted.'
                    )}
                </div>
            </div>
        </Section>
    );

    displayLevels = (level, connectors) =>
        connectors.length > 0 && (
            <div key={`displayLevel-${level}`} className={style.displayLevels}>
                <div className={style.level} style={{ marginBottom: '8px' }}>
                    {t('Level')} {level}
                </div>
                <div className={style.level}>{t('Connector Type')}</div>
                <div>
                    {uniqueObjArray(connectors).map((k, i) => (
                        <div
                            className={style.Row}
                            style={{ marginBottom: '16px' }}
                            key={`connector-${level}-${i}`}>
                            <div
                                className={style.connector}
                                style={{ marginBottom: '20px' }}>
                                <div>
                                    <img
                                        src={`/img/connectors/${k.image}.svg`}
                                    />
                                </div>
                                <div>
                                    <div>{k.type}</div>
                                    <div style={{ textTransform: 'initial' }}>
                                        {t('Max Charging Rate')}: {k.kwHour}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    displayConnectors = () => {
        const { evses } = this.state;
        if (!evses || !evses.evses || evses.evses.length < 1) return;
        const supplierName =
            (evses.operator || {}).name || evses.evses[0].supplierName;
        const connectorImagesObj = CONNECTOR_STANDARDS.reduce((acc, cs) => {
            acc[cs.standard] = { image: cs.image, type: cs.type };
            return acc;
        }, {});
        const evseStandardsByLevel = evses.evses.reduce((acc, e) => {
            const { standard, kwHour, level } = e;
            const { image, type } = connectorImagesObj[standard];
            if (!acc[level]) acc[level] = [];
            acc[level].push({ standard, kwHour, image, type });
            return acc;
        }, {});

        const level3 = evseStandardsByLevel['3'] || [];
        const level2 = evseStandardsByLevel['2'] || [];
        const level1 = evseStandardsByLevel['1'] || [];
        const levelObj = getEvseLevelCounts(evses.evses);
        return (
            <div className={style.border} style={{ padding: '12px 0' }}>
                <div
                    className={style.border}
                    style={{ marginBottom: '8px', paddingBottom: '24px' }}>
                    <div className={style.tableTitle}>{t('EV CHARGING')}</div>
                    <div style={{ marginBottom: '24px' }}>{supplierName}</div>
                    <div className={classNames(style.Table, style.Hours)}>
                        <div key={`level`} className={style.Row}>
                            <div className={style.level}>{t('Level')}</div>
                            <div className={style.ports}>{t('Ports')}</div>
                        </div>
                        {Object.keys(levelObj)
                            .reverse()
                            .map((k, i) => (
                                <div key={`count-${i}`} className={style.Row}>
                                    <div className={style.levelNum}>
                                        {k || t('Unknown')}
                                    </div>
                                    <div className={style.levelCount}>
                                        {levelObj[k].count}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
                <div key="level-display" className={style.Row}>
                    {this.displayLevels(3, level3)}
                    {this.displayLevels(2, level2)}
                    {this.displayLevels(1, level1)}
                </div>
                {this.displayNotes()}
            </div>
        );
    };

    dealerActions = ({ phone, latitude, longitude, url }) => {
        const { navigateToDealer, onRequestClose } = this.props;
        return (
            <ActionBar>
                {phone && (
                    <Action
                        text={t('Call').toUpperCase()}
                        href={phoneUrl(phone)}
                    />
                )}

                {latLngValid({ latitude, longitude }) && (
                    <Action
                        text={t('Directions').toUpperCase()}
                        onClick={() => {
                            navigateToDealer();
                            onRequestClose && onRequestClose();
                        }}
                    />
                )}

                {url && (
                    <Action
                        text={t('Website').toUpperCase()}
                        href={url}
                        target="_blank"
                    />
                )}
            </ActionBar>
        );
    };

    backLink = (onClick, text) => (
        <a onClick={onClick} className={style.arrow}>
            <span>
                <ArrowRight className={style.leftArrow} />
                <span className={style.arrowLink}>{text}</span>
            </span>
        </a>
    );

    sectionTitleCount = (
        title,
        countTitle,
        items,
        onClick = null,
        expanded = null
    ) => (
        <div className={style.sectionTitleCount}>
            <span>{title}</span>
            <span
                className={
                    style.titleRight
                }>{`${items.length} ${countTitle}`}</span>
            {onClick && (
                <span
                    className={style.caret}
                    data-expanded={expanded}
                    onClick={onClick}
                />
            )}
        </div>
    );

    eventsSidebar = (dealer, events, myLocation) => {
        return (
            <Sidebar
                contentLabel={name || 'Harley-Davidson'}
                wide={true}
                {...this.props}
                shortText={false}>
                <span className={style.backNavigation}>
                    {this.backLink(this.toggleEvents, t('Back to Dealer'))}
                </span>
                <div style={{ paddingTop: '20px' }}>
                    {this.dealerDetails(dealer)}
                </div>
                <div>
                    {this.sectionTitleCount(
                        t('Dealer Events'),
                        t('Events'),
                        events
                    )}
                    {events.map((event) =>
                        this.eventDisplay(event, myLocation)
                    )}
                </div>
            </Sidebar>
        );
    };

    eventDisplay = (event, myLocation) => {
        const cn = classNames(style.item);
        const { isMobile } = this.context;
        return (
            <DisplayRow
                Item={Event}
                item={event}
                didSelectItem={routing_selectEvent}
                narrow={true}
                heightFromRows
                seeMoreLink={`${Routes.MAP_EVENTS}?pos=${centerZoomToString(
                    myLocation,
                    9
                )}&dealer=1`}
                onSeeMore={() => {
                    centerZoom(myLocation, 9);
                }}
                className={cn}
                dealerDetails={true}
                label={'events'}
                labelKey={'events'}
                key={event.eventId}
                icon={Bookmark}
                isMobile={isMobile}
                isDealer={true}
            />
        );
    };

    ridesSidebar = (dealer, rides, myLocation) => {
        return (
            <Sidebar
                contentLabel={name || 'Harley-Davidson'}
                title={`${t('Recommended Rides')}`}
                titleRight={
                    rides.length > 1
                        ? `${rides.length} ${t('Rides')}`
                        : `1 ${t('Ride')}`
                }
                shortText={false}
                wide={true}
                {...this.props}>
                <span className={style.backNavigation}>
                    {this.backLink(this.toggleRides, t('Back to Dealer'))}
                </span>
                <div style={{ paddingTop: '20px' }}>
                    {this.dealerDetails(dealer)}
                </div>
                {this.sectionTitleCount(
                    t('Recommended Rides'),
                    t('Rides'),
                    rides
                )}
                {rides.map((ride, idx) => (
                    <Ride
                        key={`ride-${idx}`}
                        Item={Ride}
                        item={ride}
                        large={true}
                        columns={this.getNumColumns(1, 2, 3)}
                        heightFromRows
                        seeMoreLink={`${
                            Routes.MAP_RIDES
                        }?pos=${centerZoomToString(myLocation, 9)}`}
                        onSeeMore={() => {
                            centerZoom(myLocation, 9);
                        }}
                        shortImage={true}
                        style={{ marginBottom: '24px', maxWidth: '400px' }}
                    />
                ))}
            </Sidebar>
        );
    };

    render() {
        const {
            isExpanded,
            dealer,
            programs,
            distance,
            events,
            rides,
            myLocation
        } = this.props;

        const { openingTimes } = dealer || {};
        const { isRidesOpen } = this.state;

        return (
            <div>
                {/* Main dealer info */}
                {this.dealerDetails(dealer, distance)}
                {/* Dealer Actions */}
                {this.dealerActions(dealer)}

                {/* Dealer Hours */}
                {this.regularHours(openingTimes)}
                {this.displayConnectors()}
                {/* Dealer Events */}
                {events && events.length > 0 && (
                    <Section
                        title={`${t('Dealer Events')}`}
                        titleRight={
                            events.length > 1
                                ? `${events.length} ${t('Events')}`
                                : `1 ${t('Event')}`
                        }
                        padTop={true}
                        wide={true}>
                        {this.eventDisplay(events[0], myLocation)}
                        {events.length > 1 && (
                            <div
                                onClick={this.toggleEvents}
                                style={{
                                    margin: '8px 0 0 0',
                                    padding: '0',
                                    float: 'right'
                                }}>
                                <ArrowLink
                                    text={`${t('See all')} ${events.length} ${t(
                                        'Events'
                                    )}`}
                                />
                            </div>
                        )}
                        {this.isEventsOpen() &&
                            this.eventsSidebar(dealer, events, myLocation)}
                    </Section>
                )}
                {/* Dealer Rides */}
                {rides && rides.length > 0 && (
                    <Section
                        title={`${t('Recommended Rides')}`}
                        titleRight={
                            rides.length > 1
                                ? `${rides.length} ${t('Rides')}`
                                : `1 ${t('Ride')}`
                        }
                        padTop={true}
                        wide={true}>
                        {rides.slice(0, 3).map((ride, idx) => (
                            <Ride
                                key={`ride-${idx}`}
                                Item={Ride}
                                item={ride}
                                columns={this.getNumColumns(1, 2, 3)}
                                heightFromRows
                                seeMoreLink={`${
                                    Routes.MAP_RIDES
                                }?pos=${centerZoomToString(myLocation, 9)}`}
                                onSeeMore={() => {
                                    centerZoom(myLocation, 9);
                                }}
                                large={true}
                                shortImage={true}
                                style={{
                                    marginBottom: '24px',
                                    maxWidth: '400px'
                                }}
                            />
                        ))}
                        {rides.length > 3 && (
                            <div
                                onClick={this.toggleRides}
                                style={{
                                    margin: '16px 0 8px 0',
                                    float: 'right'
                                }}>
                                <ArrowLink
                                    text={`${t('See all')} ${rides.length} ${t(
                                        'Rides'
                                    )}`}
                                />
                            </div>
                        )}
                        {isRidesOpen &&
                            this.ridesSidebar(dealer, rides, myLocation)}
                    </Section>
                )}

                {/* Dealer offerings */}
                {Object.entries(programs || {}).map(
                    ([programTitle, products]) => (
                        <Section
                            key={programTitle}
                            title={`${programTitle}s`}
                            icon={DealerDetails.iconForProgram(programTitle)}>
                            <div className={style.Table}>
                                {products.map(
                                    ({ code, description, title }) => (
                                        <div
                                            className={style.Row}
                                            key={code}
                                            title={description}>
                                            {title}
                                        </div>
                                    )
                                )}
                            </div>
                        </Section>
                    )
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    const { myLocation } = state.map;
    const { dealer } = ownProps;
    return {
        distance:
            myLocation && dealer
                ? crs.distance(myLocation, normalizeLatLng(dealer))
                : null,
        programs: selectProgramsByType(state, (dealer || {}).programCodes),
        events: state.events.events,
        rides: state.rides.rides,
        isMyDealer:
            dealer &&
            dealer.dealerId === (state.user.data || {}).preferredDealer,
        isAuthenticated: isAuthenticated(state),
        user: state.user
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    navigateToDealer: () => {
        const { dealer } = ownProps;
        try {
            dispatch(navigateToPoint(dealer));
        } catch {
            const errValue = handleRoutingError(e);
            dispatch(navigateToPoint(dealer));
            dispatch(setError(errValue));
        }
    },
    fetchEventBookmarks: () => dispatch(fetchEventBookmarks()),
    fetchManyEventsWithParams: (dealerId) =>
        dispatch(fetchManyEventsWithParams(dealerId)),
    fetchManyRidesWithParams: (params) =>
        dispatch(fetchManyRidesWithParams(params)),
    setMyDealer: (dealerId, isMyDealer) =>
        dispatch(setMyDealer(dealerId, isMyDealer)),
    auth: (cb) => dispatch(auth(cb))
});

DealerDetails.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(DealerDetails)
);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
