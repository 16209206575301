/******************************************************************************\
 * File: ConvertRideModal.jsx
 *
 * Author: Gigster
 *
 * Description: ConvertRide modal
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Modal from '@/components/common/Modal';
import { connect } from 'react-redux';
import { Routes } from '@/helpers/routes';
import withRouter from '@/helpers/hooks';

import { clickAway } from '@/helpers/hoc';
import ToggleIcon from '@/components/common/icons/Toggle';
import RouteType from '@/components/mapListView/RouteType';
import ArrowLink from '@/components/common/ArrowLink';
import { forkRide, editRide } from '@/store/edit_ride';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/homeView/UploadModal.scss';

import { translate } from '@/helpers/i18n';
const t = translate('modal.convertRideModal');
const te = translate('modal.uploadModal');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ConvertRideModal extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        useMaxWaypoints: false,
        rideCopy: null
    };

    onClickAway = () => this.props.onRequestClose();
    onClickRouteType = () => {
        const { rideCopy } = this.state;
        const { offRoad } = rideCopy;
        const newRide = { ...rideCopy, offRoad: !offRoad };
        this.setState({ rideCopy: newRide });
    };
    render() {
        const {
            onRequestClose,
            isOpen,
            currentRide: { waypoints },
            currentRide,
            copy,
            copyRecordedRide,
            navigate,
            ...rest
        } = this.props;
        const headerText = t('new planned ride');
        if (!this.state.rideCopy) this.setState({ rideCopy: currentRide });
        const { rideCopy } = this.state;
        console.log('rideCopy: ', rideCopy);
        return (
            <Modal
                {...rest}
                isOpen={isOpen}
                onAfterOpen={this.onAfterOpen}
                onRequestClose={onRequestClose}>
                <div className={style.back} onClick={onRequestClose}>
                    <ToggleIcon className={style.handle} />
                </div>
                <div className={style.setttingsContainer}>
                    <div
                        className={style.Title}
                        style={{
                            textTransform: 'uppercase',
                            fontSize: '52px'
                        }}>
                        {headerText}
                    </div>
                    <div>
                        <div
                            className={style.description}
                            style={{ paddingBottom: '16px' }}>
                            {t(
                                'Select the routing type for your new planned ride:'
                            )}
                        </div>
                        <div className={style.descriptionWithIcon}>
                            <span>
                                <img
                                    className={style.icon}
                                    src={'/img/icon-route-type-on-road.svg'}
                                />
                            </span>
                            <span className={style.text}>
                                {te(
                                    'On-road routing will generate a ride that adheres to known-roads.'
                                )}
                            </span>
                        </div>
                        <div className={style.descriptionWithIcon}>
                            <span>
                                <img
                                    className={style.icon}
                                    src={'/img/icon-route-type-off-road.svg'}
                                />
                            </span>
                            <span className={style.text}>
                                {te(
                                    'Off-road routing will generate a ride directly linking waypoints together, allowing for routing over any area of the map.'
                                )}
                            </span>
                        </div>
                        {currentRide && (
                            <RouteType
                                points={waypoints}
                                isOffRoadRoute={
                                    rideCopy
                                        ? rideCopy.offRoad
                                        : currentRide.offRoad
                                }
                                isHeader={false}
                                cb={this.onClickRouteType}
                                updateRoute={false}
                                rideCopy={rideCopy}
                            />
                        )}
                        <div className={style.link}>
                            <ArrowLink
                                onClick={() => {
                                    {
                                        rideCopy.subType === 'RECORDED'
                                            ? copyRecordedRide(rideCopy)
                                            : copy(rideCopy, navigate);
                                    }
                                    onRequestClose();
                                }}
                                text={t('Continue')}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {};
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    copy: (ride, navigate) => {
        if (ride.offRoad) {
            navigate(Routes.RIDE_CREATE);
            setTimeout(() => dispatch(forkRide(ride)), 500);
        } else {
            dispatch(forkRide(ride));
        }
    },
    copyRecordedRide: (ride) => dispatch(editRide(ride))
});
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(clickAway(ConvertRideModal))
);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------

export default container;
