/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <path
                    fillRule="nonzero"
                    d="M20 17c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8v-3zm0 3v-3c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8z"></path>
                <path
                    fillRule="nonzero"
                    d="M21.152 26.78c0-1.284-.338-1.712-1.22-1.712-.748 0-1.148.527-1.148 1.496 0 .857.426 1.304 1.148 1.304.389 0 .704-.185 1.22-.652v-.436zm-1.76 2.512c-1.48 0-2.392-1.112-2.392-2.668 0-1.953 1.013-3.124 2.932-3.124 1.898 0 3.004 1.166 3.004 3.1v2.904c0 1.934-1.106 3.1-3.004 3.1-1.598 0-2.655-.91-2.776-2.48l.1-.108h1.584l.1.096c.021.528.45.924.992.924.882 0 1.22-.428 1.22-1.712v-.61c-.548.374-1.147.578-1.76.578z"></path>
                <path
                    fillOpacity="0.4"
                    fillRule="nonzero"
                    d="M20 17c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8v-3zm0 3v-3c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8z"></path>
                <path d="M18.5 11h3v9H20a1.5 1.5 0 01-1.5-1.5V11zM20 36h1.5v9h-3v-7.5A1.5 1.5 0 0120 36z"></path>
                <path d="M13.5 12L20 3l6.5 9z"></path>
            </g>
        </svg>
    );
}

export default Icon;
