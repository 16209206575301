import { unique } from '@/helpers/functions';
import features from '@/helpers/features';

import { didReceiveLocation } from '@/store/map';
import { update as updateTutorial } from '@/store/tutorial';
import { setNearRides } from '@/store/home';
import { setSession, invalidateAuth } from '@/store/auth';
import { setAccountData, setProfile, setSortKey } from '@/store/user';
import { setDisabledCategories, setChargingStations } from '@/store/poi';

export const REHYDRATE = '@@persistor/REHYDRATE';
export const AUTH_SCHEMA_VERSION = '0.2.3';
//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
const log = createLogger('Persistor', false);

const hasData = (obj) => !!Object.keys(obj || {}).length;

const hydrationActions = {
    'auth.schemaVersion': () => () => {},
    'auth.session': (session) => (dispatch) => {
        hasData(session) && dispatch(setSession(session));
    },
    'poi.disabledCategories': (categories) => (dispatch) => {
        dispatch(setDisabledCategories(categories));
    },
    'poi.chargingStations': (categories) => (dispatch) => {
        dispatch(setChargingStations(categories));
    },
    'poi.cs': () => (dispatch) => {
        dispatch(persist('poi.cs', []));
    },
    userPrefs:
        (data = { distance: 'imperial', temperature: 'fahrenheit' }) =>
        (dispatch) => {
            dispatch(persist(data));
        },
    filteredTypes: (data) => (dispatch) => {
        dispatch(persist('filteredTypes', data || []));
    },
    'map.locationInfo': (value) => (dispatch) => {
        dispatch(didReceiveLocation(value));
    },
    'home.nearRides': (rides) => (dispatch) => {
        dispatch(setNearRides(rides));
    },
    'tutorial.create': (value) => (dispatch) => {
        dispatch(updateTutorial('create', value));
    },
    'user.profile': (profile) => (dispatch) => {
        hasData(profile) && dispatch(setProfile(profile, false));
    },
    'user.saved': (saved) => (dispatch) => {
        const sort = (saved || {}).sort || {};
        Object.keys(sort).forEach((key) => {
            dispatch(setSortKey(key, sort[key]));
        });
    },
    'user.data': (data) => (dispatch) => {
        hasData(data) && dispatch(setAccountData(data, false));
    },
    localeSetByUser: (source) => (dispatch) =>
        dispatch(persist('localeSetByUser', source)),
    '11111111001001111011110100': () => () => {},
    'ride.sidebar':
        (data = { value: 'open' }) =>
        (dispatch) => {
            dispatch(persist('ride.sidebar', data));
        }
};

// Do you want to turn on Sticky Keys?
// these hydration keys will stick around even after clear:
const STICKY_KEYS = [
    'auth.schemaVersion',
    // 'poi.disabledCategories',
    'poi.chargingStations',
    'poi.cs',
    'tutorial.create',
    'user.saved',
    'user.profile',
    'user.data',
    'auth.session',
    '11111111001001111011110100',
    'userPrefs',
    'localeSetByUser',
    'filteredTypes',
    'ride.sidebar'
];

export const persist = (key, value) => () => {
    log('Persisting', key, value);

    set(key, value);
    addKey(key);
};

export const clear = () => (dispatch) => {
    if (features.localStorage) {
        const stickyValues = STICKY_KEYS.map((key) => get(key));

        localStorage.clear();

        stickyValues.forEach(
            (value, i) => value && persist(STICKY_KEYS[i], value)(dispatch)
        );
    }
};

export const rehydrate = () => (dispatch) => {
    set('persist', STICKY_KEYS);
    const storedKeys = get('persist') || [];

    storedKeys.forEach(rehydrateItem(dispatch));

    dispatch({ type: REHYDRATE });
};

export const persistorEnhancer =
    (createStore) =>
    (...args) => {
        const store = createStore(...args);
        log('dispatch rehydrate');
        validateAuth(store);
        store.dispatch(rehydrate());
        return store;
    };

const validateAuth = (store) => {
    const authSchemaVersion = get('auth.schemaVersion');
    if (AUTH_SCHEMA_VERSION === authSchemaVersion) {
        return;
    }
    console.log(
        `stale auth schema ${authSchemaVersion}, latest is ${AUTH_SCHEMA_VERSION}`
    );
    store.dispatch(invalidateAuth());
    set('auth.schemaVersion', AUTH_SCHEMA_VERSION);
};

const rehydrateItem = (dispatch) => (key) => {
    log('Rehydrating', key);

    const value = get(key);
    const handler = hydrationActions[key];

    if (!handler) return console.error('Persistor', `No handler for ${key}`);

    dispatch(handler(value));
};

export const get = (key) => {
    if (!features.localStorage) return null;
    return JSON.parse(localStorage.getItem(key));
};

export const set = (key, value) => {
    if (features.localStorage) {
        localStorage.setItem(key, JSON.stringify(value || null));
    }
};

export const remove = (key) => {
    if (features.localStorage) {
        localStorage.removeItem(key);
    }
};

const addKey = (key) => {
    const keys = get('persist') || [];
    const nextKeys = keys ? unique([...keys, key]) : [key];
    set('persist', nextKeys);
};
