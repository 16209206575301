import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 6 24 18">
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <path
                    fill="#ff6600"
                    d="M21.774 5.133l-10 15.237a1.438 1.438 0 01-1.05.63h-.158a1.456 1.456 0 01-1.009-.41l-7.14-6.942a1.358 1.358 0 01-.013-1.945l.013-.012a1.46 1.46 0 012.015 0l5.884 5.731 9.05-13.779a1.457 1.457 0 011.974-.425c.657.402.857 1.25.448 1.895l-.014.02"></path>
            </g>
        </svg>
    );
}

export default Icon;
