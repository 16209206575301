import {
    replaceTags,
    stripParens,
    normalizeLatLng,
    flatten,
    mod
} from '@/helpers/functions';
import {
    placesAutosuggest,
    placesSearch,
    hereLookup,
    POI_CATEGORIES_TYPES
} from '@/helpers/here';
import { selectMapContext } from '@/store/map';
import { searchDealers } from '@/helpers/api';
import { WaypointCategory } from '@/helpers/constants';
import { resolveSearchValue } from '@/helpers/here';
import { didModifyRidePoint } from '@/store/edit_ride';

import { translate } from '@/helpers/i18n';
const t = translate('autocomplete.index');

/* */
// Types
export const STATE_CHANGE = 'autocomplete/STATE_CHANGE';
export const UPDATE_INPUT = 'autocomplete/UPDATE_INPUT';

/* */
// Helpers
const join = (a, b, delim = ', ') =>
    !!a && !!b ? `${a}${delim}${b}` : `${a || ''}${b || ''}`;

const toSearchPosition = (latlng) => {
    if (latlng) {
        const norm = normalizeLatLng(latlng);
        return norm.lat && norm.lng && [norm.lat, norm.lng];
    }
};

export const provideCustomPlaces = (state) =>
    [
        {
            id: 'current-location',
            title: t('My Current Location'),
            position: toSearchPosition(state.map.myLocation)
        },
        {
            ...state.user.dealer,
            title: t('My Dealer'),
            type: 'DEALER',
            position: toSearchPosition(state.user.dealer)
        },
        {
            ...state.user.home,
            id: 'home',
            title: t('Home'),
            position: toSearchPosition(state.user.home)
        }
    ]
        .filter((place) => place.position)
        .map((item) => ({
            ...item,
            highlightedTitle: item.title
        }));

const matchDealerKeyword = (text) => {
    const lowerText = text.toLowerCase();
    const keywords = ['harley', 'davidson', 'hd', 'h-d'];
    return keywords.some((word) => lowerText.includes(word));
};

const checkParams = (q, center = {}) =>
    !!center.lat && !!center.lng && q.length > 2;

function dealerProvider(state, value) {
    const { center } = state.map;

    const matchesKeyword = matchDealerKeyword(value);
    const extraData = matchesKeyword ? {} : { radiusInMiles: 100 };
    const lowerValue = value.toLowerCase();
    if (!checkParams(value, center)) return { position: {} };
    return searchDealers(
        value,
        { lat: center.lat, lon: center.lng, ...extraData },
        toSearchPosition(center)
    ).then((dealers) => {
        return matchesKeyword
            ? dealers
            : dealers.filter((dealer) =>
                  dealer.dealerName.toLowerCase().includes(lowerValue)
              );
    });
}

const getHighlightedTitle = (result) => {
    const { address, title, highlights } = result;
    const { label } = address || {};
    const [titleHighlights] = (highlights || {}).title;
    const [labelHighlights] = label || [];
    if (labelHighlights) {
        const { start, end } = labelHighlights;
        return `${label.slice(0, start)}<b>${label.slice(
            start,
            end
        )}</b>${label.slice(end)}`;
    } else if (titleHighlights) {
        const { start, end } = titleHighlights;
        return `${title.slice(0, start)}<b>${title.slice(
            start,
            end
        )}</b>${title.slice(end)}`;
    } else {
        return label || title;
    }
};

function hereProvider(state, value) {
    const { center, bounds, myLocation } = state.map;
    if (!checkParams(value, center)) return { position: {} };
    return placesAutosuggest(
        value,
        {
            result_types: 'address,place,chain,query',
            at: `${center.lat},${center.lng}`
        },
        selectMapContext({ map: { myLocation, center, bounds } })
    ).then((res) => {
        const addressCheck = value.includes(',');
        let addressMatch = false;
        let data = [];
        if (res.data.items.length > 0) {
            data = res.data.items
                .map((result) => {
                    const output = {};
                    output.value = result.title;
                    if (result.position) {
                        const { lat, lng } = result.position;
                        output.position = [lat, lng];
                    }

                    output.highlightedTitle = getHighlightedTitle(result);
                    return result.title.match(/Harley(\s|-)Davidson/i)
                        ? {
                              ...result,
                              ...output,
                              type: WaypointCategory.DEALER
                          }
                        : { ...result, ...output };
                })
                .filter((item) => !item.title.match(/Harley(\s|-)Davidson/i));
        }
        if (!!addressCheck && !addressMatch) {
            return placesSearch(value, { at: `${center.lat},${center.lng}` })
                .then((res) => {
                    return (res.data.items || []).map((r) => {
                        return {
                            ...r,
                            highlightedTitle: r.title
                        };
                    });
                })
                .then((places) => [...data, ...places].reverse());
        }
        return data;
    });
}

const onSuggestionsFetchRequested = (state, value) => {
    const customPlaces = provideCustomPlaces(state);

    const providers = [dealerProvider, hereProvider];

    const useCustomList =
        !value ||
        value.trim().length === 0 ||
        customPlaces.some((place) => place.title === value);

    return Promise.all(
        useCustomList
            ? [Promise.resolve(customPlaces)]
            : providers.map((provider) => provider(state, value))
    ).then((providerResults) => {
        const results = flatten(providerResults);
        return results
            .filter((result) => ((result || {}).position || {}).length)
            .map((e) => {
                const { address, title } = e;
                const { label } = address || {};
                return {
                    ...e,
                    name: label ? label : title,
                    title: label ? label : title,
                    value: label
                        ? label
                        : join(
                              stripParens(title),
                              replaceTags(e.vicinity, ' ')
                          ),
                    highlightedTitle: stripParens(e.highlightedTitle),
                    highlightedVicinity: e.highlightedVicinity
                };
            });
    });
};

export const fetchSuggestions = (value) => (dispatch, getState) => {
    return onSuggestionsFetchRequested(getState(), value);
};

/* */
// Selectors
export const getInputState = (state) => {
    const { focusId, isOpen, isHover, inputs } = state.autocomplete;
    return {
        isOpen,
        isHover,
        focusId,
        ...initialInputState,
        ...inputs[focusId]
    };
};

export const getSelectedItem = (id, state) => {
    const { inputs } = state.autocomplete;
    const { selectedIndex, items } = inputs[id] || {};
    return (items || [])[selectedIndex];
};

export const getCurrentSelectedItem = (state) => {
    const { focusId } = state.autocomplete;
    return getSelectedItem(focusId, state);
};

/* */
// Action Creators
export const onStateChange = (changes) => {
    return {
        type: STATE_CHANGE,
        data: { changes }
    };
};

const updateInput = (id, data = {}) => ({
    type: UPDATE_INPUT,
    data: { id, data }
});

export const setItems = (id, items) => updateInput(id, { items });

export const fetchItems = (id, value) => (dispatch, getState) => {
    onSuggestionsFetchRequested(getState(), value).then((results) =>
        dispatch(setItems(id, results))
    );
};
/* do nothing but its being called*/
export const setSelectIndex = () => (/*getState*/) => {
    //const { focusId } = getState().autocomplete;
    // return dispatch(updateInput(focusId, { selectedIndex: index })); // this was comment out for the very beggining
};

export const moveSelectedIndex =
    (by = 1) =>
    (dispatch, getState) => {
        const { focusId, inputs } = getState().autocomplete;
        const { items, selectedIndex } = inputs[focusId];

        const nextIndex = mod(selectedIndex + by + 1, items.length + 1) - 1;
        return dispatch(updateInput(focusId, { selectedIndex: nextIndex }));
    };

export const handleSubmit = (item, id) => (dispatch, getState) => {
    if (
        !!item.dealerId ||
        !!item.eventId ||
        ['home', 'current-location'].includes(item.id)
    ) {
        const title = item.dealerId ? item.dealerName : item.title;
        return dispatch(didModifyRidePoint(id, { ...item, title }));
    }

    const getType = (categories) => {
        const data = categories.find(
            ({ id }) => POI_CATEGORIES_TYPES[id.substr(0, 8)]
        );
        return data
            ? POI_CATEGORIES_TYPES[data.id.substr(0, 8)]
            : POI_CATEGORIES_TYPES['350-3500'];
    };

    const hereId = item.id;
    id = id || getState().autocomplete.focusId;

    return hereLookup({ id: hereId })
        .then((res) => {
            const { title, categories, address } = res.data;
            const type = categories ? getType(categories) : null;
            const label = title !== address.label ? title : null;
            dispatch(
                didModifyRidePoint(id, {
                    ...item,
                    title: label,
                    label,
                    type,
                    markerType: 'poi'
                })
            );
        })
        .catch(function (error) {
            console.log('error', error);
        });
};

export const handleSearch = (value, id) => (dispatch, getState) => {
    const state = getState();

    const item = getSelectedItem(id, state);
    //console.log('handleSearch', value, id, item);

    if (!value && !item) {
        return;
    }

    if (item) {
        dispatch(handleSubmit(item, id));
    } else {
        resolveSearchValue(value, state.map.myLocation)
            .then((result) => {
                if (!result.lat && !result.lng && !result.position) {
                    return;
                }
                return dispatch(handleSubmit(result, id));
            })
            .catch(() => {
                // NO-OP
            });
    }
};

/* */
// Reducer
const initialInputState = {
    items: [],
    selectedIndex: -1
};

const initialState = {
    focusId: null,
    isOpen: false,
    isHover: false,
    inputs: { [null]: { ...initialInputState } }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case STATE_CHANGE: {
            const { changes } = action.data;
            const { focusId } = changes;
            return {
                ...state,
                ...changes,
                inputs: {
                    ...state.inputs,
                    [focusId]: {
                        ...state.inputs[focusId],
                        selectedIndex: -1
                    }
                }
            };
        }

        case UPDATE_INPUT: {
            const { id, data } = action.data;
            return {
                ...state,
                inputs: {
                    ...state.inputs,
                    [id]: {
                        ...initialInputState,
                        ...state.inputs[id],
                        ...data
                    }
                }
            };
        }

        default: {
            return state;
        }
    }
};
