// Auto-generated via `yarn g store`
import { combineReducers } from 'redux';

import app from './app';
import auth from './auth';
import autocomplete from './autocomplete';
import badges from './badges';
import bookmarks from './bookmarks';
import challenges from './challenges';
import dealers from './dealers';
import edit_ride from './edit_ride';
import error from './error';
import eventBookmarks from './eventBookmarks';
import events from './events';
import home from './home';
import map from './map';
import poi from './poi';
import rides from './rides';
// import routing from './routing';
import search from './search';
import sections from './sections';
import tutorial from './tutorial';
import user from './user';

export default () =>
    combineReducers({
        app,
        auth,
        autocomplete,
        badges,
        bookmarks,
        challenges,
        dealers,
        edit_ride,
        error,
        eventBookmarks,
        events,
        home,
        map,
        poi,
        rides,
        search,
        sections,
        tutorial,
        user
    });
