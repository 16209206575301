/******************************************************************************\
 * File: ProfileView.jsx
 *
 * Author: Gigster
 *
 * Description: Profile information
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { Component } from 'react';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/myAccountView/ProfileView.scss';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { showProfileScreen, hideProfileScreen } from '@/helpers/gigya';
import { setAccountData, setProfile } from '@/store/user';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ProfileView extends Component {
    componentDidMount() {
        document.body.classList.add('profile');
        showProfileScreen(this.handleUpdateProfile);
    }

    componentWillUnmount() {
        document.body.classList.remove('profile');
        hideProfileScreen();
    }

    handleUpdateProfile = (resp) => {
        const { profile, data } = resp.response;
        if (profile) {
            this.props.didEditProfile(profile, data);
        }
    };

    render() {
        // gigya helpers render into this container to fake a fullscreen page
        return <div id="gigya_target" />;
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {};
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    didEditProfile: (profile, data) => {
        dispatch(setProfile(profile));
        data && dispatch(setAccountData(data));
    }
});
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(ProfileView);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
