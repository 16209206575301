/******************************************************************************\
 * File: ErrorBoundary.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// ErrorBoundary Function ------------------------------------------------------
const ErrorBoundary = ({ children }) => children || <div />;

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ErrorBoundary;
