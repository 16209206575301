/** Here maps maneuver action name to our icon name. */
import QuiteRight from '@/helpers/turn-icons/actions/quite-right';
import EnterhighwayLeft from '@/helpers/turn-icons/actions/enterhighway-left';
import EnterhighwayRight from '@/helpers/turn-icons/actions/enterhighway-right';
import Ferry from '@/helpers/turn-icons/actions/ferry';
import Finish from '@/helpers/turn-icons/actions/finish';
import HeavyLeft from '@/helpers/turn-icons/actions/heavy-left';
import HeavyRight from '@/helpers/turn-icons/actions/heavy-right';
import KeepLeft from '@/helpers/turn-icons/actions/keep-left';
import KeepMiddle from '@/helpers/turn-icons/actions/keep-middle';
import KeepRight from '@/helpers/turn-icons/actions/keep-right';
import LeavehighwayLeft from '@/helpers/turn-icons/actions/leavehighway-left';
import LeavehighwayRight from '@/helpers/turn-icons/actions/leavehighway-right';
import LightLeft from '@/helpers/turn-icons/actions/light-left';
import LightRight from '@/helpers/turn-icons/actions/light-right';
import QuiteLeft from '@/helpers/turn-icons/actions/quite-left';
import RoundaboutLeft1 from '@/helpers/turn-icons/actions/roundabout-left-1';
import RoundaboutLeft2 from '@/helpers/turn-icons/actions/roundabout-left-2';
import RoundaboutLeft3 from '@/helpers/turn-icons/actions/roundabout-left-3';
import RoundaboutLeft4 from '@/helpers/turn-icons/actions/roundabout-left-4';
import RoundaboutLeft5 from '@/helpers/turn-icons/actions/roundabout-left-5';
import RoundaboutLeft6 from '@/helpers/turn-icons/actions/roundabout-left-6';
import RoundaboutLeft7 from '@/helpers/turn-icons/actions/roundabout-left-7';
import RoundaboutLeft8 from '@/helpers/turn-icons/actions/roundabout-left-8';
import RoundaboutLeft9 from '@/helpers/turn-icons/actions/roundabout-left-9';
import RoundaboutLeft10 from '@/helpers/turn-icons/actions/roundabout-left-10';
import RoundaboutLeft11 from '@/helpers/turn-icons/actions/roundabout-left-11';
import RoundaboutLeft12 from '@/helpers/turn-icons/actions/roundabout-left-12';
import RoundaboutRight1 from '@/helpers/turn-icons/actions/roundabout-right-1';
import RoundaboutRight2 from '@/helpers/turn-icons/actions/roundabout-right-2';
import RoundaboutRight3 from '@/helpers/turn-icons/actions/roundabout-right-3';
import RoundaboutRight4 from '@/helpers/turn-icons/actions/roundabout-right-4';
import RoundaboutRight5 from '@/helpers/turn-icons/actions/roundabout-right-5';
import RoundaboutRight6 from '@/helpers/turn-icons/actions/roundabout-right-6';
import RoundaboutRight7 from '@/helpers/turn-icons/actions/roundabout-right-7';
import RoundaboutRight8 from '@/helpers/turn-icons/actions/roundabout-right-8';
import RoundaboutRight9 from '@/helpers/turn-icons/actions/roundabout-right-9';
import RoundaboutRight10 from '@/helpers/turn-icons/actions/roundabout-right-10';
import RoundaboutRight11 from '@/helpers/turn-icons/actions/roundabout-right-11';
import RoundaboutRight12 from '@/helpers/turn-icons/actions/roundabout-right-12';
import Start from '@/helpers/turn-icons/actions/start';
import Straight from '@/helpers/turn-icons/actions/straight';
import UTurnLeft from '@/helpers/turn-icons/actions/u-turn-left';
import UTurnRight from '@/helpers/turn-icons/actions/u-turn-right';
import Undefined from '@/helpers/turn-icons/actions/undefined';

export default {
    depart: Start,
    departAirport: Start,
    arrive: Finish,
    arriveAirport: Finish,
    arriveLeft: Finish,
    arriveRight: Finish,
    leftLoop: Undefined,
    rightLoop: Undefined,
    leftUTurn: UTurnLeft,
    rightUTurn: UTurnRight,
    sharpLeftTurn: HeavyLeft,
    sharpRightTurn: HeavyRight,
    leftTurn: QuiteLeft,
    rightTurn: QuiteRight,
    continue: Straight,
    slightLeftTurn: LightLeft,
    slightRightTurn: LightRight,
    ferry: Ferry,
    leftExit: LeavehighwayLeft,
    rightExit: LeavehighwayRight,
    leftRamp: EnterhighwayLeft,
    rightRamp: EnterhighwayRight,
    leftFork: KeepLeft,
    middleFork: KeepMiddle,
    rightFork: KeepRight,
    leftMerge: KeepLeft,
    rightKeep: KeepRight,
    leftKeep: KeepLeft,
    rightMerge: KeepRight,
    nameChange: Straight,
    leftRoundaboutEnter: RoundaboutLeft1,
    leftRoundaboutPass: RoundaboutLeft1,
    leftRoundaboutExit1: RoundaboutLeft1,
    leftRoundaboutExit2: RoundaboutLeft2,
    leftRoundaboutExit3: RoundaboutLeft3,
    leftRoundaboutExit4: RoundaboutLeft4,
    leftRoundaboutExit5: RoundaboutLeft5,
    leftRoundaboutExit6: RoundaboutLeft6,
    leftRoundaboutExit7: RoundaboutLeft7,
    leftRoundaboutExit8: RoundaboutLeft8,
    leftRoundaboutExit9: RoundaboutLeft9,
    leftRoundaboutExit10: RoundaboutLeft10,
    leftRoundaboutExit11: RoundaboutLeft11,
    leftRoundaboutExit12: RoundaboutLeft12,
    rightRoundaboutEnter: RoundaboutRight1,
    rightRoundaboutPass: RoundaboutRight1,
    rightRoundaboutExit1: RoundaboutRight1,
    rightRoundaboutExit2: RoundaboutRight2,
    rightRoundaboutExit3: RoundaboutRight3,
    rightRoundaboutExit4: RoundaboutRight4,
    rightRoundaboutExit5: RoundaboutRight5,
    rightRoundaboutExit6: RoundaboutRight6,
    rightRoundaboutExit7: RoundaboutRight7,
    rightRoundaboutExit8: RoundaboutRight8,
    rightRoundaboutExit9: RoundaboutRight9,
    rightRoundaboutExit10: RoundaboutRight10,
    rightRoundaboutExit11: RoundaboutRight11,
    rightRoundaboutExit12: RoundaboutRight12
};
