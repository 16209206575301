/******************************************************************************\
 * File: Speed.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Speed extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 16 16" onClick={onClick}>
                <g fill="#FFF" fillRule="nonzero">
                    <path d="M2.343 13c-3.124-2.974-3.124-7.796 0-10.77 3.124-2.973 8.19-2.973 11.314 0 3.124 2.974 3.124 7.796 0 10.77l-1.331-1.267c2.389-2.274 2.389-5.962 0-8.236-2.389-2.274-6.263-2.274-8.652 0-2.389 2.274-2.389 5.962 0 8.236L2.343 13zm0 0c-3.124-2.974-3.124-7.796 0-10.77 3.124-2.973 8.19-2.973 11.314 0 3.124 2.974 3.124 7.796 0 10.77l-1.331-1.267c2.389-2.274 2.389-5.962 0-8.236-2.389-2.274-6.263-2.274-8.652 0-2.389 2.274-2.389 5.962 0 8.236L2.343 13z" />
                    <path d="M11.385 4.136L9.664 8.903c-.317.877-1.339 1.35-2.282 1.055a1.852 1.852 0 0 1-.51-.25C6.076 9.15 5.917 8.1 6.516 7.36c.116-.143.257-.269.416-.371l4.452-2.853z" />
                </g>
            </svg>
        );
    }
}
export default Speed;
