/******************************************************************************\
 * File: constants.js
 *
 * Author: Gigster
 *
 * Description: Exported constants shared throughout the app
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { translate, proxyProp } from '@/helpers/i18n';
const t = translate('helpers.constants');

export { COMPANY_NAME } from '@/shared/constants';

export const getSiteName = () => t('Harley-Davidson Ride Planner');

export const DYNAMIC_ZOOM_DEFAULT_LEVEL = 16;
export const DYNAMIC_ZOOM_DEFAULT_RADIUS = 321869;

export const TRAFFIC_DIVIDER_LIGHT_MEDIUM = 4;
export const TRAFFIC_DIVIDER_MEDIUM_HEAVY = 7;

export const MapZoom = {
    MIN: 4,
    MAX: 20
};

export const APP_NAME = 'Throttle';
export const IOS_APP_URL =
    'https://itunes.apple.com/us/app/harley-davidson/id1292723595';
export const ANDROID_APP_URL =
    'https://play.google.com/store/apps/details?id=com.harley_davidson.ride_planner';

export const LOCALHOST = 'local.harley-davidson.com:8080';

// enum to represent the possible drawer states
export const DrawerState = {
    CLOSED: 0,
    OPEN: 1,
    EXPANDED: 2
};

// enum for backend poi types
export const WaypointType = {
    // basic waypoint, used to alter the route path
    WAYPOINT: 'WAYPOINT',
    // a place a rider plans to stop at
    LOCATION: 'LOCATION',
    // a point of interest a rider plans to stop at
    POI: 'POI'
};

export const WaypointCategory = {
    GAS_STATION: 'GAS_STATION',
    CHARGING_STATION: 'CHARGING_STATION',
    SCENIC: 'SCENIC',
    RESTAURANT: 'RESTAURANT',
    HOTEL: 'HOTEL',
    EVENT: 'EVENT',
    DEALER: 'DEALER',
    '700-7600': 'GAS_STATION',
    '100-1000': 'RESTAURANT',
    '500-5000': 'HOTEL',
    '350-3500': 'SCENIC',
    '300-3100': 'RESTAURANT' // TODO - SHOULD BE MUSEUM AWAITING ICON
};

export const ChargingStationCategory = {
    LEVEL_1: 'LEVEL_1',
    LEVEL_2: 'LEVEL_2',
    LEVEL_3: 'LEVEL_3',
    CHARGEPOINT: 'CHARGEPOINT',
    ELECTRIFY_AMERICA: 'ELECTRIFY_AMERICA',
    INNOGY: 'INNOGY',
    OTHER_NETWORK: 'OTHER_NETWORK'
};
/* Power feed type
An integer identifying the type of power feed included in the response.
Note: This information in only provided for charging stations in North America and Australia. If you use this query parameter in requests to the resource for search geometries that do not report this information, then the response does not contain any charging stations.
The available values are as follows: • 0 – Unspecified
• 1 – Level 1
• 2 – Level 2
• 3 – Level 3
• 4 – Other
*/

// https://developer.here.com/documentation/map-tile/topics/resource-base-basetile.html#resource-base-basetile__d12e197
const tName = proxyProp('name', t);
t('Normal');
t('Classic');
t('Satellite');
export const MapSchemes = {
    NORMAL: tName({
        name: 'Normal',
        type: 'normal',
        id: 'normal.traffic.day',
        tileType: 'base'
    }),
    CLASSIC: tName({
        name: 'Classic',
        type: 'classic',
        id: 'normal.day',
        tileType: 'base'
    }),
    SATELLITE: tName({
        name: 'Satellite',
        type: 'satellite',
        id: 'satellite.day',
        labelId: 'hybrid.day',
        trafficId: 'hybrid.day',
        tileType: 'aerial'
    })
};

export const MapViewTypes = {
    MAP: 'map',

    RIDE: 'ride',
    DEALER: 'dealer',
    EVENT: 'event',

    PREVIEW: 'preview',
    CREATE: 'create',
    EDIT: 'edit'
};

export const ScreenSizes = {
    MOBILE: 'mobile',
    TABLET: 'tablet',
    DESKTOP: 'desktop'
};

export const LocationPriority = {
    DEFAULT: 0,
    MY_LOCATION: 1,
    POS: 2
};

export const LocationSource = Object.keys(LocationPriority).reduce(
    (memo, key) => {
        memo[key] = { source: key, priority: LocationPriority[key] };
        return memo;
    },
    {}
);

export const Ridetype = {
    CUSTOM: 'CUSTOM',
    CURATED: 'CURATED'
};

export const RideSubtype = {
    RECORDED: 'RECORDED',
    PLANNED: 'PLANNED',
    DEALER: 'DEALER'
};

export const LOCAL_STORAGE_CACHE_KEY = 'last-edited-ride';
export const Extended = '11111111001001111011110100';

export const LOCATION_CHANGE = 'ON_LOCATION_CHANGE';
