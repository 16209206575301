/******************************************************************************\
 * File: routes.jsx
 *
 * Author: Gigster
 *
 * Description: Like react-router-dom Switch, but sets the key based on the
 * current path match so that transitions between components work as expected.
 *
 * Notes:
 \******************************************************************************/
import React, { cloneElement } from 'react';
import { matchPath, Route, Routes } from 'react-router-dom';
import withRouter from '@/helpers/hooks';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

class TransitionSwitch extends React.Component {
    render() {
        const {
            children,
            classNames,
            location,
            match: matchProps,
            transitionProps,
            ...props
        } = this.props;

        let match, child;

        React.Children.forEach(children, (element) => {
            if (!React.isValidElement(element)) return;

            const { path: pathProp, exact, strict, from } = element.props;
            const path = pathProp || from;

            if (!match) {
                child = element;
                match = path
                    ? matchPath({ path }, location.pathname)
                    : matchProps;
            }
        });

        return (
            <TransitionGroup>
                <CSSTransition
                    key={match.path || 'default'}
                    {...transitionProps}>
                    <Routes>{children}</Routes>
                </CSSTransition>
            </TransitionGroup>
        );
    }
}

export default withRouter(TransitionSwitch);
