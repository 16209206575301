export const DIRECTION_TOP = 'Top';
export const DIRECTION_LEFT = 'Left';
export const DIRECTION_BOTTOM = 'Bottom';
export const DIRECTION_RIGHT = 'Right';

const inverseDirection = {
    [DIRECTION_BOTTOM]: DIRECTION_TOP,
    [DIRECTION_TOP]: DIRECTION_BOTTOM,
    [DIRECTION_RIGHT]: DIRECTION_LEFT,
    [DIRECTION_LEFT]: DIRECTION_RIGHT
};

const drawQuadsState = {};
const drawQuads = function (map, quads, key) {
    const styleBase = {
        strokeColor: 'black',
        fillColor: 'rgba(0, 0, 255, 0.5)',
        lineWidth: 1,
        lineCap: 'square',
        lineJoin: 'bevel'
    };
    if (!this[key]) {
        const randomColor = Math.floor(Math.random() * 255);
        const randomColor2 = Math.floor(Math.random() * 255);
        this[key] = {
            color: `rgba(${randomColor2}, ${randomColor} , 255, 0.5)`,
            quadRects: null
        };
    }
    const storage = this[key];
    if (storage.quadRects) {
        storage.quadRects.forEach((qr) => {
            try {
                map.removeObject(qr);
            } catch (e) {
                //do nothing
            }
        });
        storage.quadRects = null;
    }

    const quadRects = quads.map((quad) => {
        const style = {
            ...styleBase,
            fillColor: quad.chosen ? 'rgba(255, 0, 0, 0.5)' : storage.color,
            lineWidth: 1 + quad.index * 3
        };
        return new H.map.Rect(quad.rect, { style });
    });
    quadRects.forEach((qr) => map.addObject(qr));
    storage.quadRects = quadRects;
}.bind(drawQuadsState);

const excludeDirections = (map, offset, dimensions) => {
    const { width: w, height: h } = map.getViewPort();
    const invalidDirections = {};

    if (offset.x - 30 < dimensions.w) {
        invalidDirections[DIRECTION_RIGHT] = 1;
        if (offset.x - 90 <= dimensions.w / 2) {
            invalidDirections[DIRECTION_TOP] = 1;
            invalidDirections[DIRECTION_BOTTOM] = 1;
        }
    }

    if (offset.x > dimensions.w && w - offset.x < dimensions.w) {
        invalidDirections[DIRECTION_LEFT] = 1;
        if (w - offset.x < dimensions.w / 2) {
            invalidDirections[DIRECTION_TOP] = 1;
            invalidDirections[DIRECTION_BOTTOM] = 1;
        }
    }

    if (offset.y - 80 < dimensions.h) {
        invalidDirections[DIRECTION_BOTTOM] = 1;
        if (offset.y - 80 < dimensions.h / 2) {
            invalidDirections[DIRECTION_RIGHT] = 1;
            invalidDirections[DIRECTION_LEFT] = 1;
        }
    }

    if (h > dimensions.h && h - offset.y < dimensions.h) {
        invalidDirections[DIRECTION_TOP] = 1;
        if (h - offset.y < dimensions.h / 2) {
            invalidDirections[DIRECTION_RIGHT] = 1;
            invalidDirections[DIRECTION_LEFT] = 1;
        }
    }
    return invalidDirections;
};

const getUniqueRoutePaths = (routes) => {
    if (!routes[0].points.length) {
        return;
    }

    const uniquePaths = [];
    const seenPoints = {};

    for (let i = 0; i < routes.length; i++) {
        const route = routes[i];
        uniquePaths.push([]);
        for (let j = 0; j < route.points.length; j++) {
            const point = route.points[j];
            const key = `${point.lat},${point.lng}`;
            if (seenPoints[key]) {
                continue;
            }
            seenPoints[key] = true;
            uniquePaths[i].push(point);
        }
    }

    return uniquePaths;
};

export const getToolTipMeta = (routes, map, toolTipDimensions) => {
    let uniquePaths = getUniqueRoutePaths(routes);

    return routes
        .map((route, i) => {
            const points = (uniquePaths && uniquePaths[i]) || route.points;
            const { summary } = route;
            if (!points.length) return null;
            const startIndex = Math.floor(points.length / 2);
            const position = points[startIndex];
            const { direction } = getToolTipDirection({
                map,
                points,
                dimensions: toolTipDimensions,
                start: points[startIndex]
            });

            const offset = getToolTipDirectionOffset(
                direction,
                toolTipDimensions
            );
            // console.log({
            //     i,
            //     diverging,
            //     optimalStartPoint,
            //     len: points.length,
            //     position,
            //     offset,
            //     direction: direction.toLowerCase(),
            //     summary
            // });
            return {
                position,
                offset,
                direction: direction.toLowerCase(),
                summary
            };
        })
        .filter((r) => r);
};

const getBoundingRects = (map, screenOffset, dimensions, tipLength) => {
    const rects = [];
    // top Rect
    {
        let bottomRight = [screenOffset.x + dimensions.w / 2, screenOffset.y];
        let topLeft = [
            screenOffset.x - dimensions.w / 2,
            screenOffset.y - dimensions.h
        ];
        bottomRight = map.screenToGeo(...bottomRight);
        topLeft = map.screenToGeo(...topLeft);

        const rect = H.geo.Rect.fromPoints(topLeft, bottomRight);
        rects.push({ rect, direction: DIRECTION_BOTTOM });
    }

    // bottom rect
    {
        let bottomRight = [
            screenOffset.x + dimensions.w / 2,
            screenOffset.y + dimensions.h
        ];
        let topLeft = [screenOffset.x - dimensions.w / 2, screenOffset.y];

        bottomRight = map.screenToGeo(...bottomRight);
        topLeft = map.screenToGeo(...topLeft);

        const rect = H.geo.Rect.fromPoints(topLeft, bottomRight);
        rects.push({ rect, direction: DIRECTION_TOP });
    }

    // left rect
    {
        let bottomRight = [
            screenOffset.x - tipLength,
            screenOffset.y + dimensions.h / 2
        ];
        let topLeft = [
            screenOffset.x - dimensions.w,
            screenOffset.y - dimensions.h / 2
        ];

        bottomRight = map.screenToGeo(...bottomRight);
        topLeft = map.screenToGeo(...topLeft);

        const rect = H.geo.Rect.fromPoints(topLeft, bottomRight);
        rects.push({ rect, direction: DIRECTION_RIGHT });
    }

    // right rect
    {
        let bottomRight = [
            screenOffset.x + dimensions.w,
            screenOffset.y + dimensions.h / 2
        ];
        let topLeft = [
            screenOffset.x + tipLength,
            screenOffset.y - dimensions.h / 2
        ];

        bottomRight = map.screenToGeo(...bottomRight);
        topLeft = map.screenToGeo(...topLeft);

        const rect = H.geo.Rect.fromPoints(topLeft, bottomRight);
        rects.push({ rect, direction: DIRECTION_LEFT });
    }

    return rects;
};

export const getToolTipDirection = ({
    map,
    points,
    dimensions,
    start,
    tipLength = 30,
    offset = { x: 0, y: 0 },
    debug = false
}) => {
    start = start || points[0];
    const screenOffset = map.geoToScreen(start);
    offset.x += screenOffset.x;
    offset.y += screenOffset.y;
    const invalidDirections = excludeDirections(map, offset, dimensions);
    const rects = getBoundingRects(map, screenOffset, dimensions, tipLength);
    const potentialDirections = rects
        .map((polygon) => ({
            direction: polygon.direction,
            overlaps: points.filter((point) =>
                polygon.rect.containsLatLng(point.lat, point.lng)
            ).length
        }))
        .sort((a, b) => a.overlaps - b.overlaps)
        .map((e) => e.direction)
        .filter((d) => !invalidDirections[d]);

    if (debug) {
        const quadRects = rects.map((q, i) => {
            return {
                rect: q.rect,
                chosen: false,
                index: i
            };
        });
        drawQuads(map, quadRects, `${start.lat}${start.lng}`);
    }

    const direction =
        inverseDirection[
            potentialDirections[0] || Object.keys(invalidDirections)[0]
        ];
    return { direction };
};

const getToolTipDirectionOffset = (direction, dimensions, tipLength = 20) => {
    const offset = {};
    switch (direction) {
        case DIRECTION_BOTTOM:
            offset.x = -dimensions.w / 2;
            offset.y = dimensions.h / 2 + tipLength / 2;
            break;
        case DIRECTION_TOP:
            offset.x = -dimensions.w / 2;
            offset.y = -dimensions.h;
            break;
        case DIRECTION_LEFT:
            offset.x = -(dimensions.w / 2) - tipLength;
            offset.y = -(dimensions.h / 2);
            break;
        case DIRECTION_RIGHT:
            offset.x = -(dimensions.w / 2) + tipLength / 2;
            offset.y = -(dimensions.h / 2);
    }
    return offset;
};
