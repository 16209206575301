import history from '@/helpers/history';
import { UPDATE, BULK_UPDATE, LOAD, SAVE, DIRTY } from './ride';
import { CREATE, saveRide, clearRide } from '@/store/edit_ride';
import { types as WAYPOINT_TYPES } from './waypoints';
import { TOGGLE as OPTIONS_TOGGLE } from './options';
import { status } from '@/helpers/async';

export const SET_CONFIRM = 'edit_ride/meta/SET_CONFIRM';
export const SHOW_SAVE_MODAL = 'edit_ride/meta/SHOW_SAVE_MODAL';

let confirmPromise = null;
let confirmResolver = null;
export const setConfirm = (value, reason) => (dispatch, getState) => {
    if (value) {
        if (!confirmPromise)
            confirmPromise = new Promise((resolve) => {
                confirmResolver = resolve;
            });

        dispatch({
            type: SET_CONFIRM,
            value
        });

        return confirmPromise;
    } else {
        const { confirm } = getState().edit_ride.present.meta;
        confirmResolver &&
            confirmResolver(
                (reason === 'confirm'
                    ? dispatch(saveRide())
                    : Promise.resolve()
                ).then((result) => {
                    switch (confirm) {
                        case 'logout': {
                            break;
                        }

                        case 'back': {
                            if (result !== 'cancel' && reason !== 'cancel') {
                                dispatch(clearRide());
                                history.back();
                            }
                            break;
                        }
                    }

                    return reason;
                })
            );

        confirmResolver = null;
        confirmPromise = null;

        dispatch({
            type: SET_CONFIRM,
            value
        });
    }
};

export const setDirty = () => (dispatch) => {
    dispatch({ type: DIRTY, value: true });
};

let savePromise = null;
let saveResolver = null;
export const showSaveModal = (value, reason) => (dispatch) => {
    if (value) {
        if (!savePromise)
            savePromise = new Promise((resolve) => {
                saveResolver = resolve;
            });

        dispatch({
            type: SHOW_SAVE_MODAL,
            value
        });

        return savePromise;
    } else {
        if (reason === 'save') dispatch(saveRide());
        saveResolver && saveResolver(reason);
        saveResolver = null;
        savePromise = null;

        dispatch({
            type: SHOW_SAVE_MODAL,
            value
        });
    }
};

// data not stored on the backend
const initialState = {
    // Are there unsaved changes?
    dirty: false,
    // Are we currently saving
    saving: false,
    // Is this saved to the backend
    persisted: false,
    // Mode for confirm modal
    confirm: '', // logout, back
    // Show save modal
    saveModal: false
};

const isWaypointType = (type) => (WAYPOINT_TYPES.includes(type) ? type : !type);

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE:
        case UPDATE:
        case BULK_UPDATE:
        case OPTIONS_TOGGLE:
        case isWaypointType(action.type): {
            return { ...state, dirty: true, persisted: false };
        }

        case LOAD: {
            const rideExists = !!action.data.ride.id;
            return {
                ...initialState,
                persisted: rideExists,
                dirty: !rideExists
            };
        }

        case SAVE:
            return {
                ...state,
                saving: action.status === status.REQUEST,
                dirty: action.status !== status.SUCCESS,
                persisted: true
            };

        // undo causes issues when a ride is modified and saved
        // this is called to set previous rides to dirty when a
        // future ride has been saved and changes are undone
        case DIRTY:
            return {
                ...state,
                saving: false,
                dirty: true,
                persisted: false
            };

        case SET_CONFIRM: {
            return {
                ...state,
                confirm: action.value
            };
        }

        case SHOW_SAVE_MODAL: {
            return {
                ...state,
                saveModal: action.value
            };
        }

        default:
            return state;
    }
};
