/******************************************************************************\
* File: Info.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/sidebar/Info.scss';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Section from '@/components/mapListView/sidebar/Section';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Info extends React.Component {
    render() {
        const { header, shortText, children, className } = this.props;

        return (
            <Section
                title={header}
                header
                shortText={shortText}
                className={className}
            >
                <div className={style.Info}>{children}</div>
            </Section>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Info;
