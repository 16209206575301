/******************************************************************************\
* File: Line.jsx
*
* Author: Gigster
*
* Description: Abstracted Line component
*
* Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import HereLine from '@/components/common/map/here/Line';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// React Function -----------------------------------------------------------------
const Line = (props) => <HereLine {...props} />;

const mapStateToProps = (state) => {
    return {
        offRoad: state.edit_ride.present.ride.offRoad
    };
};

const container = connect(mapStateToProps)(Line);

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
