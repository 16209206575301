/******************************************************************************\
 * File: debug.js
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/
const NOOP = () => {};

export const createLogger = (name, enabled = true) => {
    const { hostname } = window.location;
    return enabled && hostname !== 'maps.harley-davidson.com'
        ? (...args) => console.log(`[${name}]`, ...args)
        : NOOP;
};
