/******************************************************************************\
* File: Tooltip.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: https://raw.githubusercontent.com/primer/primer-css/master/modules/primer-tooltips/lib/tooltips.scss
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/form/Tooltip.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Tooltip extends React.Component {
    static defaultProps = {
        transparent: true,
        noDelay: true
    };

    state = {
        fixedRect: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    };

    componentDidMount = () => {
        const { fixed } = this.props;

        if (fixed) {
            this.positionUpdater = setInterval(this.updatePosition, 500);
        }
    };

    componentWillUnmount = () => {
        if (this.positionUpdater !== undefined)
            clearInterval(this.positionUpdater);
    };

    updatePosition = () => {
        if (this.$fixed)
            this.setState({
                fixedRect: this.$fixed.getBoundingClientRect()
            });
    };

    render() {
        const {
            className,
            children,
            value,
            placement, // n, e, w, s, ne, nw, se, sw
            noDelay,
            multiline,
            wide,
            sticky,
            fixed,
            transparent,
            offsetright,
            leftAlign,
            highZ,
            noFlex,
            topPadding,
            ...props
        } = this.props;

        const { fixedRect } = this.state;
        const cn = classNames(style.Tooltip, {
            [className]: !!className,
            [style['noDelay']]: !!noDelay,
            [style['multiline']]: !!multiline,
            [style['wide']]: !!wide,
            [style[placement]]: !!placement,
            [style['sticky']]: !!sticky,
            [style['transparent']]: transparent,
            [style['offsetright']]: !!offsetright,
            [style['leftAlign']]: !!leftAlign,
            [style.noFlex]: noFlex,
            [style.topPadding]: !!topPadding,
            [style['highZ']]: !!highZ
        });

        return [
            <span
                key={0}
                {...props}
                className={value ? cn : className}
                aria-label={value}
                ref={(ref) => (this.$el = ref)}
                tabIndex={fixed ? '-1' : undefined}
                style={
                    fixed
                        ? {
                              position: 'fixed',
                              pointerEvents: 'none',
                              top: fixedRect.top,
                              left: fixedRect.left,
                              height: fixedRect.height,
                              width: fixedRect.width
                          }
                        : {}
                }>
                {fixed ? (
                    <div
                        style={{
                            position: 'fixed',
                            top: fixedRect.top,
                            left: fixedRect.left,
                            height: fixedRect.height,
                            width: fixedRect.width
                        }}
                    />
                ) : (
                    children
                )}
            </span>,
            fixed && (
                <div
                    key={1}
                    ref={(ref) => (this.$fixed = ref)}
                    onMouseEnter={() => {
                        this.$el.focus();
                    }}
                    onMouseLeave={() => {
                        this.$el.blur();
                    }}>
                    {fixed}
                </div>
            )
        ];
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Tooltip;
