/******************************************************************************\
 * File: RideMarker.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
import Bubble from '@/components/common/map/icons/Bubble';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RideMarker extends React.Component {
    render() {
        const {
            className,
            onClick,
            count,
            stacked,
            recomended,
            style: styleProp
        } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <div
                style={{
                    height: '40px',
                    width: '42px',
                    margin: '-40px 0 0 -19px',
                    ...styleProp
                }}
            >
                <svg
                    style={{ height: '100%', width: '100%' }}
                    className={cn}
                    onClick={onClick}
                    viewBox="0 0 30 43"
                >
                    {recomended ? (
                        <g fill="none" fillRule="evenodd">
                            <path d="M0 0h30v30H0z" opacity=".6" />
                            {stacked ? (
                                <path
                                    fill="#F60"
                                    d="M19.155 42.538l9.783-14.07c4.946-6.532 4.238-17.181-1.535-22.92A16.604 16.604 0 0 0 17 .8C22.762-1.146 29.39.484 33.522 5.39h-.005c4.825 5.716 4.605 15.685-.462 21.283L19.42 43l-.265-.462zM25.612 6.41h-.005c5.246 5.265 5.896 15.19 1.395 21.19L15 45 2.998 27.576c-4.501-6-3.851-15.9 1.395-21.165l.058-.059c5.86-5.826 15.334-5.8 21.161.059z"
                                />
                            ) : (
                                <path
                                    fill="#F60"
                                    d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
                                />
                            )}
                            <path
                                fill="#010101"
                                d="M10.055 24.823h9.13v-1.44h-9.13v1.44zm7.29 1.288s-1.178 1.078-2.725 1.467c-1.546-.39-2.724-1.467-2.724-1.467.351-.211.47-.483.509-.652h4.43c.04.169.158.44.51.652zm-5.46-4.303s1.328.07 2.735-.979c1.407 1.049 2.734.979 2.734.979-.43.438-.502.787-.51.938h-4.447c-.009-.151-.08-.5-.511-.938zm7.943.938H17.54c.013-.365.483-.539.483-.539v-.939c-2.555-.14-3.403-1.427-3.403-1.427s-.848 1.288-3.403 1.427v.939s.47.174.483.539H9.412v2.713h2.28c-.13.468-.78.587-.78.587 1.862 1.932 3.708 2.24 3.708 2.24s1.846-.308 3.708-2.24c0 0-.65-.119-.78-.587h2.28v-2.713z"
                            />
                            <path
                                fill="#000"
                                d="M19.734 20.792l1.9.02c.041.001.069-.04.053-.077-.349-.834-3.17-8.17 2.113-15.708.035-.05-.02-.113-.073-.082-1.097.644-6.008 4.222-4.049 15.8a.057.057 0 0 0 .056.047M7.075 20.809h2.248a.045.045 0 0 0 .046-.042c.058-.774 1.057-12.093 9.82-16.607.047-.024.025-.093-.026-.085-1.49.241-8.95 2.164-12.133 16.678a.046.046 0 0 0 .045.056M17.987 7.923c-.04.056-.079.113-.116.17l-.261.413c-.089.137-.163.282-.246.422a14.991 14.991 0 0 0-.843 1.76c-.034.082-.064.166-.096.248l-.297-.06.13-.302c.267-.605.578-1.19.922-1.754.088-.14.169-.284.264-.42l.28-.409c.025-.036.052-.07.078-.106l.185.038z"
                            />
                            <path
                                fill="#000"
                                d="M16.197 10.84l.197.04a17.247 17.247 0 0 0 .081-.211c.234-.586.52-1.18.847-1.765l.09-.158c.05-.088.1-.18.157-.267l.26-.413.075-.11-.081-.017-.06.08-.279.41a4.582 4.582 0 0 0-.173.272c-.03.048-.059.097-.09.145-.354.58-.662 1.168-.917 1.748-.037.082-.072.164-.107.246zm.258.153l-.395-.08.046-.11a14.864 14.864 0 0 1 1.12-2.153c.057-.092.114-.188.178-.278l.28-.408c.02-.03.041-.058.063-.087l.034-.046.289.058-.043.062a6.162 6.162 0 0 0-.115.169l-.26.412a4.85 4.85 0 0 0-.155.262l-.09.16c-.325.58-.608 1.17-.84 1.752-.02.048-.039.096-.057.145l-.04.103-.015.039zM21.295 4.638c-.434.267-.851.562-1.25.882l.068.014c.375-.322.77-.621 1.182-.896"
                            />
                            <path
                                fill="#000"
                                d="M20.126 5.587l-.194-.04.082-.066c.4-.32.822-.618 1.256-.885l.053.083c-.41.274-.807.574-1.178.892l-.019.016zM19.934 5.693a11.494 11.494 0 0 0-1.493 1.606l-.022.026-.16-.032.07-.084c.46-.56.973-1.07 1.527-1.533l.078.017z"
                            />
                            <path
                                fill="#000"
                                d="M18.35 7.261l.05.01.003-.003c.245-.323.515-.638.806-.943-.302.295-.584.6-.842.915l-.018.021zm.089.118l-.272-.054.124-.148c.447-.544.962-1.062 1.533-1.539l.018-.015.197.042-.072.065c-.56.499-1.06 1.037-1.487 1.599l-.041.05zM15.958 12.302l-.07.23c-.175.626-.32 1.261-.437 1.9a22.017 22.017 0 0 0-.327 2.9c-.013.321-.02.65-.011.962l-.572.012c.002-.346.022-.673.048-1.006.024-.333.062-.662.102-.992.085-.66.198-1.313.34-1.961.143-.648.314-1.288.516-1.919.021-.066.045-.13.066-.197l.345.071z"
                            />
                            <path
                                fill="#000"
                                d="M14.492 18.357v-.05c.002-.362.024-.7.048-1.01.026-.349.066-.699.102-.995.086-.665.201-1.326.342-1.966a21.678 21.678 0 0 1 .55-2.022c.01-.033.022-.065.033-.098l.013-.042.44.09-.043.143c-.014.046-.028.092-.04.139-.173.613-.32 1.25-.437 1.895a21.56 21.56 0 0 0-.327 2.893c-.016.397-.02.693-.011.958l.002.05-.672.015z"
                            />
                            <path
                                fill="#000"
                                d="M15.895 12.34l-.011.038a20.951 20.951 0 0 0-.48 2.046c-.115.62-.203 1.271-.265 1.934-.024.289-.05.629-.064.972-.015.375-.019.66-.012.915l-.473.011c.004-.34.025-.658.048-.952.026-.347.066-.695.102-.99a21.868 21.868 0 0 1 .853-3.87c.01-.034.022-.066.033-.098l.02-.058.249.052z"
                            />
                        </g>
                    ) : (
                        <g fill="none" fillRule="evenodd">
                            <path d="M0 0h30v30H0z" opacity=".6" />
                            <path
                                fill="#F60"
                                d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
                            />
                            <path
                                fill="#000"
                                d="M21.296 4.638c-.412.275-.808.575-1.183.896l-.068-.014c.398-.32.816-.614 1.25-.882"
                            />
                            <path
                                fill="#000"
                                d="M20.126 5.587l-.194-.04.082-.065c.397-.319.82-.617 1.256-.886l.053.083c-.405.27-.801.57-1.179.892l-.018.016zM19.734 20.794l1.9.02c.041.001.069-.04.053-.077-.349-.834-3.17-8.17 2.113-15.71.035-.05-.02-.112-.073-.081-1.097.644-6.008 4.222-4.049 15.8a.057.057 0 0 0 .056.048M7.075 20.81h2.248a.045.045 0 0 0 .046-.041c.058-.774 1.057-12.094 9.82-16.608.047-.024.025-.094-.026-.086-1.49.242-8.95 2.165-12.133 16.68a.046.046 0 0 0 .045.056M17.987 7.923c-.04.057-.079.113-.116.17l-.261.414c-.089.136-.163.282-.246.423a14.98 14.98 0 0 0-.843 1.758c-.034.083-.064.167-.096.25l-.297-.06.13-.302c.267-.606.578-1.192.922-1.755.088-.14.169-.285.264-.42l.28-.409c.025-.036.052-.07.078-.106l.185.037z"
                            />
                            <path
                                fill="#000"
                                d="M16.197 10.841l.197.04.02-.054.061-.157c.234-.584.518-1.178.847-1.764l.087-.154c.05-.09.102-.183.16-.272l.26-.413.075-.11-.081-.017-.06.08-.279.41a4.583 4.583 0 0 0-.173.271c-.03.049-.059.098-.09.146a14.704 14.704 0 0 0-1.012 1.965l-.012.03zm.258.154l-.396-.082.06-.14c.031-.072.062-.145.095-.217a14.814 14.814 0 0 1 1.013-1.906c.056-.092.113-.187.177-.277l.28-.409c.02-.03.041-.058.063-.086l.034-.047.288.059-.064.093c-.032.046-.063.091-.093.137l-.26.413a4.779 4.779 0 0 0-.158.267 14.983 14.983 0 0 0-.928 1.906l-.06.156-.05.133zM19.934 5.693A11.55 11.55 0 0 0 18.441 7.3l-.022.028-.16-.034.07-.083c.46-.56.973-1.071 1.527-1.534l.078.017z"
                            />
                            <path
                                fill="#000"
                                d="M18.35 7.262l.05.01.002-.003c.247-.324.516-.639.806-.943-.3.294-.582.6-.841.915l-.018.02zm.09.12l-.273-.057.124-.147c.45-.546.965-1.064 1.533-1.54l.018-.015.197.042-.072.065a11.524 11.524 0 0 0-1.487 1.6l-.04.051zM15.958 12.304l-.07.23c-.175.626-.32 1.26-.437 1.9a21.47 21.47 0 0 0-.328 2.9c-.012.322-.02.651-.01.961l-.572.013c.002-.346.022-.673.048-1.007.024-.331.062-.662.102-.992.085-.658.198-1.312.34-1.96.143-.647.314-1.288.516-1.92.021-.065.045-.13.066-.196l.345.071z"
                            />
                            <path
                                fill="#000"
                                d="M15.646 12.29l-.02.057c-.01.033-.022.065-.033.098-.199.625-.372 1.27-.514 1.915a21.159 21.159 0 0 0-.441 2.945c-.023.295-.044.614-.048.953l.472-.01a13.8 13.8 0 0 1 .013-.916c.013-.34.04-.683.065-.973a21.185 21.185 0 0 1 .743-3.977l.012-.04-.25-.052zm-1.154 6.068v-.05c.002-.36.024-.699.048-1.01.026-.35.066-.699.102-.995.087-.667.202-1.327.342-1.965.142-.648.316-1.295.516-1.924l.033-.099.034-.097.013-.042.44.09-.015.052a9.269 9.269 0 0 1-.069.229c-.172.615-.32 1.253-.436 1.896a22.11 22.11 0 0 0-.263 1.925c-.024.288-.051.63-.064.968-.016.398-.02.693-.011.958l.001.05-.671.014z"
                            />
                            <path
                                fill="#010101"
                                d="M18.48 26.221c.299-.61.462-1.305.448-2.04-.042-2.391-1.98-4.314-4.288-4.26-1.703.04-3.01.978-3.508 2.136 0 0-.411-.12-.515.19a4.119 4.119 0 0 0-.135.49c-.062.357.215.403.288.409l1.043.085c.641.065 1.39.268 1.39 1.184 0 .763-.659 1.055-1.39 1.195l-1.27.289c-.225.03-.432.175-.383.468.01.064.08.3.1.377.086.324.485.226.485.226l.032.176m0 0c-.077.072-.173.724-.133.913l-.013-.07c.08.244.37.229.516.219 2.025-.14 6.114-.91 7.223-1.366.395-.16.288-.145.208-.538-.017-.083-.097-.083-.097-.083"
                            />
                        </g>
                    )}
                </svg>
                {count > 1 && <Bubble large count={count} color="#000" />}
            </div>
        );
    }
}
export default RideMarker;
