/******************************************************************************\
 * File: store.js
 *
 * Author: Gigster
 *
 * Description: Constructs and exports a redux store
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import MCP, { mcpMiddleware } from '@/helpers/MCP';
//------------------------------------------------------------------------------
// Reducer ---------------------------------------------------------------------
import reducer from '@/store/reducer';
//------------------------------------------------------------------------------
// History ---------------------------------------------------------------------
import history from '@/helpers/history';
//------------------------------------------------------------------------------
// Persistor -------------------------------------------------------------------
import { persistorEnhancer } from '@/helpers/persistor';
import queryMiddleware from '@/helpers/url';
//------------------------------------------------------------------------------
// Enhancers -------------------------------------------------------------------
const NoopMiddleware = (store) => (next) => (action) => next(action);

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
const enhancers = composeEnhancers(
    persistorEnhancer,
    applyMiddleware(
        thunk,
        process.env.NODE_ENV === 'test' ? NoopMiddleware : queryMiddleware,
        mcpMiddleware(MCP)
    )
);
//------------------------------------------------------------------------------
// Store -----------------------------------------------------------------------
export default createStore(reducer(history), enhancers);
//------------------------------------------------------------------------------
// Reset Action ----------------------------------------------------------------
export const reset = () => ({ type: 'RESET_STATE' });
