/******************************************************************************\
 * File: ChallengeModal.jsx
 *
 * Author: Gigster
 *
 * Description: Home page modal
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
import Helmet from 'react-helmet';
import Close from '@/components/common/icons/Close';
import Link from '@/components/common/header/Link';
import CoinView from './CoinView';
import { getBadgeFrameUrls } from '@/helpers/api';
import { universalLinkScroll } from '@/helpers/mobile';
import { appStoreLinks } from '@/shared/constants';
import {
    formattedDateRangeOrDaysLeft,
    displayCountWithString
} from '@/helpers/functions';
import style from '@/style/homeView/HomeModal.scss';
import { translate } from '@/helpers/i18n';

const t = translate('homeView.HomeModal');

const ChallengeModal = ({
    className,
    selected,
    isMobile,
    onRequestClose,
    ...props
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const [frameUrls, setFrameUrls] = useState(null);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const selectedType = getType(selected);
        if (selectedType) {
            fetchFrames(selectedType);
        }
    }, [selected]);

    const getType = (selected) => {
        if (
            !selected ||
            !selected.availableBadges ||
            !selected.availableBadges[0]
        ) {
            return null;
        }
        return selected.availableBadges[0].type;
    };

    const fetchFrames = (type) => {
        getBadgeFrameUrls(type, { size: 376 })
            .then((urls) => {
                setFrameUrls(urls);
            })
            .catch((error) => {
                console.error('Error fetching frames:', error);
            });
    };

    useEffect(() => {
        const { href } = window.location;
        const appleLink = `app-id=1292723595, app-argument=${href}`;

        if (isMobile && !scrolled) {
            universalLinkScroll();
            setScrolled(true);
        }
    }, [isMobile, scrolled]);

    const cnOverlay = classNames(style.overlay, {
        [style.large]: !!props.large
    });

    const cn = classNames(style.content, {
        [className]: !!className
    });

    const getImageUrl = (selected) => {
        return (
            selected?.imageUrl || selected?.availableBadges[0]?.imageUrl || null
        );
    };

    const imageUrl = selected ? getImageUrl(selected) : null;

    return (
        <ReactModal
            isOpen={modalIsOpen}
            overlayClassName={cnOverlay}
            className={cn}
            shouldCloseOnOverlayClick={true}
            closeTimeoutMS={0}
            isMobile={isMobile}
            onRequestClose={onRequestClose}
            {...props}>
            <Helmet>
                <meta name="apple-itunes-app" content="app-id=1292723595" />
            </Helmet>

            <div className={style.ModalContainer}>
                <div className={style.header}>
                    <span className={style.headerImage}>
                        <img src="/img/hd-logo.png" alt="HD Logo" />
                    </span>
                    <Link className={style.close} to="/">
                        <Close onClick={onRequestClose} />
                    </Link>
                </div>
                <div className={style.content}>
                    <div className={style.HomeModalImageModalBg}>
                        <img
                            src={
                                selected.detailImageUrl ||
                                '/img/image-modal-bg@2x.jpg'
                            }
                            alt="Modal Background"
                        />
                    </div>
                    <div className={style.details}>
                        {imageUrl && (
                            <div
                                className={style.section}
                                style={{ paddingTop: '32px' }}>
                                <CoinView
                                    className={style.ChallengeCoin}
                                    imageUrl={imageUrl}
                                    frameUrls={
                                        frameUrls &&
                                        process.env.NODE_ENV === 'development'
                                            ? frameUrls.map(
                                                  (url) =>
                                                      `http://localhost:8081/${url}`
                                              )
                                            : frameUrls
                                    }
                                />
                                {selected && selected.title && (
                                    <div>
                                        <div className={style.SectionTitle}>
                                            {selected.title}
                                        </div>
                                        <div className={style.SectionIcons}>
                                            <span className={style.iconClock}>
                                                <img
                                                    src="/img/icon-clock.svg"
                                                    alt="Clock Icon"
                                                />
                                                <span className={style.winner}>
                                                    {formattedDateRangeOrDaysLeft(
                                                        selected.startDate,
                                                        selected.endDate
                                                    ).toUpperCase()}
                                                </span>
                                            </span>
                                            <span className={style.iconHelmet}>
                                                <img
                                                    src="/img/icon-helmet-dark.svg"
                                                    alt="Helmet Icon"
                                                    className={style.iconHelmet}
                                                />
                                                <span className={style.winner}>
                                                    {displayCountWithString(
                                                        selected.totalChallengers,
                                                        'Participant',
                                                        'Participants'
                                                    )}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div className={style.hr} />
                            </div>
                        )}
                        <div className={style.section}>
                            <div
                                className={style.SectionHeaderDescription}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        selected?.overviewText?.replaceAll(
                                            '\n\n',
                                            '<br /><br />'
                                        ) || ''
                                }}></div>
                            <div className={style.SectionHeaderSmall}>
                                {t('How it works')}
                            </div>
                            <div
                                className={style.SectionHeaderDescription}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        selected?.detailsText?.replaceAll(
                                            '\n\n',
                                            '<br /><br />'
                                        ) || ''
                                }}></div>
                            <div className={style.hr} />
                        </div>
                        {!!props.items &&
                            props.items.map((item, i) => (
                                <div className={style.section} key={item.id}>
                                    <div className={style.SectionTitle}>
                                        {item.title}
                                    </div>
                                    <div className={style.SectionDescription}>
                                        <span className={style.iconClock}>
                                            <img
                                                src="/img/icon-clock.svg"
                                                alt="Clock Icon"
                                            />
                                        </span>
                                        {formattedDateRangeOrDaysLeft(
                                            item.startDate,
                                            item.endDate
                                        ).toUpperCase()}
                                    </div>
                                    <div className={style.hr} />
                                </div>
                            ))}
                    </div>
                </div>
                <div className={style.footer}>
                    <div className={style.footerText}>
                        {t('GET THE HARLEY-DAVIDSON™ APP')}
                    </div>
                    <div className={style.footerLinks}>
                        {appStoreLinks.map((item, i) => (
                            <a href={item.link} key={i}>
                                <img src={item.img} alt="App Store Link" />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default ChallengeModal;
