import { clamp } from '@/helpers/functions';
import { DID_CALCULATE_ROUTE } from './ride';

/* */
// Types
export const CHANGE_ROUTE = 'edit_ride/routes/CHANGE_ROUTE';

/* */
// Selectors
export const alternativeRoutes = (state) => {
    const { data, index } = state.edit_ride.present.routes;
    // ensure that every route has an id corresponding to the index
    return data
        .map((route, i) => (i !== index ? { ...route, id: i } : null))
        .filter((e) => e);
};

/* */
// Action Creators
export const changeRoute = (index) => (dispatch, getState) => {
    const { data, index: currentIndex } = getState().edit_ride.present.routes;

    if (index === currentIndex) return;

    const ride = data[clamp(index, 0, data.length)];

    dispatch({
        type: CHANGE_ROUTE,
        data: { index, ride }
    });
};

/* */
// Reducer
export const initialState = {
    data: [],
    index: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case DID_CALCULATE_ROUTE:
            return { ...state, data: action.data.routes };

        case CHANGE_ROUTE:
            //eslint-disable-next-line
            const { index } = action.data;
            return { ...state, index };

        default:
            return state;
    }
};
