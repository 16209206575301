/******************************************************************************\
 * File: hd.js
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import {
    get as getLocalStore,
    set as setLocalStore
} from '@/helpers/persistor';

const sessionDetails = (locationHistory, session) => {
    const start = locationHistory[0];
    const end = locationHistory.slice(-1)[0];
    return { start: start, end: end, ...session };
};

const bikeYearModel = (session) => ({
    year: session.bikeYear,
    yearModel: `${session.bikeYear}-${session.bikeModel}`,
    model: session.bikeModel
});

export const processSessionData = (rides) => {
    const data = rides.reduce(
        (output, ride) => {
            if ((ride.session || {}).bikeYear) {
                output.sessionDetails.push(
                    sessionDetails(ride.locationHistory, ride.session)
                );
                output.bikeYearModels.push(bikeYearModel(ride.session));
            }
            return output;
        },
        { sessionDetails: [], bikeYearModels: [] }
    );
    setLocalStore('sessionDetails', data.sessionDetails);
    return data;
};

export const fetchAndStoreHDModelInfo = (years) => {
    let processedYears = [];
    // Annoying Array.flat issue with older versions of MS Edge
    return years.forEach((year) => {
        if (processedYears.includes(year)) return;
        processedYears.push(year);
    });
};

export const updateSessionHDModels = (
    rides,
    useGigyaData = false,
    updateHdModels = false
) => {
    const userData = getLocalStore('user.data');
    if (!rides || !userData) return;
    if (useGigyaData) {
        const years = (userData.bikesOwned || []).map((bike) => bike.year);
        return fetchAndStoreHDModelInfo(years);
    }
    if (updateHdModels) {
        const { bikeYearModels } = processSessionData(rides);
        const years = bikeYearModels.map((bikeYearModel) => bikeYearModel.year);
        return fetchAndStoreHDModelInfo(years);
    }
};
