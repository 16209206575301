import React from 'react';

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="29"
        viewBox="0 0 15 29"
    >
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M9.73 29V15.77h4.45l.665-5.156H9.73V7.32c0-1.49.416-2.512 2.549-2.512H15V.203A36.312 36.312 0 0 0 11.054 0c-3.924 0-6.61 2.401-6.61 6.81v3.798H0v5.156h4.443V29H9.73z"
        />
    </svg>
);
