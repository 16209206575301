import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';

/* */
// Types
export const SET_MOBILE_MENU = 'app/SET_MOBILE_MENU';
export const SET_IS_CREATE_MODAL_SHOWING = 'app/SET_IS_CREATE_MODAL_SHOWING';

/* */
// Action Creators

export const showMobileMenu = (open = true) => ({
    type: SET_MOBILE_MENU,
    data: !!open
});

export const toggleMobileMenu = () => (dispatch, getState) => {
    const { mobileMenu } = getState().app;
    dispatch(showMobileMenu(!mobileMenu));
};

export const isCreateModalShowing = (value) => ({
    type: SET_IS_CREATE_MODAL_SHOWING,
    data: value
});
/* */
// Reducer
const initialState = {
    mobileMenu: false,
    isCreateModalShowing: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_MOBILE_MENU: {
            return { ...state, mobileMenu: action.data };
        }
        case SET_IS_CREATE_MODAL_SHOWING: {
            return { ...state, isCreateModalShowing: action.data };
        }
        case AUTH_INVALIDATE:
        default:
            return state;
    }
};
