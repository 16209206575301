/******************************************************************************\
 * File: Map.jsx
 *
 * Author: Gigster
 *
 * Description: Abstracted Map component
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import HereMap from '@/components/common/map/here/Map';
import ErrorBoundary from '@/components/common/ErrorBoundary';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Map extends React.Component {
    render() {
        const { ...props } = this.props;
        return (
            <ErrorBoundary>
                <HereMap {...props} />
            </ErrorBoundary>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Map;
