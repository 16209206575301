/******************************************************************************\
 * File: hdOffices.js
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------

const regionOfficesWLang = {
    'United States – English//en-US': 'USA',
    'Andorra – Español//es-ES': 'EspaÑa',
    'Australia/New Zealand – English//en-AU': 'Australia',
    'Brasil – Português//pt-BR': 'BR',
    'Belgique – Français//fr-FR': 'BENELUX',
    'België – Nederlands//nl-NL': 'BENELUX',
    'Bulgaria - English//en-GB': 'INTERNATIONAL',
    'Canada – English//en-GB': 'Canada',
    'Canada – Français//fr-CA': 'Canada',
    'Česká Republika – Česky//cs-CZ': 'ČESKÁ REPUBLIKA/SLOVENSKO',
    'Croatia – English//en-GB': 'INTERNATIONAL',
    'Cyprus – English//en-GB': 'Greece/Cyprus',
    'Danmark – Dansk//da-DK': 'DANMARK',
    'Deutschland – Deutsch//de-DE': 'Deutschland',
    'España – Español//es-ES': 'EspaÑa',
    'Estonia – English//en-GB': 'INTERNATIONAL',
    'France – Français//fr-FR': 'France',
    'Greece – English//en-GB': 'Greece/Cyprus',
    'Hong Kong – Chinese (Traditional)': 'HK',
    'Italia – Italiano//it-IT': 'Italia',
    'Ireland – English//en-GB': 'IRELAND',
    'Japan – Japanese//JP': 'JP',
    'Latvija – English//en-GB': 'INTERNATIONAL',
    'Lietuva – English//en-GB': 'INTERNATIONAL',
    'Luxembourg – Français//fr-FR': 'LUXEMBOURG',
    'Malta – English//en-GB': 'INTERNATIONAL',
    'Magyarország – Magyar//hu-HU': 'HUNGARY',
    'México – Español//ES': 'ES',
    'Nederland – Nederlands//nl-NL': 'NEDERLAND',
    'Norge – Norske//no-NO': 'NORGE',
    'Österreich – Deutsch//de-DE': 'Österrich',
    'Polska – Polski//pl-PL': 'POLSKA',
    'Portugal – Português//pt-PT': 'Portugal',
    'Puerto Rico – English//en-US': 'USA',
    'Qatar – English//ar-SA': 'AR',
    'România – English//en-GB': 'INTERNATIONAL',
    'Russia – Русский//RU': 'RU',
    'Saudi Arabia – English//ar-SA': 'AR',
    'Schweiz – Deutsch//de-DE': 'SCHWEIZ',
    'Slovenija – English//en-GB': 'INTERNATIONAL',
    'Slovensko – Česky//cs-CZ': 'ČESKÁ REPUBLIKA/SLOVENSKO',
    'Suisse – Français//fr-FR': 'SUISSE',
    'Svizzera – Italiano//it-IT': 'SVIZZERA',
    'Suomi - Suomeksi//fi-FI': 'SUOMI',
    'Sverige - Svensk//sv-SE': 'SVERIGE',
    'Taiwan – Chinese//ZH': 'ZH',
    'Thailand - Thai//TH': 'TH',
    'United Arab Emirates – English//ar-SA': 'AR',
    'United Kingdom – English//en-GB': 'UK',
    'Vietnam – Vietnamese//VI': 'Vi',
    'Virgin Islands (USA) – English//en-US': 'USA'
};

const regionalOffices = (locale) => {
    const countryLang = locale.split('//')[0];
    const key = Object.keys(regionOfficesWLang).find((key) =>
        key.includes(countryLang)
    );
    return regionOfficesWLang[key];
};

export const getRegionalOffice = (locale) => {
    return regionOfficesWLang[locale] || regionalOffices(locale);
};
