import React from 'react';

export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="33"
        height="26"
        viewBox="0 0 33 26"
    >
        <path
            fill="#FFF"
            fillRule="evenodd"
            d="M33 3.081c-1.237.531-2.547.88-3.887 1.034A6.623 6.623 0 0 0 32.089.48a13.832 13.832 0 0 1-4.303 1.593A6.861 6.861 0 0 0 22.849 0c-3.71-.029-6.742 2.91-6.771 6.565 0 .503.06 1.005.178 1.495-5.425-.253-10.492-2.742-13.96-6.857a6.376 6.376 0 0 0-.917 3.302A6.51 6.51 0 0 0 4.39 9.97a6.909 6.909 0 0 1-3.069-.825v.084c.021 3.146 2.295 5.84 5.432 6.434a6.98 6.98 0 0 1-3.056.11c.911 2.687 3.447 4.513 6.323 4.55a13.85 13.85 0 0 1-8.402 2.815c-.54.001-1.08-.03-1.617-.09A19.612 19.612 0 0 0 10.375 26C22.83 26 29.641 15.997 29.641 7.326v-.852A13.542 13.542 0 0 0 33 3.081"
        />
    </svg>
);
