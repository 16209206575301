/******************************************************************************\
 * File: GPXModal.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import ConfirmModal from '@/components/common/ConfirmModal';
import { Title, Subtitle, Button } from '@/components/common/Modal';
import { gpxDownloadUrl } from '@/helpers/api';
import { saveRide } from '@/store/edit_ride';
import { showSaveModal } from '@/store/edit_ride';
import { translate } from '@/helpers/i18n';
const t = translate('directions.GPXModal');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class GPXModal extends React.Component {
    rideSaving = () => ((this.props.ride || {}).meta || {}).saving;

    downloadRideGPX = (rideId) => {
        const href = gpxDownloadUrl(rideId);
        const link = Object.assign(document.createElement('a'), { href });
        link.click();
    };

    handleConfirmUnsaved = (saveChanges) => () => {
        const { showSaveModal, ride } = this.props;

        if (saveChanges) {
            showSaveModal(true, ride);
        } else {
            this.downloadRideGPX(this.props.ride.id);
        }

        this.props.onRequestClose();
    };

    saveAndDownloadRide = () => {
        const { saveRide, showSaveModal, ride } = this.props;
        saveRide().then(() => {
            if (!ride.id) return;
            this.downloadRideGPX(ride.id);
            showSaveModal(false);
        });
    };

    propsForState(ride, isRideSaved) {
        if ((ride.waypoints || {}).length < 2) {
            return {
                title: t('Download GPX'),
                subtitle: t(
                    'Please add at least two (2) locations to create a GPX file.'
                )
            };
        }

        if (!ride.id) {
            return {
                title: t('Unsaved Changes'),
                subtitle: t(
                    'Your ride is unsaved. To download the GPX file you must save it first.'
                ),
                positiveText: t('Save changes'),
                onPositive: this.handleConfirmUnsaved(true)
            };
        }

        if (!isRideSaved) {
            return {
                title: t('Unsaved Changes'),
                subtitle: t(
                    'You have unsaved changes to your ride. Would you like to save and download the latest GPX file?'
                ),
                positiveText: t('Yes, save changes'),
                onPositive: this.handleConfirmUnsaved(true),
                negativeText: t('No, Download old GPX file'),
                onNegative: this.handleConfirmUnsaved(false)
            };
        }

        return {
            title: t('Download GPX'),
            positiveText: t('Download GPX file'),
            onPositive: () => this.downloadRideGPX(this.props.ride.id)
        };
    }

    render() {
        const { isOpen, ride, isRideSaved, onRequestClose } = this.props;
        const props = this.propsForState(ride, isRideSaved);

        return (
            <div>
                <ConfirmModal
                    isOpen={isOpen}
                    withBackground
                    onRequestClose={onRequestClose}
                    title={props.title}
                    subtitle={props.subtitle}>
                    {!!props.positiveText && (
                        <Button
                            positive
                            text={props.positiveText}
                            onClick={props.onPositive}
                        />
                    )}
                    {!!props.negativeText && (
                        <Button
                            negative
                            text={props.negativeText}
                            onClick={props.onNegative}
                        />
                    )}
                </ConfirmModal>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => ({});
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    saveRide: () => dispatch(saveRide()),
    showSaveModal: (value, reason) => {
        dispatch(showSaveModal(value, reason));
    }
});
//------------------------------------------------------------------------------
// Connect ---------------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(GPXModal);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
