/******************************************************************************\
 * File: here.js
 *
 * Author: Gigster
 *
 * Description: Here maps api wrapper
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import axios from 'axios';
import Promise from 'bluebird';
import { v4 as uuid } from 'uuid';
Promise.config({ cancellation: true });

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    //TRAFFIC_DIVIDER_LIGHT_MEDIUM,
    //TRAFFIC_DIVIDER_MEDIUM_HEAVY,
    WaypointType,
    WaypointCategory
} from '@/helpers/constants';
import { HERE_MAPS_API_KEY } from '@/shared/constants';
import { normalizeLatLng, parseLatLng, replaceTags } from '@/helpers/functions';
import { getPointsDistances } from '@/helpers/map';
import { rideFeaturesForApi } from '@/store/edit_ride/options';
import { getUnits, getLang } from '@/helpers/i18n';
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
export const HERE_SEARCH_URI =
    'https://discover.search.hereapi.com/v1/discover';

// https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics-places/places-category-system-full.html
export const POI_CATEGORIES = {
    PETROL_STATION: '700-7600',
    GAS_STATION: '700-7600',
    RESTAURANT: '100-1000',
    HOTEL: '500-5000',
    SCENIC: '350-3500'
};

export const POI_CATEGORIES_TYPES = {
    '700-7600': WaypointCategory.GAS_STATION,
    '100-1000': WaypointCategory.RESTAURANT,
    '500-5000': WaypointCategory.HOTEL,
    '350-3500': WaypointCategory.SCENIC
};

export const poiTypeToWaypointCategory = {
    [POI_CATEGORIES.PETROL_STATION]: WaypointCategory.GAS_STATION,
    [POI_CATEGORIES.EAT_DRINK]: WaypointCategory.RESTAURANT,
    [POI_CATEGORIES.RESTAURANT]: WaypointCategory.RESTAURANT,
    [POI_CATEGORIES.HOTEL]: WaypointCategory.HOTEL,
    [POI_CATEGORIES.SCENIC]: WaypointCategory.HOTEL,
    [POI_CATEGORIES.COFFEE_TEA]: WaypointCategory.RESTAURANT,
    [POI_CATEGORIES.SNACKS_FAST_FOOD]: WaypointCategory.RESTAURANT,
    [POI_CATEGORIES.KIOSK_CONVENIENCE_STORE]: WaypointCategory.RESTAURANT,
    [POI_CATEGORIES.NATURAL_GEOGRAPHICAL]: WaypointCategory.SCENIC,
    [POI_CATEGORIES.LEISURE_OUTDOOR]: WaypointCategory.SCENIC,
    [POI_CATEGORIES.RECREATION]: WaypointCategory.SCENIC,
    [POI_CATEGORIES.SPORTS_FACILITY_VENUE]: WaypointCategory.SCENIC,
    [POI_CATEGORIES.BODY_OF_WATER]: WaypointCategory.SCENIC,
    [POI_CATEGORIES.CAMPING]: WaypointCategory.SCENIC,
    [POI_CATEGORIES.MUSEUM]: WaypointCategory.MUSEUM
};

export const MAX_WAYPOINTS = 100;
export const credentials = {
    apikey: HERE_MAPS_API_KEY
};

const langToHereMap = {
    ar: 'ara', // Arabic
    ba: 'baq', // Basque
    ca: 'cat', // Catalan
    ci: 'chi', // Chinese (simplified)
    ch: 'cht', // Chinese (traditional)
    cz: 'cze', // Czech
    da: 'dan', // Danish
    du: 'dut', // Dutch
    en: 'eng', // English
    fi: 'fin', // Finnish
    fr: 'fre', // French
    ge: 'ger', // German
    gl: 'gle', // Gaelic
    gr: 'gre', // Greek
    he: 'heb', // Hebrew
    hi: 'hin', // Hindi
    in: 'ind', // Indonesian
    it: 'ita', // Italian
    ja: 'jap', // Indonesian
    no: 'nor', // Norwegian
    pe: 'per', // Persian
    po: 'pol', // Polish
    pr: 'por', // Portuguese
    ru: 'rus', // Russian
    si: 'sin', // Sinhalese
    sp: 'spa', // Spanish
    sw: 'swe', // Swedish
    th: 'tha', // Thai
    tu: 'tur', // Turkish
    uk: 'ukr', // Ukrainian
    ur: 'urd', // Urdu
    vi: 'vie', // Vietnamese
    we: 'wel' // Welsh
};

export const langToHereLng = (lang) => {
    if (lang in langToHereMap) {
        return langToHereMap[lang].toUpperCase();
    }
    console.warn(`langToHereLng: Missing ${lang}`);
    return 'eng';
};

const langToHereLocaleMap = {
    en: 'en-US',
    fr: 'fr-FR'
};

export const langToHereLocale = (l) => {
    if (l in langToHereLocaleMap) {
        return langToHereLocaleMap[l];
    }

    console.warn(`langToHereMap: Missing ${l}`);
    return 'en-US';
};

const getLocaleParams = () => ({
    // metric or imperial
    units: getUnits(),
    // https://developer.here.com/documentation/routing/topics/resource-param-type-languages.html
    lang: getLang()
});

const platform = new H.service.Platform({
    ...credentials,
    useHTTPS: true
});

const getCategory = (type) => (type && type.substring(0, 8)) || null;

export const hereTypeToCategory = (type = '') => {
    let category;
    if (typeof type === 'object') {
        category = getCategory(type.id);
    } else {
        category = getCategory(type);
    }
    if (category in WaypointCategory) return WaypointCategory[category];
    return poiTypeToWaypointCategory[category];
};

// Buckets jam factor into either light, medium, or heavy
/*const bucketJamFactor = (jamFactor) => {
    if (jamFactor < TRAFFIC_DIVIDER_LIGHT_MEDIUM) return 'light';

    if (jamFactor < TRAFFIC_DIVIDER_MEDIUM_HEAVY) return 'medium';

    return 'heavy';
};*/

// Joins all leg's links into one array
/*const legsToLinks = (legs) => {
    return legs.reduce(
        (acc, val) => [...acc.slice(0, acc.length), ...val.link],
        []
    );
};*/

// Merge 2 links
/*const combineLink = (link1, link2) => ({
    ...link1,
    length: link1.length + link2.length,
    shape: [...link2.shape, ...link1.shape]
});*/

// Combine links into traffic buckets
/*const combineLinks = (list, link) => {
    if (list.length === 0) return [link];

    const jamFactorPrev = list[list.length - 1].dynamicSpeedInfo.jamFactor;
    const jamFactorNext = link.dynamicSpeedInfo.jamFactor;

    if (bucketJamFactor(jamFactorNext) === bucketJamFactor(jamFactorPrev))
        return [
            ...list.slice(0, list.length - 1),
            combineLink(link, list[list.length - 1])
        ];

    return [...list, link];
};*/

const formatResponse = (res) => {
    const routes = res.routes.map((route) => {
        const { sections } = route;

        const convertSectionToLeg = (section) => {
            const { travelSummary, actions } = section;
            return {
                maneuvers: actions,
                length: travelSummary.length,
                rideLength: travelSummary.length,
                travelTime: travelSummary.duration
            };
        };

        const legs = sections.map(convertSectionToLeg);

        const actions = sections
            .map((section) => section.actions.flatten())
            .flatten();
        const travelSummaryTotal = sections.reduce(
            (acc, section) => {
                const { travelSummary } = section;
                const { duration, length } = travelSummary;
                acc.duration += duration;
                acc.length += length;
                return acc;
            },
            { duration: 0, length: 0 }
        );

        const notices = sections
            .map((section) =>
                (section.notices || [])
                    .filter((n) => n.severity !== 'info')
                    .flatten()
            )
            .flatten();

        const tmpPolyline = H.geo.LineString.fromFlexiblePolyline(
            sections[0].polyline
        );
        const tmpPolylines = sections.map((section) =>
            H.geo.LineString.fromFlexiblePolyline(
                section.polyline
            ).getLatLngAltArray()
        );
        const polylines = sections.map((section) =>
            H.geo.LineString.fromFlexiblePolyline(
                section.polyline
            ).getLatLngAltArray()
        );
        const boundingBox = tmpPolyline.getBoundingBox();
        const pointArray = chunkArrayIntoLatLng(tmpPolylines.flatten(), 3);
        delete route.id;
        const output = {
            ...route,
            actions,
            legs,
            points: pointArray,
            travelTime: travelSummaryTotal.duration,
            length: travelSummaryTotal.duration,
            distance: travelSummaryTotal.length,
            distances: getPointsDistances(pointArray),
            boundingBox: boundingBox,
            bounds: getBounds(boundingBox),
            travelSummary: travelSummaryTotal,
            polylines,
            notices
        };
        return output;
    });
    return {
        ...routes[0],
        routes
    };
};

const getPassThrough = (waypoint) => {
    const passThrough = waypoint.type === WaypointType.WAYPOINT;
    return passThrough ? '!passThrough=true' : '';
};

const geoWaypointParam = (waypoint) => {
    const pos = normalizeLatLng(waypoint);
    return `${pos.lat},${pos.lng}`;
};

const waypointParam = (waypoint) => {
    const pos = normalizeLatLng(waypoint);
    return `${pos.lat},${pos.lng}`;
};

export const calculateRoute = (
    waypoints,
    params = {},
    formatWaypoint = geoWaypointParam
) => {
    const defaults = {
        ...getLocaleParams(),
        transportMode: params.isBike ? 'bicycle' : 'car',
        // legAttributes: ['all'],
        return: 'polyline,actions,instructions,travelSummary'
    };

    // Here maps uses GeoWaypointParameterType for its waypoints
    // https://developer.here.com/documentation/routing/topics/resource-param-type-waypoint.html
    const formattedWaypoints = waypoints.reduce((memo, waypoint, i) => {
        memo[`waypoint${i}`] = formatWaypoint(waypoint, i, waypoints);
        return memo;
    }, {});
    const origin = formattedWaypoints.waypoint0;
    const destinationReference = `waypoint${waypoints.length - 1}`;
    const destination = formattedWaypoints[destinationReference];
    const routingParams = {
        ...defaults,
        ...credentials,
        ...params,
        origin,
        destination
    };

    const { avoid } = params;
    if (avoid && avoid.length > 0) {
        routingParams['avoid[features]'] = avoid.join(',');
    }

    if (waypoints.length > 2) {
        const viaWaypoints = waypoints.slice(1, -1);
        const via = viaWaypoints.map(
            (p) => `${p.lat},${p.lng || p.lon}${getPassThrough(p)}`
        );

        const viaPoints =
            typeof H.service.Url === 'function'
                ? new H.service.Url.MultiValueQueryParameter(via)
                : [];
        routingParams.via = viaPoints || [];
    }
    const router = platform.getRoutingService(null, 8);
    return new Promise((resolve, reject) =>
        router.calculateRoute(routingParams, resolve, reject)
    ).catch((res) => res);
};

function chunkArrayIntoLatLng(arr = [], chunkSize = 3) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        const [lat, lng, alt] = chunk;
        res.push({ lat, lng, alt });
    }
    return res;
}

const getBounds = (boundingBox) => {
    const topLeft = boundingBox.getTopLeft();
    const bottomRight = boundingBox.getBottomRight();
    const ne = { lat: topLeft.lat, lng: bottomRight.lng };
    const sw = { lat: bottomRight.lat, lng: topLeft.lng };
    return { ne, sw };
};

/**
 * Given an array of waypoints, returns an array of { lat, lon } pairs
 * corresponding to the route that passes through the waypoints.
 */
export const calculateRouteShape = (waypoints, params = {}) => {
    const defaults = {};
    // here maps doesn't allow us to use alternatives if waypoints > 2
    const overrides =
        params.alternatives > 0 && waypoints.length > 2
            ? { alternatives: 0 }
            : {};
    const args = { ...defaults, ...params, ...overrides };

    return calculateRoute(waypoints, args, geoWaypointParam)
        .then((res) => formatResponse(res))
        .catch(() => {
            return calculateRoute(waypoints, args, waypointParam).then((res) =>
                formatResponse(res)
            );
        });
};

/**
 * Fetches ride and route alternatives.
 * Will try even if the number of waypoints is too damn high.
 */

// TODO update to include transportMode bike
/*const modeAndAvoidances = (isBike, rideAvoidances) =>
    rideFeaturesForApi(rideAvoidances);
*/
export const unsafeCalculateRide = (ride, opts = {}) => {
    const { waypoints, rideAvoidances } = ride;
    // TODO: fix / update transportMode from ride
    const avoid =
        rideFeaturesForApi(rideAvoidances).length > 0
            ? { 'avoid[features]': rideFeaturesForApi(rideAvoidances) }
            : {};

    return calculateRouteShape(waypoints, {
        ...opts,
        transportMode: opts && opts.isBike ? 'bicycle' : 'car',
        ...avoid
    });
};

const mockRouteResponse = {
    routes: [
        {
            leg: [{ maneuver: [] }],
            // mode: {},
            summary: {},
            waypoint: [],
            points: [],
            distances: []
        }
    ]
};

const mockCalculateRideResponse = {
    ...mockRouteResponse.routes[0],
    ...mockRouteResponse
};

export const calculateRide = (ride, opts = {}) => {
    return ride.waypoints && ride.waypoints.length <= MAX_WAYPOINTS
        ? unsafeCalculateRide(ride, opts)
        : Promise.resolve(mockCalculateRideResponse);
};

/**
 * Given a search query, returns an array of suggestions.
 * https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/endpoint-autosuggest-brief.html
 */

// TODO update to include map bounds
export const autocomplete = (query, params = {}) => {
    const BASE_URL = 'https://autosuggest.search.hereapi.com/v1/autosuggest';
    // const headers = {
    //     ...getContextHeaders(context),
    //     ...headerConfig
    // };
    return axios.get(BASE_URL, {
        params: {
            ...credentials,
            ...getLocaleParams(),
            ...params,
            q: query || ''
        }
        // headers: headers
    });
};

export const hereLookup = (id, params = {}) => {
    const BASE_URL = 'https://autosuggest.search.hereapi.com/v1/lookup';
    return axios.get(BASE_URL, {
        params: {
            ...credentials,
            ...getLocaleParams(),
            ...params,
            ...id
        }
    });
};

export const placesSearch = (query, params = {}) => {
    const BASE_URL = 'https://discover.search.hereapi.com/v1/discover';
    return axios.get(BASE_URL, {
        params: {
            ...credentials,
            ...getLocaleParams(),
            ...params,
            q: query || ''
        }
        // headers: headerConfig
    });
};

export const geocoder = (query, params = {}) => {
    const BASE_URL = 'https://geocode.search.hereapi.com/v1/geocode';
    return axios.get(BASE_URL, {
        params: { ...credentials, ...params, searchtext: query || '' }
    });
};

// HERE documentation on Result Types
// https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/result-types.html
export const locationSearch = (query, params = {}) => {
    return autocomplete(query, { resultType: 'address', ...params });
};

const removeCountyFromResult = (result) =>
    result.category === 'city-town-village'
        ? {
              ...result,
              vicinity: result.vicinity.includes(',')
                  ? result.vicinity.split(',').slice(1).join(',')
                  : result.vicinity
          }
        : result;

/**
 * Requires either param `at` or `in`
 * https://developer.here.com/documentation/places/topics_api/resource-autosuggest.html
 */
export const placesAutosuggest = (q, params) => {
    const BASE_URL = 'https://autosuggest.search.hereapi.com/v1/autosuggest';

    return axios({
        url: BASE_URL,
        method: 'get',
        params: {
            ...credentials,
            ...params,
            ...getLocaleParams(),
            q: q || ''
        }
    }).then((res) => ({
        ...res,
        data: {
            ...res.data,
            results: res.data.items.map(removeCountyFromResult)
        }
    }));
};

const refNameForCategory = (category) => {
    switch (category) {
        case WaypointCategory.EVENT:
            return 'eventId';
        case WaypointCategory.DEALER:
            return 'dealerId';
        default:
            return 'hereId';
    }
};

/** Converts a heremaps suggestion to our waypoint object. */
export const suggestionToWaypoint = ({
    id,
    position,
    categories,
    type,
    vicinity,
    address,
    ...rest
}) => {
    const pos = Array.isArray(position)
        ? { lat: position[0], lng: position[1] }
        : position;

    const [categoryRef] = categories || [];
    const category = hereTypeToCategory(categoryRef || type || '');
    const ignoreCategories = [
        'administrative-region',
        'address',
        'city-town-village'
    ];

    const name =
        type === 'DEALER'
            ? rest.dealerName || rest.title
            : id === 'home' ||
                category ||
                (rest.category && !ignoreCategories.includes(rest.category))
              ? rest.title
              : null;

    const refName = refNameForCategory(category);
    const refId = rest[refName] || id;

    // This validation is gonna be used for cases such as selecting "my current location", "my dealer", etc.
    const undefinedLocation =
        address == undefined && type == undefined && category == undefined;

    return {
        ...pos,
        // Note: this doesn't work as this comment mentions. It actually causes
        // an extra call to HERE in ensureAddress which calls reverseGeocode.
        // Could this have been caused by a change in the HERE APIs?
        // if this is coming from our backend, we know the exact address:
        address:
            type === 'DEALER'
                ? replaceTags(vicinity, ' ')
                : undefinedLocation
                  ? null
                  : address.label,
        name,
        id: uuid(),
        [refName]: refId,
        type: category ? WaypointType.POI : WaypointType.LOCATION,
        category
    };
};

export const hereMapsCategoryToIcon = (category) => {
    /*  RPW converts level 2 category data to an category string used to get the icon
        Should return a default icon / currently returing category if no match is found
        HERE Places categories can be specified as values for the categories field at any of their 3 levels:
        level 1 for a high level (ex: 100 for places where you can eat and drink)
        level 2 for intermediate granularity (ex: 100-1000 for restaurant places)
        level 3 for fine-grained categories (ex: 100-1000-0002 for fine dining places),
    */
    const shortCategory = category.substr(0, 8);
    const iconRef = WaypointCategory[shortCategory];
    return iconRef ? iconRef : category;
};

export const poiToWaypoint = (data) => {
    if (data.type === WaypointCategory.DEALER) {
        return resolveSearchValue(data.vicinity).then((result) => {
            return suggestionToWaypoint({
                ...data,
                ...result,
                clustering: null,
                category: 'DEALER',
                type: 'DEALER',
                dealerId: data.dealerId
            });
        });
    }

    // use street access point if available
    return data.href && !data.lat
        ? axios.get(data.href).then((resp) => {
              const {
                  location: { access }
              } = resp.data;

              // use street access point if available
              const position =
                  access && access.length ? access[0].position : data.position;

              return suggestionToWaypoint({ ...data, position });
          })
        : Promise.resolve(
              Array.isArray(data.position) ? suggestionToWaypoint(data) : data
          );
};

export const reverseGeocode = (params) => {
    const BASE_URL = 'https://revgeocode.search.hereapi.com/v1/revgeocode';
    const { lang } = getLocaleParams();
    return axios({
        url: BASE_URL,
        method: 'get',
        params: {
            ...credentials,
            ...params,
            lang
        }
    }).then((res) => ({
        ...res,
        data: {
            ...res.data
        }
    }));
};

export const geocode = (q, data = {}) =>
    new Promise((resolve, reject) =>
        platform
            .getSearchService()
            .geocode({ ...getLocaleParams(), q, ...data }, resolve, reject)
    )
        .then((res) => res.items[0])
        .catch(() => false);

export const geocodeAsSuggestion = (searchtext, data = {}) =>
    geocode(searchtext, data)
        .then((location) => {
            if (!location) return false;

            const { Address: address, position, title } = location;

            const searchResult = {
                title: title || address.label,
                value: searchtext,
                resultType: 'address',
                position: [position.lat, position.lng]
            };
            return searchResult;
        })
        .catch(() => false);

export const latlngToAddress = (latlng) => {
    return reverseGeocode({
        at: `${latlng.lat},${latlng.lng}`,
        types: 'address',
        limit: '1'
    })
        .then((response) => {
            const { data } = response;
            const [item] = data.items || [];
            const { address, title, access, distance } = item;

            // 'Distance' is calculated from the input to the closest street section
            // if distance equals 0 means that it's an unreachable point to measure distance for HERE
            // We'll see that the waypoint could be away from the end of the route, but we have the same
            // behavior in Google Maps for stops that are imposible to go through in a bike
            const { lat, lng } =
                distance !== 0 ? latlng : access ? access[0] : latlng;
            return !!lat && !!lng && address
                ? { ...address, title, lat, lng }
                : { ...address, title };
        })
        .catch((e) => {
            console.error(e);
            return normalizeLatLng(latlng);
        });
};

export const formatDate = (date) => {
    return date.format('YYYY-MM-DDTHH:mm:ssZ');
};

export const search = (params = {}) =>
    axios.get(HERE_SEARCH_URI, {
        params: { ...credentials, ...getLocaleParams(), ...params }
    });

export const discoverExplore = (params = {}) =>
    axios.get('https://browse.search.hereapi.com/v1/browse', {
        params: {
            ...credentials,
            ...getLocaleParams(),
            ...params
        }
    });

export const getChargingStations = ({ lat, lng, radius, connectortype }) => {
    const BASE_URL = 'https://ev-v2.cc.api.here.com/ev/stations.json';
    const prox = `${lat},${lng},${radius}`;
    return axios.get(BASE_URL, {
        params: {
            ...credentials,
            ...getLocaleParams(),
            prox,
            connectortype
        }
    });
};

export const resolveSearchValue = (value, currentLocation) => {
    if (!(value || '').length) {
        return Promise.resolve(null);
    }

    const latlng = parseLatLng(value) || currentLocation;
    if (latlng) {
        const id = `${latlng.lat},${latlng.lng}`;
        return Promise.resolve({ ...latlng, type: 'latlng', id });
    }

    const lowerValue = value.toLowerCase();
    if (currentLocation) {
        if (
            lowerValue === 'my location' ||
            lowerValue === 'current location' ||
            lowerValue === 'current-location' ||
            lowerValue === 'my current location'
        ) {
            return Promise.resolve({
                ...currentLocation,
                type: 'latlng',
                id: uuid()
            });
        }
    }
    //ldgonzalezmedina change this is deprecated
    return geocodeAsSuggestion(value).then((item) => ({
        ...item,
        value: item.title
    }));
};

export const searchValueToWaypoint = (value, currentLocation) => {
    return resolveSearchValue(value, currentLocation)
        .then((result) =>
            Array.isArray(result.position)
                ? Promise.resolve(suggestionToWaypoint(result)).then(
                      (data) => ({
                          ...data,
                          name: null,
                          address: data.name || result.title
                      })
                  )
                : result
        )
        .catch(() => false);
};

export default platform;
