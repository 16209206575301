import { status } from '@/helpers/async';
import { getChallenges } from '@/helpers/api';

import createStore from '@/helpers/items';
import { shareChallengeUrl } from '@/helpers/routes';

import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import { safePush } from '@/store/routing';
import { $private } from '@/helpers/test';

/* */
// TODO: SPECIFIC Types
const itemsSort = (a, b) => {
    return a.startDate > b.startDate;
};

const didFetchItems = (items) => {
    return {
        type: 'challenges/FETCH',
        data: items,

        status: status.SUCCESS
    };
};

export const selectItemById = (challenge) => (dispatch) => {
    dispatch(safePush(shareChallengeUrl(challenge)));
};

export const fetchMany = () => (dispatch) => {
    dispatch({ type: FETCH, status: status.REQUEST });
    return getChallenges()
        .then((response) => response.data.challenges)
        .then((items) => dispatch(didFetchItems(items)));
};

$private(module, didFetchItems);

const { FETCH: FETCH, initialState } = createStore('challenges', fetchMany);

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH: {
            if (action.status === status.SUCCESS && !!action.data) {
                const items = action.data;
                return {
                    data: items.sort(itemsSort),
                    selected: {},
                    loading: false
                };
            }
            return { ...state, loading: action.status === status.REQUEST };
        }

        case AUTH_INVALIDATE:
        default: {
            return state;
        }
    }
};
