import { fetchRides, fetchManyWithParams } from '@/store/rides';
import { fetchItems as fetchDealers } from '@/store/dealers';
import { fetchItems as fetchEvents } from '@/store/events';
import * as api from '@/helpers/api';
import {
    normalizeLatLng,
    normalizeBounds,
    metersToMiles,
    clamp,
    debounceAction
} from '@/helpers/functions';
import {
    getBoundsCenterZoom,
    getZoomCenterBounds,
    getBoundsForCircle,
    getCircumscribedRadius,
    boundsFromPoints,
    defaultBounds
} from '@/helpers/map';
import {
    DYNAMIC_ZOOM_DEFAULT_LEVEL,
    DYNAMIC_ZOOM_DEFAULT_RADIUS,
    MapZoom,
    MapSchemes,
    LocationSource,
    LOCATION_CHANGE
} from '@/helpers/constants';
import { Routes, match as matchPath } from '@/helpers/routes';
import { persist } from '@/helpers/persistor';
import { UNDO, REDO } from '@/store/edit_ride';
import { EDIT_WAYPOINT } from '@/store/edit_ride/waypoints';
import { currentSection } from '@/store/sections';
import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
const log = createLogger('Map Reducer', false);

/* */
// Types
export const UPDATE = 'map/UPDATE';
export const DID_RECEIVE_LOCATION = 'map/DID_RECEIVE_LOCATION';
export const MAP_HAS_LOADED = 'map/MAP_HAS_LOADED';
export const INITIAL_LOAD_HANDLED = 'map/INITIAL_LOAD_HANDLED';
export const CENTER_ZOOM = 'map/CENTER_ZOOM';
export const BOUNDS = 'map/BOUNDS';
import history from '@/helpers/history';
/* */
// Action Creators

/** Sets an arbitrary map field to value. */
export const update = (field, value, data) => ({
    type: UPDATE,
    data: { field, value, data }
});

export const setPrint = (value) => update('print', value);
export const setShowSearchThisArea = (value) =>
    update('showSearchThisArea', value);

export const setShowImages = (value) => (dispatch) =>
    dispatch(update('showImages', value));

export const setShowImagesSidebar = (value) => (dispatch) =>
    dispatch(update('showImagesSidebar', value));

export const setImages = (value) => (dispatch) =>
    dispatch(update('images', value));

export const setImageIndex = (value) => (dispatch) =>
    dispatch(update('imageIndex', value));

export const mapHasLoaded = () => ({
    type: MAP_HAS_LOADED
});

export const handledInitialLoad = () => ({
    type: INITIAL_LOAD_HANDLED
});

export const selectMapContext = (state) => {
    const { myLocation, center, bounds } = state.map;
    return {
        position: myLocation
            ? { ...myLocation, source: 'gps' }
            : { ...center, source: 'map' },
        bounds
    };
};

/** Sets an arbitrary map field to value. */
export const centerZoomWithBounds =
    (bounds, options = {}, data = {}) =>
    (dispatch, getState) => {
        log('centerZoomWithBounds');

        const map = getState().map;

        if (!bounds) return;

        const result = getBoundsCenterZoom(
            normalizeBounds(bounds),
            {
                bounds: map.bounds,
                zoom: map.zoom
            },
            options
        );

        dispatch({ type: BOUNDS, data: { ...data, bounds } });
        return result;
    };

/* This only works on create // edit rides */
export const centerZoom =
    (rawCenter, data = { priority: undefined, source: undefined }) =>
    (dispatch, getState) => {
        if (rawCenter) {
            const center = normalizeLatLng(rawCenter);
            const {
                edit_ride: {
                    present: {
                        ride: { waypoints }
                    }
                }
            } = getState();
            if (waypoints.length > 0) {
                const bounds = boundsFromPoints(waypoints);
                if (center.lat && center.lng) {
                    dispatch({ type: BOUNDS, data: { ...data, bounds } });
                    dispatch({
                        type: CENTER_ZOOM,
                        data: { ...data, center, zoom }
                    });
                }
            }
        }
    };

/* Functions that takes a point and center based on the zoom map*/
export const centerPointZoom = (rawCenter) => (dispatch, getState) => {
    if (rawCenter) {
        const center = normalizeLatLng(rawCenter);
        const { zoom, bounds, zoomBounds } = getState().map;
        let camaraBounds = zoomBounds || bounds;
        const result = getZoomCenterBounds(zoom, center, {
            zoom,
            bounds: camaraBounds
        });
        if (result) {
            dispatch({ type: BOUNDS, data: { bounds: result } });
        }
    }
};
/** Updates the map zoom by amount. */
export const zoom =
    (amount = 1) =>
    (dispatch, getState) => {
        const { zoom } = getState().map;

        dispatch(update('zoom', zoom + amount));
    };

export const clampZoom =
    (min = MapZoom.MIN, max = MapZoom.MAX) =>
    (dispatch, getState) => {
        const { zoom } = getState().map;
        const clampedZoom = clamp(zoom, min, max);

        if (clampedZoom !== zoom) {
            dispatch(update('zoom', Math.floor(clampedZoom)));
        }
    };

/** Center's the user's current location (assuming one exists in the store). */
export const centerMyLocation =
    (passive = false) =>
    (dispatch, getState) => {
        const { myLocation } = getState().map;
        log('Centering map on location');
        if (window.location.pathname.includes('rides')) return;
        dispatch(
            centerZoom(
                myLocation,
                DYNAMIC_ZOOM_DEFAULT_LEVEL,
                passive ? LocationSource.MY_LOCATION : {}
            )
        );
    };

/**
 * Callback from the geolocation api.
 *
 * Updates the users location and centers it if this is the first user
 * location recieved and no ride is currently selected.
 */
export const didReceiveLocation =
    (locationInfo, onSuccess) => (dispatch, getState) => {
        const { myLocation, myLocationAccuracy } = getState().map;

        log('=========================================================');
        log('=================== Received Location ===================');

        log('Prev Location:', myLocation);
        log('Prev Location Accuracy:', myLocationAccuracy);

        log('New Location:', locationInfo.latLng);
        log('New Location Accuracy:', locationInfo.accuracy);

        if (
            !!(locationInfo || {}).latLng &&
            locationInfo.accuracy >= myLocationAccuracy &&
            (myLocation === undefined ||
                (myLocation || {}).lat !== locationInfo.latLng.lat ||
                (myLocation || {}).lng !== locationInfo.latLng.lng)
        ) {
            log('Updating Location Info In Store To New Location');
            dispatch(update('myLocation', locationInfo.latLng));
            dispatch(update('myLocationAccuracy', locationInfo.accuracy));

            log('Persisting Location');
            dispatch(persist('map.locationInfo', locationInfo));
            const { pathname } = window.location;
            if (['/', '/map', '/map/create'].includes(pathname)) {
                onSuccess && onSuccess();
            }
        }

        log('Dispatching Received Location');
        dispatch({ type: DID_RECEIVE_LOCATION });

        log('=================== Received Location ===================');
        log('=========================================================');
    };

export const refreshItems = (name) => (dispatch, getState) => {
    const section = currentSection(getState());
    if (section.stateKey !== name) {
        const func = getFetchItemsByName(name);
        if (func) {
            dispatch(func());
        }
    }
};

export const getFetchItemsByName = () => getRidesDealersEvents;

export const getFetchItemsByCollection =
    (collectionType, key, collection, collectionObject = {}) =>
    (dispatch, getState) => {
        // collections are a name list or an array of tags
        const { bounds } = getState().map;
        const mapRadius = metersToMiles(1 * getCircumscribedRadius(bounds));
        const { lat, lng, radius } = collectionObject;
        switch (collectionType) {
            case 'rides':
                return dispatch(
                    fetchManyWithParams({
                        tag: !Array.isArray(key)
                            ? `list=${collection}`
                            : `${key.map((k) => `tag=${k}`)}`,
                        lat: lat,
                        lon: lng,
                        radiusInMiles: radius ? radius : mapRadius
                    })
                );
            default:
                console.error('Unknown section', collectionType);
        }
    };

export const getFetchRideTags = () => (dispatch) =>
    api.getRideTags().then((response) => {
        return dispatch(update('rideTags', response.data.rideTags));
    });

export const fetchItems =
    (...args) =>
    (dispatch, getState) => {
        const { zoom, center, radius, hereBounds, bounds } = getState().map;
        const mapRadius = radius
            ? radius
            : metersToMiles(
                  1 *
                      getCircumscribedRadius(
                          hereBounds || bounds || initialState.bounds
                      )
              );
        dispatch(fetchRides(...args, zoom));
        dispatch(fetchDealers(...args, zoom));
        dispatch(fetchEvents(center, mapRadius));
        return;
    };

export const getRidesDealersEvents =
    (...args) =>
    (dispatch, getState) => {
        const { zoom } = getState().map;
        dispatch(fetchRides(...args, zoom));
        dispatch(fetchDealers(...args, zoom));
        dispatch(fetchEvents(...args, zoom));
        return;
    };

export const centerRide =
    (ride, padding = {}) =>
    (dispatch) => {
        let { points } = ride ?? {};
        // fallback to centering waypoints or locationHistory (recorded points)
        if (!(points || {}).length) {
            points = ride?.waypoints || ride?.locationHistory;
            // still nothing, bail
            if (!(points || {}).length) return;
        }
        const defaultPadding = { top: 100, bottom: 100, right: 40, left: 40 };
        const options = {
            padding: { ...defaultPadding, ...padding }
        };

        const boundingBox = boundsFromPoints(points);
        const data = { bounds: boundsFromPoints(points) };
        return dispatch(centerZoomWithBounds(boundingBox, options, data));
    };

export const updateDrawerState = (value) => (dispatch, getState) => {
    dispatch(update('drawerState', value));
    const ride = getState().rides.selected;
    if (ride) dispatch(centerRide(ride));
};

export const updateSidebar =
    (value, data = null) =>
    (dispatch) => {
        dispatch(update('sidebar', value || '', data));
    };

export const setZoomToMarker = (data) => (dispatch, getState) => {
    const { selectedDataArgs, zoom } = getState().map;
    const location = history.location;
    const pathname = location;
    const center = (data.waypoints || {}).length
        ? normalizeLatLng(data.waypoints[0])
        : normalizeLatLng(data);
    // Routes

    const paths = [
        Routes.MAP_DEALER,
        Routes.MAP_EVENT,
        Routes.RIDE_CREATE,
        Routes.RIDE_EDIT,
        Routes.RIDE_PREVIEW,
        Routes.RIDE_CREATE_PREVIEW,
        Routes.SHARED_RIDE
    ];
    const nextZoom = matchPath(pathname, { exact: true }, paths)
        ? selectedDataArgs.maxZoom + 3
        : zoom;
    dispatch(update('selectedData', data));
    dispatch(update('selectedDataArgs', null));
    dispatch(centerZoom(center, nextZoom));
};

/**
 * Dynamically zooms and centers a user's location if there are rides nearby
 */
export const initialLoad = (dynamicZoom) => (dispatch, getState) => {
    log('initialLoad');

    const { myLocation, zoom, bounds } = getState().map;
    const { selected: selectedRide } = getState().rides;

    log('My Location', myLocation);

    if (dynamicZoom && myLocation && !selectedRide) {
        const args = {
            center: myLocation,
            bounds: getZoomCenterBounds(
                DYNAMIC_ZOOM_DEFAULT_LEVEL,
                myLocation,
                {
                    zoom,
                    bounds
                }
            )
        };

        dispatch(
            centerZoomWithBounds(
                getBoundsForCircle(myLocation, DYNAMIC_ZOOM_DEFAULT_RADIUS),
                {},
                LocationSource.MY_LOCATION
            )
        );

        debounceAction(() => dispatch(getRidesDealersEvents(args)), 400);
        dispatch(handledInitialLoad());
    } else {
        // comment all this block to prevent requesting a lot of data before clicking on "Search This Area"
        const location = history.location;
        if (!location.search.includes('collection=')) {
            debounceAction(() => dispatch(fetchItems()), 400);
            dispatch(fetchItems());
        }
        dispatch(handledInitialLoad());
    }
};

/* */
// Initial State
export const initialState = {
    // Default to center of USA
    center: { lat: 39.8283459, lng: -98.5794797 },
    centerPriority: 0,

    // Default zoom
    zoom: 7,

    // This is for tracking bounds only and won't actually change the map's bounds
    bounds: defaultBounds().bounds,
    hereBounds: defaultBounds().bounds,

    scheme: MapSchemes.NORMAL.id,
    schemeOpts: {
        traffic: false
    },

    // Geolocation of user | undefined = yet to respond | null = unavailable
    myLocation: undefined,
    myCountry: undefined,
    myLocationAccuracy: 0,

    // Hover preview for ride
    hoverRide: null,

    // Hover preview for waypoints
    hoverWaypointId: null,

    // current selected marker data
    selectedData: null,

    // State of the drawer
    drawerState: 0,

    // State of the sidebar
    sidebar: '', // dealer || event

    // Shows images for dealer, event, ride or ?
    showImagesSidebar: false,
    images: [],
    imageIndex: null,

    sidebarData: '',

    // Allow user to search current map area
    showSearchThisArea: false,

    // Whether or not to show the print view
    print: false,

    rideTags: []
};

/* */
// Reducer
export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE: {
            const { field, value, data } = action.data;
            return {
                ...state,
                sidebarData: data,
                [field]:
                    field === 'zoom'
                        ? clamp(value, MapZoom.MIN, MapZoom.MAX)
                        : value
            };
        }

        case BOUNDS: {
            const { bounds } = action.data;
            return {
                ...state,
                bounds
            };
        }

        case CENTER_ZOOM: {
            const { center, zoom: nextZoom, priority } = action.data;

            // can't set an invalid lat/lng
            if (!center.lat || !center.lng) {
                return state;
            }

            const zoom = clamp(nextZoom, MapZoom.MIN, MapZoom.MAX);

            // passive center
            if (typeof priority === 'number') {
                return priority > state.centerPriority
                    ? {
                          ...state,
                          center,
                          zoom: typeof zoom === 'undefined' ? state.zoom : zoom,
                          centerPriority: priority
                      }
                    : state;
            }

            // force center
            return { ...state, center, zoom };
        }

        case UNDO:
        case REDO: {
            return { ...state, selectedData: null };
        }

        case EDIT_WAYPOINT: {
            // if selectedData is actually a waypoint, update it
            const { id, edits } = action.data;
            const { selectedData } = state;
            return (selectedData || {}).id === id
                ? { ...state, selectedData: { ...selectedData, ...edits } }
                : state;
        }

        case LOCATION_CHANGE: {
            return state;
        }

        case AUTH_INVALIDATE:
        default: {
            return state;
        }
    }
};
