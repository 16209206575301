/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <path
                    fillOpacity="0.4"
                    fillRule="nonzero"
                    d="M20 17c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8v-3zm0 3v-3c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8z"></path>
                <path
                    fillRule="nonzero"
                    d="M14.9 25.86l-.1-.1v-.948l.08-.098c.902-.179 1.25-.404 1.657-.938.012-.015.14-.185.259-.276h.948l.1.1v8.784l-.1.1h-1.512l-.1-.1V25.86H14.9zm9.924 5.056l.1.1v1.368l-.1.1h-5.46l-.1-.1.031-1.476c3.5-3.33 3.977-3.9 3.977-4.752 0-.679-.374-1.088-1.052-1.088-.714 0-1.136.413-1.136 1.04v.312l-.1.1H19.4l-.1-.1v-.408c0-1.411 1.153-2.512 2.8-2.512 1.813 0 2.956.963 2.956 2.62 0 1.198-.688 2.189-2.207 3.616-.137.129-.64.593-.748.695-.198.186-.36.342-.5.485h3.223z"></path>
                <path
                    fillRule="nonzero"
                    d="M20 17c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8v-3zm0 3v-3c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8z"></path>
                <path d="M18.5 11h3v9H20a1.5 1.5 0 01-1.5-1.5V11zM20 36h1.5v9h-3v-7.5A1.5 1.5 0 0120 36z"></path>
                <path d="M13.5 12L20 3l6.5 9z"></path>
            </g>
        </svg>
    );
}

export default Icon;
