/******************************************************************************\
 * File: ChargingStationSidebar.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Sidebar from '@/components/common/Sidebar';
import ChargingStationDetails from './ChargingStationDetails';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ChargingStationSidebar extends React.Component {
    render() {
        const { history } = this.props;
        return (
            <Sidebar contentLabel={name || 'Harley-Davidson'} {...this.props}>
                <ChargingStationDetails {...this.props} history={history} />
            </Sidebar>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ChargingStationSidebar;
