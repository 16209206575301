/******************************************************************************\
* File: Marker.jsx
*
* Author: Gigster
*
* Description: Abstracted marker component
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import HereMarker from '@/components/common/map/here/Marker';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Marker extends React.Component {
    render() {
        const { ...props } = this.props;

        return <HereMarker {...props} />;
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Marker;
