/******************************************************************************\
 * File: HEREMaps.jsx
 *
 * Author: Gigster
 *
 * Description: Heremaps Logo
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { translate } from '@/helpers/i18n';
const t = translate('here.HEREMapsLogo');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class HEREMaps extends React.Component {
    render() {
        const { className, style: _style } = this.props;

        return (
            <div
                className={className}
                style={{
                    zIndex: 1,
                    direction: 'ltr',
                    color: 'rgb(51, 51, 51)',
                    fontStyle: 'normal',
                    fontVariant: 'normal',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontSize: 11,
                    lineHeight: 'normal',
                    fontFamily: 'Arial, sans-serif',
                    ..._style
                }}>
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        pointerEvents: 'none'
                    }}>
                    <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="3.64em"
                        height="2.72em"
                        viewBox="7 10 33 18"
                        style={{ position: 'relative', left: 2 }}>
                        <g style={{ fill: 'rgba(0, 0, 0, 0.7)' }}>
                            <path
                                id="triangle_22_"
                                d="M14.1,28l-3.6,3.6L7,28L14.1,28z"
                            />
                            <path
                                id="here_21_"
                                d="M19.4,19.9c-0.7-0.9-0.7-1.4-0.2-1.8c0.6-0.6,1.1-0.3,1.8,0.3L19.4,19.9z M27,10.3c0.6-0.6,1.1-0.3,1.8,0.3l-1.5,1.5C26.5,11.2,26.6,10.7,27,10.3z M31.6,11.8c-0.7,1-1.9,2.7-3.2,1.4l3.2-3.2c-0.3-0.3-0.5-0.6-0.7-0.7c-1.7-1.7-3.7-1.7-5.2-0.2c-1,1-1.3,2.2-1,3.3l-1-1.1c-0.3,0.2-1.5,1.2-0.6,2.9l-1.1-1l-1.5,1.5l2.1,2.1C21,15.5,19.3,15.6,18,17c-1.5,1.5-1.4,3.2-0.3,4.7l-0.2-0.2c-1.5-1.5-3-0.9-3.8-0.2c-0.6,0.6-1,1.4-0.8,2l-3.1-3.1L8,21.8l6.2,6.2h3.3l-2.2-2.2c-1.2-1.2-1.2-1.8-0.6-2.4c0.5-0.5,1.2-0.2,2.3,0.9l2.2,2.2l1.7-1.7l-2.1-2.1c1.5,1.1,3.3,1.2,4.9-0.4c0,0,0,0,0,0l0,0c1-0.9,1.3-1.8,1.3-1.8l-1.3-0.8c-0.7,1-1.9,2.7-3.1,1.5l3.2-3.2l2,2l1.7-1.7l-2.5-2.5c-1.2-1.2-0.5-2.3,0-2.7c0.2,0.5,0.6,0.9,1,1.4c1.6,1.6,3.7,1.9,5.5,0.1c0,0,0,0,0,0l0,0c1-0.9,1.3-1.8,1.3-1.8L31.6,11.8z"
                            />
                        </g>
                    </svg>
                </div>
                {
                    <div
                        style={{
                            userSelect: 'none',
                            position: 'relative',
                            top: '2.73em',
                            paddingLeft: 1
                        }}>
                        <span unselectable="on" i18n-ignore="1">
                            © 1987–{new Date().getFullYear()} HERE &#124;{' '}
                        </span>
                        <a
                            target="_blank"
                            href="https://legal.here.com/en/terms/serviceterms/us"
                            style={{ color: 'inherit' }}>
                            {t('Terms of use')}
                        </a>
                        <span style={{ color: 'inherit', display: 'none' }}>
                            {' '}
                            |{' '}
                            <a target="_blank" style={{ color: 'inherit' }}>
                                {t('Report image')}
                            </a>
                        </span>
                    </div>
                }
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default HEREMaps;
