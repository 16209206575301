/******************************************************************************\
* File: Downshift.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { matchPath } from 'react-router-dom';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import HDLogo from '@/components/common/icons/HDLogo';
import Locate from '@/components/common/icons/Locate';
import Home from '@/components/common/icons/Home';
import Text from '@/components/common/form/Text';
import AddLocation from '@/components/common/icons/AddLocation';
import { Routes } from '@/shared/routing';

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    onStateChange,
    fetchItems,
    setItems,
    getInputState,
    handleSubmit,
    handleSearch,
    setSelectIndex,
    moveSelectedIndex,
    getSelectedItem
} from '@/store/autocomplete';
import { prettyMeters, debounce } from '@/helpers/functions';
import { WaypointCategory } from '@/helpers/constants';
import { clickAway } from '@/helpers/hoc';
import { analyticsForRideWithData } from '@/helpers/analytics';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/search/SearchBar.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const getItemValue = (item) => (item ? item.value : '');

export const Result = ({
    item,
    isHighlighted,
    handleSubmit,
    hasLocations,
    ...rest
}) => (
    <div
        key={item.id || item.title}
        className={classNames(style.dropdownItem, {
            [style['flex']]: true,
            [style['small']]: false,
            [style['highlight']]: !!isHighlighted
        })}
        onClick={(e) => {
            const { latitude, longitude, position } = item;
            const lat = !!position.length > 0 ? position[0] : latitude;
            const lng = !!position.length > 0 ? position[1] : longitude;
            analyticsForRideWithData(
                hasLocations ? 'add destination' : 'add starting point',
                { lat, lng, location: 'sidebar' }
            );
            return handleSubmit(item);
        }}
        {...rest}>
        {item.id === 'current-location' && (
            <Locate className={style.icon} style={{ padding: 6 }} />
        )}
        {item.type === WaypointCategory.DEALER && (
            <HDLogo className={style.icon} />
        )}
        {item.id === 'home' && (
            <Home className={style.icon} style={{ padding: 6 }} />
        )}
        <div className={style.group}>
            <div
                className={style.text}
                dangerouslySetInnerHTML={{ __html: item.highlightedTitle }}
            />
            <div
                className={style.text}
                dangerouslySetInnerHTML={{ __html: item.vicinity }}
            />
        </div>
        {typeof item.distance !== 'undefined' && (
            <div className={style.right} style={{ paddingLeft: 8 }}>
                {prettyMeters(item.distance)}
            </div>
        )}
    </div>
);

class ResultsList extends React.Component {
    onClickAway = (e) => {
        if (e.target.tagName !== 'INPUT') {
            const { onStateChange } = this.props;
            onStateChange({ isOpen: false, focusId: null, isHover: false });
        }
    };

    render() {
        const {
            isOpen,
            isHover,
            isInput,
            items,
            selectedIndex,
            setSelectIndex,
            hasLocations,
            heightExceeded,
            handleSubmit,
            absolute,
            onStateChange
        } = this.props;

        // dont return empty ul
        if (!items || items.length < 1) return null;

        const inlineStyle = heightExceeded
            ? { marginLeft: '0', bottom: '-4px', maxHeight: '100px' }
            : { marginLeft: '0' };

        return (
            <ul
                className={classNames(style.ResultsList, {
                    [style.absolute]: absolute,
                    [style.isInput]: isInput
                })}
                onMouseLeave={() => setSelectIndex(-1)}
                style={inlineStyle}>
                {(isOpen || isHover) &&
                    items.map((item, index) => (
                        <Result
                            key={index}
                            isHighlighted={selectedIndex === index}
                            item={item}
                            hasLocations={hasLocations}
                            handleSubmit={(e) => {
                                handleSubmit(item);
                                onStateChange({
                                    isOpen: false,
                                    focusId: null,
                                    isHover: false
                                });
                            }}
                            onMouseEnter={() => {
                                onStateChange({ isHover: true });
                                setSelectIndex(index);
                            }}
                        />
                    ))}
            </ul>
        );
    }
}

export const ConnectedResultsList = connect(
    (state) => getInputState(state),
    (dispatch) =>
        bindActionCreators(
            { setSelectIndex, handleSubmit, onStateChange },
            dispatch
        )
)(clickAway(ResultsList));

class Downshift extends React.Component {
    static defaultProps = {
        onSubmit: () => {}
    };

    constructor(props) {
        super(props);
        this.debouncedFetchItems = debounce(props.fetchItems, 200);
    }

    handleChange = (event) => {
        const { value } = event.target;
        const { id, onChange } = this.props;

        this.debouncedFetchItems(id, value);
        onChange && onChange(value);
    };

    handleKeyDown = (event) => {
        const { id, moveSelectedIndex } = this.props;

        // keyboard navigation and submission
        switch (event.key) {
            case 'Enter': {
                const { value } = event.target;
                this.handleSubmit(value);
                this.$input.blur();
                return;
            }
            case 'ArrowUp':
            case 'ArrowDown': {
                event.preventDefault();
                return moveSelectedIndex(event.key === 'ArrowDown' ? 1 : -1);
            }
        }
    };

    handleSubmit = (value) => {
        const { id, handleSearch, onSubmit } = this.props;
        handleSearch(value, id);
        onSubmit && onSubmit(value, { id });
    };

    handleFocus = (e) => {
        const { id, onStateChange } = this.props;
        this.debouncedFetchItems(id, e.target.value);
        onStateChange({ isOpen: true, focusId: id });
    };

    handleBlur = (e) => {
        if (e.target.tagName !== 'INPUT') {
            setTimeout(
                () =>
                    this.props.onStateChange({
                        isOpen: false,
                        focusId: null,
                        isHover: false
                    }),
                500
            );
        }
    };

    render() {
        const {
            id,
            value,
            previewValue,
            onStateChange,
            fetchItems,
            map,
            waypoints,
            hasLocations,
            onChange,
            setItems,
            handleSearch,
            moveSelectedIndex,
            icon,
            placeholder,
            ...rest
        } = this.props;

        // used to set first inputs initial focus on ride creation
        const { pathname } = window.location;
        const { params } =
            matchPath({ path: Routes.RIDE_EDIT, end: true }, pathname) || {};

        const inputValue = previewValue ? '' : value;
        const isInput = !inputValue;
        return (
            <span className={isInput ? style.locationInput : ''}>
                {isInput && <AddLocation className={style.svg} />}
                <Text
                    id={isInput ? 'add-location' : undefined}
                    data-testid={isInput ? 'add-location' : undefined}
                    setRef={(el) => (this.$input = el)}
                    classNameWrapper={style.locationData}
                    editable
                    value={inputValue}
                    hasLocations={hasLocations}
                    inputProps={{
                        onKeyDown: this.handleKeyDown,
                        onChange: this.handleChange,
                        autoComplete: 'new-password',
                        type: 'text',
                        onFocus: this.handleFocus,
                        onBlur: this.handleBlur
                    }}
                    initialFocus={!(params || {}).id && waypoints.length === 0}
                    selectOnFocus
                    placeholder={placeholder}
                    {...rest}
                    blurOnEnter={false}
                />
            </span>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => ({
    map: state.map,
    waypoints: state.edit_ride.present.ride.waypoints,
    previewValue: (getSelectedItem(ownProps.id, state) || {}).value
});
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) =>
    bindActionCreators(
        {
            fetchItems,
            onStateChange,
            setItems,
            moveSelectedIndex,
            handleSearch
        },
        dispatch
    );
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(Downshift);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
