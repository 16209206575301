/******************************************************************************\
 * File: Tooltip.jsx
 *
 * Author: Gigster
 *
 * Description: Onboarding tooltips
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Close from '@/components/common/icons/Close';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/Tooltip.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Tooltip extends React.Component {
    static defaultProps = {
        accent: false,
        direction: 'top'
    };

    state = {
        open: true,
        size: 0
    };

    componentDidMount() {
        const { direction } = this.props;
        const $el = this.$tooltip;
        const size = ['top', 'bottom'].includes(direction)
            ? $el.offsetHeight
            : $el.offsetWidth;
        this.setState({ open: true, size });
    }

    render() {
        const {
            accent,
            children,
            className,
            innerRef,
            direction,
            hideArrow,
            onRequestClose,
            thin,
            text,
            style: styleProp
        } = this.props;

        const { size, open } = this.state;

        const cn = classNames(style.Tooltip, {
            [className]: !!className,
            [style['open']]: open,
            [style['arrow']]: !hideArrow,
            [style['accent']]: accent,
            [style['thin']]: !!thin,
            [style[direction]]: !!direction
        });

        return (
            <div
                className={cn}
                ref={(el) => {
                    this.$tooltip = el;
                    innerRef && innerRef(el);
                }}
                style={{ ...(styleProp || {}), [direction]: -size }}>
                {onRequestClose && (
                    <div className={style.Close} onClick={onRequestClose}>
                        <Close />
                    </div>
                )}
                {text} {children}
            </div>
        );
    }
}

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Tooltip;
