/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <path
                fill="#FFF"
                d="M31.274 27.567v6.923L31.272 38h-3.508V27.567a7.121 7.121 0 00-7-7.148h-4.157v6.91L6.72 18.695 16.607 10v6.91h4.138c5.852.068 10.542 4.823 10.529 10.657z"></path>
        </svg>
    );
}

export default Icon;
