/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <path
                fill="#FFF"
                d="M20.172 15.58L15.48 10l13.8.12-1.997 13.917-4.674-5.653-5.072 4.285a9.706 9.706 0 00-3.09 7.702L14.456 38h-3.71v-7.355a13.488 13.488 0 014.343-10.77l5.083-4.295z"></path>
        </svg>
    );
}

export default Icon;
