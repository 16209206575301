/******************************************************************************\
* File: Twitter.jsx
*
* Author: Gigster
*
* Description: SVG icon of Twitter
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Twitter extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg className={cn} viewBox="0 0 18 14" onClick={onClick}>
                <g
                    id="Preview"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="Ride-Preview-POI-Preview-Rollover"
                        transform="translate(-1315.000000, -132.000000)"
                        fill="#FFFFFF"
                    >
                        <g
                            id="Direction-|-Share--|-Save"
                            transform="translate(1230.000000, 24.000000)"
                        >
                            <path
                                d="M103,109.659102 C102.32536,109.94505 101.61076,110.132648 100.8796,110.215597 C101.64784,109.777401 102.22528,109.081607 102.5032,108.259114 C101.77636,108.671411 100.98256,108.961208 100.156,109.116607 C99.45328,108.401913 98.4802,107.998017 97.4632,108.000117 C95.43928,107.984367 93.78544,109.567053 93.7696,111.534736 L93.7696,111.535086 C93.76996,111.805983 93.80236,112.076181 93.8668,112.340079 C90.90796,112.20393 88.14424,110.863791 86.2528,108.647611 C85.92448,109.185556 85.75132,109.799801 85.7524,110.425595 C85.75348,111.613135 86.37088,112.720175 87.394,113.36907 C86.80756,113.35122 86.23408,113.198621 85.72,112.924573 L85.72,112.970073 C85.73152,114.664058 86.97172,116.114446 88.6828,116.434693 C88.13884,116.576091 87.56932,116.596041 87.016,116.494192 C87.51316,117.94073 88.89628,118.923521 90.4648,118.944171 C89.15224,119.929412 87.54016,120.462107 85.882,120.459658 C85.58716,120.460007 85.29268,120.443558 85,120.411008 C86.69344,121.450849 88.65616,122.002094 90.6592,121.999994 C97.4524,121.999994 101.1676,116.613541 101.1676,111.944582 L101.1676,111.486086 C101.88472,110.98734 102.50536,110.368896 103,109.659102"
                                id="Twitter"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Twitter;
