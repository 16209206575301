import history from '@/helpers/history';
import { centerZoomWithBounds } from '@/store/map';
import { Routes } from '@/helpers/routes';
import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
import { geocode } from '@/helpers/here';
import { convertHereBounds } from '@/helpers/functions';

/* */
// Types
const CHANGE_SEARCH = 'search/CHANGE';
const SET_ITEMS = 'search/SET_ITEMS';
const SELECT_RESULT = 'search/SELECT_RESULT';

/* */
// Selectors
export const filterItems = (items, query) => {
    const lowerQuery = query.toLowerCase();
    return items.filter(
        (item) => item.name.toLowerCase().indexOf(lowerQuery) >= 0
    );
};

/* */
// Action Creators

export const changeSearch = (query) => ({
    type: CHANGE_SEARCH,
    data: { query }
});

export const setItems = (items) => ({ type: SET_ITEMS, data: { items } });

const selectResultAction = (result) => ({
    type: SELECT_RESULT,
    data: { result }
});

const moveMapToBounds = (bounds) => (dispatch, getState) => {
    const result = centerZoomWithBounds(bounds)(dispatch, getState);
    return result;
};

export const selectResult = (result) => (dispatch, getState) => {
    if (!result) return;

    dispatch(changeSearch(result.label));
    dispatch(selectResultAction(result));
    geocode(result.address.label).then((location) => {
        if (!location) return;

        const bounds = convertHereBounds(location.mapView);
        // Map doesn't zoom to bounds when loaded from homepage
        const timer = history.location.pathname === '/' ? 150 : 0;

        setTimeout(() => {
            const pos = moveMapToBounds(bounds)(dispatch, getState);
            const url = `${Routes.MAP}/?pos=${encodeURIComponent(
                pos.center.lat + ',' + pos.center.lng + ',z' + pos.zoom
            )}`;
            history.push(url);
        }, timer);
    });
};

/* */
// Reducer
const initialState = {
    query: '',
    items: [],
    selectedResult: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_SEARCH: {
            return { ...state, query: action.data.query };
        }

        case SET_ITEMS: {
            return { ...state, items: action.data.items };
        }

        case SELECT_RESULT: {
            return { ...state, selectedResult: action.data.result };
        }

        case AUTH_INVALIDATE:
        default: {
            return state;
        }
    }
};
