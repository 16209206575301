/******************************************************************************\
 * File: Distance.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/directions/Distance.scss';
import { prettyMeters, prettySeconds } from '@/helpers/functions';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export class Stats extends React.Component {
    render() {
        const { length, time, ...rest } = this.props;

        const hasLength = typeof length !== 'undefined';
        const hasTime = typeof time !== 'undefined';

        return (
            <span {...rest}>
                {hasLength && prettyMeters(length)}
                {hasLength && hasTime && ' / '}
                {hasTime && prettySeconds(time || 0.1)}
            </span>
        );
    }
}

class Distance extends React.Component {
    static defaultProps = {
        innerProps: {}
    };

    render() {
        const {
            className,
            light,
            thick,
            length,
            time,
            noLine,
            innerProps,
            right,
            ...rest
        } = this.props;

        const cn = classNames(style.Distance, {
            [className]: !!className,
            [style['light']]: !!light,
            [style['no-line']]: !!noLine,
            [style['right']]: !!right,
            [style['thick']]: !!thick
        });

        return (
            <div className={cn} {...rest}>
                <Stats length={length} time={time} {...innerProps} />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Distance;
