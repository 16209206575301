/******************************************************************************\
 * File: info-bubble.js
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { renderToString } from 'react-dom/server';
import { bfs, toArray } from '@/helpers/functions';
import RideMarker from '@/components/common/map/icons/RideMarker';
import { stackedMarkerProps } from '@/helpers/markers';
import PropTypes from 'prop-types';

import {
    POI,
    Ride,
    Dealer,
    Event,
    Waypoint
} from '@/components/common/map/InfoBubbleContent';

const componentForType = (type) => {
    switch (type) {
        case 'poi':
            return POI;
        case 'ride':
            return Ride;
        case 'dealer':
            return Dealer;
        case 'event':
            return Event;
        case 'waypoint':
            return Waypoint;
        default:
            console.error('No component for markerType', type);
    }
};

const MarkerOverlay = ({ component: PinComponent, ...rest }) => (
    <div
        style={{
            position: 'absolute',
            left: 0,
            width: '100%',
            bottom: -24,
            marginLeft: -1,
            display: 'flex',
            justifyContent: 'center',
            borderBottom: 0,
            zIndex: 9
        }}>
        <div style={{ position: 'relative', zIndex: 1 }}>
            <PinComponent {...rest} style={{ margin: 0 }} />
        </div>
    </div>
);
MarkerOverlay.propTypes = {
    component: PropTypes.any
};

export const List = ({ markers, markerProps, userId, showMarkerOverlay }) => {
    return (
        <div>
            {showMarkerOverlay && (
                <MarkerOverlay
                    component={markers[0].component || RideMarker}
                    {...markers[0].componentProps}
                    {...stackedMarkerProps(markers, userId)}
                />
            )}

            {markers.map((marker, index, isBookmarked) => {
                const MarkerComponent = componentForType(marker.markerType);
                const meta = { ...marker, icon: null };

                return (
                    <MarkerComponent
                        key={index}
                        index={index}
                        meta={meta}
                        userId={userId}
                        {...markerProps}
                        {...(marker.componentProps || {})}
                        isBookmarked={isBookmarked}
                    />
                );
            })}
        </div>
    );
};

List.propTypes = {
    markers: PropTypes.array,
    markerProps: PropTypes.any,
    userId: PropTypes.any,
    showMarkerOverlay: PropTypes.bool
};

export const findEl = (start) => (name) =>
    bfs(
        start,
        (n) => n.childNodes,
        (n) => n.classList.contains(`H_ib_${name}`)
    );

export const renderMarkers = (markers, data = {}) => {
    const selectableMarkerTypes = ['dealer', 'event', 'ride'];
    const typeOfMarkers = (markers[0] || markers || {}).markerType;
    const selectable =
        markers.length > 1 && selectableMarkerTypes.includes(typeOfMarkers);
    return renderToString(
        <List
            markers={toArray(markers)}
            showMarkerOverlay={false}
            userId={data.userId}
            rideTags={data.rideTags}
            markerProps={{
                ...data,
                isCreate: data.route === 'create',
                showActions: data.route !== 'preview',
                selectable: selectable
            }}
        />
    );
};
