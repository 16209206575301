/******************************************************************************\
* File: Search.jsx
*
* Author: Gigster
*
* Description: SVG icon of search
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Search extends React.Component {
    render() {
        const { className, onClick, active } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg className={cn} viewBox="0 0 25 26" onClick={onClick}>
                <g
                    id="Preview"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="No-Location-Selected"
                        transform="translate(-494.000000, -369.000000)"
                        fill="#FF6600"
                    >
                        <g
                            id="Search-Field"
                            transform="translate(470.000000, 345.000000)"
                        >
                            <g
                                id="Search"
                                transform="translate(24.000000, 24.000000)"
                            >
                                <g
                                    id="Group-3"
                                    transform="translate(14.634146, 15.094486)"
                                >
                                    <path
                                        d="M9.43280488,6.60307143 L3.53036585,0.585928571 C2.73402439,1.9187381 1.64439024,3.04540476 0.34804878,3.87988095 L4.61634146,8.21321429 L5.95109756,6.85069048 C6.09804878,6.7002619 6.33768293,6.69902381 6.48585366,6.84883333 L6.48768293,6.85069048 C6.63585366,7.0005 6.63707317,7.24316667 6.49012195,7.39359524 C6.48890244,7.39421429 6.48829268,7.39483333 6.48768293,7.39545238 L5.15231707,8.75735714 L5.68890244,9.30211905 L7.02426829,7.94021429 C7.17121951,7.78978571 7.41085366,7.78916667 7.55902439,7.93835714 L7.56085366,7.94021429 C7.70902439,8.08940476 7.7102439,8.33269048 7.56329268,8.48311905 C7.56268293,8.4837381 7.56207317,8.48435714 7.56085366,8.48497619 L6.2254878,9.84688095 C7.10719512,10.7457381 8.53890244,10.7494524 9.42487805,9.85554762 C9.42731707,9.85245238 9.43036585,9.84997619 9.43280488,9.84688095 C10.2578049,8.95421429 10.2212195,7.53040476 9.43280488,6.60307143"
                                        id="Fill-1"
                                    />
                                </g>
                                <g
                                    id="Group-6"
                                    transform="translate(0.000000, 0.237343)"
                                >
                                    <path
                                        d="M9.10981707,0.381766667 C4.1025,0.35762381 0.0238414634,4.45943333 6.09756098e-05,9.54367143 C-0.0237195122,14.6279095 4.01652439,18.7681 9.02445122,18.7916238 C14.032378,18.8157667 18.1104268,14.7139571 18.1335976,9.6303381 C18.1342073,9.6161 18.1342073,9.60124286 18.1342073,9.58700476 C18.1445732,4.51576667 14.104939,0.395385714 9.10981707,0.381766667 M9.10981707,3.08081429 C12.6525,3.0641 15.5378659,5.96557619 15.554939,9.56224286 C15.5714024,13.1589095 12.7134756,16.0882429 9.17079268,16.1049571 C5.62810976,16.1229095 2.7427439,13.2208143 2.72567073,9.62414762 C2.72567073,9.61176667 2.72567073,9.59938571 2.72567073,9.58700476 C2.7220122,6.0021 5.57871951,3.09071905 9.10981707,3.08081429"
                                        id="Fill-4"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Search;
