/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF">
                <path d="M21.735 19.858v18.493h-3.566l-.002-17.833.002-.66h-7.022L19.95 8.035l8.803 11.823z"></path>
                <path
                    fillOpacity="0.4"
                    d="M34.502 15.842l2.338 2.689-5.875 5.118A9.305 9.305 0 0028 31.035l.008 7.316h-3.56v-7.053a12.93 12.93 0 014.167-10.328l5.887-5.128zm-29.103 0L3.06 18.531l5.875 5.118a9.305 9.305 0 012.966 7.386l-.009 7.316h3.56v-7.053a12.93 12.93 0 00-4.167-10.328l-5.886-5.128z"></path>
            </g>
        </svg>
    );
}

export default Icon;
