/******************************************************************************\
 * File: Scenic.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Scenic extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [style['no-stroke']]: true,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 12 10" onClick={onClick}>
                <title>scenic</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <path
                        d="M0,10 L3.046,4.478 L5.396,6.558 L7.856,2 L12,10 L0,10 Z M3.5,3 C2.67157288,3 2,2.32842712 2,1.5 C2,0.671572875 2.67157288,0 3.5,0 C4.32842712,0 5,0.671572875 5,1.5 C5,2.32842712 4.32842712,3 3.5,3 Z"
                        id="scenic"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        );
    }
}
export default Scenic;
