/******************************************************************************\
 * File: CoinView.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/homeView/CoinView.scss';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { mod, move, limit } from '@/helpers/functions';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class CoinView extends React.Component {
    static params = {
        maxRotationSpeed: 22,
        friction: 0.1,
        wobble: 100,
        instantWobble: 20,
        sensitivity: 0.2,
        idleSpeed: 1
    };

    static animationState = {
        index: 0,
        mouse_down: false,
        mousex: 0,
        mousex_prev: 0,
        angle: 0,
        rotspeed: 0,
        idle: true
    };

    state = {
        running: false
    };

    constructor(props) {
        super(props);
        this.preloadCount = 0;
    }

    componentWillUnmount = () => {
        this.stop();
    };

    update = () => {
        const { frameUrls: frames } = this.props;
        const state = this.animState;
        const params = CoinView.params;

        // find the nearest face
        const target_angle = Math.round(state.angle / 180) * 180;

        if (state.mouse_down) {
            // apply rotation force
            const force = state.mousex - state.mousex_prev;
            state.mousex_prev = state.mousex;

            const scale = 720 / this.$canvas.width;
            state.rotspeed = force * params.sensitivity * scale;
            state.angle += state.rotspeed;
        } else {
            // apply friction
            state.rotspeed = move(state.rotspeed, 0, params.friction);

            // limit rotation speed
            state.rotspeed = limit(state.rotspeed, params.maxRotationSpeed);

            state.rotspeed += (target_angle - state.angle) / params.wobble;

            let inc =
                state.rotspeed +
                (target_angle - state.angle) / params.instantWobble;

            if (inc > -0.1 && inc < 0.1) inc = 0;
            if (inc !== 0 && inc > -1 && inc < 1) inc = Math.sign(inc);

            if (state.idle) {
                inc = params.idleSpeed;
            }

            state.angle += inc;
        }

        // render the coin frame
        const index = Math.floor((mod(state.angle, 360) / 360) * frames.length);
        Object.assign(this.animState, state);
        const image = this.$images.querySelector(`[data-index="${index}"]`);
        if (image) {
            this.$ctx.drawImage(
                image,
                0,
                0,
                this.$canvas.width,
                this.$canvas.height
            );
        }

        window.requestAnimationFrame(this.update);
    };

    setRef = (key) => (el) => {
        this[key] = el;

        if (this.$canvas && this.$container) {
            const canvas = this.$canvas;

            // resize canvas
            const width = this.$container.offsetWidth;
            const height = this.$container.offsetHeight;
            const scale = window.devicePixelRatio || 1;

            canvas.width = width * scale;
            canvas.height = height * scale;
            canvas.style.width = `${width}px`;
            canvas.style.height = `${height}px`;

            // get drawing context
            this.$ctx = canvas.getContext('2d');
        }
    };

    start = () => {
        this.setState({ running: true });
        this.animState = { ...CoinView.animationState };

        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onMouseUp);

        this.update();
    };

    stop = () => {
        this.setState({ running: false });

        window.cancelAnimationFrame(this.update);

        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onMouseUp);
    };

    onLoad = (url) => () => {
        const { frameUrls } = this.props;

        this.preloadCount++;

        if (this.preloadCount >= frameUrls.length) {
            this.start();
        }
    };

    onError = (url) => () => {
        console.error('error loading image:', url);
    };

    onMouseEnter = () => {
        if (Math.abs(this.animState.rotspeed) < 1) {
            this.animState.rotspeed += 5;
        }
    };

    onMouseMove = (e) => {
        const mx = e.clientX;
        const state = this.animState;
        state.mouse_prev = state.mousex || mx;
        state.mousex = mx;

        if (state.mouse_down) {
            state.idle = false;
        }
    };

    onMouseDown = () => (this.animState.mouse_down = true);
    onMouseUp = () => (this.animState.mouse_down = false);

    render() {
        const { className, frameUrls, imageUrl } = this.props;

        const { running } = this.state;

        const cn = classNames(style.CoinView, { [className]: className });

        return (
            <div className={cn} ref={this.setRef('$container')}>
                {!running && (
                    <img src={imageUrl} className={style.placeholder} />
                )}

                <canvas
                    ref={this.setRef('$canvas')}
                    onMouseDown={this.onMouseDown}
                    onMouseEnter={this.onMouseEnter}
                    onClick={() =>
                        (this.animState.rotspeed =
                            CoinView.params.maxRotationSpeed)
                    }
                />

                {/* Preload Images: */}
                <div
                    ref={(el) => (this.$images = el)}
                    style={{ display: 'none' }}>
                    {(frameUrls || []).map((url, i) => (
                        <img
                            key={url}
                            data-index={i}
                            src={url}
                            onLoad={this.onLoad(url)}
                            onError={this.onError(url)}
                        />
                    ))}
                </div>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default CoinView;
