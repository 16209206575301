/******************************************************************************\
 * File: SidebarHeader.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import style from '@/style/common/SidebarHeader.scss';

//------------------------------------------------------------------------------
// React Function --------------------------------------------------------------
const SidebarHeader = (props) => {
    const { onClick, navigationItems, selectedNavigation } = props;

    const cn = classNames(style.navigationHeaderItem, {
        [style['active']]: 'active'
    });

    return (
        <div className={style.navigationHeader}>
            {navigationItems.map((item, index) => (
                <span
                    className={
                        selectedNavigation === item.key
                            ? cn
                            : style.navigationHeaderItem
                    }
                    key={index}
                    onClick={() => onClick(item.key)}>
                    {item.label}
                </span>
            ))}
        </div>
    );
};

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default SidebarHeader;
