/******************************************************************************\
 * File: SavedView.jsx
 *
 * Author: Gigster
 *
 * Description: Page to display saved rides
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/myAccountView/SavedView.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import DisplayRows from '@/components/common/cards/DisplayRows';
import ConfirmModal from '@/components/common/ConfirmModal';
import SidebarFilter from '@/components/SidebarFilter';
import Ride from '@/components/common/cards/Ride';
import Event from '@/components/common/cards/Event';
import Toggle from '@/components/common/Toggle';
import SidebarHeader from '@/components/SidebarHeader';

//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import { fetchCustomRides, deleteCustomRide } from '@/store/rides';
import {
    bookmarkedRides,
    filterUnbookmarked,
    setBookmark,
    fetchBookmarks
} from '@/store/bookmarks';

import {
    bookmarkedEvents,
    fetchEventBookmarks,
    setEventBookmark
} from '@/store/eventBookmarks';
import { setSortKey } from '@/store/user';
import { currentUserId } from '@/store/auth';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { responsiveToggle, dateExpired } from '@/helpers/functions';
import { Routes } from '@/helpers/routes';
import {
    sorts as ridesSortMap,
    ALPHANUMERIC,
    ALPHANUMERIC_REV,
    LAST_UPDATE,
    LAST_UPDATE_REV
} from '@/helpers/rides';
import {
    sorts as eventsSortMap,
    START_DATE,
    START_DATE_REV
} from '@/helpers/events';
import {
    countByKey,
    splitKey,
    getDeepPropertyValueFromObj,
    getEventTypes,
    checkBoxGroupConfig,
    getRideCheckboxData
} from '@/helpers/checkboxes';
import {
    get as getLocalStore,
    set as setLocalStore
} from '@/helpers/persistor';
import { updateSessionHDModels } from '@/helpers/hdModelNames';
import { emptyStateRides, rideEmptyState } from '@/helpers/ridesEmptyState';

import { translate } from '@/helpers/i18n';
const t = translate('myAccountView.SavedView');
//App context
import AppContext from '@/contexts/AppContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SavedView extends React.Component {
    static getNavigationItems = () => [
        { key: 'rides', label: t('Rides') },
        { key: 'events', label: t('Events') }
    ];

    constructor(props) {
        super(props);
        this.state = {
            confirmDelete: null,
            selectedNavigation: 'rides',
            selectedCheckboxes: [],
            filteredRides: [],
            filteredEventBookmarks: [],
            eventCheckboxGroups: {},
            rideCheckboxGroups: { total: 0 },
            showBookmarkedOnly: false,
            isOpen: false,
            isLoading: false,
            ridesEmptyState: rideEmptyState()
        };
        this.$content = React.createRef();
    }

    componentDidMount = () => {
        this.props.commitChanges();
        this.fetchAndSetup();
    };

    componentDidUpdate = (prevProps) => {
        const { bookmarkCount, bookmarkEventCount } = this.props;
        if (bookmarkCount !== prevProps.bookmarkCount)
            this.fetchAndSetupRides();
        if (bookmarkEventCount !== prevProps.bookmarkEventCount)
            this.fetchAndSetupEvents();
    };

    componentWillUnmount = () => {
        this.props.commitChanges();
        this.setState({
            confirmDelete: null
        });
    };

    fetchAndSetup = () => {
        this.setState({ isLoading: true });
        this.fetchAndSetupRides();
        this.fetchAndSetupEvents();
    };

    async fetchAndSetupRides() {
        await this.props.fetchBookmarks();
        await this.props.fetchCustomRides();
        this.setState({ isLoading: true });
        const rideCheckboxGroups = this.setupRideCheckboxes();
        this.getFilteredRides(rideCheckboxGroups);
    }

    fetchAndSetupEvents = () => {
        if (!this.props.fetchEventBookmarks) return;
        this.props.fetchEventBookmarks();
        const eventCheckboxGroups = this.setupEventCheckboxes();
        this.filteredEventBookmarks(eventCheckboxGroups);
    };

    getAllRides = () => {
        const { bookmarks, custom } = this.props;
        // dedupe
        const customIds = (custom || []).map((r) => r.id);
        const curatedBookmarks = bookmarks.rides.filter(
            (r) => r.isCuratedRide || !customIds.includes(r.id)
        );
        if (custom && curatedBookmarks) return [...custom, ...curatedBookmarks];
        return [];
    };

    getSelectedRides = (showBookmarkedOnly) =>
        showBookmarkedOnly ? this.props.bookmarks.rides : this.getAllRides();

    rideTypeModifiers = () => ({
        PLANNED: 'SHARED',
        RECORDED: 'SHARED',
        SHARED: 'SHARED'
    });

    modifiedSelectedRides = (showBookmarkedOnly) => {
        // Modified Selected Rides are only used for checkbox presentation
        const { userId } = this.props;
        const rideTypeModifiers = this.rideTypeModifiers();
        const rides = this.getSelectedRides(showBookmarkedOnly);
        if (!rides) return;
        const isSharedRide = (item) =>
            userId !== item.userId && item.type === 'CUSTOM';
        const modifyRide = (item) => {
            item.subType = isSharedRide(item)
                ? rideTypeModifiers[item.subType]
                : item.subType;
            return item;
        };
        this.setState({ isLoading: false });
        const modifiedRides = rides.map((ride) => modifyRide(ride));
        // SESSION
        const lastUpdated = (getLocalStore('hdModelNames') || {}).lastUpdated;
        const updateHdModelNames = dateExpired(lastUpdated, 30);
        // Every 30 days update sessionData and HDModels
        // Only update rides modified since last update
        updateSessionHDModels(rides, updateHdModelNames);
        return modifiedRides;
    };

    resetRidesCheckboxCounts = (ridesCheckboxCounts) => {
        let { rideCheckboxGroups } = this.state;
        rideCheckboxGroups.countByType = ridesCheckboxCounts;
        this.setState({ rideCheckboxGroups });
        return rideCheckboxGroups;
    };

    getCuratedRideTypes = (checkboxGroup) =>
        checkboxGroup.children.filter((ck) => ck.group === 'CURATED');

    getCuratedRideTypeValues = (checkboxGroup) =>
        this.getCuratedRideTypes(checkboxGroup).map((chk) => chk.value);

    getRideTypes = (checkboxGroup, type) =>
        checkboxGroup.filter((cb) => cb.group === type).map((cb) => cb.value);

    setupRideCheckboxes = (
        showBookmarkedOnly = this.state.showBookmarkedOnly,
        resetCountOnly = false
    ) => {
        const rideCheckboxData = getRideCheckboxData(this.props.rideTags);
        // rideCheckboxData is used to match rideDetailType
        this.setState({
            rideCheckboxData: rideCheckboxData,
            showBookmarkedOnly: showBookmarkedOnly
        });
        const rides = this.modifiedSelectedRides(showBookmarkedOnly);

        let ridesCheckboxCounts = countByKey(rides, 'subType', '', 'CURATED');

        // consolidate combined checkbox counts
        if (!!ridesCheckboxCounts) {
            rideCheckboxData.forEach((item) => {
                if (
                    item.hideDisplay &&
                    typeof item.group !== 'string' &&
                    item.group[0]
                ) {
                    const prop = item.group[0];
                    ridesCheckboxCounts[prop] =
                        (ridesCheckboxCounts[prop] || 0) +
                        (ridesCheckboxCounts[item.value] || 0);
                }
            });
        }
        const selectedRideTypes =
            getLocalStore('selectedRideTypes') ||
            rideCheckboxData.map((r) => r.value);
        if (!!resetCountOnly)
            return this.resetRidesCheckboxCounts(ridesCheckboxCounts);
        setLocalStore('selectedRideTypes', selectedRideTypes);
        const rideCheckboxGroups = checkBoxGroupConfig({
            data: rideCheckboxData,
            selectedCheckboxes: selectedRideTypes,
            countByType: ridesCheckboxCounts,
            onClickFunc: this.getFilteredRides,
            customOnClick: this.toggleCuratedRides,
            onExpand: this.onExpandCheckBoxGroups,
            parentLabel: t('My Rides'),
            opts: { filters: { [t('My Rides')]: this.myRidesToggle } },
            groupLabels: [
                {
                    label: t('My Rides'),
                    collection: this.getRideTypes(rideCheckboxData, 'CUSTOM')
                },
                {
                    label: t('Recommended'),
                    collection: this.getRideTypes(rideCheckboxData, 'CURATED')
                }
            ]
        });
        this.setState({ rideCheckboxGroups: rideCheckboxGroups });
        return rideCheckboxGroups;
    };

    setupEventCheckboxes = () => {
        const data = getEventTypes();
        const events = this.props.eventBookmarks;
        const eventsFilterKey = 'eventActivities[0].eventActivityTypeList[0]';
        const countByType = countByKey(events, eventsFilterKey);
        const selectedEventTypes =
            getLocalStore('selectedEventTypes') ||
            data.map((r) => r.eventActivityType);
        setLocalStore('selectedEventTypes', selectedEventTypes);
        const newEventCheckboxGroups = checkBoxGroupConfig({
            data,
            selectedCheckboxes: selectedEventTypes,
            countByType,
            onClickFunc: this.filteredEventBookmarks,
            onExpand: this.onExpandCheckBoxGroups,
            valueKey: 'eventActivityType',
            labelKey: 'eventActivityDescription'
        });
        this.setState({
            eventCheckboxGroups: newEventCheckboxGroups,
            selectedCheckboxes: selectedEventTypes
        });
        return newEventCheckboxGroups;
    };

    closeDeleteModal = () => this.setState({ confirmDelete: null });

    deleteRide = () => {
        this.closeDeleteModal();

        const { id } = this.state.confirmDelete;
        this.props.deleteRide(id).then(() => {
            this.deleteBookmark(id);
        });
    };

    deleteEventBookmark = (eventId) => {
        let newBookmarks = Object.assign(this.props.eventBookmarks);
        const id = newBookmarks.hash[eventId].id;
        const filteredItems = newBookmarks.events.filter(
            (e) => e.eventId !== eventId
        );
        newBookmarks.events = filteredItems;

        let newFilteredBookmarks =
            this.state.filteredEventBookmarks.events.filter(
                (e) => e.eventId !== eventId
            );
        newFilteredBookmarks.events = filteredItems;

        this.setState({
            eventBookmarks: newBookmarks,
            filteredEventBookmarks: newFilteredBookmarks
        });
        this.props.removeEventBookmark(eventId);
        this.setupEventCheckboxes();
    };

    deleteBookmark = (id) => {
        let newBookmarks = Object.assign(this.props.bookmarks);
        newBookmarks.rides = newBookmarks.rides.filter((e) => e.id !== id);
        this.setState({
            bookmarks: newBookmarks,
            filteredRides: newBookmarks.rides
        });
        this.props.removeBookmark(id);
        this.resetCounts();
    };

    getNumColumns = (mobile, tablet, desktop, fallback = 1) => {
        const { screenSize } = this.context;

        return responsiveToggle(screenSize, fallback, mobile, tablet, desktop);
    };

    getSortList = () => {
        return [
            { label: t('A-Z'), key: ALPHANUMERIC },
            { label: t('Z-A'), key: ALPHANUMERIC_REV },
            { label: t('Last Updated (Newest First)'), key: LAST_UPDATE },
            { label: t('Last Updated (Oldest First)'), key: LAST_UPDATE_REV }
        ];
    };

    getEventSortList = () => {
        return [
            { label: t('A-Z'), key: ALPHANUMERIC },
            { label: t('Z-A'), key: ALPHANUMERIC_REV },
            { label: t('Start Date (Latest First)'), key: START_DATE },
            { label: t('Start Date (Soonest First)'), key: START_DATE_REV }
        ];
    };

    onExpandCheckBoxGroups =
        (checkboxGroups, group, checkboxGroupProp) => (e) => {
            e.preventDefault;
            e.stopPropagation();
            group.expanded = !group.expanded;
            checkboxGroups[checkboxGroupProp] = group;
            this.setState({ checkboxGroups: checkboxGroups });
        };

    filteredEventBookmarks = (eventCheckboxGroups) => {
        const eventBookmarks = this.props.eventBookmarks;
        const filterBy = 'eventActivities[0].eventActivityTypeList[0]';
        const filteredEventBookmarkEvents = this.filteredSortedItems(
            eventBookmarks.events,
            eventCheckboxGroups.selectedCheckboxes,
            filterBy
        );
        setLocalStore(
            'selectedEventTypes',
            eventCheckboxGroups.selectedCheckboxes
        );
        const filteredEventBookmarks = {
            ...eventBookmarks,
            events: filteredEventBookmarkEvents
        };
        this.setState({
            filteredEventBookmarks: filteredEventBookmarks,
            eventCheckboxGroups: eventCheckboxGroups
        });
        return filteredEventBookmarks;
    };

    myRidesToggle = () => (
        <Toggle
            text={`${t('Show Only Saved Rides')} `}
            value={this.state.showBookmarkedOnly}
            onClick={this.showBookMarkedOnClick}
        />
    );

    resetCounts = (showBookmarkedOnly = this.state.showBookmarkedOnly) => {
        const checkboxGroup = this.setupRideCheckboxes(
            showBookmarkedOnly,
            true
        );
        this.getFilteredRides(checkboxGroup, showBookmarkedOnly);
    };

    showBookMarkedOnClick = (e) => {
        const showBookmarkedOnly = !this.state.showBookmarkedOnly;
        this.setState({ showBookmarkedOnly });
        this.resetCounts(showBookmarkedOnly);
    };

    getSelectedCheckboxes = (checkboxGroup, types = []) =>
        checkboxGroup.children.filter(
            (checkbox) => types.includes(checkbox) || checkbox.checked === true
        );

    getCuratedRideTypes = (checkboxGroup) =>
        checkboxGroup.children.filter((ck) => ck.group === 'CURATED');

    getCuratedRideTypeValues = (checkboxGroup) =>
        this.getCuratedRideTypes(checkboxGroup).map((chk) => chk.value);

    setCuratedRideTypes = (checkboxGroup, newCheckedValue) => {
        const newChildren = checkboxGroup.children.map((ck) => {
            if (ck.group === 'CURATED') ck.checked = newCheckedValue;
            return ck;
        });
        checkboxGroup.children = newChildren;
        checkboxGroup.selectedCheckboxes = checkboxGroup.children
            .filter((chk) => chk.checked === true)
            .map((chk) => chk.value);
        return checkboxGroup;
    };

    toggleCuratedRides = (checkboxGroup) => {
        const curatedTypeValues = this.getCuratedRideTypeValues(checkboxGroup);
        const curatedTypesChecked = checkboxGroup.selectedCheckboxes.includes(
            curatedTypeValues[0]
        );
        checkboxGroup = this.setCuratedRideTypes(
            checkboxGroup,
            !curatedTypesChecked
        );
        setLocalStore('selectedRideTypes', checkboxGroup.selectedCheckboxes);
        this.getFilteredRides(checkboxGroup);
    };

    getFilteredRides = (
        rideCheckboxGroups,
        showBookmarkedOnly = this.state.showBookmarkedOnly
    ) => {
        const filterBy = 'subType';
        const allRides = this.getSelectedRides(showBookmarkedOnly);
        const filteredRides = this.filteredSortedItems(
            allRides,
            rideCheckboxGroups.selectedCheckboxes,
            filterBy,
            true
        );
        setLocalStore(
            'selectedRideTypes',
            rideCheckboxGroups.selectedCheckboxes
        );
        if ((rideCheckboxGroups.countByType || {}).total) {
            const emptyState = emptyStateRides(
                rideCheckboxGroups.selectedCheckboxes,
                rideCheckboxGroups.countByType
            );
            this.setState({ ridesEmptyState: emptyState });
        }
        this.setState({ filteredRides, rideCheckboxGroups });
        return filteredRides;
    };

    // SIDEBAR NAVIGATION
    onClickNavigation = (selectedNavigation) => {
        if (selectedNavigation === 'events') {
            const eventCheckboxGroups = this.setupEventCheckboxes();
            this.filteredEventBookmarks(eventCheckboxGroups);
        } else if (selectedNavigation === 'rides') {
            const rideCheckboxGroups = this.setupRideCheckboxes();
            const filteredRides = this.getFilteredRides(rideCheckboxGroups);
            if (!filteredRides) {
                this.setupRideCheckboxes();
                this.setState({ filteredRides: this.getAllRides() });
            }
        }
        this.setState({ selectedNavigation });
    };

    getTagsFromObject = (obj) => obj.tags.map((t) => t.value);

    filteredSortedItems = (
        items = [],
        filterArray,
        key = '',
        includeTags = false
    ) => {
        if (filterArray === undefined) return items;
        const keys = splitKey(key);
        const { userId } = this.props;

        const itemsMatchedOnKeys = items.filter((obj) => {
            const rideType =
                obj?.userId !== userId && obj.type === 'CUSTOM'
                    ? 'SHARED'
                    : obj?.subType;
            const propValue = getDeepPropertyValueFromObj(obj, keys);
            return filterArray.includes(rideType || propValue);
        });

        if (!includeTags) return itemsMatchedOnKeys;
        const itemsMatchedOnTags = items.filter((obj) => {
            if (!obj.tags) return false;
            const objTags = this.getTagsFromObject(obj);
            return filterArray.filter((x) => objTags.includes(x)).length > 0;
        });
        const set = new Set([...itemsMatchedOnKeys, ...itemsMatchedOnTags]);
        return [...set];
    };

    onRequestToggleSidebar = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    };

    render() {
        const { className, setSortKey, userId } = this.props;

        const {
            confirmDelete,
            selectedNavigation,
            eventCheckboxGroups,
            filteredEventBookmarks,
            filteredRides,
            rideCheckboxGroups,
            rideCheckboxData,
            showBookmarkedOnly,
            isOpen,
            isLoading,
            ridesEmptyState
        } = this.state;

        const navigationItems = SavedView.getNavigationItems();

        const { isMobile } = this.context;

        const cn = classNames(style.Saved, {
            className
        });
        const checkBoxGroups = {
            rides: rideCheckboxGroups,
            events: eventCheckboxGroups
        };
        const onDelete = (item) => this.setState({ confirmDelete: item });
        return (
            <div className={cn}>
                <Helmet>
                    <title>{t('Rides')}</title>
                </Helmet>

                <ConfirmModal
                    isOpen={!!confirmDelete}
                    withBackground
                    title={t('Delete Ride')}
                    subtitle={t('deleteMessage', {
                        name: (confirmDelete || {}).name
                    })}
                    positiveText={t('Nevermind')}
                    onPositive={this.closeDeleteModal}
                    negativeText={t('Yes, delete it')}
                    onNegative={this.deleteRide}
                    onRequestClose={this.closeDeleteModal}
                />

                <SidebarFilter
                    isOpen={!isMobile || isOpen}
                    onClickNavigation={this.onClickNavigation}
                    selectedNavigation={selectedNavigation}
                    navigationItems={navigationItems}
                    checkboxGroups={checkBoxGroups}
                    showBookMarkedOnClick={this.showBookMarkedOnClick}
                    showBookmarkedOnly={showBookmarkedOnly}
                    isMobile={isMobile}
                    label={selectedNavigation}
                    style={{
                        overlay: {
                            width: isMobile ? '100%' : '364px'
                        }
                    }}
                />

                <div className={style.content}>
                    <div
                        style={{
                            width: 100 + '%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    />
                </div>
                {isMobile && (
                    <div>
                        <SidebarHeader
                            onClick={this.onClickNavigation}
                            selectedNavigation={selectedNavigation}
                            navigationItems={navigationItems}
                        />
                    </div>
                )}
                <div className={style.section} ref={this.$content}>
                    {selectedNavigation === 'events' &&
                        filteredEventBookmarks &&
                        !!filteredEventBookmarks.events && (
                            <DisplayRows
                                label={t('Events')}
                                labelKey="events"
                                Item={Event}
                                items={filteredEventBookmarks}
                                title={t('Saved Events')}
                                deleteEventBookmark={this.deleteEventBookmark}
                                itemCount={filteredEventBookmarks.events.length}
                                sorts={this.getEventSortList()}
                                sortMap={eventsSortMap}
                                sortKey={START_DATE_REV}
                                onRequestToggleSidebar={
                                    this.onRequestToggleSidebar
                                }
                                onSortChange={(sort) =>
                                    setSortKey('eventBookmarks', sort.key)
                                }
                                nullStateData={{
                                    title: t(
                                        'You Do Not Have Any Events Saved Yet.'
                                    ),
                                    links: [
                                        {
                                            subtitle: t(
                                                "Your favorite events are saved here. See what's upcoming."
                                            ),
                                            cta: {
                                                text: t('view events'),
                                                href: Routes.MAP_EVENTS
                                            }
                                        }
                                    ]
                                }}
                                isLoading={
                                    this.props.eventBookmarks.loading ||
                                    isLoading
                                }
                            />
                        )}
                    {selectedNavigation === 'rides' && !!filteredRides && (
                        <DisplayRows
                            label={t('Rides')}
                            labelKey="rides"
                            Item={Ride}
                            items={filteredRides}
                            itemCount={filteredRides.length}
                            sorts={this.getSortList()}
                            sortMap={ridesSortMap}
                            sortKey={LAST_UPDATE}
                            title={t('Rides')}
                            rideCheckboxData={rideCheckboxData}
                            onDelete={onDelete}
                            deleteBookmark={this.deleteBookmark}
                            currentuserid={userId}
                            nullStateData={ridesEmptyState}
                            scrollElement={this.$content}
                            isLoading={
                                this.props.eventBookmarks.loading || isLoading
                            }
                        />
                    )}
                </div>
            </div>
        );
    }
}

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        custom: state.rides.custom.data,
        bookmarks: state.bookmarks,
        bookmarkCount: bookmarkedRides(state).length,
        eventBookmarks: state.eventBookmarks,
        bookmarkEventCount: bookmarkedEvents(state).length,
        rideSort: state.user.saved.sort,
        rideTags: state.map.rideTags,
        userId: currentUserId(state)
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch) => {
    return {
        commitChanges: () => dispatch(filterUnbookmarked()),
        deleteRide: (id) => dispatch(deleteCustomRide(id)),
        removeBookmark: (id) => {
            dispatch(setBookmark(id, false));
        },
        removeEventBookmark: (id) => {
            dispatch(setEventBookmark(id, false));
        },
        fetchCustomRides: () => dispatch(fetchCustomRides()),
        fetchEvents: () => dispatch(fetchEvents()),
        fetchEventBookmarks: () => dispatch(fetchEventBookmarks()),
        fetchBookmarks: () => dispatch(fetchBookmarks()),
        setSortKey: (key, value) => dispatch(setSortKey(key, value))
    };
};

SavedView.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(SavedView);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
