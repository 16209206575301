import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
/**
 * Source: https://raw.githubusercontent.com/jasonsalzman/react-add-to-calendar/master/src/helpers/index.js
 */

dayjs.extend(utc);
dayjs.extend(duration);

export default {
    getRandomKey() {
        let n = Math.floor(Math.random() * 999999999999).toString();
        return new Date().getTime().toString() + '_' + n;
    },

    formatTime(date) {
        let formattedDate = dayjs.utc(date).format('YYYYMMDDTHHmmssZ');
        return formattedDate.replace('+00:00', 'Z');
    },
    formatOutlookTime(date) {
        let formattedDate = dayjs(date).format('YYYY-MM-DDTHH:mm:ss');
        return formattedDate;
    },
    calculateDuration(startTime, endTime) {
        // snag parameters and format properly in UTC

        let end = dayjs.utc(endTime).format('DD/MM/YYYY HH:mm:ss');
        let start = dayjs.utc(startTime).format('DD/MM/YYYY HH:mm:ss');

        // calculate the difference in milliseconds between the start and end times
        let difference = dayjs(end, 'DD/MM/YYYY HH:mm:ss').diff(
            dayjs(start, 'DD/MM/YYYY HH:mm:ss')
        );

        // convert difference from above to a proper duration object
        let duration = dayjs.duration(difference);

        return (
            Math.floor(duration.asHours()) + dayjs.utc(difference).format(':mm')
        );
    },

    buildUrl(event, type) {
        let calendarUrl;
        switch (type) {
            case 'google': {
                calendarUrl = 'https://calendar.google.com/calendar/render';
                calendarUrl += '?action=TEMPLATE';
                calendarUrl += '&dates=' + this.formatTime(event.startTime);
                calendarUrl += '/' + this.formatTime(event.endTime);
                calendarUrl +=
                    '&location=' + encodeURIComponent(event.location);
                calendarUrl += '&text=' + encodeURIComponent(event.title);
                calendarUrl +=
                    '&details=' + encodeURIComponent(event.description);
                if (event.timezone)
                    calendarUrl += '&ctz=' + encodeURIComponent(event.timezone);
                calendarUrl +=
                    '&sprop=website:' + encodeURIComponent(event.url);
                break;
            }
            case 'yahoo': {
                // yahoo doesn't utilize endTime so we need to calulate duration
                let duration = this.calculateDuration(
                    event.startTime,
                    event.endTime
                );
                calendarUrl = 'https://calendar.yahoo.com/?v=60&view=d&type=20';
                calendarUrl += '&title=' + encodeURIComponent(event.title);
                calendarUrl += '&st=' + this.formatTime(event.startTime);
                calendarUrl += '&dur=' + duration;
                calendarUrl += '&desc=' + encodeURIComponent(event.description);
                calendarUrl += '&in_loc=' + encodeURIComponent(event.location);
                calendarUrl += '&url=' + encodeURIComponent(event.url);
                break;
            }
            case 'outlook.com': {
                calendarUrl = `https://outlook.office.com/calendar/action/compose/?rru=addevent?&subject=${encodeURIComponent(
                    event.title
                )}&location=${encodeURIComponent(event.location)}
                &body=${encodeURIComponent(
                    event.description
                )}&startdt=${encodeURIComponent(
                    this.formatOutlookTime(event.startTime)
                )}&enddt=${encodeURIComponent(
                    this.formatOutlookTime(event.endTime)
                )}`;
                break;
            }
            default: {
                let calendarData = [
                    'BEGIN:VCALENDAR',
                    'PRODID:-//Harley-Davidson//Events 1.0//EN',
                    'VERSION:2.0',
                    'BEGIN:VEVENT',
                    'URL:' + event.url,
                    'DTSTART:' + this.formatTime(event.startTime),
                    'DTEND:' + this.formatTime(event.endTime),
                    'SUMMARY:' + event.title,
                    'DESCRIPTION:' + event.description,
                    'LOCATION:' + event.location,
                    'TIMEZONE:' + event.timezone,
                    'END:VEVENT',
                    'END:VCALENDAR'
                ]
                    .filter((line) => line && line.split(':')[1].length > 0)
                    .join('\n');

                let data = new File([calendarData], { type: 'text/plain' });
                calendarUrl = window.URL.createObjectURL(data);
            }
        }

        return calendarUrl;
    }
};
