/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <path
                    fillOpacity="0.4"
                    fillRule="nonzero"
                    d="M20 17c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8v-3zm0 3v-3c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8z"></path>
                <path
                    fillRule="nonzero"
                    d="M14.8 25.86l-.1-.1v-.948l.08-.098c.902-.179 1.25-.404 1.657-.938.012-.015.14-.185.259-.276h.948l.1.1v8.784l-.1.1h-1.512l-.1-.1V25.86H14.8zm4.154 3.644V26.6c0-1.934 1.106-3.1 3.004-3.1s3.004 1.166 3.004 3.1v2.904c0 1.934-1.106 3.1-3.004 3.1s-3.004-1.166-3.004-3.1zm1.784-2.724v2.544c0 1.284.338 1.712 1.22 1.712.882 0 1.22-.428 1.22-1.712V26.78c0-1.284-.338-1.712-1.22-1.712-.882 0-1.22.428-1.22 1.712z"></path>
                <path
                    fillRule="nonzero"
                    d="M20 17c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8v-3zm0 3v-3c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8z"></path>
                <path d="M18.5 11h3v9H20a1.5 1.5 0 01-1.5-1.5V11zM20 36h1.5v9h-3v-7.5A1.5 1.5 0 0120 36z"></path>
                <path d="M13.5 12L20 3l6.5 9z"></path>
            </g>
        </svg>
    );
}

export default Icon;
