/******************************************************************************\
 * File: User.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class User extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [style['no-stroke']]: true,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 21 24" onClick={onClick}>
                <g fill="#FFF" fillRule="evenodd">
                    <path d="M16.319 9.678c0 .031-.008.06-.02.087-.236.842-2.818 1.506-5.97 1.506-3.152 0-5.733-.664-5.97-1.506a.207.207 0 0 1-.019-.087V5.345c0-.12.104-.219.231-.219h11.516c.128 0 .232.098.232.219v4.333zM10.358 0c-7.43 0-7.43 6.632-7.43 10.659l.002.054c.004.257.19.497.252.721.324 1.484 1.526 4.582 7.176 4.582s6.85-3.098 7.175-4.582c.062-.224.248-.464.253-.721l.001-.054c0-4.027 0-10.659-7.43-10.659zM21 23.933c0-5.446-2.764-6.877-6.301-7.654-.352 1.19-2.096 2.095-4.199 2.1-2.103-.005-3.847-.91-4.199-2.1C2.764 17.056 0 18.487 0 23.933l10.481.035h.038L21 23.933z" />
                </g>
            </svg>
        );
    }
}
export default User;
