/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <path
                    d="M14.8 12.715h3.526v24.728H14.8zm-5.8 0h3.523v24.728H9z"
                    opacity="0.4"></path>
                <path
                    fillRule="nonzero"
                    d="M23.784 16.124l-4.46-5.304 13.118.114-1.898 13.229-4.443-5.374-4.821 4.073a9.226 9.226 0 00-2.937 7.321l.008 7.252h-3.527v-6.99a12.82 12.82 0 014.128-10.238l4.832-4.083z"></path>
            </g>
        </svg>
    );
}

export default Icon;
