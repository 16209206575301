/******************************************************************************\
 * File: MCP.js
 *
 * Author: Gigster
 *
 * Description: Master Control Program
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { unique, arrDelete, arrSub } from '@/helpers/functions';
//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
const log = createLogger('MCP', false);

class MCP {
    constructor() {
        this.nonce = 0;
        this.jobs = {};
        this.seenDependencies = [];
        this.events = {};
    }

    getNonce() {
        return this.nonce++;
    }

    addJob(dependencies, job, timeless = false) {
        const filteredDeps = timeless
            ? arrSub(unique(dependencies), this.seenDependencies)
            : dependencies;

        if (filteredDeps.length === 0) {
            job();
            return;
        }

        this.jobs = {
            ...this.jobs,
            [this.getNonce()]: {
                dependencies: filteredDeps,
                job
            }
        };
    }

    removeJob(id) {
        //eslint-disable-next-line
        const { [id]: item, ...rest } = this.jobs;
        this.jobs = rest;
    }

    handleDependency(dependency) {
        log(`Handling ${dependency}`);

        this.seenDependencies = unique([...this.seenDependencies, dependency]);

        const jobKeys = Object.keys(this.jobs);

        const reducedJobs = jobKeys.reduce((acc, val) => {
            const job = this.jobs[val];

            const nextJob = {
                ...job,
                dependencies: arrDelete(job.dependencies, dependency)
            };

            if (nextJob.dependencies.length === 0) {
                nextJob.job();
                return acc;
            }

            return {
                ...acc,
                [val]: nextJob
            };
        }, {});

        const prevJobsLength = jobKeys.length;

        this.jobs = {
            ...reducedJobs,
            ...Object.keys(this.jobs)
                .slice(prevJobsLength)
                .reduce((acc, k) => ({ ...acc, [k]: this.jobs[k] }), {})
        };
    }

    on(eventName, handler) {
        this.events[eventName] = this.events[eventName] || [];
        this.events[eventName].push(handler);
        return { eventName, handler };
    }

    once(eventName, handler) {
        const self = this;
        this.on(eventName, function wrappedHandler(...args) {
            self.off(eventName, wrappedHandler);
            handler(...args);
        });
    }

    off(eventName, handler) {
        if (!this.events[eventName]) return;
        this.events[eventName] = arrSub(this.events[eventName], [handler]);
    }

    dispatch(eventName, args = []) {
        const handlers = this.events[eventName];
        if (handlers && handlers.length) {
            handlers.forEach((handler) => handler(...args));
        }
    }
}

const thing = new MCP();

if (process.env.NODE_ENV === 'development') {
    window.MCP = thing;
}

export default thing;

export const mcpMiddleware = (mcp) => () => (next) => (action) => {
    const result = next(action);

    if (action.type) mcp.handleDependency(action.type);

    return result;
};
