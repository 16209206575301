/******************************************************************************\
 * File: UserLink.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import { setConfirm, canSaveRide, clearRide } from '@/store/edit_ride';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/header/UserLink.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import User from '@/components/common/icons/User';
import Angle from '@/components/common/icons/Angle';
import Link from '@/components/common/header/Link';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { Routes } from '@/helpers/routes';
import { truncate } from '@/helpers/functions';
import { translate } from '@/helpers/i18n';
import withRouter from '@/helpers/hooks';
const t = translate('header.UserLink');
import packageJson from '@/../package.json';
// Hooks
import { useOutsideClick } from '@/hooks/useOutsideClick';
//------------------------------------------------------------------------------
// React Function -----------------------------------------------------------------
export const UserLink = (props) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const onClickAway = () => setShowDropdown(false);
    const ref = useOutsideClick(onClickAway);

    const {
        className,
        name,
        responsiveText,
        isAuthenticated,
        inline,
        active,
        onLink,
        onSignIn,
        onLogout,
        rideMeta,
        setConfirm,
        canSaveRide,
        clearRide,
        openSettingsModal,
        MiniArrowUpIcon,
        navigate
    } = props;

    const cn = classNames(style.UserLink, {
        [className]: className,
        [style['active']]: active,
        [style['responsive-text']]: responsiveText,
        [style['inline']]: inline
    });

    const checkConfirm = () =>
        showDropdown && isAuthenticated && !rideMeta.persisted && canSaveRide;

    return (
        <div ref={ref} className={cn}>
            <div
                className={style.top}
                onClick={() => {
                    isAuthenticated
                        ? setShowDropdown(
                              (prevShowDropdown) => !prevShowDropdown
                          )
                        : onSignIn();
                }}
                aria-haspopup="true"
                aria-label={t('Profile')}>
                <User className={style.icon} />
                <div className={style.text}>
                    {isAuthenticated
                        ? t('greeting', {
                              name: truncate(name, 16),
                              t: 'Hi, {name}'
                          })
                        : t('Sign In')}
                </div>
                {isAuthenticated && (
                    <Angle
                        className={style.angle}
                        direction={showDropdown ? 'up' : 'down'}
                    />
                )}
            </div>
            {inline && showDropdown && (
                <div className={style.inlineList}>
                    <Link
                        to="https://www.harley-davidson.com/profile"
                        target="_blank"
                        role="menuitem"
                        className={style.link}>
                        {t('My Account')}
                        <MiniArrowUpIcon className={style.smallIcon} />
                    </Link>

                    <Link
                        role="menuitem"
                        className={style.link}
                        onClick={() => {
                            if (checkConfirm()) {
                                setConfirm('confirm');
                            } else {
                                onLogout();
                                showDropdown(false);
                            }
                        }}>
                        {t('Logout')}
                    </Link>
                </div>
            )}
            {!inline && (
                <div
                    className={classNames(style.dropdown, {
                        [style['show']]: showDropdown
                    })}
                    role="menubar"
                    aria-hidden={!showDropdown}>
                    <div className={style.wrapper}>
                        <svg className={style.triangle} viewBox="0 0 16 8">
                            <polygon points="0,8 8,0 16,8" />
                        </svg>
                    </div>
                    <div className={style.list}>
                        <a
                            className={style.item}
                            href="https://www.harley-davidson.com/profile"
                            target="_blank">
                            {t('My Account')}
                            <MiniArrowUpIcon />
                        </a>
                        <span
                            className={style.item}
                            role="menuitem"
                            onClick={() => {
                                openSettingsModal();
                                setShowDropdown(false);
                            }}>
                            {t('Settings')}
                        </span>

                        <Link
                            className={style.item}
                            to={checkConfirm() ? null : Routes.SAVED}
                            role="menuitem"
                            onClick={() => {
                                clearRide();
                                if (checkConfirm()) {
                                    setConfirm('confirm').then(() => {
                                        navigate(Routes.SAVED);
                                        setShowDropdown(false);
                                    });
                                } else {
                                    setShowDropdown(false);
                                }
                            }}>
                            {t('Saved')}
                        </Link>

                        <Link
                            className={style.item}
                            role="menuitem"
                            onClick={() => {
                                if (checkConfirm()) {
                                    setConfirm('confirm').then(() => {
                                        onLogout();
                                        setShowDropdown(false);
                                    });
                                } else {
                                    onLogout();
                                    setShowDropdown(false);
                                }
                            }}>
                            {t('Logout')}
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        rideMeta: state.edit_ride.present.meta,
        canSaveRide: canSaveRide(state)
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setConfirm: (value) => dispatch(setConfirm(value)),
        clearRide: () => dispatch(clearRide())
    };
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(UserLink)
);
