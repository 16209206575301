/******************************************************************************\
* File: Line.jsx
*
* Author: Gigster
*
* Description: Abstracted Line component
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import HereLine from '@/components/common/map/here/AnimLine';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Line extends React.Component {
    render() {
        const { ...props } = this.props;

        return <HereLine {...props} />;
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Line;
