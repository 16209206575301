/******************************************************************************\
 * File: RouteMarker.jsx
 *
 * Author: Gigster
 *
 * Description: Route html marker
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RouteMarker extends React.Component {
    render() {
        const { style, children, highlight } = this.props;

        return (
            <div
                style={{
                    ...style,
                    height: '40px',
                    width: '37px',
                    margin: '-40px 0 0 -19px'
                }}
            >
                <svg
                    viewBox="0 0 30 43"
                    style={{ height: '100%', width: '100%' }}
                >
                    <path
                        fill="#F60"
                        fillRule="evenodd"
                        d="M25.612 4.41c-5.827-5.857-15.3-5.884-21.16-.058l-.06.059C-.852 9.676-1.502 19.575 2.999 25.576L15 43l12.002-17.4c4.501-6 3.851-15.924-1.395-21.19h.005z"
                    />
                    {typeof children === 'string' ||
                    typeof children === 'number' ? (
                        <text
                            x="15"
                            y="18"
                            textAnchor="middle"
                            alignmentBaseline="central"
                            fill={highlight ? '#fff' : '#000'}
                            style={{
                                fontFamily: "'Noto Sans', sans-serif",
                                fontSize: 16,
                                fontWeight: 'bold',
                                letterSpacing: 0.2
                            }}
                        >
                            {children}
                        </text>
                    ) : (
                        children
                    )}
                </svg>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default RouteMarker;
