/******************************************************************************\
 * File: OneWay.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class OneWay extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 8 15" onClick={onClick}>
                <path
                    d="m4 13.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm1-9.5v3.1a6.21 6.21 0 0 0 -2 0v-3.1h-3l4.001-4 3.999 4zm-1 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
                    fill="#fff"
                />
            </svg>
        );
    }
}
export default OneWay;
