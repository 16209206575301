/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <path d="M29.276 9.5h-3.489v28.638h3.489z" opacity="0.4"></path>
                <path
                    fillRule="nonzero"
                    d="M23.644 19.303l.001.644-.001 3.422a11.494 11.494 0 01-5.889 9.96l-8.245 5.14-1.845-2.953 8.238-5.135a8.108 8.108 0 004.263-6.89l-.003-3.545v-.642H13.31L21.904 9.5l8.596 9.803h-6.856z"></path>
                <path
                    d="M23.645 19.294H20.16v18.843h3.485z"
                    opacity="0.4"></path>
            </g>
        </svg>
    );
}

export default Icon;
