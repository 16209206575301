//https://stackoverflow.com/questions/63471931/using-history-with-react-router-dom-v6
/* This customRouter is being used in order to expose the history object to project and be able to use all across the app as it was possible in prev react router versions ( v5 )*/
import React from 'react';
import { Router } from 'react-router-dom';

const CustomRouter = ({ basename, children, history }) => {
    const [state, setState] = React.useState({
        action: history.action,
        location: history.location
    });

    React.useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            basename={basename}
            children={children}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
};
export default CustomRouter;
