/******************************************************************************\
 * File: UserLink.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import { updateRide, updateRouteShape } from '@/store/edit_ride';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/header/UserLink.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import Angle from '@/components/common/icons/Angle';
import Checkmark from '@/components/common/icons/Checkmark';
import { MAX_WAYPOINTS } from '@/helpers/here';
import { createMaxWaypointsError, setError } from '@/store/error';
import Tooltip from '@/components/common/form/Tooltip';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { clickAway } from '@/helpers/hoc';
import { translate } from '@/helpers/i18n';
const t = translate('modal.uploadModal');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RouteTypeDropdown extends React.Component {
    state = {
        showDropdown: false
    };

    onClickAway = () => {
        const { showDropdown } = this.state;

        if (showDropdown) {
            this.setState({ showDropdown: false });
        }
    };
    iconRender = (
        isActive,
        text,
        value,
        icon,
        exceedsMaxWaypoints = false,
        count
    ) => {
        const { onChangeRouteType, isHeader, ride, rideCopy, setError, cb } =
            this.props;
        const inactiveStyle = isHeader
            ? style.inactiveIcon
            : style.inactiveIconGrey;
        const errorMsg = createMaxWaypointsError((count = 0));
        const fullErrMsg = {
            id: 'routing_max_waypoints',
            message: `On-road ${errorMsg.message.toLowerCase()}`
        };
        return (
            <div
                className={classNames(
                    isActive ? style.activeIcon : inactiveStyle,
                    style.icon
                )}>
                <Tooltip value={text} placement="s" noDelay multiline={true}>
                    <a
                        id={`${text}`.toLowerCase()}
                        data-testid={`${text}`.toLowerCase()}
                        onClick={
                            exceedsMaxWaypoints
                                ? () => {
                                      setError(fullErrMsg);
                                  }
                                : () => {
                                      if (!this.state.showDropdown) {
                                          this.setState({ showDropdown: true });
                                          return;
                                      }
                                      if (!!cb) cb(value);
                                      onChangeRouteType(value, ride);
                                      this.setState({ showDropdown: false });
                                  }
                        }
                        className={style.dropdownItem}>
                        <img className={style.icon} src={icon} />
                        <span
                            style={{
                                verticalAlign: 'top',
                                paddingLeft: '12px',
                                flexGrow: 2
                            }}>
                            {text}
                        </span>
                        {isActive && (
                            <span className={style.checkmark}>
                                <Checkmark />
                            </span>
                        )}
                    </a>
                </Tooltip>
            </div>
        );
    };

    waypointCount = ({ locationHistory, waypoints }) =>
        (waypoints || []).length || (locationHistory || []).length;

    render() {
        const { className, ride } = this.props;

        const { showDropdown } = this.state;
        const waypointCount = this.waypointCount(ride);
        const waypointCountExceeded = waypointCount > MAX_WAYPOINTS;
        const onRoadImg = !waypointCountExceeded
            ? '/img/icon-route-type-on-road.svg'
            : '/img/icon-route-type-on-road-disabled.svg';
        const offRoadImg = '/img/icon-route-type-off-road.svg';
        const cn = classNames(style.RouteType, {
            [className]: className,
            [style['active']]: true,
            [style['routeType']]: true,
            [style['responsive-text']]: true
        });

        return (
            <span className={cn}>
                <span
                    id="route-type"
                    data-testid="route-type"
                    className={style.top}
                    onClick={() => {
                        this.setState({ showDropdown: !showDropdown });
                    }}
                    aria-haspopup="true"
                    aria-label={t('Route Type')}>
                    <span
                        className={classNames(style.text, style.routeTypeIcon)}>
                        <img src={!ride.offRoad ? onRoadImg : offRoadImg} />
                        <Angle
                            className={classNames(
                                style.angle,
                                style.routeTypeAngle
                            )}
                            direction={showDropdown ? 'up' : 'down'}
                        />
                    </span>
                </span>
                {showDropdown && (
                    <div
                        className={classNames(style.dropdown, {
                            [style['show']]: showDropdown
                        })}
                        role="menubar"
                        aria-hidden={!showDropdown}>
                        <div className={style.wrapper}>
                            <svg
                                className={style.triangleBorder}
                                viewBox="0 0 16 8">
                                <polygon points="0,8 8,0 16,8" />
                                <svg
                                    className={style.triangleBorderInside}
                                    viewBox="0 0 16 8">
                                    <polygon points="1,8, 8,1 15,8" />
                                </svg>
                            </svg>
                        </div>
                        <div className={style.routeTypeDropdown}>
                            <span className={style.routeTypeDropdownTitle}>
                                {t('Route Type')}
                            </span>
                            {this.iconRender(
                                !ride.offRoad,
                                t('On-road'),
                                null,
                                onRoadImg,
                                waypointCountExceeded,
                                waypointCount
                            )}
                            {this.iconRender(
                                ride.offRoad,
                                t('Off-road'),
                                true,
                                offRoadImg,
                                waypointCountExceeded,
                                waypointCount
                            )}
                        </div>
                    </div>
                )}
            </span>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        ride: state.edit_ride.present.ride
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onChangeRouteType: (isOffroad, ride) => {
            dispatch(updateRide('offRoad', isOffroad));
            dispatch(
                updateRouteShape((ride || {}).id, {
                    isOffroad: !!isOffroad
                })
            );
        }
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(
    mapStateToProps,
    mapDispatchToProps
)(RouteTypeDropdown);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default clickAway(container);
