/******************************************************************************\
 * File: EventSidebar.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Sidebar from '@/components/common/Sidebar';
import EventDetails from './EventDetails';
import ArrowRight from '@/components/common/icons/ArrowRight';
import style from '@/style/mapListView/sidebar/EventSidebar.scss';
import { translate } from '@/helpers/i18n';
const t = translate('sidebar.EventDetails');

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class EventSidebar extends React.Component {
    backLink = (onClick, text) => (
        <a onClick={onClick} className={style.arrow}>
            <span>
                <ArrowRight className={style.leftArrow} left={true} />
                <span className={style.arrowLink}>{text}</span>
            </span>
        </a>
    );

    render() {
        const { eventName: name } = this.props;
        const isDealer = window.location.search.includes('isDealer');
        return (
            <Sidebar
                contentLabel={name || 'Harley-Davidson Event'}
                {...this.props}
            >
                <EventDetails {...this.props} />
            </Sidebar>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default EventSidebar;
