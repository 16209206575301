/******************************************************************************\
* File: Sidebar.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import ReactModal from 'react-modal';
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#app');
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Link from '@/components/common/header/Link';
import Close from '@/components/common/icons/Close';
import { escapable } from '@/helpers/hoc';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/Sidebar.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Sidebar extends React.Component {
    render() {
        const { props } = this;
        const { className, underNavigation, topOffset, paddingBottom, highZ } =
            props;
        const cn = classNames(style.content, {
            className: !!className
        });
        const baseClassNames = !underNavigation
            ? topOffset
                ? classNames([style.overlay, style.topOffset])
                : style.overlay
            : highZ
            ? classNames([style.overlay, style.highZ])
            : classNames([style.overlay, style.oneZIndex, style.topOffset]);
        return (
            <ReactModal
                overlayClassName={{
                    base: baseClassNames,
                    afterOpen: style.afterOpen,
                    beforeClose: style.beforeClose
                }}
                className={cn}
                shouldCloseOnOverlayClick={false}
                closeTimeoutMS={200}
                {...props}>
                {!!this.props.onRequestClose && (
                    <Link
                        className={style.Close}
                        onClick={this.props.onRequestClose}>
                        <Close />
                    </Link>
                )}
                <div
                    className={classNames(style.children, {
                        [style.paddingBottom]: !!paddingBottom
                    })}>
                    {props.children}
                </div>
            </ReactModal>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default escapable(Sidebar);
