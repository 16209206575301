/******************************************************************************\
* File: Select.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/cards/Select.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Angle from '@/components/common/icons/Angle';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { clickAway } from '@/helpers/hoc';
//------------------------------------------------------------------------------
// Select Function --------------------------------------------------------------
const Select = ({ className, selected, options, onSelect, hasId = false }) => {
    const [show, setShow] = useState(false);

    const filteredOptions = useMemo(() => {
        return options.filter((option) => option.key !== selected.key);
    }, [options, selected]);

    const onSelectWrapper = (option) => {
        onSelect(option);
        setShow(false);
    };

    const cn = classNames(style.Select, {
        [className]: className
    });

    const id = hasId
        ? `${('label-' + selected.label).toLowerCase()}`
        : undefined;
    const dataId = hasId
        ? `${('label-' + selected.label).toLowerCase()}`
        : undefined;
    return (
        <div className={cn}>
            <div
                className={style.label}
                onClick={() => setShow(!show)}
                id={id}
                data-testid={dataId}>
                {selected.label}
                <Angle
                    className={style.angle}
                    direction={show ? 'up' : 'down'}
                />
            </div>
            <div
                className={classNames(style.dropdown, {
                    [style['show']]: show
                })}>
                <div className={style.wrapper}>
                    <svg className={style.triangle} viewBox="0 0 16 8">
                        <polygon points="0,8 8,0 16,8" />
                    </svg>
                </div>
                <div className={style.list}>
                    {filteredOptions.map((option) => (
                        <div
                            className={style.item}
                            onClick={() => onSelectWrapper(option)}
                            key={option.key}
                            id={hasId ? `${option.key}` : undefined}
                            data-testid={hasId ? `${option.key}` : undefined}>
                            {option.label}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default clickAway(Select);
