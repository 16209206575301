import dayjs from 'dayjs';
import {
    get as getLocalStore,
    set as setLocalStore
} from '@/helpers/persistor';

const name = (r1, r2) => {
    return r1.name.toLowerCase() > r2.name.toLowerCase()
        ? 1
        : r1.name.toLowerCase() === r2.name.toLowerCase()
          ? 0
          : -1;
};

const name_rev = (r1, r2) => {
    return r1.name.toLowerCase() < r2.name.toLowerCase()
        ? 1
        : r1.name.toLowerCase() === r2.name.toLowerCase()
          ? 0
          : -1;
};

const last_update = (r1, r2) => {
    return dayjs(r2.modifiedTime) - dayjs(r1.modifiedTime);
};

const last_update_rev = (r1, r2) => {
    return dayjs(r1.modifiedTime) - dayjs(r2.modifiedTime);
};

const sort = (func) => (rides) => {
    return (rides || []).slice().sort(func);
};

export const ALPHANUMERIC = 'alphanumeric';
export const ALPHANUMERIC_REV = 'alphanumeric_rev';
export const LAST_UPDATE = 'last_update';
export const LAST_UPDATE_REV = 'last_update_rev';

export const sorts = {
    [ALPHANUMERIC]: sort(name),
    [ALPHANUMERIC_REV]: sort(name_rev),
    [LAST_UPDATE]: sort(last_update),
    [LAST_UPDATE_REV]: sort(last_update_rev)
};

export const getFilteredRides = (types, rides, userId) => {
    if (types) setLocalStore('filteredTypes', types);
    const rideTypes = !types ? getLocalStore('filteredTypes') : types;
    return modifiedSelectedRides(rides, userId).filter(
        (r) =>
            rideTypes.includes(r.subType) ||
            rideTypes.includes(r.type) ||
            (r.tags &&
                rideTypes.some((type) =>
                    r.tags.map((t) => t.value).includes(type)
                ))
    );
};

export const rideTypeModifiers = () => ({
    PLANNED: 'SHARED',
    RECORDED: 'SHARED',
    SHARED: 'SHARED'
});

export const modifiedSelectedRides = (rides = [], userId = '') => {
    const isSharedRide = (item) =>
        userId
            ? userId !== item.userId && item.type === 'CUSTOM'
            : item.type === 'CUSTOM';
    const modifyRide = (item) => {
        item.subType = isSharedRide(item)
            ? rideTypeModifiers()[item.subType]
            : item.subType;
        return item;
    };
    return rides.map((ride) => modifyRide(ride));
};
