/******************************************************************************\
 * File: ConfirmModal.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useEffect } from 'react';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Modal, { Title, Subtitle, Button } from '@/components/common/Modal';
//------------------------------------------------------------------------------
// React Function --------------------------------------------------------------
const ConfirmModal = (props) => {
    const {
        title,
        subtitle,
        onPositive,
        positiveText,
        onNegative,
        negativeText,
        children,
        onRequestClose
    } = props;

    useEffect(() => {
        return () => {
            onRequestClose();
        };
    }, []);

    return (
        <Modal {...props} contentLabel={title}>
            <div>
                <Title>{title}</Title>
                {subtitle && (
                    <Subtitle style={{ marginTop: 16, marginBottom: 16 }}>
                        {subtitle}
                    </Subtitle>
                )}
                {!!negativeText && (
                    <Button negative text={negativeText} onClick={onNegative} />
                )}
                {!!positiveText && (
                    <Button positive text={positiveText} onClick={onPositive} />
                )}
                {children}
            </div>
        </Modal>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ConfirmModal;
