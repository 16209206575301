/******************************************************************************\
 * File: checkboxes.js
 *
 * Author: Gigster
 *
 * Description: Checkbox functionality
 *
 * Notes:
 \******************************************************************************/
import { translate } from '@/helpers/i18n';
const t = translate('helpers.checkboxes');
// SIDEBAR FILTER ITEMS
export const resetGroupsChildren = (group) => {
    return group.children.map((item) => {
        item.checked = group.checked;
        return item;
    });
};

export const onClickCheckBox = (func) => (group, itemClicked) => () => {
    if (itemClicked === group) {
        group.checked = !group.checked;
        group.children = resetGroupsChildren(group);
        group.indeterminate = false;
    } else {
        itemClicked.checked = !itemClicked.checked;
        // replace item w modified itemClicked or return item
        const newChildren = group.children.map((item) => {
            if (item.value === itemClicked.value) return itemClicked;
            // items can be grouped and optionally displayed
            // grouped items will be share a checked value
            if (!!item.group && item.group.includes(itemClicked.value))
                item.checked = itemClicked.checked;
            return item;
        });
        const checkedItems = newChildren.filter(
            (item) => item.checked === true
        );
        group.checked = checkedItems.length === group.children.length;
        group.indeterminate =
            0 !== checkedItems.length &&
            checkedItems.length !== group.children.length;
        group.children = newChildren;
    }
    group = checkedCheckBoxes(group);
    func(group);
};

export const selectedCheckboxes = (items) =>
    items.filter((checkBox) => checkBox.checked === true);

export const checkedCheckBoxes = (checkBoxGroup) => {
    const selectedCheckboxes = checkBoxGroup.children.filter(
        (checkBox) => checkBox.checked === true
    );
    checkBoxGroup.selectedCheckboxes = selectedCheckboxes.map(
        (checkBox) => checkBox.value
    );
    return checkBoxGroup;
};

const checkSelectedLength = (selectedCheckboxes, data) =>
    selectedCheckboxes &&
    selectedCheckboxes.length > 0 &&
    selectedCheckboxes.length < data.length;

// datas, valueKey, labelKey, countByType(func), func are required
export const checkBoxGroupConfig = ({
    data,
    valueKey = 'value',
    labelKey = 'label',
    countByType,
    onClickFunc,
    customOnClick,
    onExpand,
    groupLabels = null,
    expanded = true,
    indeterminate = false,
    selectedCheckboxes,
    opts
}) => {
    const parentChecked = selectedCheckboxes.length === data.length;
    const checkBoxGroup = {
        label: t('all'),
        groupLabels,
        children:
            !data.length > 0
                ? []
                : checkboxChildData(
                      data,
                      valueKey,
                      labelKey,
                      selectedCheckboxes
                  ),
        countByType,
        selectedCheckboxes,
        // defaults
        checked:
            parentChecked && !checkSelectedLength(selectedCheckboxes, data),
        expanded: expanded,
        indeterminate:
            indeterminate || checkSelectedLength(selectedCheckboxes, data),
        onExpand: onExpand,
        onClick: onClickCheckBox(onClickFunc),
        customOnClick: onClickCheckBox(customOnClick),
        opts
    };
    return checkBoxGroup;
};

// normalize - return object with value and label keys for each item in data
export const checkboxChildData = (
    data,
    valueKey = 'value',
    labelKey = 'label',
    selectedCheckboxes,
    customCount,
    checked = true
) => {
    return data.map((item) => {
        return {
            value: `${item[valueKey]}`,
            label: item[labelKey],
            checked:
                selectedCheckboxes && !!selectedCheckboxes.length
                    ? selectedCheckboxes.includes(item[valueKey])
                    : !checked,
            hideDisplay: item.hideDisplay,
            customOnClick: item.customOnClick,
            customCount: item.customCount,
            group: item.group
        };
    });
};

// recombines keys dynamically to get deep property values from objects
export const getDeepPropertyValueFromObj = (obj, keys) =>
    keys.reduce((p, c) => (p && p[c]) || null, obj);

// key is split and recombined to access to deep properties
// example: key = 'eventActivities[0].eventActivityType'
export const splitKey = (key) => key.split(/\W/).filter((i) => i !== '');

// Aggregate count of unique items.keys
export const countByKey = (
    items,
    key = '',
    overrideKey = '',
    overrideVal = ''
) => {
    const type = items.events ? 'events' : 'else';
    const keys = splitKey(key);
    const overrideKeys = splitKey(overrideKey);
    const data = type == 'events' ? items.events : items;
    if (data && data.length > 0)
        return data.reduce((totals, obj) => {
            const overrideValue = getDeepPropertyValueFromObj(
                obj,
                overrideKeys
            );
            const propValue =
                overrideValue === overrideVal
                    ? overrideValue
                    : getDeepPropertyValueFromObj(obj, keys);
            if (!!obj.tags && obj.tags.length > 0) {
                totals = obj.tags.reduce((totals, tag) => {
                    if (!tag.visible) return totals;
                    return {
                        ...totals,
                        [tag.value]: (totals[tag.value] || 0) + 1,
                        total: (totals.total || 0) + 1
                    };
                }, totals);
            }
            return {
                ...totals,
                [propValue]: (totals[propValue] || 0) + 1,
                total: (totals.total || 0) + 1
            };
        }, {});
};

export const getRideTypes = (checkboxGroup, type) =>
    checkboxGroup
        .filter((cb) => cb.group === type || cb.group.includes(type))
        .map((cb) => cb.value);

export const initialRidesFilters = [
    'hd-dealer',
    'events',
    'day-rides',
    'RECORDED',
    'SHARED',
    'milwaukee-rides',
    'eaglerider',
    'overnight-rides',
    'short-rides',
    'DEALER',
    'HOG',
    'HD',
    'PLANNED'
];

export const rideTypeData = () => [
    {
        value: 'PLANNED',
        label: t('Planned'),
        group: 'CUSTOM',
        type: t('My Ride'),
        typeIcon: 'icon-helmet.svg',
        hideDisplay: false
    },
    {
        value: 'RECORDED',
        label: t('Recorded'),
        group: 'CUSTOM',
        type: t('My Ride'),
        typeIcon: 'icon-helmet.svg',
        hideDisplay: false
    },
    {
        value: 'SHARED',
        label: t('Shared with me'),
        group: 'CUSTOM',
        type: t('My Ride'),
        typeIcon: 'icon-helmet.svg',
        hideDisplay: false
    },
    {
        value: 'HD',
        label: t('Harley-Davidson'),
        group: 'CURATED',
        type: t('Recommended'),
        typeIcon: 'icon-hd-logo.svg',
        hideDisplay: false
    },
    {
        value: 'CURATED',
        label: t('Harley-Davidson'),
        //group: ['CURATED'],
        type: t('Recommended'),
        typeIcon: 'icon-hd-logo.svg',
        group: ['HD'],
        hideDisplay: true,
        customOnClick: true
    },
    {
        value: 'USA_BYWAYS',
        label: 'USA Byways',
        //group: ['CURATED'],
        type: 'Recommended',
        group: ['HD'],
        typeIcon: 'icon-hd-logo.svg',
        hideDisplay: true
    },
    {
        value: 'DEALER',
        label: t('Dealer'),
        group: 'CURATED',
        type: t('Recommended'),
        typeIcon: 'icon-hd-logo.svg',
        hideDisplay: false
    },
    {
        value: 'HOG',
        label: t('H.O.G.'),
        group: 'CURATED',
        type: t('Recommended'),
        typeIcon: 'icon-hd-logo.svg',
        hideDisplay: false
    },
    {
        value: 'HOG_CHAPTER',
        label: t('H.O.G. Chapter'),
        //group: 'CURATED',
        type: t('Recommended'),
        typeIcon: 'icon-hd-logo.svg',
        group: ['HOG'],
        hideDisplay: true
    },
    {
        value: 'short-rides',
        label: t('Short Rides'),
        group: 'CURATED',
        type: t('Recommended'),
        typeIcon: 'icon-hd-logo.svg',
        hideDisplay: false
    },
    {
        value: 'day-rides',
        label: t('Day Rides'),
        group: 'CURATED',
        type: t('Recommended'),
        typeIcon: 'icon-hd-logo.svg',
        hideDisplay: false
    },
    {
        value: 'overnight-rides',
        label: t('Overnight Rides'),
        group: 'CURATED',
        type: t('Recommended'),
        typeIcon: 'icon-hd-logo.svg',
        hideDisplay: false
    },
    {
        value: 'hd-dealer',
        label: t('H-D Dealership'),
        group: 'POI',
        type: t('Points of interest'),
        typeIcon: 'icon-hd-logo.svg',
        customCount: 'stateDealersCount',
        hideDisplay: false
    },
    {
        value: 'events',
        label: t('Events'),
        group: 'POI',
        type: t('Points of interest'),
        typeIcon: 'icon-hd-logo.svg',
        customCount: 'stateEventsCount',
        hideDisplay: false
    }
];

// Hardcoded values, tags from api, plus custom sorting..
export const getRideCheckboxData = (
    rideTags = [],
    additionalHardcodedTags = ['short-rides', 'day-rides', 'overnight-rides']
) => [
    ...rideTypeData(),
    ...rideTags
        .filter(
            (r) => !!r.visible && !additionalHardcodedTags.includes(r.value)
        )
        .map((rt) => {
            return {
                value: rt.value,
                label: t(rt.displayText),
                group: 'CURATED',
                type: t('Recommended'),
                typeIcon: 'icon-hd-logo.svg',
                isTag: true,
                hideDisplay: !rt.visible
            };
        })
        .sort((a, b) => (a.label > b.label ? 1 : -1))
];

export const getEventTypes = () => [
    { eventActivityType: 'DDEMO', eventActivityDescription: t('Dealer Demos') },
    { eventActivityType: 'DEALR', eventActivityDescription: t('Dealer Event') },
    { eventActivityType: 'DRAG', eventActivityDescription: t('Drag Races') },
    {
        eventActivityType: 'FLAT',
        eventActivityDescription: t('Flat Track Race')
    },
    { eventActivityType: 'DEMOS', eventActivityDescription: t('H-D Demos') },
    {
        eventActivityType: 'CHPTR',
        eventActivityDescription: t('H.O.G. Chapter')
    },
    { eventActivityType: 'HGME', eventActivityDescription: t('H.O.G. Events') },
    {
        eventActivityType: 'IMS',
        eventActivityDescription: t('International Motorcycle Show')
    },
    { eventActivityType: 'MUS', eventActivityDescription: t('Museum') },
    { eventActivityType: 'OPHSE', eventActivityDescription: t('Open Houses') },
    { eventActivityType: 'LACON', eventActivityDescription: t('Laconia') },
    {
        eventActivityType: 'MUSIC',
        eventActivityDescription: t('Music Festival')
    },
    { eventActivityType: 'ZDAYT', eventActivityDescription: t('zDaytona') },
    {
        eventActivityType: 'HOMER',
        eventActivityDescription: t('Hometown Rally')
    },
    {
        eventActivityType: 'BBQ',
        eventActivityDescription: t('Bikes Blues & BBQ')
    },
    { eventActivityType: 'STURG', eventActivityDescription: t('Sturgis') },
    { eventActivityType: 'FUEL', eventActivityDescription: t('Fuel Good') },
    { eventActivityType: 'RAL', eventActivityDescription: t('Rallies') },
    {
        eventActivityType: 'MERCH',
        eventActivityDescription: t('Harley-Davidson Merchandise')
    },
    {
        eventActivityType: 'FOOD',
        eventActivityDescription: t('Food & Beverage')
    },
    {
        eventActivityType: 'ENTERTAINMENT',
        eventActivityDescription: t('Entertainment')
    },
    {
        eventActivityType: 'MEMBERSHIP',
        eventActivityDescription: t('H-D Membership')
    },
    { eventActivityType: 'BIKESHOW', eventActivityDescription: t('Bike Shows') }
];
