import { request, reducer, status } from '@/helpers/async';
import * as api from '@/helpers/api';
import { persist } from '@/helpers/persistor';
import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';
//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
const log = createLogger('Home Reducer', true);

const MIN_NUM_RIDES = 9;
const MAX_RADIUS = 500;
const RADIUS_INC = 200;

/* */
// Types
const FETCH_NEAR_RIDES = 'home/FETCH_NEAR_RIDES';

/* */
// Action Creators

export const fetchNearRides = (radius = 100) => {
    return (dispatch, getState) => {
        const { map } = getState();
        const myLocation = map.myLocation || map.center;

        if (!myLocation || radius > MAX_RADIUS) {
            dispatch(setNearRides([]));
            return;
        }

        dispatch(
            request(
                FETCH_NEAR_RIDES,
                api
                    .getRides(myLocation.lat, myLocation.lng, radius, 'CURATED')
                    .then((response) => {
                        const rides = response.data.rides;
                        log('Fetched Rides');

                        if (
                            rides.length < MIN_NUM_RIDES &&
                            radius + RADIUS_INC < MAX_RADIUS
                        ) {
                            log('Fetching again');
                            dispatch(fetchNearRides(radius + RADIUS_INC));
                            return;
                        }

                        log('Returning rides; Got enough');
                        dispatch(persist('home.nearRides', rides));
                        return rides;
                    })
            )
        );
    };
};

export const setNearRides = (rides) => ({
    status: status.SUCCESS,
    type: FETCH_NEAR_RIDES,
    data: rides
});

/* */
// Reducer
const initialState = {
    nearRides: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case AUTH_INVALIDATE:
        default:
            return {
                ...state,
                nearRides: reducer(FETCH_NEAR_RIDES)(state.nearRides, action)
            };
    }
};
