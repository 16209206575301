/******************************************************************************\
 * File: HeaderLinks.jsx
 *
 * Author: Gigster
 *
 * Description: App header
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/header/HeaderLinks.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Link from '@/components/common/header/Link';
import Logo from '@/components/common/header/Logo';
import Bookmark from '@/components/common/icons/Bookmark';
import Pin from '@/components/common/icons/Pin';
import RideIcon from '@/components/common/icons/Ride';
import MiniArrowUp from '@/components/common/icons/MiniArrowUp';
import UserLink from '@/components/common/header/UserLink';
import { clearRide } from '@/store/edit_ride';

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { Routes } from '@/helpers/routes';
import { matchRoute } from '@/helpers/url';
//------------------------------------------------------------------------------
// Store -----------------------------------------------------------------------
import {
    isAuthenticated,
    hasSessionToken,
    auth,
    invalidateAuth
} from '@/store/auth';
import { currentSection } from '@/store/sections';
import { changeSearch } from '@/store/search';

import { translate } from '@/helpers/i18n';
const t = translate('header.HeaderLinks');
//------------------------------------------------------------------------------
// React Function --------------------------------------------------------------
const HeaderLinks = ({
    className = '',
    isAuthenticated = false,
    hasSessionToken = false,
    onLink = () => {},
    auth = () => {},
    invalidateAuth = () => {},
    profile = {},
    isMobile = false,
    onChange = () => {},
    descriptor = {
        homeLink: true,
        newRideLink: true,
        savedRidesLink: true,
        mapLink: true,
        userLink: true,
        inlineUser: true,
        column: false,
        showIcons: true,
        showText: true,
        responsiveText: false,
        hdLink: true,
        hdInline: false,
        updateUploadModal: () => {},
        openSettingsModal: () => {}
    }
}) => {
    const cn = classNames(style.HeaderLinks, {
        [className]: className,
        [style['show-icons']]: descriptor.showIcons,
        [style['show-text']]: descriptor.showText,
        [style['responsive-text']]: descriptor.responsiveText,
        [style['column']]: descriptor.column
    });
    const isProfileActive = matchRoute(Routes.PROFILE, { exact: true });
    const isMap =
        window.location.pathname.includes('/map') &&
        !window.location.pathname.includes('/create') &&
        !window.location.pathname.includes('/edit');

    return (
        <div className={cn}>
            {descriptor.homeLink && (
                <Logo
                    className={style.link}
                    onClick={onLink}
                    activeClassName={style.active}
                    iconClassName={style.logo}
                    end
                    text={t('Home')}
                    showImage={!!descriptor.showIcons}
                />
            )}

            {descriptor.newRideLink && !isMobile && (
                <Link
                    to={Routes.RIDE_CREATE}
                    onClick={onChange}
                    className={style.link}
                    activeClassName={style.active}
                    end
                    title={t('Create')}
                    id="create-link"
                    data-testid="create-link">
                    <RideIcon className={style.icon} />
                    <span className={style.text}>{t('Create')}</span>
                </Link>
            )}

            {descriptor.mapLink && (
                <Link
                    to={Routes.MAP}
                    onClick={onLink}
                    className={style.link}
                    activeClassName={style.active}
                    end
                    title={t('Map')}
                    id="map-link"
                    data-testid="map-link">
                    <Pin className={isMap ? style.activeMapIcon : style.icon} />
                    <span className={isMap ? style.activeMapText : style.text}>
                        {t('Map')}
                    </span>
                </Link>
            )}

            {descriptor.savedRidesLink &&
                (isAuthenticated || hasSessionToken) && (
                    <Link
                        to={Routes.SAVED}
                        onClick={onLink}
                        className={style.link}
                        activeClassName={style.active}
                        end
                        title={t('Saved')}
                        id="saved-link"
                        data-testid="saved-link">
                        <Bookmark className={style.icon} active />
                        <span className={style.text}>{t('Saved')}</span>
                    </Link>
                )}

            {descriptor.userLink && (
                <UserLink
                    name={profile.firstName}
                    isAuthenticated={hasSessionToken}
                    inline={descriptor.inlineUser}
                    onLink={onLink}
                    onSignIn={auth}
                    onLogout={invalidateAuth}
                    openSettingsModal={descriptor.openSettingsModal}
                    active={isProfileActive}
                    responsiveText={descriptor.responsiveText}
                    MiniArrowUpIcon={MiniArrowUp}
                />
            )}

            {descriptor.hdLink && (
                <div
                    className={classNames(style.HDLink, {
                        [style.inline]: descriptor.hdInline,
                        [style.mobile]: isMobile
                    })}>
                    <a
                        href="https://www.harley-davidson.com"
                        target="_blank"
                        id="hd-link"
                        data-testid="hd-link">
                        h-d.com
                        <MiniArrowUp />
                    </a>
                </div>
            )}
        </div>
    );
};
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        isAuthenticated: isAuthenticated(state),
        hasSessionToken: hasSessionToken(state),
        profile: state.user.profile
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        auth: () => dispatch(auth()),
        invalidateAuth: () => dispatch(invalidateAuth()),
        onChange: () => {
            dispatch(changeSearch(''));
            dispatch(clearRide());
        }
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(HeaderLinks);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
