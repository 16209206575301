/******************************************************************************\
 * File: history.js
 *
 * Author: Gigster
 *
 * Description: Creates and exports a history object for the app
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// History Generator -----------------------------------------------------------
import { createBrowserHistory as createHistory } from 'history';
//------------------------------------------------------------------------------
// Singleton History -----------------------------------------------------------
const history = createHistory();

/** ensure that we don't leave the app when navigating back: */
let count = 0;
const _push = history.push;
const _back = history.back;

history.push = (path, state) => {
    count += 1;
    _push(path, state);
};

history.back = () => {
    if (count > 0) {
        if (
            window.location.href.includes('create') &&
            window.location.href.includes('#')
        ) {
            count -= 2;
            history.go(-2);
        } else {
            count -= 1;
            _back();
        }
    } else {
        history.replace('/');
    }
};

export default history;
