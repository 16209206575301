/******************************************************************************\
 * File: Home.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Home extends React.Component {
    render() {
        const { className, onClick, ...rest } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 11 10" onClick={onClick} {...rest}>
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <path
                        d="M5.333,0 L8,2.333 L8,0.667 L9.333,0.667 L9.333,3.5 L10.667,4.667 L9.333,4.667 L9.333,9.333 L10.667,9.333 L10.667,10 L0,10 L0,9.333 L1.333,9.333 L1.333,4.667 L0,4.667 L5.333,0 Z M4,9.334 L6.667,9.334 L6.667,6.667 L4,6.667 L4,9.334 Z"
                        id="Shape"
                        fill="#FFFFFF"
                        fillRule="nonzero"
                    />
                </g>
            </svg>
        );
    }
}
export default Home;
