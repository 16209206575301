/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd" transform="translate(11.833 3)">
                <path
                    fillRule="nonzero"
                    d="M9.78 10.92V22.4H6.486l-.001-10.87.001-.61H0L8.132 0l8.131 10.92z"></path>
                <circle cx="8.132" cy="34.938" r="2.8"></circle>
                <circle
                    cx="8.132"
                    cy="34.938"
                    r="6.611"
                    fillOpacity="0.2"
                    stroke="#FFF"></circle>
            </g>
        </svg>
    );
}

export default Icon;
