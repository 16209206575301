/******************************************************************************\
 * File: markers.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/
// Components
import RouteDealerMarker from '@/components/common/map/icons/RouteDealerMarker';
import RouteEventMarker from '@/components/common/map/icons/RouteEventMarker';
// Markers
import NumberIcon from '@/components/common/icons/NumberIcon';
import StartFlag from '@/components/common/icons/StartFlag';
import FinishFlag from '@/components/common/icons/FinishFlag';
import GasStation from '@/components/common/icons/GasStation';
import Restaurant from '@/components/common/icons/Restaurant';
import Scenic from '@/components/common/icons/Scenic';
import Harley from '@/components/common/icons/Harley';
import Event from '@/components/common/icons/Event';
import OvalIcon from '@/components/common/icons/Oval';
import Hotel from '@/components/common/icons/Hotel';
// Helpers
import { WaypointType } from '@/helpers/constants';
import { unique } from '@/helpers/functions';
import { userCanEditRide } from '@/store/auth';
import { match } from '@/helpers/url';
import { Routes } from '@/helpers/routes';

export const getDealerMarker = (dealer, isSelected) => {
    const { isEVDealer } = dealer;
    return {
        ...dealer,
        markerType: 'dealer',
        position: {
            lat: dealer.latitude,
            lng: dealer.longitude
        },
        component: RouteDealerMarker,
        componentProps: {
            dark: true,
            useFillColor: true,
            isEVDealer,
            fillColor: isSelected
                ? '#ff6602'
                : isEVDealer
                  ? '#056AAF'
                  : '#000000'
        }
    };
};

export const getDealerMarkers = (state) => {
    const { data, selected } = state;
    const selectedDealerId = (selected || {}).id;
    const isDealerDetailsRoute = match(window.location, Routes.MAP_DEALER);
    return data.map((dealer) =>
        getDealerMarker(
            dealer,
            dealer.id == selectedDealerId && isDealerDetailsRoute
        )
    );
};

export const getEventMarker = (event, isSelected) => {
    return {
        ...event,
        position: {
            lat: event.latitude,
            lng: event.longitude
        },
        markerType: 'event',
        component: RouteEventMarker,
        componentProps: {
            dark: true,
            useFillColor: true,
            fillColor: isSelected ? '#ff6602' : '#000000'
        }
    };
};

export const getEventMarkers = (state) => {
    const { data, selected } = state;
    const selectedEventId = (selected || {}).eventId;
    const isEventDetailsRoute = match(window.location, [
        Routes.MAP_EVENT,
        Routes.SHARED_EVENT
    ]);
    return data.map((event) =>
        getEventMarker(
            event,
            event.eventId === selectedEventId && isEventDetailsRoute
        )
    );
};

export const renderMarkerItem = (onClick) => (item, count) => {
    const { component, componentProps } = item;
    const isEVDealer = item.isEVDealer;
    return count === 1
        ? {
              componentProps,
              onClick: () => onClick && onClick(item)
          }
        : {
              component,
              componentProps: {
                  dark: true,
                  isEVDealer,
                  count
              }
          };
};

export function getMarkerNumber(point, i, waypoints) {
    return (
        waypoints
            .filter((e) => e.type !== WaypointType.WAYPOINT)
            .indexOf(point) + 1
    );
}

export function getMarkerIconType(point, i, waypoints, offRoad) {
    const isObj = (obj) => typeof obj === 'object' && obj !== null;
    if (i === 0) {
        return 'START';
    }

    if (i === waypoints.length - 1 && !offRoad) {
        return 'END';
    }

    if (isObj(point) && point.type === WaypointType.WAYPOINT) {
        return 'WAYPOINT';
    }

    if (isObj(point) && point.type === WaypointType.POI) {
        return point.category || 'DEALER';
    }

    return 'NUMBER';
}

const defaultMarkerMap = {
    WAYPOINT: OvalIcon,
    GAS_STATION: GasStation,
    RESTAURANT: Restaurant,
    SCENIC: Scenic,
    HOTEL: Hotel,
    EVENT: Event,
    DEALER: Harley,
    START: StartFlag,
    END: FinishFlag,
    NUMBER: NumberIcon
};

const alwaysUseIcon = [
    'GAS_STATION',
    'CHARGING_STATION',
    'RESTAURANT',
    'SCENIC',
    'HOTEL',
    'DEALER',
    'EVENT',
    'START',
    'POI'
];

export function getMarkerProps(
    waypoint,
    i,
    waypoints,
    shouldEnumerate = false,
    iconMap = defaultMarkerMap,
    offRoad
) {
    const markerIconType = getMarkerIconType(waypoint, i, waypoints, offRoad);
    const shouldDisplayType =
        shouldEnumerate && alwaysUseIcon.indexOf(markerIconType) > -1;
    const type =
        !shouldDisplayType && i > 0 && !(waypoint || {}).id
            ? 'NUMBER'
            : markerIconType;
    const number = getMarkerNumber(waypoint, i, waypoints);
    const icon = iconMap[type];
    return { type, icon, number };
}

export function stackedMarkerProps(rides, userId) {
    const stacked = unique(rides.map((ride) => ride.type)).length > 1;

    return {
        count: rides.length,
        recomended: stacked || !userCanEditRide(userId, rides[0]),
        stacked
    };
}
