/******************************************************************************\
 * File: analytics.js
 *
 * Author: Gigster
 *
 * Description: List of analytics functions
 *
 * Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Update Analytics ------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
const log = createLogger('Analytics', true);

export const resetHDAnalytics = () => {
    //eslint-disable-next-line
    if (!window.hd || !window.hd.analytics) window[hd || {}].analytics = {};
    delete window.hd.analytics['import gpx error'];
    delete window.hd.analytics['import gpx success'];
};
export const setHDAnalyticsUploadSuccess = (
    isRecorded = false,
    uploadFailed = false
) => {
    resetHDAnalytics();
    if (!uploadFailed) {
        window.hd.analytics['import gpx success'] = {};
        if (isRecorded) {
            window.hd.analytics['import gpx success']['recorded ride'] = true;
        } else {
            window.hd.analytics['import gpx success']['planned ride'] = true;
        }
    }
};

const createAndDispatchEvent = (type, detail = {}) => {
    const data = {
        detail: detail,
        bubbles: false,
        cancelable: true,
        composed: false
    };
    if (typeof CustomEvent !== 'undefined') {
        const e = new CustomEvent(type, { ...data });
        window.dispatchEvent(e);
    }
    log(type, data);
};

export const analyticsImportSuccessEvent = (type) => {
    const eventType = `import gpx success:${type}`;
    createAndDispatchEvent(eventType);
};

export const analyticsShareRideEvent = (type) => {
    const eventType = type ? `share ride:${type}` : 'share ride';
    createAndDispatchEvent(eventType);
};

export const getRideSubTypeForAnalytics = ({ type, subType }) => {
    if (type === 'CUSTOM') return type.toLowerCase();
    if (!!subType && subType.includes('HOG')) return 'hog';
    if (subType === 'HD') return 'hd recommended';
    if (subType) return subType.toLowerCase();
    return undefined;
};

export const setRideSubTypeForAnalytics = (ride) =>
    (window.hd.analytics.app_ridetypesource = getRideSubTypeForAnalytics(ride));

export const getRideTypeForAnalytics = ({ type, subType }) => {
    if (type === 'CURATED') return 'recommended';
    if (subType === 'RECORDED' || subType === 'PLANNED')
        return subType.toLowerCase();
    if (!!subType && subType.includes('HOG')) return 'hog';
    return undefined;
};

export const setRideTypeForAnalytics = (ride) => {
    const rideType = getRideTypeForAnalytics(ride);
    if (!rideType) {
        delete window.hd.analytics.app_ridetype;
        return;
    }
    window.hd.analytics.app_ridetype = rideType;
};

export const analyticsEvent = (type) => createAndDispatchEvent(type);
export const analyticsRideEvent = (type) => createAndDispatchEvent(type);
export const analyticsBookmarkEvent = (type) => createAndDispatchEvent(type);

const poiPoi = ({ id, markerType, position, title, multiple, type }) => {
    return {
        id,
        'poi type': type || markerType,
        'display location': 'map',
        source: 'here maps',
        title: multiple ? 'multiple' : `Single | ${title}`,
        type,
        position
    };
};

const dealerPoi = ({
    dealerId,
    id,
    markerType,
    multiple,
    dealerName,
    isEVDealer
}) => {
    return {
        id,
        dealerId,
        'poi type': markerType,
        'display location': 'map',
        source: 'HD',
        title: multiple ? 'multiple' : `Single | ${dealerName}`,
        isEVDealer
    };
};

const eventPoi = ({
    eventId,
    markerType,
    multiple,
    eventName,
    eventHost,
    eventHostDescription,
    eventDescription,
    eventActivities
}) => {
    const eventHostName = eventActivities[0].address.name
        ? eventActivities[0].address.name
        : eventHostDescription;
    return {
        eventId,
        'poi type': markerType,
        'display location': 'map',
        source: 'HD',
        title: multiple ? 'multiple' : `Single | ${eventName}`,
        eventHost,
        eventHostDescription: eventHostName,
        body: eventDescription
    };
};

export const dataForAnalytics = (marker) => {
    if (marker.markerType === 'event') return eventPoi(marker);
    if (marker.markerType === 'dealer') return dealerPoi(marker);
    return poiPoi(marker);
};

export const analyticsPOILocationDetailEvent = (data) =>
    createAndDispatchEvent('location detail card', { ...data });

export const analyticsPOIEvent = (data) =>
    createAndDispatchEvent('sidebar detail card', { ...data });

export const setHDAnalyticsUploadFailed = () =>
    (window.hd.analytics['import gpx error'] = true);

export const analyticsForRideWithData = (type, data) =>
    createAndDispatchEvent(type, { ...data });

export const analyticsWithData = (type, data) =>
    createAndDispatchEvent(type, { ...data });
