/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <path
                fill="#FFF"
                d="M30.244 38V13.759l-.522-.012a4.79 4.79 0 00-3.91 1.473L15.75 23.825l-.494.414 4.713 5.615-13.942-.3 2.103-13.81 4.721 5.628.538-.451 10.065-8.589a8.331 8.331 0 016.313-2.315l4.208.004V38h-3.73z"></path>
        </svg>
    );
}

export default Icon;
