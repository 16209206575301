/******************************************************************************\
 * File: RideHeader.jsx
 *
 * Author: Gigster
 *
 * Description: Ride header
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/header/RideHeader.scss';
import appStyle from '@/style/App.scss';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import {
    prettyMiles,
    prettyTime,
    prettyMPH,
    rideAverageSpeed
} from '@/helpers/functions';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Link from '@/components/common/header/Link';
import Text from '@/components/common/form/Text';
import BackIcon from '@/components/common/icons/Back';
import PencilIcon from '@/components/common/icons/Pencil';
import Tooltip from '@/components/common/form/Tooltip';
import { analyticsRideEvent } from '@/helpers/analytics';
import { isRideRecorded } from '@/store/rides';
import { translate } from '@/helpers/i18n';
import RouteTypeDropdown from '@/components/common/header/RouteTypeDropdown';
const t = translate('header.RideHeader');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RideHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = { expanded: true };
    }

    render() {
        const {
            children,
            className,
            inputProps,
            ride,
            onClickBack,
            onClickEdit,
            transparent
        } = this.props;

        const isCreate = window.location.pathname.includes('/create');
        const cn = classNames(style.RideHeader, appStyle.headerGroup, {
            [className]: className
        });
        const { offRoad, name, duration, length, isRecordedRide } = ride;
        const expandPanelIcon = !expanded
            ? '/img/icon-panel-expand.svg'
            : '/img/icon-panel-collapse.svg';

        const { expanded } = this.state;

        //could be improve
        return transparent ? (
            <div className={cn}>
                <Tooltip
                    value={t('Back')}
                    placement="s"
                    transparent={transparent}>
                    <Link
                        className={style.icon}
                        onClick={onClickBack}
                        role="button">
                        <span className={`${style.headerLink}`}>
                            <BackIcon className={style.back} />
                        </span>
                    </Link>
                </Tooltip>
                {!!onClickEdit && !isRecordedRide && (
                    <Tooltip
                        value={t('Edit ride name and description')}
                        placement="s"
                        transparent={transparent}
                        multiline={true}>
                        <Link
                            onClick={() => {
                                analyticsRideEvent('edit ride');
                                onClickEdit();
                            }}
                            title={t('Edit Ride')}
                            className={style.edit}>
                            <PencilIcon
                                className={classNames({
                                    [style.greyIcon]: isRecordedRide
                                })}
                            />
                        </Link>
                    </Tooltip>
                )}
                <div className={style.titleWrap}>
                    <div className={style.details}>
                        <div className={style.title} title={ride.name}>
                            <Text
                                className={style.rideText}
                                editable={!isRecordedRide && !!onClickEdit}
                                value={ride.name}
                                placeholder={t('My Awesome Ride')}
                                blurOnEnter
                                inputProps={inputProps}
                            />
                        </div>
                        <div
                            className={classNames(style.stats, {
                                [style.placeholder]:
                                    !ride.duration || !ride.length
                            })}>
                            {!offRoad && (
                                <span>
                                    {ride.duration
                                        ? prettyTime(ride.duration)
                                        : '0h 0m'}
                                </span>
                            )}
                            <span>
                                {ride.length
                                    ? prettyMiles(ride.length)
                                    : '0 mi'}
                            </span>
                            {isRecordedRide && (
                                <span>{prettyMPH(rideAverageSpeed(ride))}</span>
                            )}
                        </div>
                    </div>
                </div>
                {children}
            </div>
        ) : (
            <div className={cn}>
                <Tooltip
                    value={t('Back')}
                    placement="s"
                    transparent={transparent}>
                    <Link
                        id="back"
                        data-testid="back"
                        className={style.icon}
                        onClick={onClickBack}
                        role="button">
                        <span className={style.headerLink}>
                            <BackIcon className={style.back} />
                        </span>
                    </Link>
                </Tooltip>
                {!!onClickEdit && !isRecordedRide && (
                    <Tooltip
                        value={t('Edit ride name and description')}
                        placement="s"
                        transparent={transparent}
                        multiline={true}>
                        <Link
                            onClick={() => {
                                analyticsRideEvent('edit ride');
                                onClickEdit();
                            }}
                            id="pencil"
                            data-testid="pencil"
                            title={t('Edit Ride')}
                            className={style.edit}>
                            <PencilIcon
                                className={classNames({
                                    [style.greyIcon]: isRecordedRide
                                })}
                            />
                        </Link>
                    </Tooltip>
                )}
                <div className={style.titleWrap}>
                    <div className={style.details}>
                        <span className={style.title} title={ride.name}>
                            <Text
                                id="title"
                                data-testid="title"
                                className={style.rideText}
                                editable={!isRecordedRide && !!onClickEdit}
                                value={ride.name}
                                placeholder={t('My Awesome Ride')}
                                blurOnEnter
                                inputProps={inputProps}
                            />
                        </span>
                        {!isRecordedRide ? (
                            <div
                                className={classNames(style.stats, {
                                    [style.placeholder]:
                                        !ride.duration || !ride.length
                                })}>
                                {!offRoad && (
                                    <span>
                                        {ride.duration
                                            ? prettyTime(ride.duration || 0)
                                            : '0h 0m'}
                                    </span>
                                )}
                                <span>{prettyMiles(ride.length || 0)}</span>
                            </div>
                        ) : (
                            <div
                                className={classNames(style.stats, {
                                    [style.placeholder]:
                                        !ride.duration || !ride.length
                                })}>
                                {!offRoad && (
                                    <span>
                                        {ride.duration
                                            ? prettyTime(ride.duration)
                                            : '0h 0m'}
                                    </span>
                                )}
                                <span>
                                    {ride.length
                                        ? prettyMiles(ride.length)
                                        : '0 mi'}
                                </span>
                                {isRecordedRide && (
                                    <span>
                                        {prettyMPH(rideAverageSpeed(ride))}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    {!!isCreate && !isRideRecorded(ride) && (
                        <RouteTypeDropdown ride={ride} />
                    )}
                </div>
                {children}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default RideHeader;
