/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <path
                fill="#FFF"
                d="M10.492 29.946c-1.79 0-3.524 1.006-3.718 1.121l1.089 1.82c.013-.008 1.407-.821 2.63-.821.52 0 .785.147 1.185.371.482.267 1.08.6 2.032.6.965 0 1.55-.352 1.979-.61.374-.224.6-.361 1.15-.361.565 0 .826.148 1.222.374.466.266 1.047.598 1.997.598.984 0 1.638-.353 2.164-.633.414-.224.645-.339.964-.339.306 0 .509.102.904.318.509.276 1.207.654 2.293.654 1.09 0 1.782-.38 2.288-.657.387-.212.587-.315.887-.315.719 0 2.052.53 2.725.862l.942-1.9c-.223-.11-2.22-1.08-3.666-1.08-.857 0-1.44.318-1.908.575a6.266 6.266 0 01-.434.218c.277-4.843 3.064-9.846 3.064-9.846l-2.58-.803v-6.547h-4.573V11.43h-6.182v2.115h-4.574v6.547l-2.58.802s2.763 4.902 3.06 9.769l-.141-.078c-.485-.27-1.149-.639-2.219-.639zm4.265-14.283h10.56v3.69l-5.28-1.638-5.28 1.638v-3.69zm12.894 18.392c-.418.228-.721.395-1.267.395-.548 0-.858-.166-1.284-.399-.472-.256-1.059-.572-1.914-.572-.867 0-1.476.327-1.965.588-.413.223-.712.383-1.163.383-.39 0-.588-.114-.948-.319-.483-.275-1.145-.652-2.27-.652-1.139 0-1.778.383-2.244.664-.34.205-.509.307-.886.307-.402 0-.627-.125-1-.333-.484-.27-1.148-.639-2.218-.639-1.79 0-3.524 1.007-3.718 1.121l1.089 1.82c.013-.008 1.407-.821 2.63-.821.52 0 .785.149 1.185.37.482.268 1.08.601 2.032.601.965 0 1.55-.351 1.979-.61.374-.225.6-.361 1.15-.361.565 0 .826.15 1.222.374.466.266 1.047.597 1.997.597.984 0 1.638-.351 2.164-.633.414-.222.645-.338.964-.338.306 0 .509.102.904.318.509.276 1.207.654 2.293.654 1.09 0 1.782-.38 2.288-.657.387-.213.587-.315.887-.315.719 0 2.052.529 2.725.861l.942-1.898c-.223-.11-2.22-1.082-3.666-1.082-.857 0-1.44.318-1.908.576z"></path>
        </svg>
    );
}

export default Icon;
