/******************************************************************************\
 * File: RideDirections.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';
import history from '@/helpers/history';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import RideDropdownHeader from './RideDropdownHeader';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import {
    clearRide,
    updateRide,
    showSaveModal,
    setConfirm,
    setRideSuggestion
} from '@/store/edit_ride';
import { canSaveRide } from '@/store/edit_ride';
import {
    openDropdown,
    setCurrentRouteProgress,
    resetCurrentRoutePoint
} from '@/store/rides';
import { isCreateModalShowing } from '@/store/app';
import * as mapActions from '@/store/map';

import { isAuthenticated, hasSessionToken } from '@/store/auth';

//App context
import AppContext from '@/contexts/AppContext';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RideDirections extends React.Component {
    state = {
        showModal: false,
        showConfirmModal: false,
        userDismissedShowModal: false
    };

    componentDidMount() {
        const { params, ride } = this.props;
        const { isMobile } = this.context;

        const { id } = params;
        if (!!id && id.length > 0) return;
        if (
            !(ride || {}).cache.id &&
            !(ride || {}).shortId &&
            ride.waypoints.length < 1 &&
            !this.state.showModal
        ) {
            setTimeout(this.openCreateModal);
        } else {
            this.props.onToggleDropdown(true);
        }
        if (!isMobile) this.props.onToggleDropdown(true);
    }

    componentDidUpdate(prevProps) {
        const {
            ride: prevRide,
            past,
            query,
            ride: { waypoints: prevWaypoints, cache },
            params: { id }
        } = prevProps;

        const { ride: thisRide } = this.props;
        const urlId = id || (cache || {}).id;

        if (
            (prevWaypoints || []).length < 2 &&
            (thisRide.waypoints || []).length >= 2
        ) {
            this.props.onToggleDropdown(true);
        }

        if (
            !!urlId &&
            this.state.showModal &&
            ((thisRide.waypoints || []).length > 1 ||
                past > 1 ||
                !!cache.id ||
                !!query)
        ) {
            this.closeCreateModal();
        }

        if (
            !urlId &&
            !this.state.showModal &&
            !this.state.userDismissedShowModal &&
            !(prevRide || {}).cache.id &&
            !(thisRide || {}).shortId
        ) {
            setTimeout(this.openCreateModal);
        }
    }

    openCreateModal = () => {
        this.props.isCreateModalShowing(true);
        this.setState({ showModal: true });
    };

    closeCreateModal = () => {
        this.props.isCreateModalShowing(false);
        this.setState({ showModal: false });
    };

    showConfirmModal = () => this.props.setConfirm('back');
    closeConfirmModal = () => this.props.setConfirm('');

    openSaveModal = () => this.props.showSaveModal(true);

    handleBack = () => {
        const { canSaveRide, onResetCurrentRoutePoint, clearRide } = this.props;
        if (canSaveRide) {
            this.showConfirmModal();
        } else {
            clearRide();
            onResetCurrentRoutePoint();
            this.goBack();
        }
    };
    // TODO: Implement on clickAway
    handleNavigationChange = () => {
        const { canSaveRide } = this.props;

        if (canSaveRide) {
            this.showConfirmModal();
        }
    };

    goBack = () => {
        this.closeConfirmModal();
        this.props.clearRide();
        this.props.clearSelectedData();
        history.back();
    };

    _prefillWaypoint = () => {
        const { ride } = this.props;
        if (!((ride || {}).waypoints || []).length) return;
        return ride.waypoints[0];
    };

    render() {
        const { ride, map, onChangeName, oneDirection, updateUploadModal } =
            this.props;

        const { isMobile } = this.context;

        return (
            <div>
                <RideDropdownHeader
                    editable
                    onChangeName={onChangeName}
                    onClickEdit={
                        !ride.shortId && ride.waypoints.length < 1
                            ? this.openCreateModal
                            : this.openSaveModal
                    }
                    onClickDirection={oneDirection}
                    onClickBack={this.handleBack}
                    isMobile={isMobile}
                    ride={ride}
                    map={map}
                    updateUploadModal={updateUploadModal}
                    isCreate
                />
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => {
    const {
        present: { ride, meta, cache },
        past
    } = state.edit_ride;
    const { query } = state.search;
    return {
        isAuthenticated: isAuthenticated(state),
        hasSessionToken: hasSessionToken(state),
        canSaveRide: canSaveRide(state),
        ride: { ...ride, meta, cache },
        past,
        query,
        map: { center: state.map.center, zoom: state.map.zoom }
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch) => ({
    clearRide: () => dispatch(clearRide()),
    onChangeName: (name) => dispatch(updateRide('name', name)),
    showSaveModal: (value) => dispatch(showSaveModal(value)),
    onToggleDropdown: (value) => dispatch(openDropdown(value)),
    setConfirm: (value) => dispatch(setConfirm(value)),
    clearSelectedData: () => dispatch(mapActions.update('selectedData', null)),
    isCreateModalShowing: (value) => dispatch(isCreateModalShowing(value)),
    setRideSuggestion: (suggestion) => dispatch(setRideSuggestion(suggestion)),
    onResetCurrentRoutePoint: () => {
        dispatch(resetCurrentRoutePoint());
        dispatch(setCurrentRouteProgress(0));
    }
});

RideDirections.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(RideDirections);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
