/******************************************************************************\
 * File: browser.js
 *
 * Author: Gigster
 *
 * Description: Tests browser features
 *
 * Notes:
 \******************************************************************************/
function GetIEVersion() {
    const sAgent = window.navigator.userAgent;
    const Idx = sAgent.indexOf('MSIE');

    // If IE, return version number.
    if (Idx > 0)
        return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)));
    else if (navigator.userAgent.match(/Trident\/7\./))
        // If IE 11 then look for Updated user agent string.
        return 11;
    else return 0; //It is not IE
}

// Object of features to test
const features = {
    localStorage: () => {
        let mod = 'modernizr';
        try {
            localStorage.setItem(mod, mod);
            localStorage.removeItem(mod);
            return true;
        } catch (e) {
            return false;
        }
    },
    sessionStorage: () => {
        let mod = 'modernizr';
        try {
            sessionStorage.setItem(mod, mod);
            sessionStorage.removeItem(mod);
            return true;
        } catch (e) {
            return false;
        }
    },
    IEVersion: GetIEVersion,
    IE: () => {
        return GetIEVersion() > 0;
    }
};

// Test all features and return the results in an object
export default Object.keys(features).reduce(
    (memo, key) => ((memo[key] = features[key]()), memo),
    {}
);
