import { INVALIDATE as AUTH_INVALIDATE } from '@/store/auth';

import { translate } from '@/helpers/i18n';
const t = translate('sections.index');

/* */
// Types
const CHANGE_SECTION = 'sections/CHANGE';

/* */
// Helpers
const getSectionNamesMap = () => ({
    rides: t('Rides'),
    dealers: t('Dealers'),
    events: t('Events')
});

/* */
// Action Creators
export const changeSection = (key) => ({
    type: CHANGE_SECTION,
    data: { key }
});

/* */
// Selectors
export const sectionsWithCounts = (state) => {
    const { sections } = state;

    const sectionNames = getSectionNamesMap();

    return sections.items.map((section) => ({
        ...section,
        name: sectionNames[section.key],
        count: ((state[section.stateKey] || {}).data || {}).length || 0
    }));
};

export const currentSection = (state) => {
    const { active, items } = state.sections;
    const section = items[active];

    const sectionNames = getSectionNamesMap();
    return { ...section, name: sectionNames[section.key] };
};

export const itemsForSection = (state) => state[currentSection(state).stateKey];

/* */
// Reducer

const initialState = {
    items: [
        {
            key: 'rides',
            stateKey: 'rides',
            columns: 3
        },
        {
            key: 'dealers',
            stateKey: 'dealers',
            columns: 2
        },
        {
            key: 'events',
            stateKey: 'events',
            columns: 2
        }
    ],
    active: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHANGE_SECTION: {
            const { key } = action.data;

            const active = state.items.findIndex(
                (section) => section.key === key
            );

            return { ...state, active: Math.max(active, 0) };
        }

        case AUTH_INVALIDATE:
        default:
            return state;
    }
};
