/******************************************************************************\
 * File: map.js
 *
 * Author: Gigster
 *
 * Description: Here maps helper functions
 *
 * Notes:
 \******************************************************************************/
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import {
    pick,
    diff,
    normalizeLatLng,
    latLngValid,
    latLngEqual,
    clamp
} from '@/helpers/functions';
import { lerpPoint } from '@/helpers/math';
import { MapZoom } from '@/helpers/constants';

// here maps functions
const hereDomIcons = new Map();
export const getDomMarkerIcon = (html) => {
    if (!hereDomIcons.has(html)) {
        const icon = new H.map.DomIcon(html);
        hereDomIcons.set(html, icon);
    }

    return hereDomIcons.get(html);
};

const hereIcons = new Map();
export const getMarkerIcon = (url) => {
    if (!hereIcons.has(url)) {
        const icon = new H.map.Icon(url);
        hereIcons.set(url, icon);
    }

    return hereIcons.get(url);
};

/** Maps React event names to heremaps event names. */
const reactToHereEventMap = {
    onClick: 'tap',
    onPointerEnter: 'pointerenter',
    onPointerMove: 'pointermove',
    onPointerLeave: 'pointerleave',
    onPointerDown: 'pointerdown',
    onPointerUp: 'pointerup',
    onContextMenu: 'contextmenu'
};

export const createSquareSearchArea = (center, bounds) => {
    const { ne, sw } = bounds;
    const newBoundsLatAdj = (ne.lat - sw.lat) / 2;
    const newBoundsLngAdj = (ne.lng - sw.lng) / 2;
    const boundsAdj = Math.min(newBoundsLatAdj, newBoundsLngAdj);
    // Get New Bounds using center and boundsAdj
    const newBounds = {
        ne: {
            lng: center.lng + boundsAdj,
            lat: center.lat + boundsAdj
        },
        sw: {
            lng: center.lng - boundsAdj,
            lat: center.lat - boundsAdj
        }
    };
    return newBounds;
};

const getMin = (array, prop) =>
    array.map((item) => item[prop]).sort((a, b) => a - b)[0];

const getMax = (array, prop) =>
    array.map((item) => item[prop]).sort((a, b) => b - a)[0];

// bounding box specified as 4 values, denoting west longitude, south latitude, east longitude, north latitude.
// Check direction by comparing first to last?
export const getBoundingBoxFromPoints = (points) =>
    [
        getMin(points, 'lng'),
        getMin(points, 'lat'),
        getMax(points, 'lng'),
        getMax(points, 'lat')
    ].join();

export const getBoundingBoxFromBounds = (bounds) =>
    [bounds.sw.lng, bounds.sw.lat, bounds.ne.lng, bounds.ne.lat].join();

export const bindHereProps = (el, props) => {
    if (!el) return;

    // Bind events
    Object.entries(reactToHereEventMap).forEach(([propKey, eventName]) => {
        const listener = props[propKey];
        if (listener) el.addEventListener(eventName, listener, false);
    });

    // Update the things
    const { zIndex, draggable, data } = props;
    if (typeof data !== 'undefined') el.setData(data);
    if (typeof zIndex !== 'undefined') el.setZIndex(zIndex);
    if (typeof draggable !== 'undefined') el.draggable = draggable;
};

/** Only updates the props on el that have been modified. */
export const updateHereProps = (el, props, nextProps) => {
    if (!el) return;

    const keys = Object.keys(reactToHereEventMap);
    const updatedProps = diff(pick(props, keys), pick(nextProps, keys));
    if (!Object.keys(updatedProps).length) return;
    // Unbind old events
    Object.keys(updatedProps).forEach((propKey) => {
        el.removeEventListener(
            reactToHereEventMap[propKey],
            props[propKey],
            false
        );
    });
    return bindHereProps(el, updatedProps);
};

export const createHereMarker = (props, debugStr = '') => {
    const {
        position: _position,
        component: Component,
        componentProps,
        img,
        options
    } = props;

    const position = normalizeLatLng(_position);

    if (!latLngValid(position)) {
        let stack = '';
        try {
            stack = new Error().stack;
        } catch (e) {
            // do nothing
        }

        throw `Invalid position given to 'createHereMarker': ${JSON.stringify(
            position
        )} ${debugStr} ${stack}`;
    }

    const hereOptions = options || {};
    let marker;

    if (Component) {
        // Create a marker with a component as an icon
        const html = renderToStaticMarkup(
            <Component {...(componentProps || {})} />
        );
        const icon = getDomMarkerIcon(html);

        marker = new H.map.DomMarker(position, { ...hereOptions, icon });
    } else if (img) {
        // Create a marker with an image as an icon
        const icon = getMarkerIcon(img);

        marker = new H.map.Marker(position, { ...hereOptions, icon });
    } else {
        // Create a marker with a default icon
        marker = new H.map.Marker(position);
    }

    bindHereProps(marker, props);

    return marker;
};

export const destroyHereMarker = (marker) => {
    // Marker doesn't exist, exit
    if (!marker) return;
    // Removes listeners from the given object
    marker.dispose();
};

/** Normalizes the here maps event to provide some useful data. */
export const normalizeEvent = (event, map) => {
    const { currentPointer: pointer } = event;

    const pageX = (pointer && pointer.viewportX) || event.viewportX;
    const pageY = (pointer && pointer.viewportY) || event.viewportY;
    const geocoord = pageX && pageY && map.screenToGeo(pageX, pageY);
    if (!geocoord)
        console.error(`No geocoord found for event ${event.type}:`, event);

    return {
        preventDefault: event.preventDefault,
        stopPropagation: event.stopPropagation,
        originalEvent: event.originalEvent,
        hereEvent: event,
        target: event.target,
        isDragging: event.type === 'dragend',
        geocoord,
        map,
        pageX,
        pageY
    };
};

const toRadians = (deg) => deg * (Math.PI / 180);

// Spherical Mercator Projection
const sphericalMercator = {
    R: 6378137,
    MAX_LATITUDE: 85.0511287798,

    project: function (latlng) {
        const d = Math.PI / 180;
        const max = this.MAX_LATITUDE;
        const lat = clamp(latlng.lat, -max, max);
        const sin = Math.sin(lat * d);

        return {
            lng: this.R * latlng.lng * d,
            lat: (this.R * Math.log((1 + sin) / (1 - sin))) / 2
        };
    },

    unproject: function (point) {
        const d = 180 / Math.PI;

        return {
            lat:
                (2 * Math.atan(Math.exp(point.lat / this.R)) - Math.PI / 2) * d,
            lng: (point.lng * d) / this.R
        };
    }
};

// The selected Coordinate Reference System
export const crs = {
    // Use this projection
    ...sphericalMercator,

    scale: (zoom) => 256 * Math.pow(2, zoom),

    zoom: (scale) => Math.log(scale / 256) / Math.LN2,

    distance: function (a, b) {
        const lat1 = toRadians(a.lat);
        const lat2 = toRadians(b.lat);
        const latDiff = toRadians(b.lat - a.lat);
        const lngDiff = toRadians(b.lng - a.lng);

        const d =
            Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
            Math.cos(lat1) *
                Math.cos(lat2) *
                Math.sin(lngDiff / 2) *
                Math.sin(lngDiff / 2);

        const c = 2 * Math.atan2(Math.sqrt(d), Math.sqrt(1 - d));

        return this.R * c;
    },

    transform: function (point, scale = 1) {
        const a = 0.5 / (Math.PI * this.R);
        const b = 0.5;
        const c = -a;
        const d = b;

        return {
            lng: scale * (a * point.lng + b),
            lat: scale * (c * point.lat + d)
        };
    },

    untransform: function (point, scale = 1) {
        const a = 0.5 / (Math.PI * this.R);
        const b = 0.5;
        const c = -a;
        const d = b;

        return {
            lng: (point.lng / scale - b) / a,
            lat: (point.lat / scale - d) / c
        };
    },

    latLngToPoint: function (latlng, zoom) {
        const projectedPoint = this.project(latlng);
        const scale = this.scale(zoom);

        return this.transform(projectedPoint, scale);
    },

    pointToLatLng: function (point, zoom) {
        const scale = this.scale(zoom);
        const untransformedPoint = this.untransform(point, scale);

        return this.unproject(untransformedPoint);
    }
};

const getBoundsSize = (bounds) => ({
    lat: Math.abs(bounds.ne.lat - bounds.sw.lat),
    lng: Math.abs(bounds.ne.lng - bounds.sw.lng)
});

const getScaleZoom = (scale, zoom = 0) => crs.zoom(scale * crs.scale(zoom));

const boundsMinusPadding = ({ ne, sw }, padding = {}) => ({
    ne: {
        lat: ne.lat + (padding.top || 0),
        lng: ne.lng - (padding.right || 0)
    },
    sw: {
        lat: sw.lat - (padding.bottom || 0),
        lng: sw.lng + (padding.left || 0)
    }
});

const isPointInBounds = (point, { ne, sw }) =>
    ne.lat < point.lat &&
    ne.lng > point.lng &&
    sw.lat > point.lat &&
    sw.lng < point.lng;

export const boundsCenter = ({ ne, sw }) => ({
    lat: (ne.lat + sw.lat) / 2,
    lng: (ne.lng + sw.lng) / 2
});

export const hereBoundingBox = (points) => {
    let line = new H.geo.LineString();
    points.forEach((p) =>
        line.pushPoint(new H.geo.Point(p.lat, p.lng ?? p.lon))
    );
    const polyline = new H.map.Polyline(line);
    return polyline.getBoundingBox && polyline.getBoundingBox();
};

export const hereBoundsFromPoints = (points) => {
    const box = hereBoundingBox(points);
    const bottomRight = box.getBottomRight();
    const topLeft = box.getTopLeft();
    return [bottomRight, topLeft];
};

/** Gets bounds {ne, sw} from a list of latlng paris. */
export const boundsFromPoints = (points) => {
    let ne;
    let sw;
    // Use HEREMaps if available
    if (!!H && H.geo) {
        const [bottomRight, topLeft] = hereBoundsFromPoints(points);
        ne = { lat: topLeft.lat, lng: bottomRight.lng };
        sw = { lat: bottomRight.lat, lng: topLeft.lng };
    } else if (points.length > 1) {
        ne = points[0];
        sw = points[points.length - 1];
    } else {
        ne = points[0];
        sw = points[0];
    }
    return { ne, sw };
};

/**
 * Given bounds and mapDim {width, height}, returns the zoom level containing
 * bounds.
 */
export const boundsZoomLevel = (bounds, mapDim) => {
    const WORLD_DIM = 360;

    function latRad(lat) {
        const sin = Math.sin((lat * Math.PI) / 180);
        const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    }

    function zoom(mapPx, worldPx, fraction) {
        return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
    }

    const latFraction =
        Math.abs(latRad(bounds.ne.lat) - latRad(bounds.sw.lat)) / Math.PI;

    const lngDiff = bounds.ne.lng - bounds.sw.lng;
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

    const latZoom = zoom(mapDim.width || 721, WORLD_DIM, latFraction);
    const lngZoom = zoom(mapDim.height || 866, WORLD_DIM, lngFraction);

    return Math.floor(
        Math.min(
            latZoom < MapZoom.MIN ? MapZoom.MIN : latZoom,
            lngZoom < MapZoom.MIN ? MapZoom.MIN : latZoom
        )
    );
};

export const boundsCenterZoom = (bounds, mapDim) => {
    return {
        center: boundsCenter(bounds),
        zoom: Math.floor(boundsZoomLevel(bounds, mapDim))
    };
};

export const centerZoomForPoints = (points, mapDim) => {
    const bounds = boundsFromPoints(points.map(normalizeLatLng));
    const centerZoom = boundsCenterZoom(bounds, mapDim);
    return { ...centerZoom, bounds };
};

export const projectBounds = (bounds, zoom) => ({
    ne: crs.latLngToPoint(bounds.ne, zoom),
    sw: crs.latLngToPoint(bounds.sw, zoom)
});

export const unprojectBounds = (bounds, zoom) => ({
    ne: crs.pointToLatLng(bounds.ne, zoom),
    sw: crs.pointToLatLng(bounds.sw, zoom)
});

// flips the sign of every padding element, e.g. bottom becomes -bottom
const invert = (padding) =>
    Object.keys(padding || {}).reduce((memo, key) => {
        memo[key] = -padding[key];
        return memo;
    }, {});

export const getBoundsCenter = (cameraInfo, options = {}) => {
    const projectedBounds = boundsMinusPadding(
        projectBounds(cameraInfo.bounds, cameraInfo.zoom),
        invert(options.padding)
    );

    const projectedCenter = boundsCenter(projectedBounds);

    return crs.pointToLatLng(projectedCenter, cameraInfo.zoom);
};

// Returns a zoom level for the map to display the given bounds given that the bounds are centered
// bounds is { ne: {lat, lng} sw: {lat, lng} }
// cameraInfo is { bounds, zoom }
// options is { padding: { top, left, right, bottom }, maxZoom, minZoom }
export const getBoundsZoom = (bounds, cameraInfo, options) => {
    // Get size of projected camera bounds after padding is removed
    const cameraSize = getBoundsSize(
        boundsMinusPadding(
            projectBounds(cameraInfo.bounds, cameraInfo.zoom),
            options.padding
        )
    );
    const boundsSize = getBoundsSize(projectBounds(bounds, cameraInfo.zoom));

    // Get smallest ratio between camera bounds size and desired bounds size
    const scaleLat = cameraSize.lat / boundsSize.lat;
    const scaleLng = cameraSize.lng / boundsSize.lng;
    const scale = Math.min(scaleLat, scaleLng);

    const zoom = Math.floor(getScaleZoom(scale, cameraInfo.zoom));

    return clamp(zoom, options.minZoom, options.maxZoom);
};

const checkBounds = (bounds) =>
    [...Object.values(bounds.ne), ...Object.values(bounds.sw)].reduce(
        (obj, a) => (obj += a),
        0
    );

export const defaultBounds = () => {
    return {
        bounds: {
            ne: { lat: 44.36300416728543, lng: -66.2269854285594 },
            sw: { lat: 29.718731603826456, lng: -127.50747824307012 }
        },
        zoom: 4
    };
};

const defaultPrintBounds = () => {
    return {
        bounds: {
            ne: { lat: 37.498201287301384, lng: -121.12680942871094 },
            sw: { lat: 37.0879630258977, lng: -122.49529392578125 }
        },
        zoom: 11
    };
};

// Returns a center and zoom level for the map to display the given bounds
// bounds is { ne: {lat, lng} sw: {lat, lng} }
// cameraInfo is { bounds, zoom }
// options is { padding: { top, left, right, bottom }, maxZoom, minZoom }
export const getBoundsCenterZoom = (bounds, cameraInfo, options = {}) => {
    // if cameraInfo is 0, use default map bounds
    if (!!bounds && checkBounds(bounds) === 0) {
        const location = window.location;
        if (location.pathname === '/') cameraInfo = defaultBounds();
        if (location.search.includes('print=1'))
            cameraInfo = defaultPrintBounds();
    }
    let zoom = getBoundsZoom(bounds, cameraInfo ? cameraInfo : bounds, options);

    // fallback to default map size if invalid zoom
    if (zoom < MapZoom.MIN) {
        const mapDim = {
            width: window.innerWidth - 368,
            height: window.innerHeight - 66
        };
        zoom = boundsZoomLevel(bounds, mapDim);
    }

    // const center = getBoundsCenter({ bounds, zoom }, options);
    const points = Object.values(bounds);
    const center = hereBoundingBox(points).getCenter();
    return { zoom, center: { ...center } };
};

export const getZoomCenterBounds = (zoom, center, cameraInfo, options = {}) => {
    // Get size of projected camera bounds after padding is removed
    const cameraSize = getBoundsSize(
        boundsMinusPadding(
            projectBounds(cameraInfo.bounds, zoom),
            options.padding
        )
    );

    const projectedCenter = crs.latLngToPoint(center, zoom);

    const ratio = Math.pow(2, cameraInfo.zoom - zoom);

    const scaleLat = (ratio * cameraSize.lat) / 2;
    const scaleLng = (ratio * cameraSize.lng) / 2;

    const projectedBounds = {
        ne: {
            lat: projectedCenter.lat - scaleLat,
            lng: projectedCenter.lng + scaleLng
        },
        sw: {
            lat: projectedCenter.lat + scaleLat,
            lng: projectedCenter.lng - scaleLng
        }
    };
    return unprojectBounds(projectedBounds, zoom);
};

export const simpleMove = (point, delta) => {
    const rad = Math.PI / 180;
    const deg = 180 / Math.PI;

    return {
        lat: point.lat + deg * (delta.lat / sphericalMercator.R),
        lng:
            point.lng +
            (deg * (delta.lng / sphericalMercator.R)) /
                Math.cos(rad * point.lat)
    };
};

export const getBoundsForCircle = (point, radius) => {
    return {
        ne: simpleMove(point, { lat: radius, lng: radius }),
        sw: simpleMove(point, { lat: -radius, lng: -radius })
    };
};

export const isLatLngInBounds = (latLng, cameraInfo, options = {}) => {
    const projectedBounds = projectBounds(cameraInfo.bounds, cameraInfo.zoom);

    const withoutPadding = boundsMinusPadding(projectedBounds, options.padding);

    const projectedPoint = crs.latLngToPoint(latLng, cameraInfo.zoom);

    return isPointInBounds(projectedPoint, withoutPadding);
};

export const getPolylineLength = (waypoints) => {
    if ((waypoints || []).length < 2) return 0;
    const metersInMile = 1609.34;
    // Create lineString
    let line = new H.geo.LineString();
    waypoints.forEach((p) => line.pushPoint(normalizeLatLng(p)));
    // Create polyline
    const polyline = new H.map.Polyline(line);
    polyline.setGeometry(line);
    const geometry = polyline.getGeometry();
    let distance = 0;
    // Get distance between polyline points
    let last = geometry.extractPoint(0);
    for (let i = 1; i < geometry.getPointCount(); i++) {
        const point = geometry.extractPoint(i);
        distance += last.distance(point);
        last = point;
    }
    if (polyline.isClosed())
        distance += last.distance(geometry.extractPoint(0));

    // distance in miles
    return distance / metersInMile;
};

// Return the radius(meters) of the circle that circumscribes the bounds
export const getCircumscribedRadius = (bounds) =>
    crs.distance(bounds.sw, bounds.ne) / 2;

// while technically not exact, should be much faster
export const manhattanDistance = (a, b) =>
    Math.abs(a.lat - b.lat) + Math.abs(a.lng - b.lng);

export const pointDistance = (a, b) => {
    const dy = a.lat - b.lat;
    const dx = a.lng - b.lng;
    return Math.sqrt(dx * dx + dy * dy);
};

export const findNearestIndex = (latlng, latLngArray, distanceFn) =>
    latLngArray.reduce((prev, curr, i) => {
        const currDist = distanceFn(curr, latlng);
        return prev[0] <= currDist ? prev : [currDist, i];
    }, [])[1];

export const findNearest = (latlng, latLngArray, distanceFn) =>
    latLngArray[findNearestIndex(latlng, latLngArray, distanceFn)];

export const fastFindNearestIndex = (latlng, latLngArray) =>
    findNearestIndex(latlng, latLngArray, manhattanDistance);

export const fastFindNearest = (latlng, latLngArray) =>
    findNearest(latlng, latLngArray, manhattanDistance);

export const getPointsDistances = (points) => {
    let sum = 0;
    const list = points.slice(0, -1).map((point, i) => {
        const distance = manhattanDistance(point, points[i + 1]);
        sum += distance;
        return sum;
    });

    return [0, ...list];
};

export const pointFromProgress = (distances, points, progress) => {
    const normalizedProgress = progress * distances[distances.length - 1];
    let i = 0;
    for (; i < distances.length && distances[i] < normalizedProgress; i++) {
        //increase i with a for
    }

    if (i === 0) return points[0];
    const d0 = distances[i - 1];
    const d1 = distances[i];

    const t = (normalizedProgress - d0) / (d1 - d0);

    return lerpPoint(points[i - 1], points[i], t);
};

export const progressFromPoint = (distances, points, point) => {
    if (!(points || []).length || !point) return 0;

    const index = fastFindNearestIndex(point, points);
    return progressFromPointIndex(distances, index);
};

export const progressFromPointIndex = (distances, i) => {
    const maxDistance = distances[distances.length - 1];

    if (i < 0) return 0;

    return distances[i] / maxDistance;
};

export const pointFromProgressExact = (distances, points, progress) => {
    const normalizedProgress = progress * distances[distances.length - 1];
    let i = 0;
    for (; i < distances.length && distances[i] < normalizedProgress; i++) {
        //increase i with a for
    }

    if (i === 0) return points[0];

    return points[i - 1];
};

export const currentPointFromProgress = (
    distances,
    points,
    progress,
    nearestArray
) => {
    if (!distances || !points || !progress || !nearestArray) return null;

    const point = pointFromProgressExact(distances, points, progress);
    const index = Math.max(points.indexOf(point), 0);

    for (let i = index + 1; i >= 0; i--) {
        const p = points[i];
        const match = nearestArray.find((n) =>
            latLngEqual(p, normalizeLatLng(n))
        );
        if (match) return match;
    }

    return null;
};

export const nearestRidePointIndex = (points, waypoints, nearest) => {
    if (!nearest) {
        return -1;
    }

    // this method only works when we have some ride points
    if ((waypoints || []).length < 2 || !(points || []).length) {
        return (waypoints || []).length;
    }

    const nearestIndex = fastFindNearestIndex(nearest, points);
    const nearestIndicies = waypoints.map((e) =>
        fastFindNearestIndex(e, points)
    );
    const index = nearestIndicies.findIndex((i) => nearestIndex <= i);
    const maxIndex = Math.max(...nearestIndicies);
    if (nearestIndex >= maxIndex) {
        return nearestIndicies.length - 1;
    }
    return index;
};

export const nearestPointIndex = (points, waypoints, nearest) => {
    if (!nearest || (waypoints || []).length < 2 || !(points || []).length)
        return 0;

    const i = nearestRidePointIndex(points, waypoints, nearest);
    if (
        i <= 0 ||
        manhattanDistance(waypoints[i], nearest) <
            manhattanDistance(waypoints[i - 1], nearest)
    ) {
        return i;
    }
    return i - 1;
};
