/******************************************************************************\
* File: Section.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/sidebar/Section.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const Section = ({
    title,
    subtitle,
    icon: IconComponent,
    children,
    className,
    header,
    expanded,
    shortText,
    selected,
    titleLeft,
    titleRight,
    padTop,
    onClick,
    wide
}) => {
    return (
        <section
            className={classNames(style.Section, {
                [style[className]]: !!className,
                [style['header']]: !!header,
                [style['expanded']]: !!expanded,
                [style['short-text']]: !!shortText
            })}
        >
            {!!IconComponent && <IconComponent />}
            {title && (
                <div
                    className={classNames(style.title, {
                        [style['padTop']]: padTop
                    })}
                >
                    <span>{title}</span>
                    {titleRight && (
                        <span className={style.titleRight}>{titleRight}</span>
                    )}
                    {onClick && (
                        <span
                            className={style.caret}
                            data-expanded={expanded}
                            onClick={onClick}
                        />
                    )}
                </div>
            )}
            {subtitle && (
                <div
                    className={classNames(style.subtitle, {
                        [style['padTop']]: padTop
                    })}
                >
                    <span>{subtitle}</span>
                </div>
            )}
            {selected && <div className={style.selected}>{selected}</div>}
            <div
                className={classNames(style.children, {
                    [style['wide']]: !!wide
                })}
            >
                {children}
            </div>
        </section>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Section;
