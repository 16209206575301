import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import dayjs from 'dayjs';

import style from '@/style/mapListView/PreviewFooter.scss';
import footerStyle from '@/style/mapListView/Footer.scss';

import Progress from '@/components/mapListView/Progress';
import OptionsToggle from '@/components/mapListView/OptionsToggle';

import {
    toggleCategory,
    getPOICheckboxes,
    getChargingStationCheckboxes,
    toggleChargingStation
} from '@/store/poi';
import {
    updateForecast,
    setCurrentRouteProgress,
    getCurrentRide
} from '@/store/rides';
import { getUnixTime } from '@/helpers/functions';
import { update } from '@/store/rides/preview';
import { getFeatureCheckboxes } from '@/store/edit_ride';
import { Routes } from '@/helpers/routes';
import { translate, getLang } from '@/helpers/i18n';
//App context
import AppContext from '@/contexts/AppContext';

const t = translate('mapListView.PreviewFooter');
const isDev =
    process.env.NODE_ENV === 'development' ||
    process.env.ENVIRONMENT === 'maxwell' ||
    process.env.ENVIRONMENT === 'phoenix';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PreviewFooter extends React.Component {
    state = {
        showTimeModal: false,
        pointsAsPercents: [],
        currentPercent: 0,
        lastRouteProgress: 0,
        currentWaypointLength: 0,
        currentTime: null
    };

    openTimeModal = () => {
        this.setState({ showTimeModal: true });
    };

    closeTimeModal = () => {
        this.props.updateForecast();
        this.setState({ showTimeModal: false });
    };

    handleChangeTime = (o) => {
        const { onUpdate } = this.props;
        onUpdate('datetime')(o);
    };

    getChargingStationCheckboxes = () => {
        const { getChargingStationCheckboxes, toggleChargingStation } =
            this.props;
        return getChargingStationCheckboxes(toggleChargingStation);
    };

    render() {
        const {
            className,
            ride,
            currentRouteProgress,
            onSelectPosition,
            toggleCheckboxModal,
            showOptions,
            setShowOptions,
            dropdownOpen,
            previewOpen,
            user
        } = this.props;

        const { poiCheckboxes, toggleCategory } = this.props;
        const options = poiCheckboxes(toggleCategory);

        const { isMobile } = this.context;

        const cn = classNames(style.PreviewFooter, {
            [className]: className
        });

        const { locationHistory, isRecordedRide } = ride;

        const { pointsAsPercents, lastRouteProgress, currentWaypointLength } =
            this.state;
        const shouldUpdatePoints = pointsAsPercents.length === 0;

        const closestPoint = (percents, currentPoint) =>
            percents.sort(
                (a, b) =>
                    Math.abs(currentPoint - a.percent) -
                    Math.abs(currentPoint - b.percent)
            )[0];

        const getFirstAndTotalTime = (lh) => {
            const first = getUnixTime(lh[0].timestamp);
            const last = getUnixTime(lh[lh.length - 1].timestamp);
            const totalTime = last - first;
            return { first, totalTime };
        };

        const getDistance = (locationHistory) => {
            let prevDistance = 0;
            return locationHistory.map((l, idx) => {
                const prevTimestamp = locationHistory[idx - 1];
                const time = (prevTimestamp || {}).timestamp
                    ? getUnixTime(l.timestamp) -
                      getUnixTime(prevTimestamp.timestamp)
                    : 0;
                prevDistance += l.speed * time * 100;
                return prevDistance;
            });
        };

        const processLocationHistory = (
            { locationHistory },
            useTime = false
        ) => {
            const { first, totalTime } = getFirstAndTotalTime(locationHistory);
            const distances = getDistance(locationHistory);
            const totalDistance = distances[distances.length - 1];
            // use speed to determine distance travelled
            const pointsAsPercents = locationHistory.map((item, idx) => {
                const percentTime =
                    (getUnixTime(item.timestamp) - first) / totalTime;
                const percentDist = distances[idx] / totalDistance;
                return {
                    ...item,
                    percent: useTime ? percentTime : percentDist
                };
            });
            this.setState({ pointsAsPercents });
            return pointsAsPercents;
        };

        if (
            isRecordedRide &&
            !!locationHistory &&
            locationHistory.length > 0 &&
            shouldUpdatePoints
        ) {
            const locationHistoryTimestamp = new dayjs(
                locationHistory[0].timestamp
            );
            this.handleChangeTime({
                date: locationHistoryTimestamp,
                time: locationHistoryTimestamp.format('HH')
            });
            processLocationHistory(ride);
        }

        if (lastRouteProgress !== currentRouteProgress) {
            const { pointsAsPercents } = this.state;
            const currentLocation = closestPoint(
                pointsAsPercents,
                currentRouteProgress
            );
            if (!!currentLocation) {
                const locationTimestamp = new dayjs(currentLocation.timestamp);
                this.handleChangeTime({
                    date: locationTimestamp,
                    time: locationTimestamp.format('HH')
                });
                this.setState({
                    lastRouteProgress: currentRouteProgress,
                    currentTime: currentLocation.timestamp
                });
            }
        }

        // TODO: Remove when confirmed
        const groups = [{ title: t('Show on Map'), options }];

        if (user && user.data.evBikeOwner) {
            const chargingStationCheckboxes = {
                title: t('Charging Stations'),
                options: this.getChargingStationCheckboxes()
            };
            groups.push(chargingStationCheckboxes);
        }
        const isJapan = getLang() === 'ja';
        return (
            <div style={{ position: 'relative' }}>
                <div>
                    {previewOpen && (
                        <div className={cn}>
                            {!isMobile && (
                                <div className={style.mainBar}>
                                    <div className={footerStyle.left} />
                                    <div className={footerStyle.right}>
                                        <OptionsToggle
                                            className={classNames(
                                                style.rightPad,
                                                {
                                                    [style['mapStyles']]:
                                                        !isJapan
                                                }
                                            )}
                                            groups={groups.filter(
                                                (group) => group.options.length
                                            )}
                                            displayTitle="Show on Map"
                                            toggleCheckboxModal={
                                                toggleCheckboxModal
                                            }
                                            showTooltip={showOptions}
                                            setShowTooltip={setShowOptions}
                                            tooltipClassName={style.tooltip}
                                            evBikeOwner={
                                                user && user.data.evBikeOwner
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <Progress
                                length={ride.length}
                                value={currentRouteProgress}
                                onSelectPosition={onSelectPosition}
                                dropdownOpen={dropdownOpen}
                                previewOpen={previewOpen}
                                waypoints={ride.waypoints}
                                points={ride.points}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => ({
    currentRouteProgress: state.rides.preview.currentRouteProgress,
    ride: getCurrentRide(state),
    meta: state.edit_ride.present.meta,
    forecast: state.rides.preview.forecast,
    avoidances: getFeatureCheckboxes(state)(undefined),
    datetime: state.rides.preview.datetime,
    getChargingStationCheckboxes: getChargingStationCheckboxes(state.poi),
    poiCheckboxes: getPOICheckboxes(state.poi),
    user: state.user,
    dropdownOpen: state.rides.preview.dropdownOpen
});
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    onSelectPosition: (percent) => dispatch(setCurrentRouteProgress(percent)),
    toggleCategory: (category) => dispatch(toggleCategory(category)),
    updateForecast: () => dispatch(updateForecast()),
    toggleChargingStation: (category) =>
        dispatch(toggleChargingStation(category)),
    onUpdate: (field) => (value) => dispatch(update(field, value))
});

PreviewFooter.contextType = AppContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default connect(mapStateToProps, mapDispatchToProps)(PreviewFooter);
