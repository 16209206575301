function getAttribute(attr) {
    let value = this[attr];

    if (!value) {
        let attrs = this.attributes;
        if (attrs) {
            let length = attrs.length;
            for (let i = 0; i < length; i++)
                if (attrs[i].nodeName === attr) value = attrs[i].nodeValue;
        }
    }

    return value;
}

if (window.Element && !Element.prototype.getAttribute) {
    Element.prototype.getAttribute = getAttribute;
}

if (window.SVGUseElement && !SVGUseElement.prototype.getAttribute) {
    SVGUseElement.prototype.getAttribute = getAttribute;
}
