import { ScreenSizes } from '@/helpers/constants';

export const getScreenSize = () => {
    if (window.matchMedia('screen and (max-width: 767px)').matches) {
        return ScreenSizes.MOBILE;
    } else if (window.matchMedia('screen and (max-width: 1025px)').matches) {
        return ScreenSizes.TABLET;
    } else {
        return ScreenSizes.DESKTOP;
    }
};
