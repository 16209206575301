/******************************************************************************\
 * File: ArrowLink.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import Link from '@/components/common/header/Link';
import ArrowRight from '@/components/common/icons/ArrowRight';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/ArrowLink.scss';
import { translate } from '@/helpers/i18n';
const t = translate('common.ArrowLink');
//------------------------------------------------------------------------------
// React Function --------------------------------------------------------------
const ArrowLink = ({ text = t('See More'), left, ...rest }) => {
    return !left ? (
        <Link className={style.SeeMore} {...rest}>
            {text} <ArrowRight />
        </Link>
    ) : (
        <Link className={style.SeeMore} {...rest}>
            <ArrowRight
                style={{
                    transform: 'rotate(180deg)',
                    marginBottom: '-3px'
                }}
            />{' '}
            {text}
        </Link>
    );
};

//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ArrowLink;
