/******************************************************************************\
 * File: HomeModal.jsx
 *
 * Description: Home page modal
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import classNames from 'classnames';
import ReactModal from 'react-modal';
import Close from '@/components/common/icons/Close';
import { formattedDateRangeOrDaysLeft } from '@/helpers/functions';
import Link from '@/components/common/header/Link';
import Helmet from 'react-helmet';
import CoinView from './CoinView';
import { getBadgeFrameUrls } from '@/helpers/api';
import { universalLinkScroll } from '@/helpers/mobile';
import { appStoreLinks } from '@/shared/constants';
import style from '@/style/homeView/HomeModal.scss';
import { translate } from '@/helpers/i18n';

const t = translate('homeView.HomeModal');

const HomeModal = ({
    className,
    withBackground,
    large,
    items,
    onRequestClose,
    selected,
    isMobile,
    ...props
}) => {
    const [modalIsOpen, setModalIsOpen] = useState(true);
    const [frameUrls, setFrameUrls] = useState(null);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        if (selected && selected.frameBundleUrl) {
            fetchFrames(selected.type);
        }
    }, [selected]);

    const fetchFrames = (type) => {
        getBadgeFrameUrls(type, { size: 376 }).then((urls) => {
            setFrameUrls(urls);
        });
    };

    useEffect(() => {
        const { pathname, href } = window.location;
        const appleLink = `app-id=1292723595, app-argument=${href}`;

        if (isMobile && !scrolled) {
            universalLinkScroll();
            setScrolled(true);
        }

        return () => {
            // Cleanup code if needed
        };
    }, [isMobile, scrolled]);

    const cnOverlay = classNames(style.overlay, {
        [style['large']]: !!large
    });

    const cn = classNames(style.content, {
        [className]: !!className
    });

    return (
        <ReactModal
            isOpen={modalIsOpen}
            overlayClassName={cnOverlay}
            className={cn}
            shouldCloseOnOverlayClick={true}
            closeTimeoutMS={0}
            isMobile={isMobile}
            {...props}>
            <Helmet>
                <meta name="apple-itunes-app" content={`app-id=1292723595`} />
            </Helmet>

            <div className={style.ModalContainer}>
                <div className={style.header}>
                    <span className={style.headerImage}>
                        <img src="/img/hd-logo.png" alt="HD Logo" />
                    </span>
                    <Link className={style.close} to="/">
                        <Close onClick={onRequestClose} />
                    </Link>
                </div>
                <div className={style.content}>
                    <div className={style.HomeModalImageModalBg}>
                        <img
                            src="/img/image-modal-bg@2x.jpg"
                            alt="Modal Background"
                        />
                    </div>
                    <div className={style.details}>
                        <div
                            className={style.section}
                            style={{ paddingTop: '32px' }}>
                            <CoinView
                                className={style.ChallengeCoin}
                                imageUrl={selected.imageUrl}
                                frameUrls={
                                    frameUrls &&
                                    process.env.NODE_ENV === 'development'
                                        ? frameUrls.map(
                                              (url) =>
                                                  `http://localhost:8081/${url}`
                                          )
                                        : frameUrls
                                }
                            />
                            <div className={style.WinnerName}>
                                {t('achievement', {
                                    name: (
                                        <span className={style.winner}>
                                            {selected.nickName}
                                        </span>
                                    ),
                                    t: '{name} earned a custom digital coin.'
                                })}
                            </div>
                            <div className={style.hr} />
                        </div>
                        <div className={style.section}>
                            <div className={style.SectionHeader}>
                                {t('H-D ACTIVE & UPCOMING CHALLENGES')}
                            </div>
                            <div className={style.SectionHeaderDescription}>
                                {t(
                                    'More riding, more fun. Hit the road and conquer a challenge.'
                                )}
                            </div>
                            <div className={style.hr} />
                        </div>
                        {!!items &&
                            items.map((item, i) => (
                                <div className={style.section} key={item.id}>
                                    <div className={style.SectionTitle}>
                                        {item.title}
                                    </div>
                                    <div className={style.SectionDescription}>
                                        <span className={style.clockIcon}>
                                            <img
                                                src="/img/icon-clock.svg"
                                                alt="Clock Icon"
                                            />
                                        </span>
                                        {formattedDateRangeOrDaysLeft(
                                            item.startDate,
                                            item.endDate
                                        ).toUpperCase()}
                                    </div>
                                    <div className={style.hr} />
                                </div>
                            ))}
                    </div>
                </div>
                <div className={style.footer}>
                    <div className={style.footerText}>
                        {t('GET THE HARLEY-DAVIDSON™ APP')}
                    </div>
                    <div className={style.footerLinks}>
                        {appStoreLinks.map((item, i) => (
                            <a href={item.link} key={i}>
                                <img src={item.img} alt="App Store Link" />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default HomeModal;
