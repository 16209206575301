/******************************************************************************\
 * File: MarkerCluster.jsx
 *
 * Author: Gigster
 *
 * Description: Here map marker cluster
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { createHereMarker } from '@/helpers/map';
import {
    normalizeLatLng,
    latLngValid,
    testSupportsPassive
} from '@/helpers/functions';
//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
import MapContext from '@/contexts/MapContext';
const log = createLogger('MarkerCluster', false);
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class MarkerCluster extends React.Component {
    static defaultProps = {
        // Each item should have geocoords
        items: [],
        // Maximum radius of the neighborhood
        eps: 24,
        // minimum weight of points required to form a cluster
        minWeight: 500,
        render: (count) => {},
        disableBehavior: false,
        // number of re-updates to keep previously added datapoints around for
        lifetime: 2,
        zIndex: 1,
        getItemPosition: (item) => item.position || item
    };

    clusterTheme = (render, getPosition) => ({
        getClusterPresentation: function (cluster) {
            // Pick random datapoint in cluster for data
            const datapoints = [];
            cluster.forEachDataPoint((datapoint) => datapoints.push(datapoint));

            const data = datapoints.map((datapoint) => datapoint.getData());

            const clusterMarker = createHereMarker(
                {
                    ...data[0],
                    ...(render(data[0], data.length, data) || {}),
                    position: getPosition(data[0]),
                    options: {
                        min: cluster.getMinZoom(),
                        max: cluster.getMaxZoom()
                    }
                },
                'getClusterPresentation'
            );

            clusterMarker.setData({ ...data[0], clustering: cluster });

            return clusterMarker;
        },

        getNoisePresentation: function (noisePoint) {
            const data = noisePoint.getData();

            const noiseMarker = createHereMarker(
                {
                    ...data,
                    ...(render(data, 1, [data]) || {}),
                    position: noisePoint.getPosition(),
                    options: {
                        min: noisePoint.getMinZoom()
                    }
                },
                'getNoisePresentation'
            );

            noiseMarker.setData({ ...data, clustering: noisePoint });

            return noiseMarker;
        }
    });

    supportsPassive = false;

    componentDidMount = () => {
        const { items, eps, minWeight, render, zIndex } = this.props;
        const { map } = this.context;

        // Create a cluster provider with no datapoints
        const clusterProvider = new H.clustering.Provider([], {
            clusteringOptions: { eps, minWeight },
            theme: this.clusterTheme(render, this.props.getItemPosition)
        });
        this.supportsPassive = testSupportsPassive();

        // Cluster tap event
        clusterProvider.addEventListener(
            'tap',
            this.onClick,
            this.supportsPassive ? { passive: true } : false
        );

        // Create a layer that will consume objects from our clustering provider
        const clusterLayer = new H.map.layer.ObjectLayer(clusterProvider);
        if (!!clusterLayer) {
            // Add layer to map
            map.addLayer(clusterLayer, zIndex);
            // Store everything in state
            this.setState({ clusterLayer, clusterProvider });
        }

        // Set datapoints on clusterProvider
        this.items = {};
        this.updateClustering(clusterProvider, items);
    };

    componentDidUpdate = (prevProps) => {
        const { items: nextItems } = this.props;
        const { items } = prevProps;
        const { clusterProvider } = this.state;

        if (clusterProvider && nextItems !== items && nextItems.length) {
            if (
                nextItems.length !== items.length ||
                nextItems.some((item, i) => item.id !== items[i].id)
            ) {
                this.updateClustering(clusterProvider, nextItems);
            }
        }
    };

    componentWillUnmount = () => {
        const { map } = this.context;
        const { clusterLayer } = this.state || { clusterLayer: null };
        if (!!clusterLayer) map.removeLayer(clusterLayer);
    };

    createItem = (item) => {
        const position = normalizeLatLng(
            this.props.getItemPosition(item) || {}
        );

        if (!latLngValid(position)) return;

        return {
            id: item.id,
            life: 0,
            datapoint: new H.clustering.DataPoint(
                position.lat,
                position.lng,
                null,
                item
            )
        };
    };

    updateClustering = (clusterProvider, nextItems) => {
        const nextDatapoints = [];

        // sort through nextItems
        nextItems.forEach((nextItem) => {
            const item = this.createItem(nextItem);
            if (!item) return;

            // reset life counter
            if (item.id in this.items) {
                this.items[item.id].life = 0;
            } else {
                this.items[item.id] = item;
                nextDatapoints.push(item.datapoint);
            }
        });

        // add new items
        clusterProvider.addDataPoints(nextDatapoints);

        // clean up old datapoints
        const { lifetime } = this.props;
        this.updateItems(clusterProvider, lifetime);
    };

    updateItems = (clusterProvider, lifetime) => {
        this.items = Object.keys(this.items).reduce((items, id) => {
            const item = this.items[id];

            if (item.life >= lifetime) {
                clusterProvider.removeDataPoint(item.datapoint);
                return items;
            }

            items[id] = { ...item, life: item.life + 1 };
            return items;
        }, {});
    };

    onClick = (e) => {
        const { onClick, disableBehavior } = this.props;
        const { map } = this.context;

        log('Cluster was clicked');

        onClick && onClick({ ...e, map });

        if (disableBehavior) return;
        log('Behavior not disabled');

        const marker = e.target;
        const center = marker.getGeometry();

        // Get clustering data
        const clustering = marker.getData().clustering;

        const minZoom = clustering.getMinZoom();

        if (clustering.isCluster()) {
            log('Marker is a cluster');

            const zoom = clustering.getMaxZoom();
            const nextZoom = map.getBaseLayer().isValid(zoom + 1)
                ? zoom + 1
                : zoom;

            log('Current Zoom:', zoom);
            log('Next Zoom:', nextZoom);

            const markers = [];
            clustering.forEachDataPoint((datapoint) =>
                markers.push(datapoint.getData())
            );

            // callback with all markers
            const { onMarkerClick } = this.props;
            const args = {
                map,
                center,
                zoom: nextZoom,
                minZoom,
                maxZoom: zoom,
                isValid: nextZoom !== zoom
            };
            onMarkerClick && onMarkerClick(markers, args);
        } else {
            // callback with single marker
            const { onMarkerClick } = this.props;
            const args = { map, center, minZoom, zoom: minZoom };
            onMarkerClick && onMarkerClick(marker.getData(), args);
        }
    };

    render() {
        // React component won't actually handle rendering
        return null;
    }
}

MarkerCluster.contextType = MapContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default MarkerCluster;
