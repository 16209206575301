/******************************************************************************\
 * File: NumberIcon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const NumberIcon = ({ fill, children, className, ...rest }) => (
    <div
        className={classNames(style.Icon, {
            [style['no-stroke']]: true,
            [className]: !!className
        })}
        {...rest}
    >
        <svg viewBox="0 0 16 16" style={{ width: '100%', height: '100%' }}>
            <g stroke="none">
                <rect
                    fill="#4C4C4C"
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                    style={{ fill: fill ? fill : '#4C4C4C' }}
                />
                <text
                    x="8"
                    y="8"
                    dy="4"
                    textAnchor="middle"
                    fill="#fff"
                    style={{
                        fontFamily: "'Noto Sans', sans-serif",
                        fontSize: 12,
                        fontWeight: 'bold',
                        letterSpacing: 0.2
                    }}
                >
                    {children}
                </text>
            </g>
        </svg>
    </div>
);

export default NumberIcon;
