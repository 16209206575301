/******************************************************************************\
* File: react.jsx
*
* Author: Gigster
*
* Description:  React helpers
*
* Notes: 
\******************************************************************************/

export const getComponentName = (Component) =>
    Component.displayName || Component.name || 'Component';
