/******************************************************************************\
 * File: ShareBar.jsx
 *
 * Author: Gigster
 *
 * Description: App header
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/header/ShareBar.scss';
//------------------------------------------------------------------------------
// Store -----------------------------------------------------------------------
import store from '@/store';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import { Button } from '@/components/common/Modal';
import SaveButton from '@/components/mapListView/SaveButton';

import Actions from './Actions';
import Link from '@/components/common/header/Link';
import Bookmark from '@/components/common/Bookmark';
import PencilIcon from '@/components/common/icons/Pencil';
import PrintIcon from '@/components/common/icons/Print';
import DownloadGPXButton from '@/components/mapListView/DownloadGPXButton';
import CopyIcon from '@/components/common/icons/Copy';
import ShareDropdown from '@/components/common/header/ShareDropdown';
import Tooltip from '@/components/common/form/Tooltip';
import { analyticsRideEvent } from '@/helpers/analytics';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { translate } from '@/helpers/i18n';
const t = translate('header.ShareBar');
const tPrint = translate('directions.PrintView');

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ShareBar extends React.Component {
    render() {
        const {
            className,
            isBookmarked,
            setBookmark,
            onEditRide,
            onCopyRide,
            ride,
            url,
            profile,
            isAuthenticated,
            onSignIn,
            onLogout,
            canEdit,
            onClickPrint,
            openSettingsModal,
            openConvertRideModal,
            isRecorded
        } = this.props;

        const cn = classNames(style.ShareBar, {
            [className]: className
        });
        const showPrint = !ride.offRoad && !isRecorded;
        return (
            <Actions className={cn}>
                {setBookmark && (
                    <Tooltip
                        value={isBookmarked ? t('Unbookmark') : t('Bookmark')}
                        placement="s"
                        noDelay>
                        <div>
                            <Bookmark
                                className={style.icon}
                                active={isBookmarked}
                                onToggle={setBookmark}
                            />
                        </div>
                    </Tooltip>
                )}
                {url && (
                    <ShareDropdown
                        active={!!ride && (ride.shortId || ride.id)}
                        url={url}
                        iconClassName={style.icon}
                        activeClassName={style.active}
                    />
                )}
                {showPrint && (
                    <Tooltip value={tPrint('Print')} placement="s" noDelay>
                        <div className={style.printIcon} active={isBookmarked}>
                            <PrintIcon onClick={onClickPrint} />
                        </div>
                    </Tooltip>
                )}
                <DownloadGPXButton ride={ride} padded={true} position="s" />
                <Tooltip
                    value={canEdit && !isRecorded ? t('Edit ride') : t('Copy')}
                    placement="s"
                    noDelay>
                    <div>
                        {canEdit && !isRecorded ? (
                            <PencilIcon
                                className={classNames(style.icon, style.active)}
                                onClick={() => {
                                    analyticsRideEvent('edit ride');
                                    onEditRide();
                                }}
                            />
                        ) : (
                            <CopyIcon
                                className={style.icon}
                                onClick={() => {
                                    analyticsRideEvent('copy ride');
                                    openConvertRideModal();
                                }}
                            />
                        )}
                    </div>
                </Tooltip>
            </Actions>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ShareBar;
