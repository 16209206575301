/******************************************************************************\
* File: Map.jsx
*
* Author: Gigster
*
* Description: Map
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/mapListView/Map.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Map_ from '@/components/common/map/Map';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Map extends React.Component {
    render() {
        const { className, children, ...rest } = this.props;

        const cn = classNames(style.Map, {
            [className]: className
        });

        return (
            <div className={cn}>
                <Map_ {...rest}>{children}</Map_>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Map;
