/******************************************************************************\
 * File: RideEventEdit.jsx
 *
 * Author: Gigster
 *
 * Description: Modal that accepts a date and time
 *
 * Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/RideEvent.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import DatePicker from '@/components/common/form/DatePicker';
import Select from '@/components/common/form/Select';
import Clock from '@/components/common/icons/Clock';
import Link from '@/components/common/header/Link';
import Tooltip from '@/components/common/form/Tooltip';
import Text from '@/components/common/form/Text';
import MiniArrowUp from '@/components/common/icons/MiniArrowUp';

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { halfHours } from '@/helpers/lists';
import { translate, formatTime, formatDate } from '@/helpers/i18n';
const t = translate('mapListView.RideEvent');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class RideEventEdit extends React.Component {
    render() {
        const {
            ride,
            ride: { event },
            onLinkChange,
            linkError,
            onTimeChange,
            onDateChange
        } = this.props;

        return (
            <div className={style.RideEventEdit}>
                <div className={style.column}>
                    <div className={style.inputs}>
                        <DatePicker
                            value={
                                event && event.rideDate
                                    ? dayjs(event.rideDate)
                                    : null
                            }
                            onChange={onDateChange('rideDate', 'rideEditDate')}
                            className={style.select}
                            tooltipLeftAlign={true}
                            wide={true}
                        />
                        <div style={{ display: 'flex' }}>
                            <Select
                                placeholder={t('Time')}
                                value={
                                    event && event.startTime
                                        ? event.startTime
                                        : null
                                }
                                options={halfHours.map((hour) => ({
                                    ...hour,
                                    text: hour.text
                                }))}
                                onChange={onTimeChange('time')}
                                icon={Clock}
                                className={style.select}
                                wide={true}
                                leftAlign={true}
                                highZ={true}
                            />
                            <Tooltip
                                value={t(
                                    'Timezone will be determined by date, time, and start location of the ride.'
                                )}
                                placement="s"
                                multiline={true}
                                noDelay
                                leftAlign={true}>
                                <div className={style.infoIcon}>
                                    <img
                                        src={'/img/icon-info-small-black.png'}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                        <div className={style.rideEventDealerId}>
                            <MiniArrowUp className={style.smallIcon} />
                            <Text
                                editable={true}
                                value={(event || {}).link}
                                placeholder={t('URL to event page [optional]')}
                                customOnBlur={onLinkChange}
                                wide={true}
                                icon={'/img/icon-info-small-black.png'}
                                toolTipText={t(
                                    'Optionally you can provide a link to an event page where people can learn more, rsvp or register as necessary.  We suggest linking to a Facebook event.'
                                )}
                                tooltipOffsetright={true}
                                tooltipLeftAlign={true}
                                errorText={t(
                                    'Invalid URL (ex: https://www.h-d.com)'
                                )}
                                error={linkError}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default RideEventEdit;
