/******************************************************************************\
 * File: Button.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/Button.scss';
import Link from '@/components/common/header/Link';
//------------------------------------------------------------------------------
// React Function --------------------------------------------------------------
const Button = (props) => {
    const {
        primary,
        success,
        disabled,
        className,
        text,
        children,
        onClick,
        ...rest
    } = props;

    const cn = classNames(style.Button, {
        [style['primary']]: !!primary,
        [style['success']]: !!success,
        [style['disabled']]: !!disabled,
        [style['clickable']]: !!onClick || !!rest.href,
        [className]: !!className
    });

    return (
        <Link className={cn} onClick={onClick} {...rest}>
            {text || children}
        </Link>
    );
};
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Button;
