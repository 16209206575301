/******************************************************************************\
 * File: gigya.js
 *
 * Author: Gigster
 *
 * Description: Functions for loading the Gigya WebSDK
 *
 * Notes: https://developers.gigya.com/display/GD/Web+SDK
 \******************************************************************************/

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { Env } from '@/shared/constants';
import { getLang } from '@/helpers/i18n';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------

// id attribute for Gigya WebSDK script tag
const GIGYA_DOM_ID = 'gigya-injected-script';

// List of callbacks to be invoked when the Gigya WebSDK is loaded
let onSDKLoadedCallbacks = [];

// Determines if the Gigya WebSDK has loaded
let gigyaWebSDKLoaded = false;

// Status constants for login attempts
const STATUS_FAIL = 'FAIL';
const STATUS_SUCCESS = 'OK';

// Gigya screen sets
const DEFAULT_REGISTRATION_LOGIN_SCREEN_SET = `Default-RegistrationLogin`;
const DEFAULT_PROFILE_UPDATE_SCREEN_SET = `Default-ProfileUpdate`;

// Gigya screen types
const COMPLETE_REGISTRATION_SCREEN = 'gigya-complete-registration-screen';
const LOGIN_SCREEN = 'gigya-login-screen';
const REGISTRATION_SCREEN = 'gigya-registration-screen';

// Gigya events
const AFTER_SUBMIT = 'afterSubmit';

// Gigya errors to suppress (feedback given within screenset or non actionable)
const SUPPRESS_ERRORS = [403005, 206001];

const GIGYA_ENV = (() => {
    switch (process.env.ENVIRONMENT) {
        case Env.PRODUCTION:
            return '';
        case Env.STAGING:
            return 'qa';
        case Env.ACCEPTANCE:
        default:
            return 'dev';
    }
})();

// Used to inject the Gigya WebSDK into the DOM
export const ensureGigyaWebSDK = (cb) => {
    if (cb && typeof cb == 'function') {
        onSDKLoadedCallbacks.push(cb);
    }
    if (gigyaWebSDKLoaded) {
        invokeCallbacks();
    } else {
        injectGigyaWebSDK();
    }
};

// Invokes the given screenset after ensuring that gigya is loaded on the page
// This uses hd's showScreenSet proxy method
export const showScreenSet = (screenSet, onError, onAfterSubmit) => {
    ensureGigyaWebSDK(() => {
        gigya.accounts.showScreenSet({
            screenSet: screenSet,
            lang: getLang(),
            containerID:
                screenSet === DEFAULT_PROFILE_UPDATE_SCREEN_SET
                    ? 'gigya_target'
                    : undefined,
            onError: onError,
            onAfterSubmit: onAfterSubmit
        });
    });
};

export const hideScreenSet = (screenSet) => {
    ensureGigyaWebSDK(() => {
        gigya.accounts.hideScreenSet({ screenSet });
    });
};

export const showLoginScreen = (onError, onAfterSubmit) => {
    showScreenSet(DEFAULT_REGISTRATION_LOGIN_SCREEN_SET, onError, (event) => {
        if (
            event.response.status === STATUS_FAIL &&
            SUPPRESS_ERRORS.indexOf(event.response.errorCode) === -1
        ) {
            const err = new Error(event.response.errorMessage);
            err.code = event.response.errorCode;
            return onError(err);
        }

        if (
            [
                COMPLETE_REGISTRATION_SCREEN,
                LOGIN_SCREEN,
                REGISTRATION_SCREEN
            ].indexOf(event.screen) === -1 &&
            !event.profile
        ) {
            return;
        }

        if (event.eventName !== AFTER_SUBMIT) {
            return;
        }

        const response = {
            newUser: event.response.newUser,
            userId: event.response.UID,
            profile: event.response.profile,
            data: event.response.data,
            id_token: event.response.id_token,
            status: event.response.status,
            UIDSignature: event.response.UIDSignature,
            signatureTimestamp: event.response.signatureTimestamp
        };

        // skip registration
        if (
            !response.UIDSignature ||
            !response.userId ||
            !response.signatureTimestamp
        ) {
            return;
        }

        onAfterSubmit(response);
    });
};

export const showProfileScreen = (onAfterSubmit, onError) => {
    showScreenSet(DEFAULT_PROFILE_UPDATE_SCREEN_SET, onError, onAfterSubmit);
};

export const hideProfileScreen = () => {
    hideScreenSet(DEFAULT_PROFILE_UPDATE_SCREEN_SET);
};

export const setAccountData = (data) => {
    return new Promise((resolve, reject) => {
        ensureGigyaWebSDK(() => {
            const callback = (response) => {
                if (response.status !== STATUS_SUCCESS) {
                    return reject(new Error(response.errorMessage));
                }

                resolve(data);
            };

            gigya.accounts.setAccountInfo({ data, callback });
        });
    });
};
export const getIdToken = () => {
    return new Promise((resolve, reject) => {
        ensureGigyaWebSDK(() => {
            gigya.accounts.getJWT({
                callback: (response) => {
                    if (response.status === STATUS_SUCCESS) {
                        resolve({
                            id_token: response.id_token
                        });
                    } else {
                        reject(new Error(response.errorMessage));
                    }
                }
            });
        });
    });
};
export const getAccountData = () => {
    return new Promise((resolve, reject) => {
        ensureGigyaWebSDK(() => {
            gigya.accounts.getAccountInfo({
                callback: (response) => {
                    if (response.status === STATUS_SUCCESS) {
                        resolve({
                            id_token: response.id_token,
                            response: response,
                            profile: response.profile,
                            data: response.data
                        });
                    } else {
                        reject(new Error(response.errorMessage));
                    }
                }
            });
        });
    });
};

const injectGigyaWebSDK = () => {
    let gigyaWebSDKPresent = !!document.getElementById(GIGYA_DOM_ID);
    if (gigyaWebSDKPresent) {
        return;
    }
    let s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = `https://login.harley-davidson.com/hd/${
        GIGYA_ENV + (GIGYA_ENV ? '/' : '')
    }hd.com.js`;
    //    s.src = `https://cdns.gigya.com/js/gigya.js?apikey=${apiKey}`;
    s.setAttribute('id', GIGYA_DOM_ID);
    document.getElementsByTagName('head')[0].appendChild(s);
};

const invokeCallbacks = () => {
    while (onSDKLoadedCallbacks.length) {
        onSDKLoadedCallbacks.shift()();
    }
};

// This function will be called when the Gigya script has finished loading
// Unfortunately needs to be global
window.onGigyaServiceReady = () => {
    gigyaWebSDKLoaded = true;
    invokeCallbacks();
};
