/******************************************************************************\
 * File: InfoBubble.jsx
 *
 * Author: Gigster
 *
 * Description: Here map marker tooltip
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import withRouter from '@/helpers/hooks';

//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/map/here/InfoBubble.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import { normalizeLatLng } from '@/helpers/functions';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { findEl } from '@/helpers/info-bubble';
import { Routes } from '@/helpers/routes';

//------------------------------------------------------------------------------
// Debug -----------------------------------------------------------------------
import { createLogger } from '@/helpers/debug';
const log = createLogger('Info Bubble', true);

//MapContext
import MapContext from '@/contexts/MapContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class InfoBubble extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        currentBubble: null
    };

    previousScrollTop = 0;

    getSnapshotBeforeUpdate(prevProps, prevState) {
        const substring = 'InfoBubble__content';
        const el = document.querySelectorAll(`[class*="${substring}"]`)[0];
        if (el && el.scrollTop > 0) {
            this.previousScrollTop = el.scrollTop;
        }
        return this.previousScrollTop;
    }

    componentDidMount() {
        this.updateInfoBubble(this.props);
    }

    componentWillUnmount() {
        this.previousScrollTop = 0;
        this.removeInfoBubble();
    }

    componentDidUpdate(prevProps) {
        const { position, innerHTML } = this.props;
        const { position: currentPosition, innerHTML: currentInnerHTML } =
            prevProps;

        const { currentBubble } = this.state;
        if (!currentBubble) {
            this.updateInfoBubble(this.props);
            return;
        }

        if (position !== currentPosition) {
            const nextPosition = normalizeLatLng(position);
            if (nextPosition.lat && nextPosition.lng) {
                currentBubble.setPosition(nextPosition);
            } else {
                log('Invalid position in componentWillReceiveProps', position);
            }
        }
        if (innerHTML !== currentInnerHTML) {
            currentBubble.setContent(innerHTML);
            this.scheduleUpdate();

            const substring = 'InfoBubble__content';
            const infoBubbleElement = document.querySelectorAll(
                `[class*="${substring}"]`
            )[0];
            if (infoBubbleElement) {
                // Use the previous scrollTop value to set scrollTop
                infoBubbleElement.scrollTop = this.previousScrollTop || 0;
            }
        }
    }

    setupBubbleClasses = (bubble) => {
        bubble.addClass(style.InfoBubble);

        const element = bubble.getElement();
        const get = findEl(element);

        ['body', 'tail', 'close', 'content'].forEach((o) =>
            get(o).classList.add(style[o])
        );
    };

    onStateChange = (e) => {
        const { onStateChange } = this.props;

        onStateChange(e.target.getState());
    };

    removeInfoBubble = () => {
        const { ui } = this.context;
        const { currentBubble } = this.state;
        const isRides = window.location.pathname.includes('/rides');
        const isPreview = window.location.pathname.includes('/preview');
        const isEdit = window.location.pathname.includes('/edit');
        if (!ui || !currentBubble) return;
        currentBubble
            .getContentElement()
            .removeEventListener('click', this.onClick);
        ui.removeBubble(currentBubble);
        if (isRides && !isPreview && !isEdit) this.props.navigate(Routes.MAP);
        this.setState({ currentBubble: null });
    };

    updateInfoBubble = (props) => {
        const { position: rawPosition, innerHTML, onStateChange } = props;
        const { ui } = this.context;
        log('updateInfoBubble', props);
        this.removeInfoBubble();

        const position = normalizeLatLng(rawPosition || {});
        if (!position.lat || !position.lng) {
            log('Invalid position in updateInfoBubble', rawPosition);
            return;
        }
        const newBubble = new H.ui.InfoBubble(position, {
            content: innerHTML
        });
        newBubble.addEventListener('statechange', this.onStateChange);
        onStateChange('open');

        ui.addBubble(newBubble);
        this.setupBubbleClasses(newBubble);
        this.setState({ currentBubble: newBubble }, this.scheduleUpdate);
    };

    scheduleUpdate = () =>
        setTimeout(() => {
            if (!this) return;
            const { currentBubble } = this.state;
            this.bindEventListeners(currentBubble.getContentElement());
        });

    bindEventListeners = (el) => {
        el.addEventListener('click', this.onClick);
        el.addEventListener('input', this.onInput);

        // attach listeners to the first input (if one exists)
        const input = el.getElementsByTagName('input')[0];
        if (input) {
            input.addEventListener('focus', this.onAction);
            input.addEventListener('blur', this.onAction);
            input.addEventListener('keydown', this.onAction);
        }
    };

    onInput = (e) => {
        const {
            value,
            dataset: { action }
        } = e.target;
        const { onAction } = this.props;

        onAction({ type: action, value });
    };

    onClick = (e) => {
        let { action } = e.target.dataset;
        const itemBookmarkAction =
            e?.srcElement?.id || e?.srcElement?.farthestViewportElement?.id;

        action = !!action ? action : itemBookmarkAction;

        const { onAction } = this.props;

        if (action && action !== 'onchange') {
            if (action !== 'markAsFavorite') this.removeInfoBubble();
            onAction({ type: action, event: e });
        }
    };

    onAction = (e) => {
        const { onAction } = this.props;
        onAction(e);
    };

    render() {
        return null;
    }
}
InfoBubble.contextType = MapContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(InfoBubble);
