/******************************************************************************\
 * File: Hero.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/homeView/Hero.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Hero extends React.Component {
    render() {
        const { title, subtitle, main, children, footer } = this.props;

        const cn = classNames(style.Hero, {
            [style['main']]: !!main
        });

        return (
            <div className={cn}>
                <h2 className={style.title}>{title}</h2>
                <p className={style.subtitle}>{subtitle}</p>
                {children}
                {footer && <div className={style.footer}>{footer}</div>}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Hero;
