/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#FFF" transform="translate(-24 -800)">
                    <g transform="translate(0 66)">
                        <g transform="translate(24 202)">
                            <g transform="translate(0 525)">
                                <g transform="translate(0 7)">
                                    <path d="M17.064 6.081l-2.537.357-.309-1.888 2.537-.356.309 1.887zm.648 3.961l-2.537.357-.309-1.888 2.537-.356.309 1.887zm-5.921-3.22l-2.577.362-.31-1.887 2.578-.362.309 1.887zm.407 1.988l-.316-1.925 2.577-.362.315 1.925-2.576.362zm-2.645.447l2.577-.362.309 1.888-2.577.362-.309-1.888zM6.609 7.626l2.537-.356.315 1.925-2.537.356-.315-1.925zm.806 4.923L19 10.922 17.704 3 5 4.785l1.21 7.398.002.005v.006l1.369 8.36c.042.26.283.446.558.446a.606.606 0 00.086-.006c.308-.043.52-.31.474-.597L7.415 12.55z"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default Icon;
