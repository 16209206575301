/******************************************************************************\
* File: Section.jsx
*
* Author: Gigster
*
* Description: Home page section
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/homeView/Section.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import SectionTitle from '@/components/homeView/SectionTitle';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Section extends React.Component {
    render() {
        const { className, title, subtitle, children } = this.props;

        const cn = classNames(style.Section, {
            [className]: className
        });

        return (
            <div className={cn}>
                {title && <SectionTitle text={title} />}
                {subtitle && <SectionTitle text={subtitle} size={'med'} />}

                {children}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Section;
