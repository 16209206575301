/******************************************************************************\
 * File: InfoBubbleContent.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import dayjs from 'dayjs';
//import { connect } from 'react-redux';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/map/InfoBubbleContent.scss';
import {
    bfs,
    truncate,
    waypointName,
    getOpenState,
    formattedDateRange,
    htmlToPlainText,
    prettyAddressSplit
} from '@/helpers/functions';
import ArrowRight from '@/components/common/icons/ArrowRight';
import Clock from '@/components/common/icons/Clock';
import EventIcon from '@/components/common/icons/Event';

import Bookmark from '@/components/common/icons/Bookmark';
import Link from '@/components/common/header/Link';
import Tooltip from '@/components/common/form/Tooltip';
import {
    translate,
    formatDate,
    getFormattedDateRange,
    formatHDTimeRange
} from '@/helpers/i18n';
import { isEventBookmarked } from '@/store/eventBookmarks';
import { isBookmarked } from '@/store/bookmarks';
import { rideTypeData } from '@/helpers/checkboxes';
import { getRideCheckboxData } from '@/helpers/checkboxes';
import RideEvent from '@/components/common/RideEvent';

//------------------------------------------------------------------------------
// Store -----------------------------------------------------------------------
//import store from '@/store';
const t = translate('map.InfoBubbleContent');
const tr = translate('sidebar.DealerDetails');
const tRide = translate('cards.Ride');
import { userCanEditRide } from '@/store/auth';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------

export const findEl = (start) => (name) =>
    bfs(
        start,
        (n) => n.childNodes,
        (n) => n.classList.contains(`H_ib_${name}`)
    );

const Label = ({ action, label, index }) => (
    <label data-action={action} className={style.action} data-index={index}>
        {label} <ArrowRight />
    </label>
);

const Content = ({
    selectable,
    iconHeader,
    dateInfo,
    head,
    dealerHead,
    isride,
    beforeBody,
    body,
    afterbody,
    dealerAfterBody,
    icon,
    index,
    actions,
    children,
    type
}) => {
    return (
        <div className={type !== 'ride' ? style.wrap : style.wrapRideContent}>
            {!!icon && (
                <div
                    className={
                        type !== 'ride' ? style.left : style.leftRideContent
                    }>
                    <img className={style.icon} src={icon} />
                </div>
            )}
            <div
                className={classNames(
                    type !== 'ride' ? style.right : style.rightRideContent,
                    {
                        [style.itemBorder]: !isride
                    }
                )}>
                {iconHeader && <span>{iconHeader}</span>}
                {dateInfo && dateInfo}
                {head && (
                    <div>
                        <h3
                            className={style.head}
                            {...(selectable
                                ? {
                                      'data-action': 'select',
                                      'data-index': index
                                  }
                                : {})}>
                            {head}
                        </h3>
                    </div>
                )}
                {dealerHead && dealerHead}
                {beforeBody && <p className={style.itemBody}>{beforeBody}</p>}
                {body && typeof body === 'string' && (
                    <p className={style.itemBody}>{htmlToPlainText(body)}</p>
                )}
                {afterbody && <p className={style.itemBody}>{afterbody}</p>}
                {dealerAfterBody && (
                    <div className={style.dealerOpenStatus}>
                        <Clock /> <span>{dealerAfterBody}</span>
                    </div>
                )}
                {children}
                {actions && actions.length > 0 && (
                    <div className={style.actions}>
                        {actions
                            .filter((item) => item.action != 'markAsFavorite')
                            .map((action, i) => (
                                <Label {...action} index={index} key={i} />
                            ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export const ItemBookMark = (item, type, isBookmarked, index) => {
    return (
        <Link>
            <Tooltip
                value={
                    type == 'dealer'
                        ? isBookmarked
                            ? tr('My Dealer') //supplimentalTranslations('My Dealer')
                            : tr('Make My Dealer') //supplimentalTranslations('Make My Dealer')
                        : ''
                }
                placement="w"
                noDelay
                data-index={index}>
                <Bookmark active={isBookmarked} className={'markAsFavorite'} />
            </Tooltip>
        </Link>
    );
};

export const POI = ({ meta, isCreate, ...rest }) => {
    const addRemove = {
        label: `${
            !meta.onRoute || !rest.canRemove
                ? t('Add To Ride')
                : t('Remove From Ride')
        }`,
        action: !meta.onRoute || !rest.canRemove ? 'add' : 'remove'
    };

    const actions = [];
    if (isCreate) actions.push(addRemove);
    if (meta.type === 'CHARGING_STATION')
        actions.push({ label: t('Details'), action: 'more' });

    return (
        <Content
            {...rest}
            head={meta.title}
            body={meta.body}
            icon={meta.icon}
            actions={actions}
        />
    );
};

const rideTypeDetail = (
    ride,
    index,
    ridesBookmarked,
    currentuserId,
    rideTags,
    isItemBookmarked = false
) => {
    const { transportMode, offRoad } = ride;
    const isBicycleRoute = transportMode === 'BICYCLE';
    const itemsBookmarked = {
        bookmarks: ridesBookmarked
    };
    const isRideBookmarked = isItemBookmarked
        ? isItemBookmarked
        : isBookmarked(ride.id, itemsBookmarked);

    const ridetypes = getRideCheckboxData(rideTags);
    const rideSubType =
        ride?.userId !== currentuserId && ride?.type === 'CUSTOM'
            ? 'SHARED'
            : ride.subType;
    const rideType = ridetypes.find((val) => val.value === rideSubType);

    if (!rideType) return;

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px'
            }}>
            <span className={style.iconHeader} style={{ verticalAlign: 'top' }}>
                {!!rideSubType && <img src={`/img/${rideType.typeIcon}`} />}
                <span style={{ paddingRight: '4px', flexGrow: 2 }}>
                    {!!rideType && `${rideType?.type} • ${rideType?.label}`}
                    {isBicycleRoute && (
                        <span>
                            {' '}
                            •{' '}
                            <img
                                src={'/img/icon-ebike.svg'}
                                style={{ width: '32px' }}
                            />
                        </span>
                    )}
                    {!isBicycleRoute && !!offRoad && (
                        <span>
                            {' '}
                            •{' '}
                            <img
                                src={'/img/icon-route-type-off-road.svg'}
                                style={{ width: '32px' }}
                            />
                        </span>
                    )}
                </span>
            </span>
            <span className={style.bookmark} style={{ marginLeft: '35px' }}>
                {ItemBookMark(ride, 'ride', isRideBookmarked, index)}
            </span>
        </div>
    );
};

const rideEvent = (event) => {
    return <RideEvent event={event} isSidePanel={true}></RideEvent>;
};

export const Ride = ({
    meta,
    showActions,
    userId,
    rideTags,
    isBookmarked = false,
    ...rest
}) => {
    const { index, infoBubbleItemsBookmarked: ridesBookmarked } = rest;

    const formattedDate = (dateTime) =>
        `${dayjs(dateTime).format('MMM D, YYYY')}`;
    const { dealerName, modifiedTime, event } = meta;
    const userCanEditCurrentRide = userCanEditRide(userId, meta);
    const editText = userCanEditCurrentRide ? 'Edit' : 'Customize';

    return (
        <Content
            {...rest}
            type="ride"
            iconHeader={rideTypeDetail(
                meta,
                index,
                ridesBookmarked,
                userId,
                rideTags
            )}
            head={meta.name}
            beforeBody={
                !!dealerName ? `${'Recommended by:'} ${dealerName}` : ' '
            }
            body={
                !!userCanEditCurrentRide
                    ? truncate(
                          `${'Last Modified:'} ${formattedDate(modifiedTime)}`
                      )
                    : ''
            }
            afterbody={!!event ? rideEvent(event) : ''}
            isride={true}
            actions={
                showActions
                    ? [
                          {
                              label: t('Preview'),
                              action: 'preview'
                          },
                          {
                              label: tRide(editText).toUpperCase(),
                              action: 'customize'
                          },
                          {
                              action: 'markAsFavorite'
                          }
                      ]
                    : []
            }
        />
    );
};

export const Dealer = ({
    meta,
    route,
    isCreate,
    setMyDealer,
    isBookmarked = false,
    ...rest
}) => {
    const { index, infoBubbleItemsBookmarked } = rest;
    const preferredDealer = infoBubbleItemsBookmarked.preferredDealer;
    const isDealerBookmarked = meta && meta.dealerId === preferredDealer;
    const [address, restOfAddress] = prettyAddressSplit(meta, true) || [];

    const dealerHead = (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3
                className={style.head}
                {...(meta.selectable
                    ? { 'data-action': 'select', 'data-index': index }
                    : {})}>
                {meta.dealerName}
            </h3>
            <span
                className={style.bookmark}
                style={{ margin: '-10px -15px 0 10px' }}>
                {ItemBookMark(meta, 'dealer', isDealerBookmarked, index)}
            </span>
        </div>
    );

    return (
        <Content
            {...rest}
            type="dealer"
            onClick={(e) => {
                e.stopPropagation();
            }}
            // head={}
            dealerHead={dealerHead}
            body={address}
            afterbody={restOfAddress}
            dealerAfterBody={getOpenState(meta.dealerHours, meta.timeZone)}
            icon={meta.icon}
            actions={[
                ...(isCreate
                    ? [{ label: t('Add To Ride'), action: 'add' }]
                    : []),
                { label: t('Details'), action: 'more' },
                { action: 'markAsFavorite' }
            ]}
        />
    );
};

const eventDateDetail = (
    event,
    index,
    eventsBookmarked,
    isBookmarked = false
) => {
    const { startDate, endDate } = event;
    const itemsBookmarked = {
        eventBookmarks: eventsBookmarked
    };
    const isItemBookmarked = isBookmarked
        ? isBookmarked
        : isEventBookmarked(event.eventId, itemsBookmarked);

    const activities = event.eventActivities || [];
    const { hours: eventHours } = activities[0];
    return (
        <div className={style.eventWrapper}>
            <span className={style.CalendarIcon}>
                <EventIcon className={style.icon} />
                {!!startDate && !endDate && (
                    <span style={{ paddingLeft: '4px', color: 'white' }}>
                        {formatDate(startDate)}
                    </span>
                )}
                {!!startDate && !!endDate && (
                    <span style={{ paddingLeft: '4px', color: 'white' }}>
                        {formattedDateRange(startDate, endDate)}
                    </span>
                )}
                {eventHours[0] && (
                    <span style={{ paddingLeft: '4px', color: 'white' }}>
                        {formatHDTimeRange(eventHours[0])}
                    </span>
                )}
            </span>
            <span
                className={style.bookmark}
                style={{ margin: '-5px -15px 0 0' }}>
                {ItemBookMark(event, 'event', isItemBookmarked, index)}
            </span>
        </div>
    );
};

export const Event = ({
    meta,
    showActions,
    isCreate,
    isBookmarked = false,
    ...rest
}) => {
    const { index, infoBubbleItemsBookmarked } = rest;
    const eventsBookmarked = infoBubbleItemsBookmarked.eventsBookmarked;
    const dealerName =
        meta?.eventActivities[0]?.address?.name ||
        meta.eventHostDescription ||
        '';

    return (
        <Content
            {...rest}
            type="event"
            dateInfo={eventDateDetail(meta, index, eventsBookmarked)}
            head={meta.eventName || meta.title}
            body={truncate(dealerName || meta.eventHostName, 120)}
            icon={meta.icon}
            actions={[
                ...(isCreate
                    ? [{ label: t('Add To Ride'), action: 'add' }]
                    : []),
                { label: t('Details'), action: 'more' },
                { action: 'markAsFavorite' }
            ]}
        />
    );
};

export const Waypoint = ({
    meta,
    showActions,
    canConvert,
    canRemove,
    isCreate,
    editable,
    isnotfirst,
    index
}) => (
    <div className={style.contentWrap}>
        {showActions ? (
            <input
                data-action="onchange"
                data-index={index}
                className={style.input}
                type="text"
                defaultValue={meta.name || ''}
                placeholder={t('Name this Location')}
            />
        ) : (
            <h3 className={style.head}>{waypointName(meta)}</h3>
        )}
        <p className={style.body}>
            {meta.address &&
                typeof meta.address === 'string' &&
                htmlToPlainText(meta.address)}
        </p>
        {isCreate && canRemove && (
            <Label action="remove" label={t('Remove')} index={index} />
        )}
        {isCreate && canConvert && isnotfirst && (
            <Label
                action="convert"
                label={t('Change to Waypoint')}
                index={index}
            />
        )}
    </div>
);
