/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <path
                fill="#FFF"
                d="M9.756 38V13.759l.522-.012a4.79 4.79 0 013.91 1.473l10.063 8.605.494.414-4.713 5.615 13.942-.3-2.103-13.81-4.721 5.628-.538-.451-10.065-8.589a8.331 8.331 0 00-6.313-2.315l-4.208.004V38h3.73z"></path>
        </svg>
    );
}

export default Icon;
