/******************************************************************************\
 * File: ImageCarousel.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useEffect } from 'react';

// import 'keen-slider/keen-slider.min.css';
import '@/style/mapListView/directions/ImageCarousel.scss';
import { useKeenSlider } from 'keen-slider/react';

export default (props) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [sliderRef, instanceRef] = useKeenSlider({
        initial: props.imageIndex,
        slides: {
            origin: 'center',
            perView: props.slidesPerView,
            spacing: 15
        },
        vertical: !!props.vertical,
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        }
    });
    useEffect(() => {
        setCurrentSlide(props.imageIndex);
        instanceRef.current.moveToIdx(props.imageIndex);
    }, [props.imageIndex]);
    const {
        setShowImagesSidebar,
        images,
        isMobile,
        isHorizontal,
        onShowImages,
        vertical,
        tall,
        large,
        wide
    } = props;
    const navigationWrapperStyle = {
        position: 'relative',
        top: isMobile ? '64px' : 0
    };
    const sliderStyle = !isMobile
        ? {
              maxHeight: !!tall ? '100%' : '131px',
              maxWidth: '100%',
              userSelect: 'none',
              overflowX: 'hidden'
          }
        : { height: '100vh', width: '100vw' };
    if (isHorizontal) sliderStyle.width = '100%';
    const imageStyle = large
        ? { maxHeight: '100%', maxWidth: '100%' }
        : {
              height: '320px',
              height: wide ? '100%' : '181px',
              overflowY: 'hidden !important'
          };

    const showArrows = !!images && images.length > 1 && !vertical;

    const imageCN = wide
        ? `keen-slider__slide number-slide wide`
        : `keen-slider__slide number-slide`;
    return (
        !!images && (
            <div>
                <div style={navigationWrapperStyle}>
                    <div
                        ref={sliderRef}
                        className="keen-slider"
                        style={sliderStyle}>
                        {images.map((image, index) => (
                            <div
                                className={imageCN}
                                key={index}
                                onClick={() => {
                                    setShowImagesSidebar(index, images);
                                    !!onShowImages && onShowImages();
                                }}>
                                {vertical ? (
                                    <img src={image} style={imageStyle} />
                                ) : (
                                    <img src={image} style={imageStyle} />
                                )}
                            </div>
                        ))}
                    </div>

                    {instanceRef.current && (
                        <div>
                            {showArrows && currentSlide !== 0 && (
                                <ArrowLeft
                                    onClick={(e) => {
                                        e.stopPropagation() ||
                                            instanceRef.current.prev();
                                    }}
                                    large={large}
                                    disabled={currentSlide === 0}
                                />
                            )}
                            {showArrows &&
                                currentSlide !==
                                    instanceRef.current.track.details.slides
                                        .length -
                                        1 && (
                                    <ArrowRight
                                        onClick={(e) => {
                                            e.stopPropagation() ||
                                                instanceRef.current.next();
                                        }}
                                        large={large}
                                        disabled={
                                            currentSlide ===
                                            instanceRef.current.track.details
                                                .slides.length -
                                                1
                                        }
                                    />
                                )}
                        </div>
                    )}
                </div>
            </div>
        )
    );
};
let arrowStyle = {
    background: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 0,
    height: '48px',
    width: '24px'
};

function ArrowLeft(props) {
    const disabled = props.disabled ? ' arrow--disabled' : '';
    const style = props.large
        ? { ...arrowStyle, marginLeft: '40px' }
        : arrowStyle;
    return (
        <div
            style={style}
            className={'carousel--arrow arrow--left' + disabled}
            onClick={props.onClick}>
            <svg
                width="8px"
                height="16px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 8 16">
                <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd">
                    <g id="Icon/Caret/Left" fill="#FFFFFF">
                        <path
                            d="M6.98667661,16 C6.714559,16.0033011 6.45226382,15.8807632 6.25908943,15.6588475 L0.296512433,8.82030681 C-0.095293267,8.37268558 -0.0992949965,7.64209026 0.287417593,7.18857374 C0.290327942,7.185205 0.293602084,7.18141517 0.296512433,7.17804643 L6.27000323,0.339505752 C6.66762963,-0.113168584 7.30572359,-0.113168584 7.70334999,0.339505752 C8.09515569,0.78712698 8.09952121,1.5177223 7.71244483,1.97123883 C7.70953448,1.97460757 7.70662413,1.9783974 7.70334999,1.98176614 L2.44653257,7.99917662 L7.70334999,14.0165871 C8.09515569,14.4646294 8.09952121,15.1948037 7.71244483,15.6483202 L7.70334999,15.6588475 C7.51235835,15.8773944 7.25479249,16 6.98667661,16"
                            transform="translate(4.000000, 8.000000) scale(1, -1) translate(-4.000000, -8.000000) "></path>
                    </g>
                </g>
            </svg>
        </div>
    );
}

function ArrowRight(props) {
    const disabled = props.disabled ? ' arrow--disabled' : '';
    const style = props.large
        ? { ...arrowStyle, marginRight: '40px' }
        : arrowStyle;
    return (
        <div
            style={style}
            className={'carousel--arrow arrow--right' + disabled}
            onClick={props.onClick}>
            <svg
                width="16px"
                height="16px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 8 16">
                <g
                    id="Symbols"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd">
                    <g id="Icon/Caret/Right" fill="#FFFFFF">
                        <path
                            d="M6.98667661,16 C6.714559,16.0033011 6.45226382,15.8807632 6.25908943,15.6588475 L0.296512433,8.82030681 C-0.095293267,8.37268558 -0.0992949965,7.64209026 0.287417593,7.18857374 C0.290327942,7.185205 0.293602084,7.18141517 0.296512433,7.17804643 L6.27000323,0.339505752 C6.66762963,-0.113168584 7.30572359,-0.113168584 7.70334999,0.339505752 C8.09515569,0.78712698 8.09952121,1.5177223 7.71244483,1.97123883 C7.70953448,1.97460757 7.70662413,1.9783974 7.70334999,1.98176614 L2.44653257,7.99917662 L7.70334999,14.0165871 C8.09515569,14.4646294 8.09952121,15.1948037 7.71244483,15.6483202 L7.70334999,15.6588475 C7.51235835,15.8773944 7.25479249,16 6.98667661,16"
                            transform="translate(4.000000, 8.000000) scale(-1, -1) translate(-4.000000, -8.000000) "></path>
                    </g>
                </g>
            </svg>
        </div>
    );
}
