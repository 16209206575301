/******************************************************************************\
 * File: items.jsx
 *
 * Author: Gigster
 *
 * Description: Meta-store functions for rides, dealers and events.
 *
 * Notes:
 \******************************************************************************/
import { request, reducer } from '@/helpers/async';
import { getCircumscribedRadius, createSquareSearchArea } from '@/helpers/map';
import { metersToMiles, normalizeLatLng } from '@/helpers/functions';
import { centerZoom } from '@/store/map';

export const createTypes = (name) => ({
    FETCH: `${name}/FETCH`,
    SELECT: `${name}/SELECT`
});

/** Fetches rides, dealers and events to display on the map based on the map center. */
export const createFetch =
    (type, fetchCall) =>
    (override = {}) =>
    (dispatch, getState) => {
        const map = getState().map;
        if (!map || map.bounds.ne.lat === 0) return;
        const center = normalizeLatLng(override.center || map.center);
        const bounds = override.bounds || map.bounds;
        const newBounds = createSquareSearchArea(center, bounds);
        const radiusInMeters = getCircumscribedRadius(newBounds);
        const radius = map.radius
            ? map.radius
            : center.lat && center.lng
              ? metersToMiles(radiusInMeters)
              : null;
        const lat = center.lat;
        const lng = center.lng;

        dispatch(
            request(
                type,
                fetchCall(lat, lng, radius),
                {} // Meta
            )
        );
    };

const createSelectItem = (SELECT) => (item) => (dispatch, getState) => {
    if (!item) {
        return dispatch({ type: SELECT, data: null });
    }
    const { zoom } = getState().map;
    dispatch(centerZoom(item, zoom));
    dispatch({ type: SELECT, data: item });
};

const initialState = {
    selected: null,
    data: [],
    loading: false,
    error: null
};

export const createReducer =
    ({ SELECT, FETCH }) =>
    (state = initialState, action) => {
        switch (action.type) {
            case SELECT:
                return {
                    ...state,
                    selected: action.data
                };

            default:
                return {
                    ...state,
                    ...reducer(FETCH)(state, action)
                };
        }
    };

/** Default item store API. */
export default (pluralName, fetchMany) => {
    const types = createTypes(pluralName);

    const selectItem = createSelectItem(types.SELECT);
    return {
        ...types,
        fetchItems: createFetch(types.FETCH, fetchMany),
        selectItem,
        reducer: createReducer(types, pluralName),
        initialState
    };
};
