/******************************************************************************\
* File: Undo.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Undo extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 18 18" onClick={onClick}>
                <path
                    fill="#FFF"
                    fillRule="nonzero"
                    d="M4.451 7.352l3.119 3.119a1.022 1.022 0 1 1-1.446 1.445L.538 6.33 6.124.744A1.022 1.022 0 0 1 7.57 2.189L4.45 5.308h7.626a5.393 5.393 0 0 1 0 10.786 1.022 1.022 0 1 1 0-2.045 3.349 3.349 0 0 0 0-6.697H4.45z"
                />
            </svg>
        );
    }
}
export default Undo;
