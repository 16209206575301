/******************************************************************************\
 * File: Title.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/Title.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Title extends React.Component {
    render() {
        const { text, children, className, noClip, ...rest } = this.props;

        const cn = classNames(style.Title, {
            [className]: !!className,
            [style['no-clip']]: !!noClip
        });

        return (
            <h2 className={cn} {...rest}>
                {children || text}
            </h2>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Title;
