/******************************************************************************\
 * File: IEModal.jsx
 *
 * Author: Gigster
 *
 * Description: IEModal modal
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Modal from '@/components/common/Modal';
import ArrowLink from '@/components/common/ArrowLink';

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class IEModal extends React.Component {
    onAfterOpen = () => {
        const { onAfterOpen } = this.props;
        onAfterOpen && onAfterOpen();

        this.$input && this.$input.select();
    };

    openLinkInNewTab(url) {
        window.open(url, '_blank');
    }

    onClick = () => {
        this.openLinkInNewTab('https://www.google.com/chrome/');
    };

    render() {
        const { onRequestClose, isOpen, ...rest } = this.props;
        const headerText = 'YOUR BROWSER IS NO LONGER SUPPORTED';
        const bodyText =
            'You can continue, but some features may not work. For the best experience, we recommend using Google Chrome.';
        const linkText = 'Get Google Chrome';
        return (
            <Modal
                {...rest}
                isOpen={isOpen}
                onAfterOpen={this.onAfterOpen}
                onRequestClose={onRequestClose}
            >
                <div>
                    <h1 style={{ fontSize: '20px', paddingBottom: '24px' }}>
                        {headerText}
                    </h1>
                    <div style={{ fontSize: '14px', paddingBottom: '24px' }}>
                        {bodyText}
                    </div>
                </div>
                <ArrowLink text={linkText} onClick={this.onClick} />
            </Modal>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default IEModal;
