/******************************************************************************\
* File: StartFlag.jsx
*
* Author: Gigster
*
* Description:
*
* Notes:
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class StartFlag extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [style['no-stroke']]: true,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 16 18" onClick={onClick}>
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <path
                        d="M15.1687875,5.8562333 C15.1687875,4.42152048 15.0731422,1.00252248 15.0731422,1.00252248 L14.8056414,1.15988734 C13.8297533,1.49380472 12.9308405,1.69031919 12.1470846,1.69031919 C10.8842606,1.69031919 9.96675434,1.29729025 8.97127805,0.865115638 C7.99538991,0.432174616 6.98223837,0 5.56611405,0 C5.04978246,0 4.49458061,0.0591076214 3.92070881,0.196514467 C3.32740188,0.353879322 2.71542499,0.530431849 2.0078028,0.806023737 L1.91290544,0.864364952 L1.91290544,1.02172981 C1.91290544,2.37736521 1.12830791,7.58491997 0.669516531,11.7708746 C0.343729508,14.5421216 0,16.8802508 0,17.2732797 L0,17.4697942 L1.62596954,17.6862745 L1.81726014,17.6862745 L1.87479653,17.4306485 C1.93233291,17.1558429 1.97044183,16.7037024 2.06608713,16.1333386 C2.21926882,14.8951402 2.48677342,12.831699 2.86935462,9.53005947 C3.38568621,9.47095185 3.88334784,9.43180224 4.41911456,9.43180224 C5.85379407,9.43180224 6.84831391,9.86397685 7.91992386,10.355263 C8.99145729,10.8465492 10.0621873,11.3178695 11.6120238,11.3178695 C12.626017,11.3178695 13.792354,11.121355 15.1704115,10.7083602 L15.3617021,10.6492526 C15.2645628,10.4934225 15.2077725,7.29082035 15.1689195,5.85606823 L15.1687875,5.8562333 Z"
                        id="Fill-2"
                        fill="#FFFFFF"
                    />
                </g>
            </svg>
        );
    }
}
export default StartFlag;
