/******************************************************************************\
 * File: CreateHeader.jsx
 *
 * Author: Gigster
 *
 * Description: App header
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import headerStyle from '@/style/common/header/Header.scss';
import style from '@/style/common/header/PreviewHeader.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import RideDirections from '@/components/mapListView/directions/RideDirections';
import CreateActionBar from '@/components/common/header/CreateActionBar';
//App context
import AppContext from '@/contexts/AppContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class CreateHeader extends React.Component {
    render() {
        const {
            className,
            openSettingsModal,
            updateUploadModal,
            openConvertRideModal,
            onClickPrint,
            closeUploadModal
        } = this.props;

        const { isMobile } = this.context;

        const cn = classNames(style.PreviewHeader, {
            [className]: className,
            [style['transparent']]: true,
            isMobile: isMobile
        });

        if (isMobile) {
            return <div className={cn} />;
        }

        return (
            <div className={cn}>
                <div className={classNames(headerStyle.left, style.left)}>
                    <RideDirections
                        updateUploadModal={updateUploadModal}
                        closeUploadModal={closeUploadModal}
                    />
                </div>
                <div
                    className={headerStyle.right}
                    style={{ marginTop: '24px' }}>
                    <CreateActionBar
                        openSettingsModal={openSettingsModal}
                        openConvertRideModal={openConvertRideModal}
                        onClickPrint={onClickPrint}
                    />
                </div>
            </div>
        );
    }
}

CreateHeader.contextType = AppContext;
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default CreateHeader;
