/******************************************************************************\
* File: Folder.jsx
*
* Author: Gigster
*
* Description: 
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Folder extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: !!className
        });

        return (
            <svg className={cn} viewBox="0 0 17 14" onClick={onClick}>
                <title>Group</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="Group-7"
                        transform="translate(0.000000, -646.000000)"
                        fillRule="nonzero"
                        fill="#FF6602"
                    >
                        <g
                            id="Group"
                            transform="translate(0.000000, 646.000000)"
                        >
                            <path
                                d="M2.7918386,5.33448276 C2.92869343,4.99655172 3.284516,4.75517241 3.6950805,4.75517241 L15.6288219,4.75517241 C15.4098542,3.06551724 14.4518704,2.34137931 12.5632737,2.34137931 L9.77143509,2.34137931 C9.41561252,2.34137931 9.19664479,2.17241379 9.19664479,2.07586207 C9.19664479,0.989655172 8.07443516,0.120689655 6.70588683,0.120689655 L2.60024183,0.120689655 C1.2316935,0.120689655 0.109483867,0.989655172 0.109483867,2.07586207 L0.109483867,12.5275862 L2.7918386,5.33448276 Z"
                                id="Shape"
                            />
                            <path
                                d="M4.40672563,6.44482759 L1.61488703,13.8793103 L13.5212575,13.8793103 C13.931822,13.8793103 14.3150155,13.637931 14.4244994,13.2758621 L16.8605154,6.44482759 L4.40672563,6.44482759 Z"
                                id="Shape"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
export default Folder;
