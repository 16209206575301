/******************************************************************************\
 * File: PreviewHeader.jsx
 *
 * Author: Gigster
 *
 * Description: App header
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import withRouter from '@/helpers/hooks';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import headerStyle from '@/style/common/header/Header.scss';
import style from '@/style/common/header/PreviewHeader.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import ShareBar from '@/components/common/header/ShareBar';
import RideDropdownHeader from '@/components/mapListView/directions/RideDropdownHeader';
import Link from '@/components/common/header/Link';
//------------------------------------------------------------------------------
// Actions ---------------------------------------------------------------------
import { setBookmark, isBookmarked } from '@/store/bookmarks';
import { getCurrentRide, routing_unselectRide } from '@/store/rides';
import { setPrint, centerZoom } from '@/store/map';
import {
    forkRide,
    editRide,
    showSaveModal,
    updateRide
} from '@/store/edit_ride';
import history from '@/helpers/history';
import {
    isAuthenticated,
    auth,
    invalidateAuth,
    currentUserCanEditRide
} from '@/store/auth';
import { analyticsForRideWithData } from '@/helpers/analytics';

//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { Routes, generatePath, rideSharePath } from '@/helpers/routes';
import { RideSubtype } from '@/helpers/constants';
import { setCacheId } from '@/store/edit_ride/cache';
//App context
import AppContext from '@/contexts/AppContext';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PreviewHeader extends React.Component {
    render() {
        const {
            className,
            navigate,
            ride,
            bookmarked,
            setBookmark,
            onEditRide,
            onCopyRide,
            onSetCacheId,
            isAuthenticated,
            profile,
            onClickPrint,
            auth,
            invalidateAuth,
            canEdit,
            onChangeName,
            updateUploadModal,
            openSettingsModal,
            openConvertRideModal,
            showImages,
            showSaveModal
        } = this.props;

        const { isMobile } = this.context;

        const cn = classNames(style.PreviewHeader, {
            [className]: className,
            [style['transparent']]: true
        });

        const isRecorded = ride.subType === RideSubtype.RECORDED;

        const onClickEdit =
            isRecorded && canEdit
                ? (ride) => {
                      onSetCacheId(ride);
                      showSaveModal(true, ride);
                  }
                : null;
        return (
            <div className={cn}>
                <div className={classNames(headerStyle.left, style.left)}>
                    <RideDropdownHeader
                        onClickEdit={onClickEdit}
                        onChangeName={onChangeName}
                        ride={ride}
                        isMobile={isMobile}
                        updateUploadModal={updateUploadModal}
                        onClickBack={(e) => {
                            history.back();
                        }}
                        canEdit={canEdit}
                    />
                </div>
                {!isMobile && !showImages && (
                    <div
                        className={headerStyle.right}
                        style={{ marginTop: '24px' }}>
                        <ShareBar
                            {...(ride.id
                                ? {
                                      isBookmarked: bookmarked,
                                      url:
                                          ride.shortId &&
                                          rideSharePath(ride.shortId),
                                      setBookmark: (value) => {
                                          if (!!value)
                                              analyticsForRideWithData(
                                                  'star ride',
                                                  {
                                                      location: 'map',
                                                      dealerId: ride.dealerId
                                                  }
                                              );
                                          setBookmark(ride.id, value);
                                      },
                                      onEditRide: () => onEditRide(ride),
                                      ...(canEdit
                                          ? {
                                                onCopyRide: () =>
                                                    onCopyRide(ride)
                                            }
                                          : {})
                                  }
                                : {
                                      onEditRide: () =>
                                          navigate(Routes.RIDE_CREATE)
                                  })}
                            canEdit={canEdit}
                            onClickPrint={onClickPrint}
                            openConvertRideModal={() =>
                                openConvertRideModal(ride)
                            }
                            onCopyRide={() => onEditRide(ride)}
                            profile={profile}
                            isAuthenticated={isAuthenticated}
                            onSignIn={auth}
                            onLogout={invalidateAuth}
                            openSettingsModal={openSettingsModal}
                            isRecorded={isRecorded}
                            ride={ride}
                        />
                    </div>
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    const prevRide = getCurrentRide(state);
    let ride = state.edit_ride.present.ride.name
        ? state.edit_ride.present.ride
        : prevRide;
    ride.locationHistory = prevRide.locationHistory;
    ride.waypoints = prevRide.waypoints;
    ride.name = prevRide.name;
    ride.description = prevRide.description;
    const bookmarked = isBookmarked(ride.id, state);
    return {
        bookmarked,
        ride,
        canEdit: currentUserCanEditRide(state, ride),
        isAuthenticated: isAuthenticated(state),
        profile: state.user.profile,
        showImages: state.map.showImages
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => ({
    setBookmark: (id, value) => dispatch(setBookmark(id, value)),
    onChangeName: (name) => dispatch(updateRide('name', name)),
    onCopyRide: (ride) => dispatch(forkRide(ride)),
    onEditRide: (ride) => {
        dispatch(routing_unselectRide());
        dispatch(editRide(ride));
    },
    onSetCacheId: (ride) => dispatch(setCacheId(ride.id)),
    auth: () => dispatch(auth()),
    invalidateAuth: () => dispatch(invalidateAuth()),
    showSaveModal: (value, reason) => {
        dispatch(showSaveModal(value, reason));
    }
});

PreviewHeader.contextType = AppContext;
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(PreviewHeader);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
