/******************************************************************************\
* File: Route.jsx
*
* Author: Gigster
*
* Description: Route map icon
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------

//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Route extends React.Component {
    render() {
        const { img, selected } = this.props;

        return (
            <div
                style={{
                    height: '46px',
                    width: '46px',
                    margin: '-23px 0px 0px -23px'
                }}
            >
                <div
                    style={{
                        height: '100%',
                        width: '100%',
                        cursor: 'pointer',
                        backgroundColor: '#333',
                        padding: '3px',
                        borderRadius: '1px',
                        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)'
                    }}
                >
                    <img
                        src={img}
                        style={{
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover'
                        }}
                    />
                </div>
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Route;
