/******************************************************************************\
 * File: icon.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
\******************************************************************************/

import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="48"
            viewBox="0 0 40 48">
            <g fill="#FFF" fillRule="evenodd">
                <path
                    fillRule="nonzero"
                    d="M20 17c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8v-3zm0 3v-3c6.351 0 11.5 4.925 11.5 11S26.351 39 20 39v-3c4.724 0 8.5-3.612 8.5-8s-3.776-8-8.5-8z"></path>
                <path
                    fillRule="nonzero"
                    d="M21.224 29.876c0-.752-.474-1.232-1.22-1.232s-1.22.48-1.22 1.232c0 .763.373 1.16 1.22 1.16s1.22-.397 1.22-1.16zm-.216-3.792c0-.573-.427-1.016-1.004-1.016S19 25.511 19 26.084c0 .554.445.992 1.004.992a.991.991 0 001.004-.992zm2 3.84c0 1.687-1.2 2.68-3.004 2.68-1.803 0-3.004-.993-3.004-2.68 0-1.013.358-1.722 1.14-2.101-.58-.385-.924-1.105-.924-1.871 0-1.566 1.109-2.452 2.788-2.452 1.68 0 2.788.886 2.788 2.452 0 .766-.344 1.486-.924 1.871.782.38 1.14 1.088 1.14 2.101z"></path>
                <path
                    fillOpacity="0.4"
                    fillRule="nonzero"
                    d="M20 17c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8v-3zm0 3v-3c-6.351 0-11.5 4.925-11.5 11S13.649 39 20 39v-3c-4.724 0-8.5-3.612-8.5-8s3.776-8 8.5-8z"></path>
                <path d="M18.5 11h3v9H20a1.5 1.5 0 01-1.5-1.5V11zM20 36h1.5v9h-3v-7.5A1.5 1.5 0 0120 36z"></path>
                <path d="M13.5 12L20 3l6.5 9z"></path>
            </g>
        </svg>
    );
}

export default Icon;
