/******************************************************************************\
* File: Facebook.jsx
*
* Author: Gigster
*
* Description: SVG icon of Facebook
*
* Notes: 
\******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/icons/Icon.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Facebook extends React.Component {
    render() {
        const { className, onClick } = this.props;

        const cn = classNames(style.Icon, {
            [style['clickable']]: onClick,
            [className]: className
        });

        return (
            <svg className={cn} viewBox="0 0 9 16" onClick={onClick}>
                <g
                    id="Preview"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                >
                    <g
                        id="Ride-Preview-POI-Preview-Rollover"
                        transform="translate(-1320.000000, -86.000000)"
                        fill="#FFFFFF"
                    >
                        <g
                            id="Direction-|-Share--|-Save"
                            transform="translate(1230.000000, 24.000000)"
                        >
                            <path
                                d="M95.8382126,78 L95.8382126,70.7008433 L98.5077042,70.7008433 L98.9067411,67.8560601 L95.8382126,67.8560601 L95.8382126,66.0384709 C95.8382126,65.2160758 96.0878274,64.6528791 97.3671032,64.6528791 L99,64.6528791 L99,62.1120942 C98.2137134,62.0352946 97.4229199,61.9978549 96.6321263,62.0000948 C94.2781202,62.0000948 92.6660247,63.324887 92.6660247,65.7568726 L92.6660247,67.8528602 L90,67.8528602 L90,70.6976433 L92.6660247,70.6976433 L92.6660247,78 L95.8382126,78 Z"
                                id="Facebook"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Facebook;
