/******************************************************************************\
 * File: languages.jsx
 *
 * Author: Gigster
 *
 * Description: Languages for globalization.
 *
 * Notes:
\******************************************************************************/

export const privacyPolicy = (val) =>
    `https://www.harley-davidson.com${val}footer/utility/privacy-policy.html`;

export const website = (val) =>
    `https://www.harley-davidson.com${val}index.html`;

const hereFormat = (val) => val.replace(/(\/\w{2})\/(\w{2}\/)/, '$1-$2');

export const hereTerms = (val) =>
    `https://legal.here.com${hereFormat(val)}terms`;

export const herePolicy = (val) =>
    `https://legal.here.com${hereFormat(val)}privacy/policy`;

export const languages = [
    {
        countryName: 'Argentina',
        name: 'Argentina – Español',
        code: 'AR',
        codeExt: 'ARG',
        number: '032',
        locale: 'es-LA',
        country: 'AR',
        active: false,
        value: '/la/es/',
        lang: 'es'
        //"default": true
    },
    {
        countryName: 'Algeria',
        name: 'Algeria – English',
        code: 'DZ',
        codeExt: 'DZA',
        number: '012',
        locale: 'en-AC',
        country: 'DZ',
        active: false,
        value: '/ac/en/',
        lang: 'en'
    },
    {
        countryName: 'Andorra',
        name: 'Andorra - Español',
        code: 'AD',
        codeExt: 'AND',
        number: '020',
        locale: 'es-ES',
        country: 'AD',
        active: true,
        value: '/es/es/',
        lang: 'es',
        default: true
    },
    {
        countryName: 'Aruba',
        name: 'Aruba – English',
        code: 'AW',
        codeExt: 'ABW',
        number: '533',
        locale: 'en-US',
        country: 'AW',
        active: false,
        value: '/us/en/',
        lang: 'en'
    },
    {
        countryName: 'Australia',
        name: 'Australia/New Zealand – English',
        code: 'AU',
        codeExt: 'AUS',
        number: '036',
        locale: 'en-AU',
        country: 'AU',
        active: true,
        value: '/au/en/',
        lang: 'en',
        customDisplay: 'Australia - English',
        default: true
    },
    {
        countryName: 'Azerbaijan',
        code: 'AZ',
        codeExt: 'AZE',
        number: '031',
        locale: 'ru-RU',
        active: false,
        value: '/ru/ru/',
        lang: 'ru',
        name: 'Azərbaycan – Русский'
    },
    {
        countryName: 'Bahamas (the)',
        name: 'Bahamas – English',
        code: 'BS',
        codeExt: 'BHS',
        number: '044',
        locale: 'en-US',
        country: 'BS',
        active: false,
        value: '/us/en/',
        lang: 'en'
    },
    {
        countryName: 'Bahrain',
        name: 'Bahrain – English',
        code: 'BH',
        codeExt: 'BHR',
        number: '048',
        locale: 'en-AC',
        country: 'BH',
        active: false,
        value: '/ac/en/',
        lang: 'en'
    },
    {
        countryname: 'Bahrain',
        code: 'BH',
        codeExt: 'BHR',
        number: '048',
        locale: 'ar-AC',
        active: false,
        value: '/ac/ar/',
        lang: 'ar',
        name: 'العربية – مملكة البحرين'
        //"default": true
    },
    {
        countryName: 'Saudi Arabia',
        code: 'SA',
        codeExt: 'SAU',
        number: '682',
        locale: 'ar-SA',
        active: false,
        value: '/sa/ar/',
        lang: 'ar',
        name: 'العربية'
    },
    {
        countryName: 'Barbados',
        name: 'Barbados – English',
        code: 'BB',
        codeExt: 'BRB',
        number: '052',
        locale: 'en-US',
        country: 'BB',
        active: false,
        value: '/us/en/',
        lang: 'en'
    },
    {
        countryName: 'Belarus',
        code: 'BY',
        codeExt: 'BLR',
        number: '112',
        locale: 'ru-RU',
        active: false,
        value: '/ru/ru/',
        lang: 'ru',
        name: 'Беларусь – Русский'
    },
    {
        countryName: 'Belgium',
        code: 'BE',
        codeExt: 'BEL',
        number: '056',
        locale: 'nl-BE',
        active: true,
        value: '/be/nl/',
        lang: 'nl',
        name: 'België – Nederlands',
        altLocale: 'nl-NL',
        default: true
    },
    {
        countryName: 'Belgium',
        code: 'BE',
        codeExt: 'BEL',
        number: '056',
        locale: 'fr-BE',
        active: true,
        value: '/be/fr/',
        lang: 'fr',
        name: 'Belgique – Français',
        altLocale: 'fr-FR'
    },
    {
        countryName: 'Belize',
        name: 'Belize – English',
        code: 'BZ',
        codeExt: 'BLZ',
        number: '084',
        locale: 'en-US',
        country: 'BZ',
        active: false,
        value: '/us/en/',
        lang: 'en'
        //"default": true
    },
    {
        countryName: 'Bolivia (Plurinational State of)',
        name: 'Bolivia – Español',
        code: 'BO',
        codeExt: 'BOL',
        number: '068',
        locale: 'es-LA',
        country: 'BO',
        active: false,
        value: '/la/es/',
        lang: 'es'
    },
    {
        countryName: 'Brazil',
        code: 'BR',
        codeExt: 'BRA',
        number: '076',
        locale: 'pt-BR',
        active: true,
        value: '/br/pt/',
        lang: 'pt',
        name: 'Brasil – Português',
        default: true
    },
    {
        countryName: 'Brunei Darussalam',
        name: 'Brunei Darussalam – English',
        code: 'BN',
        codeExt: 'BRN',
        number: '096',
        locale: 'en-AP',
        country: 'BN',
        active: false,
        value: '/ap/en/',
        lang: 'en'
        //"default": true
    },
    {
        countryName: 'Bulgaria',
        name: 'Bulgaria - English',
        code: 'BG',
        codeExt: 'BGR',
        number: '100',
        locale: 'en-EU',
        country: 'BG',
        active: true,
        value: '/eu/en/',
        lang: 'en',
        altLocale: 'en-GB'
    },
    {
        countryName: 'Cambodia',
        name: 'Cambodia – English',
        code: 'KH',
        codeExt: 'KHM',
        number: '116',
        locale: 'en-AP',
        country: 'KH',
        active: false,
        value: '/ap/en/',
        lang: 'en'
    },
    {
        countryName: 'Canada',
        name: 'Canada – English',
        code: 'CA',
        codeExt: 'CAN',
        number: '124',
        locale: 'en-CA',
        country: 'CA',
        active: false,
        value: '/ca/en/',
        lang: 'en'
        //"default": true
    },
    {
        countryName: 'Canada',
        name: 'Canada – Français',
        code: 'CA',
        codeExt: 'CAN',
        number: '124',
        locale: 'fr-CA',
        country: 'CA',
        active: true,
        value: '/ca/fr/',
        lang: 'fr',
        default: true
    },
    {
        countryName: 'Czechia',
        code: 'CZ',
        codeExt: 'CZE',
        number: '203',
        locale: 'cs-CZ',
        active: true,
        value: '/cz/cs/',
        lang: 'cs',
        name: 'Česká Republika – Česky',
        default: true
    },
    {
        countryName: 'Chile',
        name: 'Chile – Español',
        code: 'CL',
        codeExt: 'CHL',
        number: '152',
        locale: 'es-LA',
        country: 'CL',
        active: false,
        value: '/la/es/',
        lang: 'es'
        //"default": true
    },
    {
        countryName: 'China',
        name: 'China – Chinese',
        code: 'CN',
        codeExt: 'CHN',
        number: '156',
        locale: 'zh-CN',
        country: 'CN',
        active: false,
        value: '/cn/zh/',
        lang: 'zh'
        //"default": true
    },
    {
        countryName: 'Colombia',
        name: 'Colombia – Español',
        code: 'CO',
        codeExt: 'COL',
        number: '170',
        locale: 'es-LA',
        country: 'CO',
        active: false,
        value: '/la/es/',
        lang: 'es'
        //"default": true
    },
    {
        countryName: 'Costa Rica',
        name: 'Costa Rica – Español',
        code: 'CR',
        codeExt: 'CRI',
        number: '188',
        locale: 'es-LA',
        country: 'CR',
        active: false,
        value: '/la/es/',
        lang: 'es'
        //"default": true
    },
    {
        countryName: 'Croatia',
        name: 'Croatia – English',
        code: 'HR',
        codeExt: 'HRV',
        number: '191',
        locale: 'en-EU',
        country: 'HR',
        active: true,
        value: '/eu/en/',
        lang: 'en',
        altLocale: 'en-GB',
        default: true
    },
    {
        countryName: 'Cyprus',
        name: 'Cyprus – English',
        code: 'CY',
        codeExt: 'CYP',
        number: '196',
        locale: 'el-GR',
        country: 'CY',
        active: true,
        value: '/gr/el/',
        lang: 'el',
        altLocale: 'en-GB',
        default: true
    },
    {
        countryName: 'Denmark',
        code: 'DK',
        codeExt: 'DNK',
        number: '208',
        locale: 'da-DK',
        active: true,
        value: '/dk/da/',
        lang: 'da',
        name: 'Danmark – Dansk',
        default: true
    },
    {
        countryName: 'Germany',
        code: 'DE',
        codeExt: 'DEU',
        number: '276',
        locale: 'de-DE',
        active: true,
        value: '/de/de/',
        lang: 'de',
        name: 'Deutschland – Deutsch',
        default: true
    },
    {
        countryName: 'Ecuador',
        name: 'Ecuador – Español',
        code: 'EC',
        codeExt: 'ECU',
        number: '218',
        locale: 'es-LA',
        country: 'EC',
        active: false,
        value: '/la/es/',
        lang: 'es'
        //"default": true
    },
    {
        countryName: 'Egypt',
        name: 'Egypt – English',
        code: 'EG',
        codeExt: 'EGY',
        number: '818',
        locale: 'en-AC',
        country: 'EG',
        active: false,
        value: '/ac/en/',
        lang: 'en'
    },
    {
        countryName: 'El Salvador',
        name: 'El Salvador – Español',
        code: 'SV',
        codeExt: 'SLV',
        number: '222',
        locale: 'es-LA',
        country: 'SV',
        active: false,
        value: '/la/es/',
        lang: 'es'
        //"default": true
    },
    {
        countryName: 'Spain',
        code: 'ES',
        codeExt: 'ESP',
        number: '724',
        locale: 'es-ES',
        active: true,
        value: '/es/es/',
        lang: 'es',
        name: 'España – Español',
        default: true
    },
    {
        countryName: 'Estonia',
        name: 'Estonia – English',
        code: 'EE',
        codeExt: 'EST',
        number: '233',
        locale: 'en-EU',
        country: 'EE',
        active: true,
        value: '/eu/en/',
        lang: 'en',
        altLocale: 'en-GB',
        default: true
    },
    {
        countryName: 'France',
        name: 'France – Français',
        code: 'FR',
        codeExt: 'FRA',
        number: '250',
        locale: 'fr-FR',
        country: 'FR',
        active: true,
        value: '/fr/fr/',
        lang: 'fr',
        default: true
    },
    {
        countryName: 'Cayman Islands (the)',
        code: 'KY',
        codeExt: 'CYM',
        number: '136',
        locale: 'en-US',
        active: false,
        value: '/us/en/',
        lang: 'en',
        name: 'Grand Cayman – English'
    },
    {
        countryName: 'Greece',
        name: 'Greece – English',
        code: 'GR',
        codeExt: 'GRC',
        number: '300',
        locale: 'el-GR',
        country: 'GR',
        active: true,
        value: '/gr/el/',
        lang: 'el',
        altLocale: 'en-GB',
        default: true
    },
    {
        countryName: 'Guam',
        name: 'Guam – English',
        code: 'GU',
        codeExt: 'GUM',
        number: '316',
        locale: 'en-GU',
        country: 'GU',
        active: false,
        value: '/gu/en/',
        lang: 'en'
        //"default": true
    },
    {
        countryName: 'Guatemala',
        name: 'Guatemala – Español',
        code: 'GT',
        codeExt: 'GTM',
        number: '320',
        locale: 'es-LA',
        country: 'GT',
        active: false,
        value: '/la/es/',
        lang: 'es'
        //"default": true
    },
    {
        countryName: 'Honduras',
        name: 'Honduras – Español',
        code: 'HN',
        codeExt: 'HND',
        number: '340',
        locale: 'es-LA',
        country: 'HN',
        active: false,
        value: '/la/es/',
        lang: 'es'
        //"default": true
    },
    {
        countryName: 'Hong Kong',
        name: 'Hong Kong – 中文(台灣)',
        code: 'HK',
        codeExt: 'HKG',
        number: '344',
        locale: 'zh-TW',
        country: 'HK',
        active: true,
        value: '/tw/zh/',
        lang: 'zh'
    },
    {
        countryName: 'India',
        name: 'India – English',
        code: 'IN',
        codeExt: 'IND',
        number: '356',
        locale: 'en-IN',
        country: 'IN',
        active: false,
        value: '/in/en/',
        lang: 'en'
        //"default": true
    },
    {
        countryName: 'Indonesia',
        name: 'Indonesia – Bahasa Indonesia',
        code: 'ID',
        codeExt: 'IDN',
        number: '360',
        locale: 'id-ID',
        country: 'ID',
        active: false,
        value: '/id/id/',
        lang: 'id'
        //"default": true
    },
    {
        countryName:
            'United Kingdom of Great Britain and Northern Ireland (the)',
        name: 'Ireland – English',
        code: 'GB',
        codeExt: 'GBR',
        number: '826',
        locale: 'en-IE',
        country: 'GB',
        active: true,
        value: '/ie/en/',
        lang: 'en',
        altLocale: 'en-GB'
    },
    {
        countryname: 'Iceland',
        code: 'IS',
        codeExt: 'ISL',
        number: '352',
        locale: 'en-EU',
        active: false,
        value: '/eu/en/',
        lang: 'en',
        name: 'Ísland – English',
        altLocale: 'en-GB'
    },
    {
        countryname: 'Italy',
        code: 'IT',
        codeExt: 'ITA',
        number: '380',
        locale: 'it-IT',
        active: true,
        value: '/it/it/',
        lang: 'it',
        name: 'Italia – Italiano',
        default: true
    },
    {
        countryName: 'Jamaica',
        name: 'Jamaica – English',
        code: 'JM',
        codeExt: 'JAM',
        number: '388',
        locale: 'en-US',
        country: 'JM',
        active: false,
        value: '/us/en/',
        lang: 'en'
    },
    {
        countryName: 'Japan',
        name: 'Japan – 日本語',
        code: 'JP',
        codeExt: 'JPN',
        number: '392',
        locale: 'ja-JP',
        country: 'JP',
        active: true,
        value: '/jp/ja/',
        lang: 'ja',
        default: true
    },
    {
        countryName: 'Jordan',
        name: 'Jordan – English',
        code: 'JO',
        codeExt: 'JOR',
        number: '400',
        locale: 'en-AC',
        country: 'JO',
        active: false,
        value: '/ac/en/',
        lang: 'en'
    },
    {
        countryName: 'Kazakhstan',
        code: 'KZ',
        codeExt: 'KAZ',
        number: '398',
        locale: 'ru-RU',
        active: false,
        value: '/ru/ru/',
        lang: 'ru',
        name: 'Казахстан – Русский'
    },
    {
        countryName: "Korea (the Democratic People's Republic of)",
        name: 'Korea – 한국어',
        code: 'KP',
        codeExt: 'PRK',
        number: '408',
        locale: 'ko-KR',
        country: 'KP',
        active: false,
        value: '/kr/ko/',
        lang: 'ko'
    },
    {
        countryName: 'Kuwait',
        name: 'Kuwait – English',
        code: 'KW',
        codeExt: 'KWT',
        number: '414',
        locale: 'en-AC',
        country: 'KW',
        active: false,
        value: '/ac/en/',
        lang: 'en'
    },
    {
        countryName: 'Kuwait',
        code: 'KW',
        codeExt: 'KWT',
        number: '414',
        locale: 'ar-AC',
        active: false,
        value: '/ac/ar/',
        lang: 'ar',
        name: 'العربية – دولة الكويت'
    },
    {
        countryName: 'Latvia',
        code: 'LV',
        codeExt: 'LVA',
        number: '428',
        locale: 'en-EU',
        active: true,
        value: '/eu/en/',
        lang: 'en',
        name: 'Latvija – English',
        altLocale: 'en-GB',
        default: true
    },
    {
        countryName: 'Lebanon',
        name: 'Lebanon – English',
        code: 'LB',
        codeExt: 'LBN',
        number: '422',
        locale: 'en-AC',
        country: 'LB',
        active: false,
        value: '/ac/en/',
        lang: 'en'
    },
    {
        countryname: 'Lithuania',
        code: 'LT',
        codeExt: 'LTU',
        number: '440',
        locale: 'en-EU',
        active: true,
        value: '/eu/en/',
        lang: 'en',
        name: 'Lietuva – English',
        altLocale: 'en-GB'
    },
    {
        countryName: 'Luxembourg',
        name: 'Luxembourg – Français',
        code: 'LU',
        codeExt: 'LUX',
        number: '442',
        locale: 'fr-LU',
        country: 'LU',
        active: true,
        value: '/lu/fr/',
        lang: 'fr',
        altLocale: 'fr-FR',
        default: true
    },
    {
        countryname: 'Macao',
        code: 'MO',
        codeExt: 'MAC',
        number: '446',
        locale: 'en-AP',
        active: false,
        value: '/ap/en/',
        lang: 'en',
        name: 'Macau – English'
    },
    {
        countryname: 'Hungary',
        code: 'HU',
        codeExt: 'HUN',
        number: '348',
        locale: 'hu-HU',
        active: true,
        value: '/hu/hu/',
        lang: 'hu',
        name: 'Magyarország – Magyar',
        default: true
    },
    {
        countryName: 'Malaysia',
        name: 'Malaysia – English',
        code: 'MY',
        codeExt: 'MYS',
        number: '458',
        locale: 'en-AP',
        country: 'MY',
        active: false,
        value: '/ap/en/',
        lang: 'en'
        //"default": true
    },
    {
        countryName: 'Malta',
        name: 'Malta – English',
        code: 'MT',
        codeExt: 'MLT',
        number: '470',
        locale: 'en-EU',
        country: 'MT',
        active: true,
        value: '/eu/en/',
        lang: 'en',
        altLocale: 'en-GB'
    },
    {
        countryName: 'Mauritius',
        name: 'Mauritius – English',
        code: 'MU',
        codeExt: 'MUS',
        number: '480',
        locale: 'en-AA',
        country: 'MU',
        active: false,
        value: '/aa/en/',
        lang: 'en'
    },
    {
        countryname: 'Mexico',
        code: 'MX',
        codeExt: 'MEX',
        number: '484',
        locale: 'es-MX',
        active: true,
        value: '/mx/es/',
        lang: 'es',
        name: 'México – Español',
        default: true
    },
    {
        countryName: 'Morocco',
        name: 'Morocco – English',
        code: 'MA',
        codeExt: 'MAR',
        number: '504',
        locale: 'en-AC',
        country: 'MA',
        active: false,
        value: '/ac/en/',
        lang: 'en'
    },
    {
        countryName: 'Myanmar',
        name: 'Myanmar – English',
        code: 'MM',
        codeExt: 'MMR',
        number: '104',
        locale: 'en-AP',
        country: 'MM',
        active: false,
        value: '/ap/en/',
        lang: 'en'
    },
    {
        countryName: 'Namibia',
        name: 'Namibia - English',
        code: 'NA',
        codeExt: 'NAM',
        number: '516',
        locale: 'en-AA',
        country: 'NA',
        active: false,
        value: '/aa/en/',
        lang: 'en'
    },
    {
        countryname: 'Netherlands (the)',
        code: 'NL',
        codeExt: 'NLD',
        number: '528',
        locale: 'nl-NL',
        active: true,
        value: '/nl/nl/',
        lang: 'nl',
        name: 'Nederland – Nederlands',
        default: true
    },
    {
        countryName: 'New Caledonia',
        name: 'New Caledonia – English',
        code: 'NC',
        codeExt: 'NCL',
        number: '540',
        locale: 'en-AP',
        country: 'NC',
        active: false,
        value: '/ap/en/',
        lang: 'en'
    },
    {
        countryName: 'Australia',
        name: 'Australia/New Zealand – English ',
        code: 'AU',
        codeExt: 'AUS',
        number: '036',
        locale: 'en-AU',
        country: 'AU',
        active: true,
        value: '/au/en/',
        lang: 'en',
        customDisplay: 'New Zealand – English',
        default: true
    },
    {
        countryname: 'Norway',
        code: 'NO',
        codeExt: 'NOR',
        number: '578',
        locale: 'no-NO',
        active: true,
        value: '/no/no/',
        lang: 'no',
        name: 'Norge – Norske',
        default: true
    },
    {
        countryName: 'Oman',
        name: 'Oman – English',
        code: 'OM',
        codeExt: 'OMN',
        number: '512',
        locale: 'en-AC',
        country: 'OM',
        active: false,
        value: '/ac/en/',
        lang: 'en'
    },
    {
        countryname: 'Oman',
        code: 'OM',
        codeExt: 'OMN',
        number: '512',
        locale: 'ar-AC',
        active: false,
        value: '/ac/ar/',
        lang: 'ar',
        name: 'العربية – سلطنة عمان'
    },
    {
        countryname: 'Austria',
        code: 'AT',
        codeExt: 'AUT',
        number: '040',
        locale: 'de-AT',
        active: false,
        value: '/at/de/',
        lang: 'de',
        name: 'Österreich – Deutsch'
        //"default": true
    },
    {
        countryname: 'Panama',
        code: 'PA',
        codeExt: 'PAN',
        number: '591',
        locale: 'es-LA',
        active: false,
        value: '/la/es/',
        lang: 'es',
        name: 'Panamá – Español'
        //"default": true
    },
    {
        countryName: 'Paraguay',
        name: 'Paraguay – Español',
        code: 'PY',
        codeExt: 'PRY',
        number: '600',
        locale: 'es-LA',
        country: 'PY',
        active: false,
        value: '/la/es/',
        lang: 'es'
        //"default": true
    },
    {
        countryName: 'Peru',
        name: 'Peru – Español',
        code: 'PE',
        codeExt: 'PER',
        number: '604',
        locale: 'es-LA',
        country: 'PE',
        active: false,
        value: '/la/es/',
        lang: 'es'
    },
    {
        countryName: 'Philippines (the)',
        name: 'Philippines – English',
        code: 'PH',
        codeExt: 'PHL',
        number: '608',
        locale: 'en-AP',
        country: 'PH',
        active: false,
        value: '/ap/en/',
        lang: 'en'
        //"default": true
    },
    {
        countryname: 'Russian Federation (the)',
        code: 'RU',
        codeExt: 'RUS',
        number: '643',
        locale: 'ru-RU',
        active: true,
        value: '/ru/ru/',
        lang: 'ru',
        name: 'Россия – Русский',
        default: true
    },
    {
        countryname: 'Poland',
        code: 'PL',
        codeExt: 'POL',
        number: '616',
        locale: 'pl-PL',
        active: true,
        value: '/pl/pl/',
        lang: 'pl',
        name: 'Polska – Polski',
        default: true
    },
    {
        countryName: 'Portugal',
        name: 'Portugal – Português',
        code: 'PT',
        codeExt: 'PRT',
        number: '620',
        locale: 'pt-PT',
        country: 'PT',
        active: true,
        value: '/pt/pt/',
        lang: 'pt',
        default: true
    },
    {
        countryName: 'Puerto Rico',
        name: 'Puerto Rico – English',
        code: 'PR',
        codeExt: 'PRI',
        number: '630',
        locale: 'en-US',
        country: 'PR',
        active: true,
        value: '/us/en/',
        lang: 'en'
    },
    {
        countryName: 'Qatar',
        name: 'Qatar – العربية',
        code: 'QA',
        codeExt: 'QAT',
        number: '634',
        locale: 'ar-SA',
        country: 'QA',
        active: false,
        value: '/sa/ar/',
        lang: 'ar',
        activeInDev: true
    },
    {
        countryname: 'Qatar',
        code: 'QA',
        codeExt: 'QAT',
        number: '634',
        locale: 'ar-AC',
        active: false,
        value: '/ac/ar/',
        lang: 'ar',
        name: 'العربية – دولة قطر'
    },
    {
        countryName: 'Dominica',
        name: 'República Dominicana – Español',
        code: 'DM',
        codeExt: 'DMA',
        number: '212',
        locale: 'es-LA',
        country: 'DM',
        active: false,
        value: '/la/es/',
        lang: 'es'
    },
    {
        countryName: 'Réunion',
        name: 'Réunion – English',
        code: 'RE',
        codeExt: 'REU',
        number: '638',
        locale: 'en-AA',
        country: 'RE',
        active: false,
        value: '/aa/en/',
        lang: 'en'
        //"default": true
    },
    {
        countryname: 'Romania',
        code: 'RO',
        codeExt: 'ROU',
        number: '642',
        locale: 'en-EU',
        active: true,
        value: '/eu/en/',
        lang: 'en',
        name: 'România – English',
        altLocale: 'en-GB',
        default: true
    },
    {
        countryname: 'Russian Federation (the)',
        code: 'RU',
        codeExt: 'RUS',
        number: '643',
        locale: 'ru-RU',
        active: true,
        value: '/ru/ru/',
        lang: 'ru',
        activeInDev: true,
        name: 'Russia – Русский',
        default: true
    },
    {
        countryname: 'Saint Martin (French part)',
        code: 'MF',
        codeExt: 'MAF',
        number: '663',
        locale: 'en-US',
        active: false,
        value: '/us/en/',
        lang: 'en',
        name: 'Saint Maarten – English'
    },
    {
        countryName: 'Saudi Arabia',
        name: 'Saudi Arabia – العربية',
        code: 'SA',
        codeExt: 'SAU',
        number: '682',
        locale: 'ar-SA',
        country: 'SA',
        active: false,
        value: '/sa/ar/',
        lang: 'ar',
        activeInDev: true
    },
    {
        countryname: 'Saudi Arabia',
        code: 'SA',
        codeExt: 'SAU',
        number: '682',
        locale: 'ar-AC',
        active: false,
        value: '/ac/ar/',
        lang: 'ar',
        name: 'العربية – المملكة العربية السعودية'
    },
    {
        countryname: 'Switzerland',
        code: 'CH',
        codeExt: 'CHE',
        number: '756',
        locale: 'de-CH',
        active: true,
        value: '/ch/de/',
        lang: 'de',
        name: 'Schweiz – Deutsch',
        altLocale: 'de-DE'
    },
    {
        countryName: 'Serbia',
        name: 'Serbia – English',
        code: 'RS',
        codeExt: 'SRB',
        number: '688',
        locale: 'en-EU',
        country: 'RS',
        active: false,
        value: '/eu/en/',
        lang: 'en',
        altLocale: 'en-GB'
    },
    {
        countryName: 'Singapore',
        name: 'Singapore – English',
        code: 'SG',
        codeExt: 'SGP',
        number: '702',
        locale: 'en-AP',
        country: 'SG',
        active: false,
        value: '/ap/en/',
        lang: 'en'
        //"default": true
    },
    {
        countryname: 'Slovenia',
        code: 'SI',
        codeExt: 'SVN',
        number: '705',
        locale: 'en-EU',
        active: true,
        value: '/eu/en/',
        lang: 'en',
        name: 'Slovenija – English',
        altLocale: 'en-GB',
        default: true
    },
    {
        countryname: 'Slovakia',
        code: 'SK',
        codeExt: 'SVK',
        number: '703',
        locale: 'cs-CZ',
        active: true,
        value: '/cz/cs/',
        lang: 'cs',
        name: 'Slovensko – Česky'
    },
    {
        countryName: 'South Africa',
        name: 'South Africa – English',
        code: 'ZA',
        codeExt: 'ZAF',
        number: '710',
        locale: 'en-AA',
        country: 'ZA',
        active: false,
        value: '/aa/en/',
        lang: 'en'
    },
    {
        countryname: 'Saint Kitts and Nevis',
        code: 'KN',
        codeExt: 'KNA',
        number: '659',
        locale: 'en-US',
        active: false,
        value: '/us/en/',
        lang: 'en',
        name: 'St. Kitts and Nevis – English'
    },
    {
        countryname: 'Switzerland',
        code: 'CH',
        codeExt: 'CHE',
        number: '756',
        locale: 'fr-CH',
        active: true,
        value: '/ch/fr/',
        lang: 'fr',
        name: 'Suisse – Français',
        altLocale: 'fr-FR',
        default: true
    },
    {
        countryname: 'Finland',
        code: 'FI',
        codeExt: 'FIN',
        number: '246',
        locale: 'fi-FI',
        active: true,
        value: '/fi/fi/',
        lang: 'fi',
        name: 'Suomi - Suomeksi',
        default: true
    },
    {
        countryname: 'Switzerland',
        code: 'CH',
        codeExt: 'CHE',
        number: '756',
        locale: 'it-CH',
        active: true,
        value: '/ch/it/',
        lang: 'it',
        name: 'Svizzera – Italiano',
        altLocale: 'it-IT'
    },
    {
        countryname: 'Sweden',
        code: 'SE',
        codeExt: 'SWE',
        number: '752',
        locale: 'sv-SE',
        active: true,
        value: '/se/sv/',
        lang: 'sv',
        name: 'Sverige - Svensk',
        default: true
    },
    {
        countryName: 'Taiwan (Province of China)',
        name: 'Taiwan - 中文(台灣)',
        code: 'TW',
        codeExt: 'TWN',
        number: '158',
        locale: 'zh-TW',
        country: 'TW',
        active: true,
        value: '/tw/zh/',
        lang: 'zh',
        default: true
    },
    {
        countryName: 'Thailand',
        name: 'Thailand - ไทย',
        code: 'TH',
        codeExt: 'THA',
        number: '764',
        locale: 'th-TH',
        country: 'TH',
        active: true,
        value: '/th/th/',
        lang: 'th',
        default: true
    },
    {
        countryname: 'Turkey',
        code: 'TR',
        codeExt: 'TUR',
        number: '792',
        locale: 'tr-TR',
        active: false,
        value: '/tr/tr/',
        lang: 'tr',
        name: 'Türkiye – Türk'
        //"default": true
    },
    {
        countryName: 'United Arab Emirates (the)',
        name: 'United Arab Emirates – العربية',
        code: 'AE',
        codeExt: 'ARE',
        number: '784',
        locale: 'ar-SA',
        country: 'AE',
        active: false,
        value: '/sa/ar/',
        lang: 'ar',
        activeInDev: true
    },
    {
        countryName:
            'United Kingdom of Great Britain and Northern Ireland (the)',
        name: 'United Kingdom – English',
        code: 'GB',
        codeExt: 'GBR',
        number: '826',
        locale: 'en-GB',
        country: 'GB',
        active: true,
        value: '/gb/en/',
        lang: 'en',
        default: true
    },
    {
        countryName: 'United States Minor Outlying Islands (the)',
        name: 'United States – English',
        code: 'UM',
        codeExt: 'UMI',
        number: '581',
        locale: 'en-US',
        country: 'UM',
        active: true,
        value: '/us/en/',
        lang: 'en'
    },
    {
        countryName: 'Uruguay',
        name: 'Uruguay – Español',
        code: 'UY',
        codeExt: 'URY',
        number: '858',
        locale: 'es-LA',
        country: 'UY',
        active: false,
        value: '/la/es/',
        lang: 'es'
        //"default": true
    },
    {
        countryname: 'Viet Nam',
        code: 'VN',
        codeExt: 'VNM',
        number: '704',
        locale: 'vi-VN',
        active: true,
        value: '/vn/vi/',
        lang: 'vi',
        name: 'Vietnam – Việtnam',
        default: true
    },
    {
        countryname: 'Virgin Islands (U.S.)',
        code: 'VI',
        codeExt: 'VIR',
        number: '850',
        locale: 'en-US',
        active: true,
        value: '/us/en/',
        lang: 'en',
        name: 'Virgin Islands (USA) – English',
        default: true
    },
    {
        countryname: 'Ukraine',
        code: 'UA',
        codeExt: 'UKR',
        number: '804',
        locale: 'en-EU',
        active: false,
        value: '/eu/en/',
        lang: 'en',
        name: 'Украина – Aнглійська',
        altLocale: 'en-GB'
    },
    {
        countryname: 'United Arab Emirates (the)',
        code: 'AE',
        codeExt: 'ARE',
        number: '784',
        locale: 'ar-AC',
        active: false,
        value: '/ac/ar/',
        lang: 'ar',
        name: 'العربية - الامارات العربية المتحدة'
        //"default": true
    }
];
