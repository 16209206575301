import queryString from 'query-string';

import { diff } from '@/helpers/functions';
import { LOCATION_CHANGE } from './constants';
import { createLogger } from '@/helpers/debug';
const log = createLogger('query-middleware', false);

function parse(str) {
    return queryString.parse(str.replace(/,/g, '%252C'));
}

function stringify(obj) {
    return queryString.stringify(obj).replace(/%252C/g, ',');
}

function filterByLocation(query, location) {
    return !query.when || query.when(location);
}

function stripDefaults(queryArgs, history, urlState) {
    const location = history.location;
    return Object.keys(urlState).reduce((memo, key) => {
        const query = queryArgs[key];
        if (
            !query ||
            (filterByLocation(query, location) &&
                urlState[key] != query.defaultValue)
        ) {
            memo[key] = urlState[key];
        }

        return memo;
    }, {});
}

function trigger(action, delay = false) {
    if (typeof delay === 'number') {
        return setTimeout(action, delay);
    }
    action();
}

const RESTORE_STATE = '@@query-middleware/RESTORE_STATE';
export const restoreState = () => ({ type: RESTORE_STATE });

export default (history, queryArgs, options = { debounce: false }) => {
    let didInit = false;
    let timeoutId;
    let lastState = {};

    function getHandlers() {
        const location = history.location;
        return Object.entries(queryArgs).filter(([, query]) =>
            filterByLocation(query, location)
        );
    }

    function selectState(state) {
        return getHandlers().reduce((memo, [k, v]) => {
            memo[k] = v.selector(state);
            return memo;
        }, {});
    }

    function updateState(urlState) {
        const actions = getHandlers()
            .filter(([, query]) => typeof query.action === 'function')
            .map(([key, query]) => {
                const toValue =
                    typeof query.toValue === 'function'
                        ? query.toValue
                        : (v) => v;

                const value = urlState[key]
                    ? toValue(urlState[key])
                    : query.defaultValue;
                log('toValue', key, value);

                return query.action(value);
            })
            .filter((e) => e);

        log('updateState', JSON.stringify(urlState), actions);

        return actions;
    }

    function updateLocation(urlState, state) {
        const nextUrlState = getHandlers().reduce((memo, [k, v]) => {
            const toString =
                typeof v.toString === 'function' ? v.toString : (v) => `${v}`;

            const value = state[k] || v.defaultValue;
            const string = toString(value);

            memo[k] = string;
            return memo;
        }, {});

        log('updateLocation', JSON.stringify(urlState), nextUrlState);

        return nextUrlState;
    }

    return (store) => (next) => (action) => {
        const result = next(action);

        // on load, restore state from location
        if (!didInit) {
            log('init');
            didInit = true;
            store.dispatch(restoreState());
        }

        // on init or user-triggered restore
        if (action.type === RESTORE_STATE) {
            const { search, pathname } = history.location;

            log('restore state', 'pathname', pathname, 'search', search);

            if (search) {
                const urlState = parse(search);
                const actions = updateState(urlState);
                actions.forEach((action) => store.dispatch(action));
            }
            return result;
        }

        // ignore location changes
        if (action.type === LOCATION_CHANGE) {
            return result;
        }

        const shouldHandleAction = Object.values(queryArgs).some(
            (v) => !v.filter || v.filter(action)
        );

        const state = selectState(store.getState());
        const stateChanges = diff(lastState, state);
        lastState = state;

        // update url from state
        if (shouldHandleAction && Object.keys(stateChanges).length) {
            log('stateChanges', stateChanges);
            const updateUrlAction = () => {
                const { search } = history.location;
                const urlState = parse(search);
                const nextUrlState = updateLocation(urlState, state);

                const { pathname } = history.location;
                const nextSearch = stringify(
                    stripDefaults(queryArgs, history, {
                        ...urlState,
                        ...nextUrlState
                    })
                );

                log('replace', 'pathname', pathname, 'search', nextSearch);

                return history.replace({ pathname, search: nextSearch });
            };

            if (timeoutId) clearTimeout(timeoutId);
            timeoutId = trigger(updateUrlAction, options.debounce);
        }

        return result;
    };
};
