/******************************************************************************\
 * File: DealerSidebar.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import Sidebar from '@/components/common/Sidebar';
import DealerDetails from './DealerDetails';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class DealerSidebar extends React.Component {
    render() {
        const { dealerName: name, history } = this.props;
        return (
            <Sidebar contentLabel={name || 'Harley-Davidson'} {...this.props}>
                <DealerDetails {...this.props} history={history} />
            </Sidebar>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default DealerSidebar;
