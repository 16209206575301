/******************************************************************************\
 * File: MapLayer.jsx
 *
 * Author: Gigster
 *
 * Description: Map layer icon
 *
 * Notes: Image tiles are from `/13/2095/3009/512/png8` and are 384px x 220px
 *        cropped from the top left
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import style from '@/style/common/map/icons/MapLayer.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { MapSchemes } from '@/helpers/constants';
import { clickAway } from '@/helpers/hoc';
import { translate, getLocale } from '@/helpers/i18n';
import Checkbox from '@/components/common/form/Checkbox';
const t = translate('helpers.constants');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class MapLayer extends React.Component {
    static defaultProps = {
        value: 'normal',
        onChange: () => {}
    };

    // Allows the HD Custom Map Style scheme to be shown globally, except for Japan
    static getExtendedMapSchemes = () => {
        const allowCustomHDStyle = !getLocale().includes('Japan');
        if (allowCustomHDStyle) {
            // Replace CLASSIC style with HD CUSTOM vector style
            return {
                NORMAL: MapSchemes.NORMAL,
                CUSTOM: {
                    name: 'H-D',
                    type: 'h-d',
                    id: 'custom',
                    tileType: 'base'
                },
                SATELLITE: MapSchemes.SATELLITE
            };
        }
        return MapSchemes;
    };

    static Layer = ({ name, type, selected, onClick }) => (
        <div
            className={style.layer}
            onClick={onClick}
            id={`map-${type}`.toLowerCase()}
            data-testid={`map-${type}`.toLowerCase()}>
            {selected && <div className={style.selected} />}

            <div className={style.name}>{name}</div>

            <img className={style.img} src={`/img/map-type-${type}.png`} />
        </div>
    );

    static getSchemeFromType = (type) => {
        const extendedMapSchemes = MapLayer.getExtendedMapSchemes();
        return extendedMapSchemes[
            Object.keys(extendedMapSchemes).find(
                (scheme) =>
                    extendedMapSchemes[scheme].id === type ||
                    extendedMapSchemes[scheme].trafficId === type
            )
        ];
    };

    state = {
        expanded: false
    };

    open = () => {
        this.setState({ expanded: true });
    };

    close = () => {
        this.setState({ expanded: false });
    };

    onChange = (type) => {
        const { value, onChange } = this.props;
        if (type !== value) onChange(type);

        this.close();
    };

    onClickAway = () => {
        this.close();
    };

    toggleTraffic = () => {
        const traffic = !this.state.traffic;
        this.setState({ traffic });

        const { onChangeOpts, opts } = this.props;

        onChangeOpts({ ...opts, traffic });
    };

    render() {
        const {
            className,
            value,
            opts: { traffic }
        } = this.props;

        const { expanded } = this.state;

        const cn = classNames(style.MapLayer, {
            [className]: className
        });
        const extendedMapSchemes = MapLayer.getExtendedMapSchemes();

        return (
            <div className={cn}>
                {expanded && (
                    <div
                        className={classNames(style.layer, style.toggle)}
                        style={{ justifyContent: 'space-around' }}>
                        <span className={style.label}>{t('Traffic')}</span>
                        <Checkbox
                            onChange={this.toggleTraffic}
                            checked={traffic}
                        />
                    </div>
                )}
                {(expanded &&
                    Object.keys(extendedMapSchemes).map((scheme, i) => (
                        <MapLayer.Layer
                            {...extendedMapSchemes[scheme]}
                            selected={value === extendedMapSchemes[scheme].id}
                            onClick={() => {
                                this.onChange(extendedMapSchemes[scheme].id);
                            }}
                            key={i}
                        />
                    ))) || (
                    <MapLayer.Layer
                        {...MapLayer.getSchemeFromType(value)}
                        selected={undefined}
                        onClick={this.open}
                    />
                )}
            </div>
        );
    }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default clickAway(MapLayer);
